import React, { Suspense } from 'react';
import { IconButton } from '@mui/material';
import JumboVerticalNavbar from '@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar';
import { DrawerHeader } from '@jumbo/components/JumboLayout/style';
import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import useJumboSidebarTheme from '@jumbo/hooks/useJumboSidebarTheme';
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from '@jumbo/utils/constants/layout';
import Logo from '../../../../shared/Logo';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Zoom from '@mui/material/Zoom';
import Div from '@jumbo/shared/Div';
import { useJumboLayoutSidebar } from '@jumbo/components/JumboLayout/hooks';
import { useSelector } from 'react-redux';
import AdminMenuPreMatricula from './menus/AdminMenu';
import menus from '../Sidebar/menus';
import SidebarSkeleton from '../Sidebar/SidebarSkeleton';

const PreMatriculaSideBar = () => {
  const { roleName, permisos } = useSelector(({ auth }) => auth);
  const [menuItems, setMenuItems] = React.useState(menus);

  const userPermisos = permisos.reduce(
    (obj, item) => ({
      ...obj,
      [item.permiso.type]: { id: item._id, estado: item.estado },
    }),
    {}
  );

  React.useEffect(() => {
    const roleMenus = {
      Superadministrador: AdminMenuPreMatricula,
    };

    const updatedMenuItems = roleMenus[roleName] || menus;
    setMenuItems(updatedMenuItems);
  }, [roleName, setMenuItems]);

  return (
    <React.Fragment>
      <SidebarHeader />
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                alignContent: 'center',
                px: 3,
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          <JumboVerticalNavbar translate items={menuItems} />
        </Suspense>
      </JumboScrollbar>
    </React.Fragment>
  );
};

const SidebarHeader = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { sidebarTheme } = useJumboSidebarTheme();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <DrawerHeader
          sx={{
            pb: 0,
          }}
        >
          <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />
          {sidebarOptions?.view !== SIDEBAR_VIEWS.MINI && (
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                onClick={() => setSidebarOptions({ open: false })}
              >
                <MenuOpenIcon />
              </IconButton>
            </Zoom>
          )}
        </DrawerHeader>
      )}
    </React.Fragment>
  );
};

export default PreMatriculaSideBar;
