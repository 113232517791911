import Div from '@jumbo/shared/Div';
import { Grid } from '@mui/material';
import CustomDataGrid from 'app/shared/CustomDataGrid/CustomDataGrid';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import Spinner from 'app/shared/Spinner';
import React from 'react';
import { useUserColumns } from '../hooks/useUserColumns';
import useGetUsers from 'app/services/users/getUsers';

const UsersList = () => {
  const columns = useUserColumns();
  const { data: users, isLoading } = useGetUsers();
  return (
    <Grid item xs={12}>
      {isLoading ? (
        <Div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: { xs: 300, xl: 600 },
          }}
        >
          <Spinner />
        </Div>
      ) : users?.length > 0 ? (
        <CustomDataGrid
          columns={columns}
          data={users}
          br={0}
          id="_id"
          height={{ xs: 630, sm: 1000, md: 600, xl: 775 }}
          sortModel={[{ field: 'name', sort: 'asc' }]}
          isLoading={isLoading}
        />
      ) : (
        <NoDataFound2 label="No se encontraron resultados para la busqueda" />
      )}
    </Grid>
  );
};

export default UsersList;
