import React from 'react';
import { Card, CardActions, CardContent, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';

const CardCurso = ({ curso }) => {
  const backgroundImage =
    curso?.estado === 'Cancelado por Inasistencia'
      ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
      : curso?.estado === 'Finalizada'
      ? 'linear-gradient(45deg, #3BD2A2 30%, #40eb5b 90%)'
      : curso?.estado === 'En Curso'
      ? 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
      : 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)';

  return (
    <Card
      sx={{
        height: 150,
        backgroundImage: backgroundImage,
      }}
    >
      <CardActions
        disableSpacing
        sx={{ p: 0, alignItems: 'stretch', height: '100%' }}
      >
        <Div
          sx={{
            display: 'flex',
            width: 126,
            justifyContent: 'flex-end',
            alignItems: 'center',
            backgroundColor: 'common.white',
            borderRadius: '50%',
            outline: 'solid 10px rgba(255, 255, 255, 0.2)',
            margin: '0 10px 0 -60px',
          }}
        >
          <Div
            sx={{
              display: 'flex',
              minWidth: 56,
              height: 56,
              mr: '6px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <GraphicEqIcon sx={{ fontSize: 36 }} />
          </Div>
        </Div>
        <CardContent sx={{ p: 2.5, flex: 1, alignSelf: 'center' }}>
          <Typography variant={'h4'} color={'common.white'}>
            <strong>{curso?.asignatura}</strong>
          </Typography>
          <Typography
            className="text-white text-nowrap text-truncate"
            variant="body2"
            color={'common.white'}
          >
            {curso?.periodo}
          </Typography>

          <Typography variant={'h6'} color={'common.white'} mb={0}>
            {curso?.estado}
          </Typography>
        </CardContent>
      </CardActions>
    </Card>
  );
};

export default CardCurso;
