import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const updateDocente = async ({ id, dataDocente }) => {
  const { data } = await client.patch(`/profesor/update/${id}`, dataDocente);
  return data;
};

export default function useUpdateDocente() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateDocente, {
    onSuccess: (data) => {
      console.log(data, 'data actualizada');
      queryClient.invalidateQueries([data?.sede, 'docentesBySede']);
      queryClient.invalidateQueries('docentes');
      enqueueSnackbar(`Actualizacion exitosa`, {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al actualizar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
}
