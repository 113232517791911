import client from '../config';
import { useMutation } from 'react-query';

export const verifyDate = async ({ fechaReserva, dataForm }) => {
  console.log(fechaReserva, 'reserva');
  const { data } = await client.post(
    `reserve/verify-user/${fechaReserva}`,
    dataForm
  );
  return data;
};

export default function useVerifyDate() {
  return useMutation(verifyDate, {
    onSuccess: (data) => {
      console.log(data, 'reservaa');
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
    },
  });
}

export const verifyUserProgramDate = async ({
  fechaReserva,
  programCode,
  dataForm,
}) => {
  console.log(dataForm, 'reserva');
  const { data } = await client.post(
    `/reserve/verify-user-for-program/${fechaReserva}/program/${programCode}`,
    dataForm
  );
  return data;
};

export function useVerifyUserProgramDate() {
  return useMutation(verifyUserProgramDate, {
    onSuccess: (data) => {
      console.log(data, 'reservaa');
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
    },
  });
}
