import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { amber, blue, green, grey, red } from '@mui/material/colors';
import FuseSvgIcon from 'app/shared/FuseSvgIcon';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import ExcelViewer from './ExcelViewer';
import moment from 'moment';
import useGetPeriodoBySede from 'app/services/periodo/getPeriodoBySede';

const TypeBadge = styled(Box)(({ theme, ...props }) => ({
  backgroundColor: {
    PDF: red[600],
    DOC: blue[600],
    XLS: green[600],
    TXT: grey[600],
    JPG: amber[600],
  }[props.color],
}));
function FileItem(props) {
  const { item } = props;
  const { showDialog, hideDialog } = useJumboDialog();
  const { data: periodos, isLoading: isLoadingPeriodos } = useGetPeriodoBySede(
    item?.sede
  );
  if (!item) {
    return null;
  }
  const periodo = periodos?.find(
    (p) => p.Consecutivo === parseInt(item?.periodo)
  );
  const handleShowDetail = () => {
    showDialog({
      title: `Pre-matricula - ${item?.date && moment(item.date).format('DD/MM/YYYY')}`,
      content: <ExcelViewer urlFile={item.url} />,
      maxWidth: 'xl',
    });
  };

  return (
    <Box
      sx={{ backgroundColor: 'background.paper' }}
      className="flex flex-col relative w-full sm:w-160 h-160 m-8 p-16 shadow rounded-16 cursor-pointer"
      onClick={handleShowDetail}
    >
      <div className="flex flex-auto w-full items-center justify-center">
        <div className="relative">
          <FuseSvgIcon className="" size={90} color="disabled">
            heroicons-outline:document
          </FuseSvgIcon>
          <TypeBadge
            color={'XLS'}
            className="absolute left-0 bottom-0 px-6 rounded text-10 font-semibold leading-20 text-white"
          >
            {'XLS'}
          </TypeBadge>
        </div>
      </div>
      <div className="flex shrink flex-col justify-center text-center">
        <Typography className="truncate text-12 font-medium">
          Pre-matricula - {item?.date && moment(item.date).format('DD/MM/YYYY')}
        </Typography>
        <Typography
          className="truncate text-12 font-medium"
          color="text.secondary"
        >
          Periodo {periodo?.Nombre}
        </Typography>
      </div>
    </Box>
  );
}

export default FileItem;
