import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  FormHelperText,
  IconButton,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Div from '@jumbo/shared/Div';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useSnackbar } from 'notistack';
import { alpha, useTheme } from '@mui/material/styles';
import bgImg from '../../../assets/images/bg_mixer.jpg';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useSendCodeChangePass from 'app/services/auth/sendCodeChangePass';
import useUpdatePassword from 'app/services/auth/updatePassword';

const RecoveryPassword = () => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { enqueueSnackbar } = useSnackbar();
  const [correo, setCorreo] = useState('');
  const { mutate: sendCodeEmail, isLoading: isSendingCode } =
    useSendCodeChangePass();
  const [error, setError] = useState(false);

  const { mutate: updatePassword } = useUpdatePassword();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClick = (path) => {
    navigate(path);
  };
  const handleSendcode = ({ email }) => {
    setCorreo(email);
    sendCodeEmail(email, { onSuccess: handleNext });
  };

  const verifyEmail = (code) => {
    // verificar si el codigo es correcto
    if (code.length === 4) {
      const resetCode = localStorage.getItem('resetCode');
      console.log(resetCode, 'resetCode is');
      if (code !== resetCode) {
        enqueueSnackbar('El codigo no es correcto', { variant: 'error' });
        setError(true);
        return;
      }
      localStorage.removeItem('resetCode');
      enqueueSnackbar('Codigo correcto', { variant: 'success' });
      handleNext();
    }
  };

  const handleNewPassword = (user) => {
    const { password2, ...resto } = user;
    if (user.passWord !== password2) {
      enqueueSnackbar('Las contraseñas no coinciden', { variant: 'error' });
      return;
    }
    updatePassword(resto, { onSuccess: navigate('/user/login') });
  };
  return (
    <Div sx={{ width: 720, maxWidth: '100%', margin: 'auto', p: 4 }}>
      {isDesktop && (
        <>
          <Box
            component={'img'}
            src="/logo_horizontal.png"
            sx={{
              position: 'absolute',
              top: { md: 90, xl: '15%' },
              left: '50%', // Centra horizontalmente en el centro del contenedor padre
              transform: 'translate(-50%, -50%)',
              width: { md: 200, xl: 300 },
              objectPosition: 'top',
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            }}
          />
          <Box
            component={'img'}
            src="/backgrounds/bg_purple1.png"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: '100%',
              height: '50vh',
              objectPosition: 'center',
              zIndex: -1,
              // msTransform: 'scaleX(-1)',
            }}
          />
          <Box
            component={'img'}
            src="/icons/SVG/LogoDNA.svg"
            sx={{
              position: 'absolute',
              top: '30%', // Centra verticalmente en el centro del contenedor padre
              left: '50%', // Centra horizontalmente en el centro del contenedor padre
              transform: 'translate(-50%, -50%)', // Centra completamente
              zIndex: -2,
              width: '60%',
            }}
          />

          {/* <Box
        component={'img'}
        src="/backgrounds/bg_purple2.png"
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '50%',
          height: '100%',
          objectPosition: 'top',
          zIndex: -1,
          // msTransform: 'scaleX(-1)',
        }}
      /> */}
        </>
      )}
      <Card
        sx={{
          marginTop: { xs: 4, md: 8 },
          display: 'flex',
          minWidth: 0,
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <CardContent
          sx={{
            flex: '0 1 300px',
            position: 'relative',
            background: `#222328ff url(${bgImg}) no-repeat center`,
            backgroundSize: 'cover',

            '&::after': {
              display: 'inline-block',
              position: 'absolute',
              content: `''`,
              inset: 0,
              backgroundColor: (theme) =>
                alpha(theme.palette.secondary.dark, 0.72),
            },
          }}
        >
          <Div
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              color: 'common.white',
              position: 'relative',
              zIndex: 1,
              height: '100%',
              minHeight: { md: 320 },
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={'h3'}
                color={'inherit'}
                fontWeight={500}
                mb={3}
              >
                Recuperar Contraseña
              </Typography>
              <Typography variant={'body1'} mb={1} sx={{ maxWidth: 270 }}>
                Envíaremos un código de verificación a tu correo electrónico,
                para que puedas cambiar tú contraseña de forma segura.
              </Typography>
            </Div>
            <Div sx={{ mt: 'auto' }}>
              <Link
                href="/user/login"
                underline="none"
                sx={{ display: 'inline-flex' }}
              >
                <Box
                  component="img"
                  src={'/logo_horizontal.png'}
                  sx={{ height: 50 }}
                  mr={1}
                />
              </Link>
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          <Div
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              sx={{
                mb: 2,
              }}
            >
              <Step>
                <form onSubmit={handleSubmit(handleSendcode)}>
                  <StepLabel>
                    {activeStep === 0
                      ? 'Ingresa tu correo'
                      : 'Ingresa el codigo enviado a tu correo'}
                  </StepLabel>
                  <StepContent>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: ' Email invalido',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          //   disabled={isLoading}
                          label="Email"
                          type="email"
                          placeholder="Esciba su email"
                          fullWidth
                          helperText={errors.email?.message}
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref}
                          error={!!errors.email}
                        />
                      )}
                    />
                    <Box sx={{ my: 2 }}>
                      <Button
                        fullWidth
                        disabled={isSendingCode}
                        startIcon={
                          isSendingCode ? <CircularProgress size={20} /> : null
                        }
                        color="primary"
                        type="submit"
                        variant="contained"
                        sx={{ mt: 1, mr: 1 }}
                      >
                        solicitar codigo
                      </Button>
                    </Box>
                  </StepContent>
                </form>
              </Step>
              <Step>
                {activeStep === 1 && (
                  <StepLabel
                    optional={
                      <Typography variant="caption">
                        Ingrese el codigo de verificación
                      </Typography>
                    }
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Typography variant="h6" component="div">
                        {correo}
                      </Typography>
                      <IconButton onClick={handleBack}>
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </StepLabel>
                )}
                <StepContent>
                  <Controller
                    name="code"
                    control={control}
                    rules={{
                      required: true,
                      minLength: {
                        value: 4,
                        message: 'Codigo invalido',
                      },
                    }}
                    render={({ field }) => (
                      <Box>
                        <MuiOtpInput
                          sx={{ gap: 1 }}
                          onChange={(otp) => {
                            field.onChange(otp);
                            verifyEmail(otp);
                          }}
                          value={field.value}
                          autoFocus
                          TextFieldsProps={{
                            // disabled: isLoadingVerify,
                            placeholder: '-',
                            error: error,
                          }}
                          validateChar={(character, index) => true}
                          length={4}
                        />
                        {error ? (
                          <FormHelperText sx={{ mt: 2 }} error>
                            Codigo invalido, intente de nuevo
                          </FormHelperText>
                        ) : null}
                      </Box>
                    )}
                  />
                </StepContent>
              </Step>
              <Step>
                {activeStep === 2 && (
                  <StepLabel
                    optional={
                      <Typography variant="caption">
                        Establece una nueva contraseña
                      </Typography>
                    }
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Typography variant="h6" component="div">
                        {correo}
                      </Typography>
                      <IconButton onClick={() => setActiveStep(0)}>
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </StepLabel>
                )}
                <StepContent>
                  <form onSubmit={handleSubmit(handleNewPassword)}>
                    <Stack spacing={2} sx={{ my: 2 }}>
                      <Controller
                        name="passWord"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'La contraseña es requerida',
                          },
                          minLength: {
                            value: 8,
                            message:
                              'La contraseña debe tener al menos 8 caracteres',
                          },
                          pattern: {
                            value:
                              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_.+])/,
                            message:
                              '*La contraseña debe contener al menos un dígito, una letra mayúscula y un carácter especial (e.g. !@#$%^&*()_.+).',
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Contraseña"
                            type="password"
                            placeholder="Esciba su contraseña"
                            fullWidth
                            helperText={errors.passWord?.message}
                            error={!!errors.passWord}
                          />
                        )}
                      />
                      <Controller
                        name="password2"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: {
                            value: true,
                            message: 'La contraseña es requerida',
                          },
                          minLength: {
                            value: 8,
                            message:
                              'La contraseña debe tener al menos 8 caracteres',
                          },
                          pattern: {
                            value:
                              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_.+])/,
                            message:
                              '*La contraseña debe contener al menos un dígito, una letra mayúscula y un carácter especial (e.g. !@#$%^&*()_.+).',
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            label="Confirmar contraseña"
                            type="password"
                            placeholder="Contraseña"
                            fullWidth
                            helperText={
                              errors.password2?.type === 'minLength' &&
                              errors.password2?.message
                            }
                            value={field.value}
                            onChange={field.onChange}
                            inputRef={field.ref}
                            error={!!errors.password2}
                          />
                        )}
                      />
                      <Div
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row' },
                          alignItems: 'center',
                          gap: 1,
                          justifyContent: 'space-between',
                        }}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          sx={{ mt: 1 }}
                          onClick={() => setActiveStep(0)}
                        >
                          CANCELAR
                        </Button>
                        <Button
                          fullWidth
                          color="primary"
                          variant="contained"
                          sx={{ mt: 1 }}
                          type="submit"
                          //   disabled={isLoading}
                        >
                          CONFIRMAR
                        </Button>
                      </Div>
                    </Stack>
                  </form>
                </StepContent>
              </Step>
            </Stepper>
            <Typography variant={'body1'} textAlign={'right'}>
              ¿Ya tienes una cuenta?{' '}
              <Link
                href="/user/login"
                color="primary.dark"
                fontWeight={600}
                fontStyle={'italic'}
                underline={'hover'}
              >
                {' '}
                Iniciar Sesión
              </Link>{' '}
            </Typography>
          </Div>
        </CardContent>
      </Card>
    </Div>
  );
};

export default RecoveryPassword;
