import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  Button,
  CardActions,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import useGetPermisosByUser from 'app/services/users/getPermisosByUser';
import useGetPermisos from 'app/services/permissions/getPermisos';
import Spinner from 'app/shared/Spinner';
import { CustomCheckbox } from 'app/shared/ReactHookForm/CustomCheckbox';
import { useEffect } from 'react';
import useUpdateUserPermisos from 'app/services/users/updateUserPermisos';

const EditarPermisos = ({ user, onClose }) => {
  const { data: permisosByUser, isLoading: isLoadingPermisosByUser } =
    useGetPermisosByUser(user?._id);
  const { data: permisos, isLoading: isLoadingPermisos } = useGetPermisos();

  const { mutate: updatePermisos, isLoading } = useUpdateUserPermisos();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {},
  });
  const {
    control,
    reset,
    formState: { isValid, dirtyFields },
  } = methods;
  const onSubmit = (dataUser) => {
    const permisosToSend = Object.entries(dataUser)
      .map(([key, value]) => ({
        permiso: key,
        estado: value,
      }))
      .filter((permiso) => permiso.estado);
    updatePermisos(
      {
        userId: user?._id,
        permisos: permisosToSend?.map((permiso) => permiso?.permiso),
      },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };
  useEffect(() => {
    const permisosUser = permisosByUser?.reduce((acc, permiso) => {
      acc[permiso?.permiso] = permiso?.estado ? true : false;
      return acc;
    }, {});
    reset(permisosUser);
  }, [permisosByUser, reset]);

  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Editar Usuario: <strong>{user?.name}</strong>`
          </Typography>
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      />
      {isLoadingPermisosByUser || isLoadingPermisos ? (
        <Div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Div>
      ) : (
        <FormProvider {...methods}>
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: 'background.default',
              px: 2,
              pb: 2,
            }}
          >
            {permisos?.map((permiso) => (
              <Grid item xs={12} key={permiso?._id}>
                <CustomCheckbox
                  control={control}
                  name={permiso?._id}
                  label={permiso?.type}
                />
              </Grid>
            ))}
          </Grid>
          <CardActions
            sx={{
              justifyContent: 'flex-end',
              p: (theme) => theme.spacing(1.5, 2),
              pl: 3,
            }}
          >
            <Button onClick={onClose} color="secondary" sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <Button
              startIcon={isLoading && <CircularProgress size={16} />}
              variant="contained"
              disabled={!isValid || _.isEmpty(dirtyFields) || isLoading}
              onClick={methods.handleSubmit(onSubmit)}
              sx={{ mr: 1 }}
            >
              {isLoading ? 'Guardando...' : 'Guardar'}
            </Button>
          </CardActions>
        </FormProvider>
      )}
    </Div>
  );
};

export default EditarPermisos;
