import { ArrowDropDown } from '@mui/icons-material';
import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import useGetProgramasBySede from 'app/services/programas/getProgramasBySede';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

const ProgramasPrerequisitos = ({ prerequisito, selectedSede, index }) => {
  const { data: programasBysede, isLoading: isLoadingProgramas } =
    useGetProgramasBySede(selectedSede?._id || selectedSede);
  const { control } = useFormContext();
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: `prerequisitos.${index}.programas`,
  });

  useEffect(() => {
    console.log('fields', fields);
  }, [fields, append, remove]);
  return (
    <>
      <Grid item xs={12}>
        <Typography
          className="font-bold text-1xl text-center sm:text-left "
          sx={{ mb: 2 }}
        >
          Programas del Prerequisito {prerequisito?.materiaName}
        </Typography>
        <Autocomplete
          id={'prerequisitos.' + index + '.programas'}
          onChange={(event, newValue) => {
            if (newValue.length > fields.length) {
              const newProgram = newValue[newValue.length - 1];
              append({ _id: newProgram?._id, name: newProgram?.name });
            } else {
              const removedIndex = fields.findIndex(
                (field) =>
                  !newValue.find((programa) => programa._id === field._id)
              );
              remove(removedIndex);
            }
          }}
          loadingText="Cargando..."
          multiple
          value={fields}
          disableClearable
          loading={isLoadingProgramas}
          getOptionLabel={(option) => option?.name}
          noOptionsText="No hay opciones"
          isOptionEqualToValue={(option, value) => option._id === value._id}
          options={programasBysede || []}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option?.name}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onKeyDown={(event) => {
                if (event.key === 'Backspace' || event.key === 'Enter') {
                  event.stopPropagation();
                }
              }}
              placeholder={`Seleccione los programas del prerequisito ${prerequisito?.materiaName}`}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoadingProgramas && (
                      <CircularProgress color="inherit" size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default ProgramasPrerequisitos;
