import { useMutation, useQuery } from 'react-query';
import client from '../config';

export const getHistorialPrematricula = async ({ idSede, periodo }) => {
  const query = periodo
    ? `?periodo=${periodo}`
    : idSede
    ? `?sede=${idSede}`
    : periodo && idSede
    ? `?sede=${idSede}&periodo=${periodo}`
    : '';
  console.log(query);
  const { data } = await client.get(`/pre-matricula/get-files${query}`);
  return data;
};

export const useGetHistorialPrematricula = ({ idSede, periodo }) => {
  return useQuery(['historialPrematricula', idSede, periodo], () =>
    getHistorialPrematricula({ idSede, periodo })
  );
};
export const useGetHistorialPrematriculaMutate = () => {
  return useMutation((data) => getHistorialPrematricula(data));
};
