import { useMutation, useQuery } from 'react-query';
import client from '../config';

export const getHorarioBySede = async (idSede) => {
  const { data } = await client.get(`/horario/all-bySede/${idSede}`);
  return data;
};

export default function useGetHorarioBySede(idSede) {
  return useQuery([idSede, 'horarioBySede'], () => getHorarioBySede(idSede), {
    refetchOnWindowFocus: false,
    onError: (error) => {
      console.log(error.response.data);
    },
  });
}

export const useGetHorarioBySedeMutation = () => {
  return useMutation((idSede) => getHorarioBySede(idSede));
};
