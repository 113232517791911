import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ClassIcon from '@mui/icons-material/Class';
import RefreshIcon from '@mui/icons-material/Refresh';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BallotIcon from '@mui/icons-material/Ballot';
import { useForm } from 'react-hook-form';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import useGetSedes from 'app/services/sedes/getSedes';
import useGetActualizarMateriasQ10 from 'app/services/actualizar-datos-q10/actualizarMateriasQ10';
import useActualizarDocentesQ10 from 'app/services/actualizar-datos-q10/actualizarDocentesQ10';
import useActualizarProgramasQ10 from 'app/services/actualizar-datos-q10/actualizarProgramasQ10';
const opciones = [
  {
    title: 'Actualizar Materias desde Q10',
    subtitle: 'Actualiza las materias de la sede seleccionada',
    icon: <ClassIcon sx={{ fontSize: 20 }} />,
    tipo: 'materias',
  },
  {
    title: 'Actualizar Docentes desde Q10',
    icon: <ManageAccountsIcon sx={{ fontSize: 20 }} />,
    subtitle: 'Actualiza los docentes de la sede seleccionada',
    tipo: 'docentes',
  },
  {
    title: 'Actualizar Programas desde Q10',
    icon: <BallotIcon sx={{ fontSize: 20 }} />,
    subtitle: 'Actualiza los programas de la sede seleccionada',
    tipo: 'programas',
  },
];
const ActualizarDatosQ10Page = () => {
  const navigate = useNavigate();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const { mutate: actualizarMaterias, isLoading: isLoadingMaterias } =
    useGetActualizarMateriasQ10();
  const { mutate: actualizarDocentes, isLoading: isLoadingDocentes } =
    useActualizarDocentesQ10();
  const { mutate: actualizarProgramas, isLoading: isLoadingProgramas } =
    useActualizarProgramasQ10();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      idSede: '',
    },
  });
  const { control, handleSubmit } = methods;
  const onSubmit = async (tipo, data) => {
    try {
      // Este método realiza la validación del formulario
      await handleSubmit(({ idSede }) => {
        switch (tipo) {
          case 'materias':
            actualizarMaterias(idSede);
            break;
          case 'docentes':
            actualizarDocentes(idSede);
            break;
          case 'programas':
            actualizarProgramas(idSede);
            break;
          default:
            break;
        }
      })(data);
    } catch (error) {
      console.error('Error de validación:', error);
    }
  };

  return (
    <Card sx={{ mt: 4 }}>
      <CardContent>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
              <IconButton
                onClick={() => navigate('/panel-control', { replace: true })}
              >
                <ArrowBack />
              </IconButton>
              ACTUALIZAR DATOS DESDE Q10
            </Typography>
          </Grid>
          <Grid item xs={12} px={3}>
            <Typography variant="h6" gutterBottom mb={2}>
              1. Selecione la sede:
            </Typography>
            <CustomAutoComplete
              isRequired
              control={control}
              name="idSede"
              label="Sedes"
              options={sedes?.map((sede) => ({
                id: sede._id,
                ...sede,
              }))}
              isLoading={isLoadingSedes}
              getOptionLabel={(option) => option?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <List className="py-0 mt-8 divide-y">
              {opciones.map((opcion) => (
                <ListItem className="px-0" key={opcion.tipo}>
                  <ListItemText
                    classes={{ root: 'px-8', primary: 'font-bold' }}
                    primary={
                      <span className="font-medium capitalize">
                        {opcion.title}
                      </span>
                    }
                    secondary={
                      <span className="flex flex-col sm:flex-row sm:items-center -ml-2 mt-8 sm:mt-4 space-y-4 sm:space-y-0 sm:space-x-12">
                        <span className="flex items-center">
                          {opcion.icon}
                          <Typography
                            component="span"
                            className="mx-16 text-md "
                            color="text.secondary"
                            sx={{ ml: 1 }}
                          >
                            {opcion.subtitle}
                          </Typography>
                        </span>
                      </span>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Button
                      sx={{ ml: 1 }}
                      startIcon={
                        isLoadingMaterias && opcion.tipo === 'materias' ? (
                          <CircularProgress size={20} />
                        ) : isLoadingDocentes && opcion.tipo === 'docentes' ? (
                          <CircularProgress size={20} />
                        ) : isLoadingProgramas &&
                          opcion.tipo === 'programas' ? (
                          <CircularProgress size={20} />
                        ) : (
                          <RefreshIcon />
                        )
                      }
                      disabled={
                        (isLoadingMaterias && opcion.tipo === 'materias') ||
                        (isLoadingDocentes && opcion.tipo === 'docentes') ||
                        (isLoadingProgramas && opcion.tipo === 'programas')
                      }
                      color="secondary"
                      variant="contained"
                      onClick={() => onSubmit(opcion.tipo, methods.getValues())}
                    >
                      {isLoadingMaterias && opcion.tipo === 'materias' ? (
                        <span>Actualizando...</span>
                      ) : isLoadingDocentes && opcion.tipo === 'docentes' ? (
                        <span>Actualizando...</span>
                      ) : isLoadingProgramas && opcion.tipo === 'programas' ? (
                        <span>Actualizando...</span>
                      ) : (
                        <span>Actualizar</span>
                      )}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ActualizarDatosQ10Page;
