import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const createMonitor = async (user) => {
  const { data } = await client.post(`/user/register-monitor`, user);
  return data;
};

export default function useCreateMonitorUser() {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(createMonitor, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('users');
      enqueueSnackbar('usuario creado correctamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      enqueueSnackbar('Error al crear el usuario', {
        variant: 'error',
      });
    },
  });
}
