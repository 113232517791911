import client from '../config';
import { useMutation } from 'react-query';

export const getReservaByAulaHorario = async (dataForm) => {
  console.log(dataForm, 'reserva');
  const { data } = await client.post(
    `/student-reserva/reservas-by-aulaHorario`,
    dataForm
  );
  return data;
};

export default function useGetReservaByAulaHorario() {
  return useMutation(getReservaByAulaHorario, {
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
    },
  });
}
