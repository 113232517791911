import React, { useState } from 'react';

export const SignUpContext = React.createContext();

const SignUpProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const contextValue = {
    user,
    setUser,
  };
  return (
    <SignUpContext.Provider value={contextValue}>
      {children}
    </SignUpContext.Provider>
  );
};

export default SignUpProvider;
