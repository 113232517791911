import { useQuery } from 'react-query';
import client from '../config';

const getUsers = async () => {
  try {
    const response = await client.get('/user/get-usersAll');
    const { data } = response;
    return data;
  } catch (e) {
    return e.response;
  }
};

export default function useGetUsers() {
  return useQuery(['users'], getUsers, { refetchOnWindowFocus: false });
}
