import { useMutation, useQuery, useQueryClient } from 'react-query';
import client from '../config';

export const getHorariosPrerequisitoBySede = async (idSede) => {
  if (!idSede) return [];
  const { data } = await client.get(
    `/horario-prerequisitos/all-by-sede/${idSede}`
  );
  return data;
};

export default function useGetHorariosPrerequisitoBySede(idSede) {
  return useQuery(
    [idSede, 'horariosBySede'],
    () => getHorariosPrerequisitoBySede(idSede),
    {
      onError: (error) => {
        console.log(error.response.data);
      },
    }
  );
}

export const useGetHorariosPrerequisitoBySedeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((idSede) => getHorariosPrerequisitoBySede(idSede), {
    onSuccess: (data) => {
      if (data.length > 0)
        queryClient.setQueryData([data[0]?.sede, 'horariosBySede'], data);
    },
  });
};
