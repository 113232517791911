import { useMutation } from 'react-query';
import client from '../config';

export const getAulasBySubjectAdmin = async ({ subjectName, idSede }) => {
  try {
    const { data } = await client.get(
      `/subject/aulas-bySubject/${subjectName}/${idSede}`
    );
    return data;
  } catch (error) {
    console.log('error', error);
    return [];
  }
};

export const useGetAulasBySubjectAdmin = () => {
  return useMutation((student) => getAulasBySubjectAdmin(student));
};
