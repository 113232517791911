import { useQuery } from 'react-query';
import client from '../config';

export const getPermisosByUser = async (id) => {
  const { data } = await client.get(`/admin/permisos-by-admin/${id}`);
  return data;
};

export default function useGetPermisosByUser(id) {
  return useQuery([id, 'permisosByUser'], () => getPermisosByUser(id), {
    refetchOnWindowFocus: false,
    onError: (error) => {
      console.log(error.response.data);
    },
  });
}
