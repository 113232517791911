const config = {
  columnNames: [
    'Documento',
    'Nombre',
    'Modalidad',
    'Programa',
    'Curso',
    'Sede',
    'Jornada',
    'Horario',
    'Periodo',
  ],
  keys: [
    'documento',
    'nombre',
    'modalidad',
    'programa',
    'curso',
    'sede',
    'jornada',
    'horario',
    'perido',
  ],

  fileName: 'data.xlsx',
  sheetName: 'Estudiantes Info',
};

export { config };
