import EditIcon from '@mui/icons-material/Edit';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import PasswordIcon from '@mui/icons-material/Password';
import EditarEstudiante from './EditarEstudiante';
import EditPasswordStudent from './EditPasswordStudent';
import { useDeleteStudent } from 'app/services/users/deleteStudent';

const StudentsActions = ({ params }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const { mutate: deleteStudent, isLoading } = useDeleteStudent();
  const student = params.row;
  const handleDelete = () => {
    deleteStudent(
      {
        idStudent: student?._id,
        idSede: student?.sede,
      },
      { onSuccess: hideDialog }
    );
  };
  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          content: <EditarEstudiante student={student} onClose={hideDialog} />,
          maxWidth: 'md',
        });
        break;
      case 'delete':
        showDialog({
          title: 'Estas seguro de eliminar este estudiante?',
          variant: 'confirm',
          onYes: handleDelete,
          onNo: hideDialog,
        });
        break;
      case 'changePassword':
        showDialog({
          content: (
            <EditPasswordStudent student={student} onClose={hideDialog} />
          ),
        });
        break;

      default:
        break;
    }
  };

  const menuItems = [
    {
      icon: <EditIcon />,
      title: 'Editar',
      action: 'edit',
    },
    {
      icon: <PasswordIcon />,
      title: 'Cambiar Contraseña',
      action: 'changePassword',
    },
    {
      icon: <DeleteIcon />,
      title: 'Eliminar',
      action: 'delete',
    },
  ];

  return (
    <>
      <JumboDdMenu
        icon={<MoreHorizIcon />}
        menuItems={menuItems}
        onClickCallback={handleItemAction}
      />
    </>
  );
};

export default StudentsActions;
