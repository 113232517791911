import client from './config';

const authServices = {};

authServices.getCurrentUser = async () => {
  try {
    const response = await client.get('/user/get-user');
    return response.data;
  } catch (e) {
    console.log(e.response.data.message);
    throw new Error(e.response.data.message);
  }
};

//loginCreds must be {email: "abc@example.com", passWord: "ABC123DEF"}
authServices.signIn = async (loginCreds) => {
  try {
    const response = await client.post('/auth/login', loginCreds);
    return response;
  } catch (e) {
    // return e.response.data.message;
    throw new Error(e.response.data.message);
  }
};

authServices.verifyQ10User = async () => {
  try {
    const response = await client.post('/user/verify-stateQ10');
    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export default authServices;
