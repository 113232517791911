import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deleteHorarioJornada = async ({ idJornada, idHorario }) => {
  const { data } = await client.delete(
    `/jornada/delete-horario/${idHorario}/jornada/${idJornada}`
  );
  return data;
};

export const useDeleteHorarioJornada = (idSede) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(deleteHorarioJornada, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('materias');
      queryClient.invalidateQueries([idSede, 'jornadasBySede']);

      enqueueSnackbar('El Horario ha sido eliminado con éxito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al eliminar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
};
