import PensumActions from '../components/PensumActions';
import ListMateriasPensum from '../components/ListMateriasPensum';

export const usePensumColumns = () => {
  const columns = [
    {
      field: 'sede',
      headerName: 'Sede',
      minWidth: 200,
      valueGetter: (params) => params.row?.sede?.name,
    },
    {
      field: 'program',
      headerName: 'Programa',
      minWidth: 600,
    },
    {
      field: 'materias',
      headerName: 'Materias',
      minWidth: 50,
      type: 'actions',
      renderCell: (params) => <ListMateriasPensum pensum={params.row} />,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      minWidth: 100,
      renderCell: (params) => <PensumActions params={params} />,
    },
  ].filter(Boolean);

  return columns;
};
