import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import client from "../config";

export const sendCodeEmail = async (email) => {
  console.log("email a solicitar", email);
  const { data } = await client.post(`/user/send-code/${email}`);
  return data;
};

export default function useSendCodeEmail() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(sendCodeEmail, {
    onSuccess: (data) => {
      // console.log(data);
      localStorage.setItem("emailCode", data);
      enqueueSnackbar("Codigo enviado con exito", { variant: "success" });
    },
    onError: (error) => {
      const { message } = error.response.data;
      enqueueSnackbar(
        message === "USER_NOT_FOUND"
          ? "El usuario no existe"
          : "Ocurrio un error, intente de nuevo",
        { variant: "error" }
      );
    },
  });
}
