import { useQuery } from 'react-query';
import client from '../config';

export const getAulaVsMaterias = async (id) => {
  const { data } = await client.get(`/aula-materia/get-by-id/${id}`);
  return data;
};

export default function useGetAulaVsMaterias(id) {
  return useQuery([id, 'aulaVsMaterias'], () => getAulaVsMaterias(id), {
    onError: (error) => {
      console.log(error.response.data);
    },
  });
}
