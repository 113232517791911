import { useMutation } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const actualizarDocentesQ10 = async (idSede) => {
  if (!idSede) return [];
  const { data } = await client.get(`/profesor/get-data-q10/${idSede}`);
  return data;
};

export default function useActualizarDocentesQ10() {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(actualizarDocentesQ10, {
    onSuccess: (data) => {
      enqueueSnackbar('Docentes actualizados desde q10 correctamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al actualizar desde q10', {
        variant: 'error',
      });
    },
  });
}
