import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { Button } from '@mui/material';
import useCancelReserveStudent from 'app/services/reserve/cancelReserveStudent';
import React from 'react';

const CancelPracticaStudent = ({ params }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const { mutate: deleteReserve } = useCancelReserveStudent();
  const handleCancel = () => {
    showDialog({
      variant: 'confirm',
      title: '¿Estás seguro de cancelar la práctica?',
      onYes: () => {
        deleteReserve(
          {
            idReserva: params.row.reserve,
          },
          {
            onSuccess: () => {
              hideDialog();
            },
          }
        );
      },
      onNo: hideDialog,
    });
  };

  return (
    <div>
      <Button
        size="small"
        disabled={params.row.status !== 'Reservado'}
        variant="contained"
        color="error"
        onClick={handleCancel}
      >
        Cancelar
      </Button>
    </div>
  );
};

export default CancelPracticaStudent;
