import Div from '@jumbo/shared/Div';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, TextField } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect } from 'react';
import { SignUpContext } from '../SignUpProvider';
import * as yup from 'yup';
import useGetRoles from 'app/services/roles/getRoles';
import useSignUp from 'app/services/auth/signUp';
import { useNavigate } from 'react-router-dom';
import useSendCodeEmail from 'app/services/auth/sendCodeEmail';

const schema = yup.object().shape({
  email: yup.string().email('Email no valido').required('Campo requerido'),
  password: yup
    .string()
    .required('Campo requerido')
    .min(6, 'La contraseña debe tener al menos 6 caracteres.')
    .test(
      'tiene-caracteres-especiales-y-mayuscula',
      '*La contraseña debe contener al menos un dígito, una letra mayúscula y un carácter especial (e.g. !@#$%^&*()_.+).',
      (value) => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_.+])/.test(
          value
        );
      }
    ),
  confirm_password: yup
    .string()
    .required('Campo requerido')
    .oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir'),
});

const Step3 = ({ handleNext, handleBack }) => {
  const { user } = React.useContext(SignUpContext);
  const navigate = useNavigate();
  const { mutate: signUp, isLoading: isSigningUp } = useSignUp();
  const { mutate: sendCodeEmail, isLoading: isSendingCode } =
    useSendCodeEmail();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      confirm_password: '',
    },
  });

  const onSubmit = (dataForm) => {
    const userData = {
      codigo_rol: user.codigo_rol,
      program_code: user.program_code,
      sede: user?.sede,
      passWord: dataForm?.password,
      name: user?.name,
      tipe_document: user?.tipe_document,
      document: user?.document,
      email: user?.email,
      phone: user?.phone,
      rol: user?.rol,
    };
    signUp(userData, {
      onSuccess: (data) => {
        sendCodeEmail(user?.email, {
          onSuccess: (data) => {
            reset();
            navigate('/user/login');
          },
        });
      },
    });
  };
  useEffect(() => {
    if (user) {
      reset({
        email: user?.email,
      });
    }
  }, [user, handleBack, reset]);

  return (
    <>
      <Div sx={{ mt: 1, mb: 2 }}>
        <Controller
          shouldUnregister
          name="email"
          control={control}
          rules={{
            required: 'Campo requerido',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              disabled
              label="Email"
              type="email"
              placeholder="Email"
              fullWidth
              helperText={errors.name?.message}
              error={!!errors.name}
            />
          )}
        />
      </Div>
      <Div sx={{ mt: 1, mb: 2 }}>
        <Controller
          name="password"
          control={control}
          rules={{
            required: 'Campo requerido',
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Contraseña"
              type="password"
              placeholder="Contraseña"
              fullWidth
              helperText={error ? error.message : null}
              error={!!error}
            />
          )}
        />
      </Div>
      <Div sx={{ mt: 1, mb: 2 }}>
        <Controller
          name="confirm_password"
          control={control}
          rules={{
            required: 'Campo requerido',
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Confirmar contraseña"
              type="password"
              placeholder="Confirmar contraseña"
              fullWidth
              helperText={error ? error.message : null}
              error={!!error}
            />
          )}
        />
      </Div>
      <Div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Button
          disabled={isSigningUp}
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
          variant="contained"
          color="secondary"
          fullWidth
        >
          Atras
        </Button>
        <Button
          disabled={isSigningUp}
          startIcon={isSigningUp ? <CircularProgress size={20} /> : null}
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          fullWidth
        >
          {isSigningUp ? 'Cargando...' : 'Registrarse'}
        </Button>
      </Div>
    </>
  );
};

export default Step3;
