import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'checking',
  token: null,
  id: null,
  codigo_rol: null,
  program_code: null,
  sede: null,
  passWord: null,
  name: null,
  tipe_document: null,
  document: null,
  email: null,
  rol: null,
  createAt: null,
  updateAt: null,
  active: null,
  verify: null,
  accept: null,
  q10State: null,
  permisos: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.status = 'authenticated';
      state.token = payload.token;
      state.id = payload._id;
      state.codigo_rol = payload.codigo_rol;
      state.program_code = payload.program_code;
      state.sede = payload.sede;
      state.passWord = payload.passWord;
      state.name = payload.name;
      state.tipe_document = payload.tipe_document;
      state.document = payload.document;
      state.email = payload.email;
      state.rol = payload.rol;
      state.createAt = payload.createAt;
      state.updateAt = payload.updateAt;
      state.active = payload.active;
      state.verify = payload.verify;
      state.accept = payload.accept;
      state.q10State = payload.q10State;
      state.permisos = payload.permisos;
    },
    logout: (state, { payload }) => {
      state.status = 'not-authenticated';
      state.token = null;
      state.id = null;
      state.codigo_rol = null;
      state.program_code = null;
      state.sede = null;
      state.passWord = null;
      state.name = null;
      state.tipe_document = null;
      state.document = null;
      state.email = null;
      state.rol = null;
      state.createAt = null;
      state.updateAt = null;
      state.active = null;
      state.verify = null;
      state.accept = null;
      state.q10State = null;
      state.permisos = [];
      localStorage.removeItem('token');
    },
    setRoleName: (state, { payload }) => {
      state.roleName = payload;
    },

    checkingCredentials: (state) => {
      state.status = 'checking';
    },
  },
});

export const { login, logout, checkingCredentials, setRoleName, setAreaName } =
  authSlice.actions;

export default authSlice.reducer;
