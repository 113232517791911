import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const updatePrograma = async ({ id, dataPrograma }) => {
  const { data } = await client.patch(`/program/delete/${id}`, dataPrograma);
  return data;
};

export default function useUpdatePrograma() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updatePrograma, {
    onSuccess: (data) => {
      console.log(data, 'data actualizada');
      queryClient.invalidateQueries('programasBySede');
      queryClient.invalidateQueries([data?.sede, 'programasBySede']);
      enqueueSnackbar(`Actualizacion exitosa`, {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al actualizar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
}
