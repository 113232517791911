import React from "react";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import { useNavigate } from "react-router-dom";
import notFound from "../../../assets/images/404.svg";
const Error404 = () => {
  const navigate = useNavigate();
  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(4),
      }}
    >
      <Div sx={{ display: "inline-flex", mb: 3 }}>
        <Box component="img" src={notFound} alt="404" width={200} />
      </Div>
      <Typography
        align={"center"}
        variant={"h2"}
        color={"text.secondary"}
        mb={3}
      >
        Oops, parece que la página que estás buscando no existe.
      </Typography>

      <Button variant="contained" onClick={() => navigate("/")}>
        Volver al inicio
      </Button>
    </Div>
  );
};

export default Error404;
