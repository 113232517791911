import React, { useEffect } from 'react';
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import useConfirmAssistance from 'app/services/reserve/confirmAssistance';
import Spinner from 'app/shared/Spinner';
import { Box } from '@mui/material';

const ScannerBox = ({ hideDialog }) => {
  const { mutate: confirmAssistance, isLoading } = useConfirmAssistance();
  const [qrCodeMessage, setQrCodeMessage] = React.useState(null);
  useEffect(() => {
    const html5QrCode = new Html5Qrcode('reader', {
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
    });
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      setQrCodeMessage(decodedText);
      confirmAssistance({ decodedText });
      hideDialog();
      html5QrCode.pause().then((ignore) => {
        /* QR Code scanning is stopped. */
      });
    };
    const config = { fps: 2, qrbox: { width: 240, height: 240 } };

    // If you want to prefer front camera
    html5QrCode.start(
      { facingMode: 'environment' },
      config,
      qrCodeSuccessCallback
    );
    return () => {
      html5QrCode.stop().then((ignore) => {
        /* QR Code scanning is stopped. */
      });
    };
  }, []);
  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: 400,
          alignItems: 'center',
        }}
      >
        <Spinner />
      </Box>
    );
  }
  return (
    <>
      {qrCodeMessage ? (
        <div>scanned QR code: {qrCodeMessage}</div>
      ) : (
        <div id="reader" style={{ width: '100%' }}></div>
      )}
    </>
  );
};

export default ScannerBox;
