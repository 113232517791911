import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const updateSede = async ({ id, dataSede }) => {
  const { data } = await client.put(`/sede/update/${id}`, dataSede);
  return data;
};

export default function useUpdateSede() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateSede, {
    onSuccess: (data) => {
      console.log(data, 'data actualizada');
      queryClient.invalidateQueries('sedes');
      enqueueSnackbar(`Sede ${data?.name} actualizado`, {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al actualizar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
}
