import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import client from '../config';

const confirmAssistance = async ({ decodedText }) => {
  console.log('decodedText', decodedText)
  const { data } = await client.get(
    `/student-reserva/check-assistance${decodedText}`
  );
  return data;
};

export default function useConfirmAssistance() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(confirmAssistance, {
    onSuccess: (isSuccess) => {
      enqueueSnackbar(
        !isSuccess
          ? 'No es posible realizar la validación debido a que el código QR no es valido para esta reserva.'
          : 'La asistencia de este usuario se ha validado con éxito.',
        {
          variant: !isSuccess ? 'error' : 'success',
        }
      );
    },
    onError: (error) => {
      const { message } = error.response.data;
      console.log('error', error.response);
      enqueueSnackbar('Este código QR ya no existe en nuestra base de datos.', {
        variant: 'error',
      });
    },
  });
}
