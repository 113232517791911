import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import AppLayout from "./components/AppLayout";
import AppRoutes from "./components/AppRoutes";
import configureStore, { history } from "./redux/store";
import AppProvider from "./components/AppProvider";
import AppJumboBuilder from "./components/AppJumboBuilder";
import AppSnackbar from "./components/AppSnackbar";
import Spinner from "./shared/Spinner";
import 'animate.css';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const store = configureStore();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter history={history}>
          <AppProvider>
            <AppSnackbar>
              <AppJumboBuilder>
                <AppLayout>
                  <Suspense fallback={<Spinner />}>
                    <AppRoutes />
                    {/* react Query Devtools */}
                    {/* <ReactQueryDevtools /> */}
                  </Suspense>
                </AppLayout>
              </AppJumboBuilder>
            </AppSnackbar>
          </AppProvider>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
