import { useMutation } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deleteAulaSubject = async ({ idAula, idMateria }) => {
  const { data } = await client.delete(
    `/aula/delete-aula-subject/${idAula}/${idMateria}`
  );
  return data;
};

export const useDeleteAulaSubject = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((student) => deleteAulaSubject(student), {
    onSuccess: (data) => {
      enqueueSnackbar('El aula fue eliminada con éxito', {
        variant: 'success',
      });
    },
  });
};
