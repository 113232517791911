import { useMemo, useState } from 'react';
import { debounce } from '@mui/material/utils';
import { InputBase } from '@mui/material';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';
import Div from '@jumbo/shared/Div';
import { useEffect } from 'react';

const CustomDataGridToolbar = ({ label }) => {
  const apiRef = useGridApiContext();
  const [searchValue, setSearchValue] = useState('');

  const updateSearchValue = useMemo(() => {
    return debounce((newValue) => {
      apiRef.current.setQuickFilterValues(
        newValue.split(' ').filter((word) => word !== '')
      );
    }, 500);
  }, [apiRef]);

  const handleSearchValueChange = (event) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    updateSearchValue(newValue);
  };

  useEffect(() => {
    if (searchValue === '') {
      apiRef?.current?.setQuickFilterValues([]);
    }
  }, [searchValue, apiRef]);
  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ p: 2 }}
      >
        <GridToolbarContainer>
          <Div
            sx={{
              position: 'relative',
              width: '100%',
            }}
          >
            <Div
              sx={{
                padding: (theme) => theme.spacing(0, 2),
                height: '100%',
                position: 'absolute',
                pointerEvents: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 2,
              }}
            >
              <SearchIcon />
            </Div>
            <InputBase
              autoComplete="off"
              sx={{
                color: 'inherit',
                display: 'flex',
                borderRadius: 24,
                backgroundColor: (theme) =>
                  theme.jumboComponents.JumboSearch.background,

                '& .MuiInputBase-input': {
                  padding: (theme) => theme.spacing(1, 1, 1, 0),
                  // vertical padding + font size from searchIcon
                  paddingLeft: (theme) => `calc(1em + ${theme.spacing(4)})`,
                  transition: (theme) => theme.transitions.create('width'),
                  width: '100%',
                  height: 24,
                },
              }}
              placeholder={label}
              inputProps={{ 'aria-label': label, autoComplete: 'off' }}
              onChange={handleSearchValueChange}
              value={searchValue}
            />
          </Div>
        </GridToolbarContainer>
        {/* <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <IconButton aria-label="refresh" onClick={handleRefresh}>
            <ReplayIcon />
          </IconButton>
        </Stack> */}
      </Stack>
    </>
  );
};

export default CustomDataGridToolbar;
