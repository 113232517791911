import Div from '@jumbo/shared/Div';
import { useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';

export const useHistorialReservasColumns = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const columns = [
    {
      field: 'sede',
      headerName: 'Sede',
      minWidth: 100,
      valueGetter: (params) => params.row.sede?.name,
    },
    {
      field: 'user',
      headerName: 'Usuario',
      minWidth: isDesktop ? 350 : 230,
      valueGetter: (params) => params.row.user?.name,
    },
    {
      field: 'aula',
      headerName: 'Aula',
      minWidth: isDesktop ? 350 : 230,
      valueGetter: (params) => params.row.aula?.name,
    },
    {
      field: 'materia',
      headerName: 'Curso/Materia',
      minWidth: 230,
      valueGetter: (params) => params.row?.subject?.name,
    },

    {
      field: 'date',
      headerName: 'Fecha	',
      minWidth: 100,
      valueGetter: (params) => params.row?.date ?? '',
    },
    {
      field: 'horario',
      headerName: 'Horario',
      minWidth: isDesktop ? 150 : 130,
      valueGetter: (params) => params.row?.horario?.name,
    },
    {
      field: 'Tipo De practica',
      headerName: 'Practica',
      minWidth: isDesktop ? 200 : 160,
      valueGetter: (params) =>
        params.row?.subject?.name?.toLowerCase()?.includes('audición') ||
        params.row?.subject?.name?.toLowerCase()?.includes('Audición')
          ? 'Practica por audición'
          : 'Practica libre',
    },

    {
      field: 'status',
      headerName: 'Estado',
      minWidth: 120,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params) => {
        return (
          <Div
            className="rounded text-center  p-3"
            sx={{
              minWidth: 90,
              bgcolor: (theme) =>
                params.row?.status?.name === 'Cancelado'
                  ? theme.palette.error.main
                  : params.row?.status?.name === 'Inasistio'
                  ? theme.palette.primary.main
                  : params.row?.status?.name === 'Asistio'
                  ? theme.palette.success.main
                  : params.row?.status?.name === 'Reservado'
                  ? theme.palette.info.main
                  : theme.palette.warning.main,

              color: (theme) =>
                params.row?.status?.name === 'Cancelado'
                  ? theme.palette.error.contrastText
                  : params.row?.status?.name === 'Inasistio'
                  ? theme.palette.primary.contrastText
                  : params.row?.status?.name === 'Asistio'
                  ? theme.palette.success.contrastText
                  : params.row?.status?.name === 'Reservado'
                  ? theme.palette.info.contrastText
                  : theme.palette.warning.contrastText,
            }}
          >
            <span>{params.row?.status?.name}</span>
          </Div>
        );
      },
    },
  ].filter(Boolean);

  return columns;
};
