import React from 'react';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import AulaList from './components/AulaList';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import CustomNumberTextField from 'app/shared/ReactHookForm/CustomNumberTextField';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import useGetSedes from 'app/services/sedes/getSedes';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import useCreateAulaPrematricula from 'app/services/aula-prematriculas/createAulaPrematriculas';
const aulaModel = {
  name: '',
  aforo_clase: '',
  aforo: 0,
  sede: '',
};
const AulasPage = () => {
  const { mutate: create, isLoading: isCreating } = useCreateAulaPrematricula();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const [selectedSede, setSelectedSede] = React.useState(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: aulaModel,
  });

  const { control, handleSubmit, reset } = methods;
  const onSubmit = (data) => {
    create(data, {
      onSuccess: () => {
        enqueueSnackbar('Aula creada correctamente', {
          variant: 'success',
        });
        reset(aulaModel);
      },
      onError: (error) => {
        enqueueSnackbar('Error al Crear el Aula', {
          variant: 'error',
        });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                <IconButton
                  onClick={() => navigate('/panel-control', { replace: true })}
                >
                  <ArrowBack />
                </IconButton>
                CREAR AULA
              </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                1. Nombre del Aula:
              </Typography>
              <CustomTextField
                name={'name'}
                control={control}
                label="Nombre"
                isRequired
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                2. Aforo Clase:
              </Typography>
              <CustomNumberTextField
                name={'aforo_clase'}
                control={control}
                label="Aforo Clase"
                isRequired
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                3. Selecione la sede:
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="sede"
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>

            <Grid item xs={12} md={4} xl={3}>
              <Button
                startIcon={isCreating ? <CircularProgress size={16} /> : null}
                disabled={isCreating}
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mt: { xl: 5 } }}
                fullWidth
                size="large"
              >
                {isCreating ? 'Creando...' : 'Crear'}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'}>
                LISTA DE AULAS
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" mb={2} gutterBottom>
                Selecciona una Sede:
              </Typography>
              <Autocomplete
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                value={selectedSede}
                onChange={(event, newValue) => {
                  setSelectedSede(newValue);
                }}
                getOptionLabel={(option) => option?.name}
                placeholder="Por favor seleccione una sede"
                renderInput={(params) => (
                  <TextField
                    label="Sedes"
                    placeholder="Por favor seleccione una sede"
                    {...params}
                  />
                )}
              />
            </Grid>
            <AulaList selectedSede={selectedSede} />
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default AulasPage;
