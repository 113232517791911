import { useMutation, useQuery } from 'react-query';
import client from '../config';

export const getCargaAcademicaByIdStudent = async ({ idStudent, idSede }) => {
  const { data } = await client.get(
    `/user/carga-academica/${idSede}/${idStudent}`
  );
  return data;
};

export default function useGetCargaAcademicaByIdStudent(student) {
  return useQuery(
    [student?.idStudent, 'cargaAcademicaByIdStudent'],
    () => getCargaAcademicaByIdStudent(student),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        console.log(error.response.data);
      },
    }
  );
}

export const useGetCargaAcademicaByIdStudentMutation = () => {
  return useMutation((student) => getCargaAcademicaByIdStudent(student));
};
