import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useBasicAuth } from 'app/auth-providers/BasicAuth/hooks';
import useGetRolById from 'app/services/roles/getRolById';
import Spinner from 'app/shared/Spinner/Spinner';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const GetRoutesForUser = () => {
  const { authUser, isLoading, isAuthenticated } = useJumboAuth();
  const selectedModule = localStorage.getItem('selectedModule');

  const { logout } = useBasicAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { data: userRol, isLoading: isLoadingRol } = useGetRolById(
    authUser?.rol
  );

  useEffect(() => {
    if (!isAuthenticated) {
      enqueueSnackbar('Su sessión ha expirado, por favor ingrese nuevamente', {
        variant: 'error',
      });
      logout();
      navigate('/');
    }
  }, [isAuthenticated, logout, navigate]);

  if (!!isLoading || !!isLoadingRol) {
    return <Spinner pt={30} />;
  }
  if (authUser?.rolName === 'Superadministrador' && !selectedModule) {
    return <Navigate to="/dashboard" />;
  }
  return <Navigate to={'/perfil'} />;
};

export default GetRoutesForUser;
