import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import client from '../config';

const verifyCodeEmail = async ({ correo }) => {
  console.log(correo);
  const { data } = await client.patch(`/user/virify/${correo}`);
  return data;
};

export default function useVerifyCodeEmail() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(verifyCodeEmail, {
    onSuccess: (data) => {
      enqueueSnackbar('Correo verificado exitosamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const { message } = error.response.data;
      console.log(message);
      enqueueSnackbar(
        message === 'CODE_TIME_EXPIRED'
          ? 'Código invalido, por favor vuelva a intentarlo '
          : message === 'CODE_NO_VALID'
          ? 'Código invalido, por favor vuelva a intentarlo'
          : message === 'USER_NOT_FOUND'
          ? 'El usuario no existe'
          : 'Ocurrio un error, intente de nuevo',

        { variant: 'error' }
      );
    },
  });
}
