import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  Box,
  Button,
  CardActions,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useEffect } from 'react';
import Spinner from 'app/shared/Spinner';
import CustomAutocompleteMultipleForm from 'app/shared/ReactHookForm/CustomAutocompleteMultipleForm';
import useGetMateriasBySede from 'app/services/materias/getMateriasBySede';
import { useState } from 'react';
import useGetAulaVsMaterias from 'app/services/aulas-vs-materias/getAulaVsMaterias';
import { useDeleteMateriaFromAula } from 'app/services/aulas-vs-materias/removeMateriaFromAula';
import useUpdateAulaVsMaterias from 'app/services/aulas-vs-materias/updateAulaVsMaterias';
const aulaVsMateriaModel = {
  sede: '',
  // aula: '',
  materias: [],
};
const EditarAulaVsMaterias = ({ aula, onClose }) => {
  const { mutate: updateAulaVsMaterias, isLoading } = useUpdateAulaVsMaterias();
  const { data: aulaData, isLoading: isLoadingAulasData } =
    useGetAulaVsMaterias(aula?._id);
  const [selectedSede, setSelectedSede] = useState(null);
  const { data: materias, isLoading: isLoadingMaterias } =
    useGetMateriasBySede(selectedSede);

  const { mutate: deleteMateriaFromAula } =
    useDeleteMateriaFromAula(selectedSede);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: aulaVsMateriaModel,
  });
  const {
    control,
    reset,
    watch,
    formState: { isValid, dirtyFields },
  } = methods;

  const watchMaterias = watch('materias', []);
  const onSubmit = (dataForm) => {
    updateAulaVsMaterias({ dataForm, id: aulaData?._id }, { onSuccess: onClose });
  };

  const onRemoveMateria = (newValues) => {
    if (newValues.length > watchMaterias.length) return; // si se agrega un elemento no hago nada
    const removedElementId = (watchMaterias || []).find(
      (oldOption) => !newValues.some((newOption) => newOption.id === oldOption)
    );
    deleteMateriaFromAula({
      idAula: aulaData?._id,
      idMateria: removedElementId,
    });
  };

  useEffect(() => {
    if (!aulaData) return;
    setSelectedSede(aulaData?.sede);
    console.log(aulaData, 'aulaData');
    reset({
      materias: aulaData?.materias?.map((p) => p?.id) || [],
    });
  }, [aulaData]);

  if (isLoadingAulasData)
    return (
      <Box
        sx={{
          height: 300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </Box>
    );
  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Editar Materias del Aula - <strong>{aulaData?.aula}</strong>`
          </Typography>
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: 'background.default',
            px: 2,
            pb: 2,
          }}
        >
          <Grid item xs={12}>
            <CustomAutocompleteMultipleForm
              name="materias"
              label={'Materias'}
              placeholder={'Seleccione las materias'}
              control={control}
              limitTags={10}
              options={materias?.map((m) => ({ id: m?._id, ...m })) || []}
              getOptionLabel={(option) => option?.materia}
              isLoading={isLoadingMaterias}
              isRequired
              optionLabel="materia"
              onChangeCallback={onRemoveMateria}
            />
          </Grid>
        </Grid>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            p: (theme) => theme.spacing(1.5, 2),
            pl: 3,
          }}
        >
          <Button onClick={onClose} color="secondary" sx={{ mr: 1 }}>
            Cancelar
          </Button>
          <Button
            startIcon={isLoading && <CircularProgress size={16} />}
            variant="contained"
            disabled={!isValid || _.isEmpty(dirtyFields) || isLoading}
            onClick={methods.handleSubmit(onSubmit)}
            sx={{ mr: 1 }}
          >
            {isLoading ? 'Guardando...' : 'Guardar'}
          </Button>
        </CardActions>
      </FormProvider>
    </Div>
  );
};

export default EditarAulaVsMaterias;
