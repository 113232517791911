import React from 'react';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import PensumList from './components/PensumList';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import useGetSedes from 'app/services/sedes/getSedes';
import CustomNumberTextField from 'app/shared/ReactHookForm/CustomNumberTextField';
import CustomAutocompleteMultipleForm from 'app/shared/ReactHookForm/CustomAutocompleteMultipleForm';
import useGetMateriasBySede from 'app/services/materias/getMateriasBySede';
import { useState } from 'react';
import useGetProgramasBySede from 'app/services/programas/getProgramasBySede';
import useCreatePensum from 'app/services/pensum/createPensum';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
const pensumModel = {
  sede: '',
  programa: '',
  materias: [],
};
const PensumPage = () => {
  const navigate = useNavigate();
  const { mutate: create, isLoading: isCreating } = useCreatePensum();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const [selectedSede, setSelectedSede] = useState(null);
  const { data: programas, isLoading: isLoadingProgramas } =
    useGetProgramasBySede(selectedSede?._id);
  const [selectedSedeForList, setSelectedSedeForList] = useState(null);
  const { data: materias, isLoading: isLoadingMaterias } = useGetMateriasBySede(
    selectedSede?._id
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: pensumModel,
  });

  const { control, handleSubmit, reset, resetField } = methods;
  const onSubmit = (data) => {
    create(data, { onSuccess: () => reset(pensumModel) });
  };

  const onChangeSede = (sede) => {
    resetField('prerequisitos');
    resetField('programa');
    setSelectedSede(sede);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                <IconButton
                  onClick={() => navigate('/panel-control', { replace: true })}
                >
                  <ArrowBack />
                </IconButton>
                CREAR PENSUM
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                1. Selecione la sede:
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="sede"
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
                onChangeCallback={onChangeSede}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom mb={2}>
                2. Selecione el programa:
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="programa"
                label="Programas"
                options={programas?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingProgramas}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom mb={2}>
                3. Seleccione las materias:
              </Typography>
              <CustomAutocompleteMultipleForm
                name="materias"
                label={'Materias'}
                placeholder={'Seleccione las materias'}
                control={control}
                limitTags={10}
                options={materias?.map((m) => ({ id: m?._id, ...m })) || []}
                getOptionLabel={(option) => option?.materia}
                isLoading={isLoadingMaterias}
                optionLabel="materia"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                startIcon={isCreating ? <CircularProgress size={16} /> : null}
                disabled={isCreating}
                sx={{ mt: { md: 5 } }}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                size="large"
              >
                {isCreating ? 'Creando...' : 'Crear'}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'}>
                LISTADO DE PENSUM
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" mb={2} gutterBottom>
                Selecciona una Sede:
              </Typography>
              <Autocomplete
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                value={selectedSedeForList}
                onChange={(event, newValue) => {
                  setSelectedSedeForList(newValue);
                }}
                getOptionLabel={(option) => option?.name}
                placeholder="Por favor seleccione una sede"
                renderInput={(params) => (
                  <TextField
                    label="Sedes"
                    placeholder="Por favor seleccione una sede"
                    {...params}
                  />
                )}
              />
            </Grid>
            <PensumList selectedSede={selectedSedeForList} />
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default PensumPage;
