import { useMutation, useQuery } from 'react-query';
import client from '../config';

export const getReservesByStudent = async (idUser) => {
  const { data: reserves } = await client.get(
    `/student-reserva/reservas-byUser-admin/${idUser}`
  );
  const mallaIds = [...new Set(reserves?.map((reserva) => reserva.malla))];
  const mallas = await Promise.all(
    mallaIds?.map(async (mallaId) => {
      const { data: malla } = await client.get(`/malla/malla-by-id/${mallaId}`);
      return { id: mallaId, ...malla };
    })
  );
  const reservesWithMalla = reserves.map((reserva) => {
    const malla = mallas.find((m) => m.id === reserva.malla);
    return { ...reserva, malla };
  });
  return reservesWithMalla;
};

export default function useGetReservesByStudent(idUser) {
  return useQuery(
    [idUser, 'reservasByUser'],
    () => getReservesByStudent(idUser),
    {
      onError: (error) => {
        console.log(error.response.data);
      },
    }
  );
}

export function useGetReservesByStudentMutation() {
  return useMutation((idUser) => getReservesByStudent(idUser));
}
