import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  Button,
  CardActions,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useEffect } from 'react';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import CustomSelect from 'app/shared/ReactHookForm/CustomSelect';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import useGetSedes from 'app/services/sedes/getSedes';
import useUpdatePrograma from 'app/services/programas/updatePrograma';

const EditarPrograma = ({ programa, onClose }) => {
  const { mutate: updateAula, isLoading } = useUpdatePrograma();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      codigo: '',
      abreviatura: '',
      modalidad: '',
      sede: '',
      tipo: '',
    },
  });
  const {
    control,
    reset,
    formState: { isValid, dirtyFields },
  } = methods;
  const onSubmit = (dataPrograma) => {
    updateAula({ dataPrograma, id: programa?._id }, { onSuccess: onClose });
  };
  useEffect(() => {
    if (!programa) return;
    reset({
      name: programa?.name,
      sede: programa?.sede?._id,
      codigo: programa?.codigo,
      abreviatura: programa?.abreviatura,
      modalidad: programa?.modalidad,
      tipo: programa?.tipo,
    });
  }, [programa]);

  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Editar Programa - <strong>{programa?.name}</strong>`
          </Typography>
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: 'background.default',
            px: 2,
            pb: 2,
          }}
        >
          <Grid item xs={12} md={4} xl={3}>
            <Typography variant="h6" gutterBottom mb={2}>
              1. Nombre del Programa:
            </Typography>
            <CustomTextField
              name={'name'}
              control={control}
              label="Nombre"
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <Typography variant="h6" gutterBottom mb={2}>
              2. Selecione la sede:
            </Typography>
            <CustomAutoComplete
              isRequired
              control={control}
              name="sede"
              label="Sedes"
              options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
              isLoading={isLoadingSedes}
              getOptionLabel={(option) => option?.name}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <Typography variant="h6" gutterBottom mb={2}>
              3. Codigo:
            </Typography>
            <CustomTextField
              name={'codigo'}
              control={control}
              label="Codigo"
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <Typography variant="h6" gutterBottom mb={2}>
              4. Abreviatura:
            </Typography>
            <CustomTextField
              name={'abreviatura'}
              control={control}
              label="Abreviatura"
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                5. Tipo:
              </Typography>
              <CustomSelect
                name={'modalidad'}
                control={control}
                label={'Tipo'}
                isRequired
                menuItems={[
                  { id: 'NINGUNA', name: 'NINGUNA' },
                  { id: 'ESTANDAR', name: 'ESTANDAR' },
                  { id: 'INTENSIVO', name: 'INTENSIVO' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                6. Modalidad:
              </Typography>
              <CustomSelect
                name={'tipo'}
                control={control}
                label={'Modalidad'}
                isRequired
                menuItems={[
                  { id: 'virtual', name: 'VIRTUAL' },
                  { id: 'presencial', name: 'PRESENCIAL' },
                ]}
              />
            </Grid>
        </Grid>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            p: (theme) => theme.spacing(1.5, 2),
            pl: 3,
          }}
        >
          <Button onClick={onClose} color="secondary" sx={{ mr: 1 }}>
            Cancelar
          </Button>
          <Button
            startIcon={isLoading && <CircularProgress size={16} />}
            variant="contained"
            disabled={!isValid || _.isEmpty(dirtyFields) || isLoading}
            onClick={methods.handleSubmit(onSubmit)}
            sx={{ mr: 1 }}
          >
            {isLoading ? 'Guardando...' : 'Guardar'}
          </Button>
        </CardActions>
      </FormProvider>
    </Div>
  );
};

export default EditarPrograma;
