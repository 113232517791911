import Div from '@jumbo/shared/Div';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import Spinner from 'app/shared/Spinner';
import React from 'react';
import {
  StyledTableCell,
  StyledTableRow,
} from 'app/shared/StyledTable/StyledTable';
import { useState } from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useDeleteMateriaFromAula } from 'app/services/aulas-vs-materias/removeMateriaFromAula';
import AulasVsMateriasActions from './AulasVsMateriasActions';
import useGetAulaVsMateriasBysede from 'app/services/aulas-vs-materias/getAulaVsMateriasBySede';

const AulasVsMateriasList = ({ selectedSede }) => {
  const { showDialog, hideDialog } = useJumboDialog();

  const { data: aulasVsMaterias, isLoading } = useGetAulaVsMateriasBysede(
    selectedSede?._id
  );
  const { mutate: removeMateria, isLoading: isRemovingMateria } =
    useDeleteMateriaFromAula(selectedSede?.id);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRemoveMateria = ({ materia, aula }) => {
    showDialog({
      title: `Estas seguro que desea remover la materia: ${materia?.materia}  del Aula: ${aula?.aula}?`,
      variant: 'confirm',
      onYes: () =>
        removeMateria(
          {
            idAula: aula?._id,
            idMateria: materia?.id,
          },
          {
            onSuccess: hideDialog,
          }
        ),
      onNo: hideDialog,
    });
  };
  return (
    <Grid item xs={12}>
      {isLoading ? (
        <Div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: { xs: 300, xl: 600 },
          }}
        >
          <Spinner />
        </Div>
      ) : aulasVsMaterias?.length > 0 ? (
        <Paper
          className="scrollbar"
          sx={{
            borderRadius: '0.5rem',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <TableContainer
            sx={{
              height: { xs: 400, md: 500, xl: 700 },
            }}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Aula</StyledTableCell>
                  <StyledTableCell>Materias</StyledTableCell>
                  <StyledTableCell align="center">Sede</StyledTableCell>
                  <StyledTableCell align="center">Acciones</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {aulasVsMaterias
                  // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((aula, index) => (
                    <StyledTableRow
                      key={index}
                      className={
                        index % 2 === 0 ? 'tableRow-even' : 'tableRow-odd'
                      }
                    >
                      <StyledTableCell colSpan={1}>
                        {aula?.aula}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          {aula?.materias?.length === 0 && (
                            <Chip
                              size="small"
                              label={
                                aula?.aulaId === aula?._id
                                  ? 'Recuerde, primero crear el aula'
                                  : 'Sin Materias'
                              }
                            />
                          )}
                          {aula?.materias?.map((materia, index) => (
                            <div
                              key={materia?.id}
                              className="flex  items-center"
                            >
                              <Chip
                                color="primary"
                                size="small"
                                sx={{ mb: 0.5 }}
                                label={materia?.materia}
                              />
                              <IconButton
                                disabled={isRemovingMateria}
                                onClick={() =>
                                  handleRemoveMateria({ materia, aula })
                                }
                                size="small"
                                color="error"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          ))}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {selectedSede?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <AulasVsMateriasActions aula={aula} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={aulasVsMaterias.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Filas por página"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      ) : (
        <NoDataFound2 label="No se encontraron resultados para la busqueda" />
      )}
    </Grid>
  );
};

export default AulasVsMateriasList;
