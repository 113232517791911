import authServices from 'app/services/auth-services';
import { login, logout, setRoleName } from './authSlice';
import { getRolById } from 'app/services/roles/getRolById';

export const startGetttingUser = () => {
  return async (dispatch) => {
    try {
      const user = await authServices.getCurrentUser();
      const rol = await getRolById(user?.rol);
      if (rol?.name === 'Superadministrador') {
        const formatUser = {
          q10State: true,
          ...user,
        };
        // console.log('formatUser', formatUser);
        dispatch(login(formatUser));
        dispatch(setRoleName(rol?.name));
        return formatUser;
      } else {
        // si no es admin verifica el estado de q10
        const q10State = await authServices.verifyQ10User();
        const formatUser = {
          q10State:
            q10State?.cursos_inscritos?.includes(false) &&
            !q10State?.pagos_pendientes
              ? false
              : true,
          ...user,
        };
        // console.log('formatUser', formatUser);
        dispatch(login(formatUser));
        dispatch(setRoleName(rol?.name));
        return formatUser;
      }
    } catch (error) {
      dispatch(logout());
      throw new Error(error);
    }
  };
};
