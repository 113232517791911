import React from 'react';
import { Avatar, Box, Typography, alpha } from '@mui/material';
import Button from '@mui/material/Button';
import Span from '@jumbo/shared/Span';
import CardModulo from './CardModulo';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SchoolIcon from '@mui/icons-material/School';
import { Link, useNavigate } from 'react-router-dom';
import { MODULOS_APP } from 'app/utils/appHelpers';
import SpeakerIcon from '@mui/icons-material/Speaker';

import Div from '@jumbo/shared/Div';
import { useBasicAuth } from 'app/auth-providers/BasicAuth/hooks';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import shadows from '@mui/material/styles/shadows';

const Dashboard = () => {
  const navigate = useNavigate();
  const { logout } = useBasicAuth();
  const { authUser } = useJumboAuth();
  const onLogout = () => {
    logout();
    navigate('/user/login');
  };
  const handleClickAgendamiento = () => {
    localStorage.setItem('selectedModule', MODULOS_APP.AGENDAMIENTOS);
    navigate('/perfil');
  };

  const handleCLickPreMatricula = () => {
    localStorage.setItem('selectedModule', MODULOS_APP.PREMATRICULA);
    navigate('/perfil');
  };
  return (
    <>
      <Div
        sx={{
          flex: 1,
          flexWrap: 'wrap',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          p: (theme) => theme.spacing(4),
          background: `url(/backgrounds/bg_mixer.jpg) no-repeat center`,
          backgroundSize: 'cover',
          '&::after': {
            display: 'inline-block',
            position: 'absolute',
            content: `''`,
            inset: 0,
            backgroundColor: (theme) => alpha(theme.palette.common.black, 0.84),
          },
        }}
      >
        <Div
          sx={{
            maxWidth: '100%',
            position: 'relative',
            zIndex: 1,
            width: '100%',
            textAlign: 'center',
            mt: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Avatar
            alt={authUser?.name}
            sx={{
              background:
                'linear-gradient(90deg, rgba(131,29,180,1) 0%, rgba(95,66,173,1) 35%, rgba(206,103,255,1) 100%)',
              color: 'common.white',
              width: 90,
              height: 90,
              mx: 'auto',
              mb: 2,
              boxShadow: shadows[3],
            }}
          >
            <SpeakerIcon sx={{ fontSize: 52 }} />
          </Avatar>
          <Typography
            variant={'h1'}
            mb={0.5}
            fontWeight={700}
            color={'common.white'}
          >
            BIENVENIDO
          </Typography>
          <Typography variant={'h2'} mb={2} color={'common.white'}>
            {authUser?.name} - {authUser?.rolName}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CardModulo
              title={
                <Typography
                  variant={'h5'}
                  color="inherit"
                  sx={{ textTransform: 'uppercase', letterSpacing: 3 }}
                >
                  DNA MUSIC
                </Typography>
              }
              subheader={
                <Typography
                  variant={'h2'}
                  fontSize={26}
                  fontWeight={700}
                  mb={0}
                  color="inherit"
                >
                  <Span
                    sx={{
                      fontSize: '50%',
                      verticalAlign: 'super',
                      fontWeight: '400',
                      mr: 0.5,
                    }}
                  >
                    <CalendarMonthIcon sx={{ fontSize: 52, mt: 1 }} />
                  </Span>
                  AGENDAMIENTOS
                </Typography>
              }
              headerSx={{
                color: 'common.black',
                bgcolor: 'common.white',
              }}
              sx={{
                mr: { md: 3 },
                minWidth: { xs: 350, md: 370 },
                background:
                  'linear-gradient(90deg, rgba(131,29,180,1) 0%, rgba(95,66,173,1) 35%, rgba(206,103,255,1) 100%)',
                color: 'common.white',
              }}
            >
              <Button
                variant={'contained'}
                onClick={handleClickAgendamiento}
                disableElevation
                fullWidth
                size={'large'}
                sx={{
                  mb: 2,
                  borderRadius: 8,
                  maxWidth: 260,
                  bgcolor: 'common.black',
                  color: 'primary.main',
                  '&:hover': {
                    bgcolor: (theme) => theme.palette.secondary.main,
                    color: (theme) => theme.palette.common.white,
                  },
                }}
              >
                Entrar
              </Button>
            </CardModulo>
            <CardModulo
              title={
                <Typography
                  variant={'h5'}
                  color="inherit"
                  fontWeight={700}
                  sx={{ textTransform: 'uppercase', letterSpacing: 3 }}
                >
                  DNA MUSIC
                </Typography>
              }
              subheader={
                <Typography
                  variant={'h6'}
                  fontSize={26}
                  fontWeight={700}
                  mb={0}
                  color="inherit"
                >
                  <Span
                    sx={{
                      fontSize: '50%',
                      verticalAlign: 'super',
                      fontWeight: '400',
                      mr: 0.5,
                    }}
                  >
                    <SchoolIcon sx={{ fontSize: 52, mt: 1 }} />
                  </Span>
                  PRE MATRICULA
                </Typography>
              }
              headerSx={{
                background:
                  'linear-gradient(90deg,rgba(206,103,255,1) 0%, rgba(95,66,173,1) 35%,  rgba(131,29,180,1) 100%)',
                backgroundSize: 'cover',
                color: 'primary.main',
                bgcolor: 'common.white',
              }}
              sx={{
                mr: { md: 3 },
                minWidth: { xs: 350, md: 370 },
                color: 'common.black',
                bgcolor: 'common.white',
              }}
            >
              <Button
                variant={'contained'}
                disableElevation
                fullWidth
                onClick={handleCLickPreMatricula}
                size={'large'}
                sx={{
                  mb: 2,
                  borderRadius: 8,
                  maxWidth: 260,
                  bgcolor: 'common.black',
                  color: 'primary.main',
                  '&:hover': {
                    bgcolor: (theme) => theme.palette.secondary.main,
                    color: (theme) => theme.palette.common.white,
                  },
                }}
              >
                Entrar
              </Button>
            </CardModulo>
          </Box>
          <Button
            type="submit"
            // fullWidth
            variant="contained"
            size="large"
            sx={{ my: 3, borderRadius: 8 }}
            disableElevation
            onClick={onLogout}
          >
            CERRAR SESIÓN
          </Button>
        </Div>

        <Div sx={{ mt: 'auto', position: 'relative', zIndex: 1 }}>
          <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
            <Box
              component="img"
              src={'/logo_horizontal.png'}
              sx={{ height: { xs: 50, xl: 70 } }}
            />
          </Link>
        </Div>
      </Div>
    </>
  );
};

export default Dashboard;
