import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClassIcon from '@mui/icons-material/Class';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BallotIcon from '@mui/icons-material/Ballot';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import TuneIcon from '@mui/icons-material/Tune';
const options = [
  {
    title: 'Actualizar Datos de la Academia desde Q10',
    subtitle:
      'En esta sección podrá actualizar los datos de los Docentes, Materias y Programas desde Q10.',
    icon: <TuneIcon sx={{ fontSize: 20 }} />,
    link: '/actualizar-datos-q10',
  },
  {
    title: 'Gestión de Sedes',
    subtitle: 'En esta sección podrá gestionar las sedes de la academia.',
    icon: <ApartmentIcon sx={{ fontSize: 20 }} />,
    link: '/sedes',
  },
  {
    title: 'Gestión de Jornadas',
    subtitle: 'En esta sección podrá gestionar las jornadas para cada sede.',
    icon: <CalendarMonthIcon sx={{ fontSize: 20 }} />,
    link: '/jornadas',
  },
  {
    title: 'Gestión de Aulas',
    subtitle: 'En esta sección podrá gestionar las aulas para cada sede.',
    icon: <AccountBalanceIcon sx={{ fontSize: 20 }} />,
    link: '/aulas',
  },
  {
    title: 'Gestión de Materias',
    subtitle:
      'En esta sección podrá gestionar las materias y sus prerequisitos para cada sede.',
    icon: <ClassIcon sx={{ fontSize: 20 }} />,
    link: '/materias',
  },
  {
    title: 'Gestión de Docentes',
    subtitle: 'En esta sección podrá gestionar los docentes para cada sede.',
    icon: <ManageAccountsIcon sx={{ fontSize: 20 }} />,
    link: '/docentes',
  },
  {
    title: 'Plan de Estudios (Pensum)',
    subtitle:
      'En esta sección podrá gestionar los planes de estudios para cada sede.',
    icon: <AutoStoriesIcon sx={{ fontSize: 20 }} />,
    link: '/pensum',
  },
  {
    title: 'Gestión de Programas',
    subtitle:
      'En esta sección podrá gestionar los programas de estudio para cada sede.',
    icon: <BallotIcon sx={{ fontSize: 20 }} />,
    link: '/programas',
  },
  {
    title: 'Integración de Aulas y Materias',
    subtitle:
      'En esta sección podrá integrar las materias a las aulas para cada sede.',
    icon: <AccountTreeIcon sx={{ fontSize: 20 }} />,
    link: '/integraciones-aulas',
  },
];
const PanelControl = () => {
  const navigate = useNavigate();

  return (
    <Paper className="flex mt-10 flex-col flex-auto p-24 shadow rounded-2xl overflow-hidden h-full">
      <div className="flex flex-col sm:flex-row items-start justify-between">
        <Typography
          className="text-lg font-bold tracking-tight leading-6 truncate"
          variant="h4"
          fontWeight={700}
        >
          <TuneIcon sx={{ fontSize: 24 }} />
          PANEL DE CONTROL
        </Typography>
      </div>
      <List className="py-0 mt-8 divide-y">
        {options?.map((item, index) => (
          <ListItemButton
            key={index}
            className="px-0"
            onClick={() => navigate(item?.link)}
          >
            <ListItemText
              classes={{ root: 'px-8', primary: 'font-bold' }}
              primary={
                <span className="font-medium capitalize">{item.title}</span>
              }
              secondary={
                <span className="flex flex-col sm:flex-row sm:items-center -ml-2 mt-8 sm:mt-4 space-y-4 sm:space-y-0 sm:space-x-12">
                  <span className="flex items-center">
                    {item.icon}
                    <Typography
                      component="span"
                      className="mx-16 text-md "
                      color="text.secondary"
                      sx={{ ml: 1 }}
                    >
                      {item.subtitle}
                    </Typography>
                  </span>
                </span>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                aria-label="more"
                size="large"
                onClick={() => navigate(item.link)}
              >
                <ArrowForwardIosIcon fontSize="inherit" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItemButton>
        ))}
      </List>
    </Paper>
  );
};

export default PanelControl;
