import React from 'react';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';

const estudianteMenu = [
  {
    label: 'Mi Cuenta',
    type: 'section',
    children: [
      {
        uri: '/perfil',
        label: 'Perfil',
        type: 'nav-item',
        icon: <AccountBoxOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: 'Gestión de Prácticas',
    type: 'section',
    children: [
      {
        uri: '/agendar-practicas',
        label: 'Agendar',
        type: 'nav-item',
        icon: <CalendarMonthIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/agendamientos',
        label: 'Mis Agendamientos',
        type: 'nav-item',
        icon: <CalendarViewMonthIcon  sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default estudianteMenu;
