import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import Div from '@jumbo/shared/Div';
import Spinner from 'app/shared/Spinner';
import CustomDataGrid from 'app/shared/CustomDataGrid/CustomDataGrid';
import { useSelector } from 'react-redux';
import useGetReserves from 'app/services/reserve/getReserves';
import { useReservasStudentColumns } from './hooks/useReservasStudentColumns';
import useGetStudentReserves from 'app/services/reserve/getStudentReserves';
const CancelarPracticasEstudiante = () => {
  const AuthUser = useSelector(({ auth }) => auth);

  const columns = useReservasStudentColumns();

  const { data: reserves, isLoading: isLoadingReservas } =
    useGetStudentReserves(AuthUser?.id);

  return (
    <Card sx={{ mt: 4 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight={'Bold'}>
              MIS AGENDAMIENTOS
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {isLoadingReservas ? (
              <Div
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: { xs: 300, xl: 600 },
                }}
              >
                <Spinner />
              </Div>
            ) : reserves?.length > 0 ? (
              <CustomDataGrid
                columns={columns}
                data={reserves}
                br={0}
                pageSize={15}
                id="qr"
                // pageOptions={[15, 30, 50, 100]}
                sortModel={[{ field: 'subjectName', sort: 'asc' }]}
                isLoading={isLoadingReservas}
              />
            ) : (
              <NoDataFound2 label="No se encontraron resultados para la busqueda" />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CancelarPracticasEstudiante;
