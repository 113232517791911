import React from 'react';
import List from '@mui/material/List';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import styled from '@emotion/styled';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useSelector } from 'react-redux';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 24,
  height: 48,
  width: 48,
  borderRadius: '50%',
  minWidth: 42,
  marginRight: 16,
  padding: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
  border: `solid 1px ${theme.palette.divider}`,
}));
const About = () => {
  const { authUser } = useJumboAuth();
  const { roleName } = useSelector(({ auth }) => auth);
  return (
    <JumboCardQuick
      title={
        <Typography variant="h4" fontWeight={'bold'}>
          INFORMACIÓN DEL USUARIO
        </Typography>
      }
      headerSx={{
        borderTop: 1,
        borderBottom: 1,
        borderColor: 'divider',
      }}
      sx={{ mb: 3.75, boxShadow: 0, px: 4, mt: { xs: 2, md: 0 } }}
    >
      <List
        disablePadding
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          justifyContent: 'space-between',
          margin: (theme) => theme.spacing(0, -2),
        }}
      >
        {authUser?.email && (
          <ListItem>
            <StyledListItemIcon>
              <ApartmentIcon fontSize={'inherit'} />
            </StyledListItemIcon>
            <ListItemText
              primary={
                <Typography
                  fontSize={'12px'}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Email
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {authUser?.email}
                </Typography>
              }
            />
          </ListItem>
        )}

        {authUser?.phone && (
          <ListItem>
            <StyledListItemIcon>
              <ContactPhoneIcon fontSize={'inherit'} />
            </StyledListItemIcon>
            <ListItemText
              primary={
                <Typography
                  fontSize={'12px'}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Celular
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {authUser?.phone}
                </Typography>
              }
            />
          </ListItem>
        )}

        <ListItem>
          <StyledListItemIcon>
            <GroupsOutlinedIcon fontSize={'inherit'} />
          </StyledListItemIcon>
          <ListItemText
            primary={
              <Typography
                fontSize={'12px'}
                variant="h6"
                color="text.secondary"
                mb={0.5}
              >
                Tipo de usuario
              </Typography>
            }
            secondary={
              <Typography variant="body1" color="text.primary">
                {roleName}
              </Typography>
            }
          />
        </ListItem>
        {authUser?.document && (
          <ListItem>
            <StyledListItemIcon>
              <AssignmentIndIcon fontSize={'inherit'} />
            </StyledListItemIcon>
            <ListItemText
              primary={
                <Typography
                  fontSize={'12px'}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Documento de identidad
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {authUser?.document}
                </Typography>
              }
            />
          </ListItem>
        )}
      </List>
    </JumboCardQuick>
  );
};

export default About;
