import { useMutation, useQuery } from 'react-query';
import client from '../config';

export const getPrematriculaBySedePeriodo = async (idSede) => {
  if (!idSede) return [];
  const { data } = await client.get(`/pre-matricula/get-all-students-for-sede/${idSede}`);
  return data;
};

export const useGetPrematriculaBySedePeriodo = (idSede) => {
  return useQuery(['getPrematriculaBySedePeriodo', idSede], () =>
    getPrematriculaBySedePeriodo(idSede)
  );
};

export const useGetPrematriculaBySedePeriodoMutation = () => {
  return useMutation((idSede) => getPrematriculaBySedePeriodo(idSede));
};
