import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Div from '@jumbo/shared/Div';

const CustomFabLoadingButton = ({ onClick, loading, isSuccess, disabled }) => {
  const buttonSx = {
    ...(isSuccess && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  return (
    <Div sx={{ m: 1, position: 'fixed', bottom: 16, right: 16 }}>
      <Fab
        aria-label="save"
        color="secondary"
        disabled={disabled ? disabled : loading}
        // sx={buttonSx}
        sx={{
          ...buttonSx,

          // display: { xs: 'none', sm: 'flex' },
        }}
        onClick={onClick}
      >
        {isSuccess ? <CheckIcon /> : <SaveAltIcon />}
      </Fab>
      {loading && (
        <CircularProgress
          size={68}
          sx={{
            color: green[500],
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
            display: { xs: 'none', sm: 'flex' },
          }}
        />
      )}
    </Div>
  );
};
export default CustomFabLoadingButton;
