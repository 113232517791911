import Div from '@jumbo/shared/Div';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useGetHorarioBySedeMutation } from 'app/services/horario/getHorarioBySede';
import { useGetMallasBySede } from 'app/services/malla/getMallasBySede';
import useGetSedes from 'app/services/sedes/getSedes';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import Spinner from 'app/shared/Spinner';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import TablaGrillaHorarios from './components/TablaGrillaHorarios';
import { useGetSubjectsMalla } from 'app/services/subject/getSubjectsMalla';

const Grilla = () => {
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const [mallasByAulas, setMallas] = useState([]);
  const { mutate: getOpcionesEstados, isLoading: isLoadingOpcionesEstados } =
    useGetSubjectsMalla();
  const { mutate: getHorarios, isLoading: isLoadingHorarios } =
    useGetHorarioBySedeMutation();
  const { mutate: getMayasBySede, isLoading: isLoadingMallas } =
    useGetMallasBySede();
  const [horarios, setHorarios] = useState([]);
  const [opcionesEstados, setOpcionesEstados] = useState([]);
  const methods = useForm({
    defaultValues: {
      sede: null,
    },
  });
  const { control, watch, resetField } = methods;
  const watchSede = watch('sede');

  useEffect(() => {
    setMallas([]);
    setHorarios([]);
    if (!watchSede) return;
    getMayasBySede(watchSede, {
      onSuccess: (mallasBySede) => {
        const aulasSeparadas = [
          ...new Set(mallasBySede.map((aula) => aula.aulaName)),
        ];

        const mallasSeparadas = aulasSeparadas.map((aula) => {
          const mallasAula = mallasBySede.filter(
            (malla) => malla.aulaName === aula
          );
          return {
            aulaName: aula,
            idAula: mallasAula[0].aula, // Puedes tomar el id de la primera malla (asumiendo que todas las mallas para la misma aula tienen el mismo id)
            horarios: mallasAula,
          };
        });
        setMallas(mallasSeparadas);
      },
    });
    getOpcionesEstados(watchSede, {
      onSuccess: (data) => {
        console.log(data, 'data');
        setOpcionesEstados(data);
      },
    });

    getHorarios(watchSede, {
      onSuccess: (horariosBySede) => {
        setHorarios(horariosBySede);
      },
    });
  }, [watchSede, getHorarios]);
  return (
    <Card sx={{ mt: 4 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
              GRILLA DE HORARIOS
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" mb={2} gutterBottom>
              1. Selecione la sede
            </Typography>
            <CustomAutoComplete
              isRequired
              control={control}
              name="sede"
              label="Sedes"
              options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
              isLoading={isLoadingSedes}
              getOptionLabel={(option) => option?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Div
              sx={{
                bgcolor: 'secondary.dark',
                color: 'white',
                p: 2,
                borderRadius: 1,
              }}
            >
              <Typography className="text-justify text-muted font-weight-bold text-sm mb-2">
                NOTA: Recuerde que si desea modificar la disponibilidad de
                tiempos en la malla y estos cambios no son permanentes y son
                temporales por días, usted deberá realizar modificaciones el día
                anterior ya que si no se realizan, las practicas agendadas que
                se registren en no disponible serán canceladas de manera
                automática
              </Typography>
            </Div>
          </Grid>

          <Grid item xs={12}>
            {isLoadingOpcionesEstados ||
            isLoadingHorarios ||
            isLoadingMallas ? (
              <Div
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: { xs: 300, xl: 600 },
                }}
              >
                <Spinner />
              </Div>
            ) : mallasByAulas?.length > 0 ? (
              <TablaGrillaHorarios
                opcionesEstados={opcionesEstados}
                horariosBySede={horarios}
                mallasByAulas={mallasByAulas}
                selectedSede={watchSede}
              />
            ) : (
              <NoDataFound2 label="No se encontraron resultados para la busqueda" />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Grilla;
