import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const createDocente = async (docente) => {
  const { data } = await client.post(`/profesor/create`, docente);
  return data;
};

export default function useCreateDocente() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(createDocente, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('docentes');
      queryClient.invalidateQueries([data?.sede, 'docentesBySede']);
      enqueueSnackbar('Docente creado correctamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      enqueueSnackbar(
        errorMessages === 'DOCUEMENTO_YA_CREADO'
          ? 'Ya se encuentra registrado un docente con este documento'
          : 'Error al crear',
        {
          variant: 'error',
        }
      );
    },
  });
}
