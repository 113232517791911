import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Link from '@mui/material/Link';
import { alpha, useTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import Div from '@jumbo/shared/Div';
import { Controller, useForm } from 'react-hook-form';
import { useBasicAuth } from 'app/auth-providers/BasicAuth/hooks';
import { useSnackbar } from 'notistack';
import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';
import bgImg from '../../../assets/images/bg_mixer.jpg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const Login = () => {
  const { signIn } = useBasicAuth();
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      passWord: '',
    },
  });

  const onSubmit = ({ email, passWord }) => {
    signIn(
      email,
      passWord,
      (response) => {},
      (error) => {
        const { message } = error;
        enqueueSnackbar(
          message === 'USER_NOT_FOUND'
            ? 'Usuario no encontrado'
            : message === 'PASSWORD_INCORRECT'
            ? 'Contraseña invalida'
            : 'Error al iniciar sesion  ',
          {
            variant: 'error',
          }
        );
      }
    );
  };

  return (
    <Div
      sx={{
        minWidth: { xs: '100%', sm: 720 },
        maxWidth: '100%',
        margin: 'auto',
        p: { xs: 2, md: 4 },
        py: { xs: 6, md: 0 },
      }}
    >
      {isDesktop && (
        <>
          <Box
            component={'img'}
            src="/logo_horizontal.png"
            sx={{
              position: 'absolute',
              top: { md: 60, xl: '10%' }, // Centra verticalmente en el centro del contenedor padre
              left: '50%', // Centra horizontalmente en el centro del contenedor padre
              transform: 'translate(-50%, -50%)',
              width: { md: 180, xl: 250 },
              objectPosition: 'top',
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            }}
          />
          <Box
            component={'img'}
            src={"/backgrounds/bg_purple1.png"}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: '100%',
              height: '50vh',
              objectPosition: 'center',
              zIndex: -1,
              boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.25)',
              // msTransform: 'scaleX(-1)',
            }}
          />
          <Box
            component={'img'}
            src="/icons/SVG/LogoDNA.svg"
            sx={{
              position: 'absolute',
              top: '30%', // Centra verticalmente en el centro del contenedor padre
              left: '50%', // Centra horizontalmente en el centro del contenedor padre
              transform: 'translate(-50%, -50%)', // Centra completamente
              zIndex: -2,
              width: '65%',
            }}
          />
        </>
      )}
      <Card
        sx={{
          display: 'flex',
          minWidth: 0,
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <CardContent
          sx={{
            flex: '0 1 300px',
            position: 'relative',
            backgrounColor: (theme) => theme.palette.primary.dark,
            background: `#222328ff url(${bgImg}) no-repeat center`,
            backgroundSize: 'cover',

            '&::after': {
              display: 'inline-block',
              position: 'absolute',
              content: `''`,
              inset: 0,
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.contrastText, 0.5),
            },
          }}
        >
          <Div
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              color: 'common.white',
              position: 'relative',
              zIndex: 1,
              height: '100%',
            }}
          >
            <Div sx={{ mb: 2, pt: { xs: 2, md: 0 } }}>
              <Typography
                variant={'h3'}
                color={'inherit'}
                fontWeight={500}
                mb={3}
              >
                Bienvenido a <strong>Agendamientos DNA Music</strong>
              </Typography>
            </Div>

            <Div sx={{ mt: 'auto' }}>
              <Link
                href="/user/login"
                underline="none"
                sx={{ display: 'inline-flex' }}
              >
                <Box
                  component="img"
                  src={'/logo_horizontal.png'}
                  sx={{ height: 50 }}
                  mr={1}
                />
              </Link>
            </Div>
          </Div>
        </CardContent>
        <CardContent
          sx={{
            flex: 1,
            p: { xs: 2, md: 4 },
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Div sx={{ mt: 1, mb: 2 }}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Campo requerido',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: ' Email invalido',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    type="email"
                    placeholder="Esciba su email"
                    fullWidth
                    helperText={errors.email?.message}
                    error={!!errors.email}
                  />
                )}
              />
            </Div>
            <Div sx={{ mt: 1, mb: 2 }}>
              <Controller
                name="passWord"
                control={control}
                rules={{
                  minLength: {
                    value: 6,
                    message: 'Debe tener almenos 6 caraacteres',
                  },
                  required: 'Campo requerido',
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type={showPassword ? 'text' : 'password'}
                    label="Contraseña"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      ),
                    }}
                    placeholder="Esciba su contraseña"
                    fullWidth
                    helperText={errors.passWord?.message}
                    error={!!errors.passWord}
                  />
                )}
              />
            </Div>
            <Typography textAlign={'right'} variant={'body1'}>
              <Link
                underline="hover"
                href="/user/recuperar-password"
                color={'secondary.dark'}
              >
                ¿Olvidaste tu contraseña?
              </Link>
            </Typography>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 1, mb: 2 }}
              fullWidth
            >
              Ingresar
            </Button>
            <Typography textAlign={'center'} variant={'body1'} mt={1}>
              ¿No tienes una cuenta?
              <Link
                underline="none"
                color={'secondary.dark'}
                fontWeight={600}
                fontSize={15}
                fontStyle={'italic'}
                href="/user/registrarse"
              >
                &nbsp; Registrate
              </Link>
            </Typography>
          </form>
        </CardContent>
      </Card>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Typography variant={'body1'} mb={2}>
          Siguenos
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            component={NavLink}
            to="https://www.facebook.com/DNAMusicDJ"
            sx={{
              bgcolor: '#385196',
              color: 'common.white',
              p: (theme) => theme.spacing(1.25),

              '&:hover': {
                backgroundColor: '#385196',
              },
            }}
            aria-label="Facebook"
          >
            <Facebook fontSize={'small'} />
          </IconButton>
          <IconButton
            component={NavLink}
            to="https://twitter.com/DnaMusicDJ"
            sx={{
              bgcolor: '#00a8ff',
              color: 'common.white',
              p: (theme) => theme.spacing(1.25),

              '&:hover': {
                backgroundColor: '#00a8ff',
              },
            }}
            aria-label="Twitter"
          >
            <Twitter fontSize={'small'} />
          </IconButton>
          <IconButton
            component={NavLink}
            to="https://www.instagram.com/dnamusicdj/"
            sx={{
              bgcolor: '#e84393',
              color: 'common.white',
              p: (theme) => theme.spacing(1.25),

              '&:hover': {
                backgroundColor: '#e84393',
              },
            }}
            aria-label="Instagram"
          >
            <Instagram fontSize={'small'} />
          </IconButton>
          <IconButton
            component={NavLink}
            to="https://www.youtube.com/@DNAMusicdj"
            sx={{
              bgcolor: '#e84118',
              color: 'common.white',
              p: (theme) => theme.spacing(1.25),

              '&:hover': {
                backgroundColor: '#e84118',
              },
            }}
            aria-label="Youtube"
          >
            <YouTube fontSize={'small'} />
          </IconButton>
        </Stack>
      </Box>
    </Div>
  );
};

export default Login;
