import { useSelector } from 'react-redux';
import client from '../config';
import { useQuery } from 'react-query';

export const getCargaAcademica = async () => {
  const { data } = await client.get(`/user/carga-academica`);
  return data;
};

export default function useGetCargaAcademica() {
  const { id } = useSelector(({ auth }) => auth);

  return useQuery(['cargaAcademica', id], getCargaAcademica, {
    refetchOnWindowFocus: false,
  });
}
