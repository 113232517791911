import client from '../config';
import { useMutation } from 'react-query';

export const createReserva = async ({ dataForm }) => {
  console.log(dataForm, 'reserva');
  const { data } = await client.post(`reserve/reservar`, dataForm);
  return data;
};

export default function useCreateReserva() {
  return useMutation(createReserva, {
    onSuccess: (data) => {
      console.log(data, 'reservaa exitosa');
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
    },
  });
}
