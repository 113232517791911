import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Checkbox, Chip, CircularProgress, ThemeProvider, createTheme } from '@mui/material';
import _ from 'lodash';
import { Controller } from 'react-hook-form';

export default function CustomAutocompleteMultipleForm({
  options = [],
  label,
  control,
  onChangeCallback = () => {},
  getOptionLabel,
  size,
  loading,
  noOptionsText = 'No hay opciones',
  isOptionEqualToValue = (option, value) => option?.id === value?.id,
  display = { xs: 'block' },
  limitTags = 2,
  name = 'name',
  disableClearable = true,
  renderOption,
  value,
  optionLabel = 'name',
  isRequired = false,
}) {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#941d80', // Modifica el color principal
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Controller
        control={control}
        name={name}
        rules={{ required: isRequired ? 'Campo requerido' : false }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Autocomplete
            id={label}
            value={
              value
                ? value?.map((id) =>
                    options?.find((option) => option?.id === id)
                  )
                : []
            }
            onChange={(event, newValue) => {
              onChange(newValue.map((item) => item?.id));
              onChangeCallback(newValue);
            }}
            sx={{ display: display }}
            size={size}
            loadingText="Cargando..."
            multiple
            color='secondary'
            limitTags={limitTags}
            disableClearable={disableClearable}
            loading={loading}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption  ? renderOption : (props, option, { selected }) => { 
              const label = getOptionLabel(option);
              return (
                <li {...props}>
                  <Checkbox
                  // color='secondary' 
                  style={{ marginRight: 8 }} checked={selected} />
                  {label}
                </li>
              );
            }}

            noOptionsText={noOptionsText}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const tagLabel = option
                  ? option[optionLabel]
                  : 'Etiqueta no válida';
                return (
                  <Chip
                    key={option?.id}
                    size="small"
                    label={tagLabel}
                    className="mr-2"
                    {...getTagProps({ index })}
                  />
                );
              })
            }
            isOptionEqualToValue={isOptionEqualToValue}
            classes={{ outlinedInput: 'myOutlinedInput-notchedOutline' }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.stopPropagation();
                  }
                }}
                color='primary'
                error={!!error}
                helperText={error ? error.message : null}
                placeholder={label}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      />
    </ThemeProvider>
  );
}

