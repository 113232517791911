import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import useGetSubjectsBySedeQuery from 'app/services/subject/getSubjectsBySede';
import CustomAutocompleteMultipleForm from 'app/shared/ReactHookForm/CustomAutocompleteMultipleForm';
import useSetAulaSubjects from 'app/services/aula/setAulaSubjects';
const AddMateriasToAula = ({ aulas }) => {
  const { control, handleSubmit, setValue, watch, resetField } =
    useFormContext();
  const watchSede = watch('sede');
  const { mutate: setAulaSubjects, isLoading: isSetting } =
    useSetAulaSubjects();
  const { data: materias, isLoading: isLoadingMaterias } =
    useGetSubjectsBySedeQuery(watchSede);
  const onSubmit = (data) => {
    setAulaSubjects(
      {
        idAula: data.aula,
        materias: data.materias,
      },
      {
        onSuccess: () => {
          setValue('refetch', true, { shouldValidate: true }, false, true);
          resetField('aula');
          resetField('materias');
        },
      }
    );
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" fontWeight="bold">
          Agregar Materias:
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <CustomAutoComplete
          isRequired
          control={control}
          name="aula"
          label="Aula"
          options={aulas?.map((aula) => ({ id: aula._id, ...aula }))}
          getOptionLabel={(option) => option?.name}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <CustomAutocompleteMultipleForm
          name="materias"
          label={'Materias'}
          control={control}
          isRequired
          options={
            materias?.map((materia) => ({
              id: materia._id,
              ...materia,
            })) || []
          }
          limitTags={4}
          getOptionLabel={(option) => option.name}
          isLoading={isLoadingMaterias}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          fullWidth
          disabled={isSetting}
          startIcon={isSetting ? <CircularProgress size={20} /> : <AddIcon />}
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          size="large"
          color="primary"
          sx={{ mt: 1 }}
        >
          {isSetting ? 'Creando...' : 'Crear'}
        </Button>
      </Grid>
    </>
  );
};

export default AddMateriasToAula;
