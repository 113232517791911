import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import SedeList from './components/SedeList';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import useCreateSede from 'app/services/sedes/createSede';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
const sedeModel = {
  name: '',
  code: '',
};
const SedesPage = () => {
  const { mutate: create, isLoading: isCreating } = useCreateSede();
  const navigate = useNavigate();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: sedeModel,
  });

  const { control, handleSubmit, reset, setError } = methods;
  const onSubmit = (data) => {
    create(data, { onSuccess: () => reset(sedeModel) });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                <IconButton
                  onClick={() => navigate('/panel-control', { replace: true })}
                >
                  <ArrowBack />
                </IconButton>
                CREAR SEDE
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom mb={2}>
                Nombre:
              </Typography>
              <CustomTextField
                name={'name'}
                control={control}
                label="Nombre"
                isRequired
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom mb={2}>
                Codigo:
              </Typography>
              <CustomTextField name={'code'} control={control} label="Codigo" />
            </Grid>

            <Grid item xs={12} md={4}>
              <Button
                startIcon={isCreating ? <CircularProgress size={16} /> : null}
                disabled={isCreating}
                sx={{ mt: { md: 5 } }}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                size="large"
              >
                {isCreating ? 'Creando...' : 'Crear'}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'}>
                LISTA DE SEDES
              </Typography>
            </Grid>
            <SedeList />
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default SedesPage;
