import EditIcon from '@mui/icons-material/Edit';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditarPensum from './EditarPensum';
import { useDeletePensum } from 'app/services/pensum/deletePensum';

const PensumActions = ({ params }) => {
  const { showDialog, hideDialog } = useJumboDialog();

  const pensum = params.row;
  const { mutate: deletePensum } = useDeletePensum(pensum?.sede?._id);

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          content: <EditarPensum pensum={pensum} onClose={hideDialog} />,
          maxWidth: 'md',
        });
        break;
      case 'delete':
        showDialog({
          title: 'Estas seguro de eliminar este Pensum?',
          variant: 'confirm',
          onYes: () => deletePensum(pensum?._id, { onSuccess: hideDialog }),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  const menuItems = [
    {
      icon: <EditIcon />,
      title: 'Editar',
      action: 'edit',
    },
    {
      icon: <DeleteIcon />,
      title: 'Eliminar',
      action: 'delete',
    },
  ];

  return (
    <>
      <JumboDdMenu
        icon={<MoreHorizIcon />}
        menuItems={menuItems}
        onClickCallback={handleItemAction}
      />
    </>
  );
};

export default PensumActions;
