import { useMutation } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const actualizarProgramasQ10 = async (idSede) => {
  if (!idSede) return [];
  const { data } = await client.post(`/program/updateProgram/${idSede}`);
  return data;
};

export default function useActualizarProgramasQ10() {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(actualizarProgramasQ10, {
    onSuccess: (data) => {
      enqueueSnackbar('Programas actualizados desde q10 correctamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al actualizar desde q10', {
        variant: 'error',
      });
    },
  });
}
