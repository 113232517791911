import React from 'react';
import TablaAgendamientosAdmin from './components/TablaAgendamientosAdmin';
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import useGetSedes from 'app/services/sedes/getSedes';
import { useState } from 'react';
import { useGetStudentBySedeMutation } from 'app/services/users/getStudentsBySede';
import { useEffect } from 'react';
import { useGetCargaAcademicaByIdStudentMutation } from 'app/services/users/getCargaAcademicaByIdStudent';
import { useGetAulasBySubjectAdmin } from 'app/services/subject/getAulasBySubjectAdmin';
import { useGetMallaBySubjectAdmin } from 'app/services/malla/getMallaBySubjectAdmin';
import { useGetHorarioBySedeMutation } from 'app/services/horario/getHorarioBySede';
import Spinner from 'app/shared/Spinner';
import { GroupHeader, GroupItems } from './Agendamientos';

const AgendamientosAdmin = () => {
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const [students, setStudents] = useState([]);
  const [materias, setMaterias] = useState([]);
  const [aulas, setAulas] = useState([]);
  const [malla, setMalla] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const { mutate: getStudents, isLoading: isLoadingStudents } =
    useGetStudentBySedeMutation();
  const { mutate: getMaterias, isLoading: isLoadingMaterias } =
    useGetCargaAcademicaByIdStudentMutation();
  const { mutate: getAulas, isLoading: isLoadingAulas } =
    useGetAulasBySubjectAdmin();
  const { mutate: getMalla, isLoading: isLoadingMalla } =
    useGetMallaBySubjectAdmin();
  const { mutate: getHorariosBySede, isLoading: isLoadingHorarios } =
    useGetHorarioBySedeMutation();
  const methods = useForm({
    defaultValues: {
      sede: null,
      estudiante: null,
      materia: null,
      aula: null,
      tipoPractica: null,
    },
  });
  const { control, watch, resetField } = methods;
  const watchSede = watch('sede');
  const watchMateria = watch('materia');
  const watchEstudiante = watch('estudiante');
  const watchAula = watch('aula');
  useEffect(() => {
    resetField('estudiante');
    if (!watchSede) {
      setStudents([]);
      return;
    }
    getStudents(watchSede, {
      onSuccess: (data) => {
        setStudents(data);
      },
    });
    getHorariosBySede(watchSede, {
      onSuccess: (data) => {
        setHorarios(data);
      },
    });
  }, [watchSede]);

  useEffect(() => {
    resetField('materia');
    if (!watchEstudiante) {
      setMaterias([]);
      return;
    }
    getMaterias(
      {
        idStudent: watchEstudiante,
        idSede: watchSede,
      },
      {
        onSuccess: (materias) => {
          const formattedData = materias.reduce((result, program) => {
            const programCode = program.programCode;
            const nombrePrograma = program.nombrePrograma;

            const cursosFormatted = program.cursos
              .map((curso) => ({
                programCode,
                nombrePrograma,
                codigo_matricula: curso.codigo_matricula,
                periodo: curso.periodo,
                asignatura: curso.asignatura,
                estado: curso.estado,
              }))
              .filter(
                (curso) =>
                  curso.estado === 'En Curso' ||
                  curso.estado === 'Finalizada' ||
                  curso.estado === 'Homologación'
              );

            return result.concat(cursosFormatted);
          }, []);
          console.log(formattedData, 'opciones');

          setMaterias(formattedData || []);
        },
      }
    );
  }, [watchEstudiante]);

  useEffect(() => {
    resetField('aula');
    setAulas([]);
    setMalla([]);
    if (!watchMateria) {
      return;
    }
    getAulas(
      {
        subjectName: watchMateria?.trim(),
        idSede: watchSede,
      },
      {
        onSuccess: (aulas) => {
          setAulas(aulas || []);
        },
      }
    );
  }, [watchMateria]);

  useEffect(() => {
    if (!watchAula) {
      return;
    }
    getMalla(
      {
        subjectName: watchMateria?.trim(),
        idSede: watchSede,
      },
      {
        onSuccess: (malla) => {
          console.log(malla, 'malla');
          const filterMallaByAula = malla?.filter(
            (item) => item?.aula === watchAula
          );
          setMalla(filterMallaByAula);
        },
      }
    );
  }, [watchAula]);

  return (
    <FormProvider {...methods}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} gutterBottom>
                AGENDAR PRACTICAS
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                1. Selecione la sede
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomAutoComplete
                isRequired
                control={control}
                name="sede"
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                2. Selecione el estudiante
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomAutoComplete
                isRequired
                control={control}
                name="estudiante"
                label="Estudiantes"
                placeholder="Buscar por nombre o documento de identidad"
                options={students?.map((student) => ({
                  id: student._id,
                  ...student,
                }))}
                isLoading={isLoadingStudents}
                getOptionLabel={(option) =>
                  `${option?.name}  - doc: ${option?.document}`
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">3. Selecione la materia</Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'materia'}
                rules={{
                  required: 'Campo requerido',
                }}
                control={control}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    id="course-autocomplete"
                    options={materias}
                    loading={isLoadingMaterias}
                    groupBy={(option) => option.nombrePrograma}
                    getOptionLabel={(option) => option.asignatura}
                    value={
                      value
                        ? materias.find((option) => {
                            return value === option.asignatura;
                          }) ?? value
                        : null
                    }
                    onChange={(_, data) => {
                      const selectedValue = data
                        ? data.asignatura
                          ? data.asignatura
                          : data
                        : null;
                      onChange(selectedValue);
                    }}
                    // value={selectedCourse}
                    // onChange={(event, newValue) => {
                    //   setSelectedCourse(newValue);
                    // }}
                    // sx={{ width: 300 }}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Selecciona un curso" />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">4. Selecione el aula</Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomAutoComplete
                isRequired
                control={control}
                name="aula"
                label="Aulas"
                options={aulas?.map((aula) => ({
                  id: aula._id,
                  ...aula,
                }))}
                isLoading={isLoadingAulas}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">
                5. Selecione el tipo de practica
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomAutoComplete
                isRequired
                control={control}
                name="tipoPractica"
                label="Tipo de practica"
                options={['Practica libre', 'Practica por audición']}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </Grid>

            <Grid item xs={12} className="overflow-x-auto scrollbar">
              {isLoadingHorarios || isLoadingMalla ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 500,
                  }}
                >
                  <Spinner />
                </Box>
              ) : (
                <TablaAgendamientosAdmin
                  horariosBySede={horarios}
                  malla={malla}
                  aulas={aulas}
                  students={students}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </FormProvider>
  );
};

export default AgendamientosAdmin;
