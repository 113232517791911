import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deleteStudent = async ({ idStudent, idSede }) => {
  console.log(idStudent, idSede);
  const { data } = await client.delete(`/user/delete-student/${idStudent}`);
  return { ...data, idSede };
};

export const useDeleteStudent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation((student) => deleteStudent(student), {
    onSuccess: (data) => {
      console.log(data, 'data');
      queryClient.invalidateQueries([
        data?.sede ?? data?.idSede,
        'studentsBySede',
      ]);

      enqueueSnackbar('El usuario ha sido eliminado con éxito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar(
        'Error al eliminar el usuario, por favor intente de nuevo',
        {
          variant: 'error',
        }
      );
    },
  });
};
