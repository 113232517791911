import Div from '@jumbo/shared/Div';
import { Grid } from '@mui/material';
import CustomDataGrid from 'app/shared/CustomDataGrid/CustomDataGrid';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import Spinner from 'app/shared/Spinner';
import React from 'react';
import { usePensumColumns } from '../hooks/usePensumColumns';
import useGetPensumBySede from 'app/services/pensum/getPensumBySede';

const PensumList = ({ selectedSede }) => {
  const columns = usePensumColumns();
  const { data: listPensum, isLoading } = useGetPensumBySede(selectedSede?._id);
  return (
    <Grid item xs={12}>
      {isLoading ? (
        <Div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: { xs: 300, xl: 600 },
          }}
        >
          <Spinner />
        </Div>
      ) : listPensum?.length > 0 ? (
        <CustomDataGrid
          columns={columns}
          data={listPensum?.map((m) => ({ ...m, sede: selectedSede })) || []}
          br={0}
          id="_id"
          height={{ xs: 630, md: 450, xl: 775 }}
          sortModel={[{ field: 'program', sort: 'asc' }]}
          isLoading={isLoading}
        />
      ) : (
        <NoDataFound2 label="No se encontraron resultados para la busqueda" />
      )}
    </Grid>
  );
};

export default PensumList;
