import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  TextField,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomAutoComplete = ({
  name,
  control,
  options = [],
  size,
  disabled = false,
  label,
  placeholder = 'Buscar..',
  errors,
  isRequired = false,
  renderOption,
  getOptionLabel,
  defaultValue = '',
  noOptionsText = 'No hay opciones',
  isLoading = false,
  shouldUnregister = false,
  isOptionEqualToValue = (option, value) => option?.id === value?.id,
  onChangeCallback,
}) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#941d80', // Modifica el color principal
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Controller
        name={name}
        shouldUnregister={shouldUnregister}
        defaultValue={defaultValue}
        rules={{
          required: isRequired ? 'Campo requerido' : false,
          shouldUnregister: shouldUnregister,
        }}
        control={control}
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <Autocomplete
            id={name}
            getOptionLabel={getOptionLabel}
            autoHighlight
            disabled={disabled}
            renderOption={
              renderOption
                ? renderOption
                : (props, option, { selected }) => {
                    const label = getOptionLabel(option);
                    return (
                      <li {...props}>
                        <Checkbox
                          // color="secondary"
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {label}
                      </li>
                    );
                  }
            }
            options={options}
            size={size}
            loading={isLoading}
            loadingText="Cargando..."
            value={
              value
                ? options.find((option) => {
                    return value === option.id;
                  }) ?? value
                : null
            }
            noOptionsText={noOptionsText}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={(_, data) => {
              const selectedValue = data ? (data.id ? data.id : data) : null;
              onChange(selectedValue);
              onChangeCallback && onChangeCallback(data);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={label}
                placeholder={placeholder}
                helperText={error?.message}
                inputRef={ref}
                error={!!error}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      />
    </ThemeProvider>
  );
};

export default CustomAutoComplete;
