import { useQuery } from 'react-query';
import client from '../config';

export const getSede = async (id) => {
  const { data } = await client.get(`/sede/byid/${id}`);
  return data;
};

export default function useGetSede(id) {
  return useQuery([id, 'sede'], () => getSede(id), {
    refetchOnWindowFocus: false,
    onError: (error) => {
      console.log(error.response.data);
    },
  });
}
