import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import client from '../config';

const cancelReserve = async ({ idReserva, idUser }) => {
  const { data } = await client.patch(
    `/student-reserva/cancel-reserve-admin/${idReserva}/${idUser}`
  );
  return data;
};

export default function useCancelReserve() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(cancelReserve, {
    onSuccess: (data) => {
      enqueueSnackbar('Reserva Cancelada con éxito.', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const { message } = error.response.data;
      console.log('error', error.response);
      enqueueSnackbar('Expiraste el tiempo de cancelación', {
        variant: 'error',
      });
    },
  });
}
