import React from 'react';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
const AdminMenu = [
  {
    label: 'Mi Cuenta',
    type: 'section',
    children: [
      {
        uri: '/perfil',
        label: 'Perfil',
        type: 'nav-item',
        icon: <AccountBoxOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: 'Gestión de Prácticas',
    type: 'section',
    children: [
      {
        uri: '/agendar-practicas/admin',
        label: 'Agendar ',
        type: 'nav-item',
        icon: <CalendarMonthIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/cancelar-practicas',
        label: 'Cancelar ',
        type: 'nav-item',
        icon: <EventRepeatIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/grilla-horarios',
        label: 'Grilla DNA',
        type: 'nav-item',
        icon: <CalendarViewMonthIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/integraciones',
        label: 'Integraciones',
        type: 'nav-item',
        icon: <IntegrationInstructionsIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/historial-agendamientos',
        label: 'Historial',
        type: 'nav-item',
        icon: <HistoryEduIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: 'Gestión de Usuarios',
    type: 'section',
    children: [
      {
        uri: '/usuarios',
        label: 'Usuarios',
        type: 'nav-item',
        icon: <GroupIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/estudiantes',
        label: 'Estudiantes',
        type: 'nav-item',
        icon: <SchoolIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default AdminMenu;
