import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deleteHorarioDocente = async ({ idHorario, idDocente }) => {
  const { data } = await client.delete(
    `/profesor/delete-horario-idHorario/${idHorario}/idProfesor/${idDocente}`
  );
  return data;
};

export const useDeleteHorarioDocente = (idSede) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(deleteHorarioDocente, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('docentes');
      queryClient.invalidateQueries([idSede, 'docentesBySede']);
      enqueueSnackbar('El horario ha sido removido exitosamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al eliminar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
};
