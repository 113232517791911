
export const useStudentsColumns = () => {
  const columns = [
    {
      field: 'documento',
      headerName: 'Documento',
      minWidth: 150,
    },
    {
      field: 'nombre',
      headerName: 'Nombre',
      minWidth: 300,
    },
    {
      field: 'modalidad',
      headerName: 'Modalidad',
      minWidth: 150,
    },
    {
      field: 'programa',
      headerName: 'Programa',
      minWidth: 280,
    },
    {
      field: 'curso',
      headerName: 'Curso',
      minWidth: 300,
    },
    {
      field: 'sede',
      headerName: 'Sede',
      minWidth: 100,
    },
    {
      field: 'jornada',
      headerName: 'Jornada',
      minWidth: 350,
    },
    {
      field: 'horario',
      headerName: 'Horario',
      minWidth: 120,
    },
    {
      field: 'perido',
      headerName: 'Periodo',
      minWidth: 120,
    },
  ];

  return columns;
};
