import EditIcon from '@mui/icons-material/Edit';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditarJornada from './EditarJornada';
import { useDeleteJornada } from 'app/services/jornada/deleteJornada';
import { memo } from 'react';

const JornadaActions = ({ jornada }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const { mutate: deleteJornada } = useDeleteJornada(jornada?.sede);

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          content: <EditarJornada jornada={jornada} onClose={hideDialog} />,
          maxWidth: 'md',
        });
        break;
      case 'delete':
        showDialog({
          title: 'Estas seguro de eliminar esta Jornada?',
          variant: 'confirm',
          onYes: () => deleteJornada(jornada?._id, { onSuccess: hideDialog }),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  const menuItems = [
    {
      icon: <EditIcon />,
      title: 'Editar',
      action: 'edit',
    },
    {
      icon: <DeleteIcon />,
      title: 'Eliminar',
      action: 'delete',
    },
  ].filter(Boolean);

  return (
    <>
      <JumboDdMenu
        icon={<MoreHorizIcon />}
        menuItems={menuItems}
        onClickCallback={handleItemAction}
      />
    </>
  );
};

export default memo(JornadaActions);
