import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const updateAulaVsMaterias = async ({ id, dataForm }) => {
  const { data } = await client.patch(`/aula-materia/update/${id}`, dataForm);
  return data;
};

export default function useUpdateAulaVsMaterias() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateAulaVsMaterias, {
    onSuccess: (data) => {
      console.log(data, 'data actualizada');
      queryClient.invalidateQueries('aulasVsMaterias');
      queryClient.invalidateQueries([data?.sede, 'aulasVsMateriasBySede']);
      enqueueSnackbar(`Actualizacion exitosa`, {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al actualizar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
}
