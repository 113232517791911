import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const createMateria = async (materia) => {
  const { data } = await client.post(`/materias/create`, materia);
  return data;
};

export default function useCreateMateria() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(createMateria, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('materias');
      queryClient.invalidateQueries([data?.sede, 'materiasBySede']);
      enqueueSnackbar('materia creada correctamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      enqueueSnackbar('Error al crear', {
        variant: 'error',
      });
    },
  });
}
