import Div from '@jumbo/shared/Div';
import ProgramaActions from '../components/ProgramaActions';

export const useProgramasColumns = () => {
  const columns = [
    {
      field: 'name',
      headerName: 'Programa',
      minWidth: 400,
    },
    {
      field: 'codigo',
      headerName: 'Codigo',
      minWidth: 100,
    },
    {
      field: 'sede',
      headerName: 'Sede',
      minWidth: 100 ,
      valueGetter: (params) => params.row.sede.name,
    },
    {
      field: 'abreviatura',
      headerName: 'Abreviatura',
      minWidth: 300,
    },
    {
      field: 'tipo',
      headerName: 'Modalidad',
      minWidth: 120,
      valueFormatter: (params) =>  params.value?.toUpperCase() || '',
    },
    {
      field: 'modalidad',
      headerName: 'Tipo',
      minWidth: 120,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      minWidth: 100,
      renderCell: (params) => <ProgramaActions params={params} />,
    },
  ];

  return columns;
};
