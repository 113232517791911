import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import moment from 'moment';

export const savePrematricula = async (dataForm) => {
  console.log(dataForm, 'dataForm');
  const formData = new FormData();
  formData.append(
    'file',
    dataForm.file,
    `DNA PREMATRICULA -${dataForm?.selectedSede?.name}-${moment().format(
      'DD-MM-YYYY HH:mm'
    )}.xlsx`
  );
  formData.append('sede', dataForm?.selectedSede?._id);

  const { data } = await client.post(
    `/pre-matricula/guardar-archivo`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return data;
};

export default function useSavePrematricula() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(savePrematricula, {
    onSuccess: (data) => {
      console.log(data, 'data');
      enqueueSnackbar('Prematricula guardada con exito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al crear el curso', {
        variant: 'error',
      });
    },
  });
}
