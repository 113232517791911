import { useMutation } from 'react-query';
import client from '../config';

export const getMallasBySede = async (idSede) => {
  const { data } = await client.get(`/malla/malla-by-sede/${idSede}`);
  return data;
};

export const useGetMallasBySede = () => {
  return useMutation((idSede) => getMallasBySede(idSede));
};
