import React from 'react';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import UserIcon from '@mui/icons-material/Person';
const AdminMenuPreMatricula = [
  {
    label: 'Mi Cuenta',
    type: 'section',
    children: [
      {
        uri: '/perfil',
        label: 'Perfil',
        type: 'nav-item',
        icon: <AccountBoxOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/panel-control',
        label: 'Panel de Control',
        type: 'nav-item',
        icon: <TuneIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/prematricula',
        label: 'Prematricula',
        type: 'nav-item',
        icon: <AddTaskIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/historial-prematriculas',
        label: 'Historial Prematriculas',
        type: 'nav-item',
        icon: <CloudDownloadIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/prematricula-estudiantes',
        label: 'Estudiantes',
        type: 'nav-item',
        icon: <UserIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default AdminMenuPreMatricula;
