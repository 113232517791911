import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import {
  Button,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Menu,
  MenuItem,
  CircularProgress,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useUpdateMalla from 'app/services/malla/updateMalla';
import { useGetSubjectsMallaBySede } from 'app/services/subject/getSubjectsMalla';
const TablaGrillaHorarios = ({
  horariosBySede,
  mallasByAulas,
  opcionesEstados,
  selectedSede,
}) => {
  const { data: subjectsMallaBySede, isLoading: isLoadingSubjectsBySede } =
    useGetSubjectsMallaBySede(selectedSede);
  const { mutate: updateMalla, isLoading: isLoadingUpdateMalla } =
    useUpdateMalla();
  moment.locale('es');
  // Estado local para los switches
  const [switchStates, setSwitchStates] = useState({});
  const [aulasToDisplay, setAulasToDisplay] = React.useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 4; // Cambia este valor según la cantidad de aulas que desees mostrar por página

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  useEffect(() => {
    const aulasToDisplay = mallasByAulas.slice(startIndex, endIndex);
    console.log(aulasToDisplay, 'aulasToDisplay');
    // Inicializa el estado de los switches
    const initialSwitchStates = {};
    aulasToDisplay.forEach((aula) => {
      aula.horarios.forEach((horario) => {
        // Comprueba si el horario está disponible y establece el estado correspondiente
        if (horario.subjectName === 'Practica Libre') {
          initialSwitchStates[horario._id] = {
            subjectName: horario.subjectName,
            checked: true,
            subject: horario.subject,
          }; // Supongamos que tienes un ID único para cada horario
        } else {
          initialSwitchStates[horario._id] = {
            subjectName: horario.subjectName,
            checked: false,
            subject: horario.subject,
          }; // Supongamos que tienes un ID único para cada horario
        }
      });
    });
    console.log(initialSwitchStates, 'initialSwitchStates');
    setSwitchStates(initialSwitchStates);
    setAulasToDisplay(aulasToDisplay);
    return () => {
      setSwitchStates({});
      setAulasToDisplay([]);
    };
  }, [page, mallasByAulas]);

  // const aulasToDisplay = mallasByAulas.slice(startIndex, endIndex);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // Manejador de eventos para cambiar el estado del switch
  const handleSwitchChange = ({ horarioId, subject }) => {
    console.log(subject, 'subject');
    console?.log(subjectsMallaBySede, 'subjectsMallaBySede');
    const findSubject = subjectsMallaBySede?.find(
      (subjectMalla) => subjectMalla._id !== subject
    );
    if (!findSubject) return;
    updateMalla(
      [
        {
          malla: horarioId,
          subject: findSubject?._id,
        },
      ],
      {
        onSuccess: () => {
          setSwitchStates((prevSwitchStates) => ({
            ...prevSwitchStates,
            [horarioId]: {
              subjectName: findSubject?.name,
              checked: !prevSwitchStates[horarioId].checked,
              subject: findSubject?._id,
            },
          }));
        },
      }
    ); // Asegúrate de pasar el ID correcto a la función
  };

  return (
    <div>
      <TablePagination
        component="div"
        count={mallasByAulas.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count} Aulas`
        }
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="border-b px-4 py-2">
              <Typography variant="h1">Horario</Typography>
            </TableCell>
            {aulasToDisplay?.map((aula, index) => (
              <TableCell key={index} className="border px-4 py-2">
                <Div
                  className=" rounded-lg py-5 px-5 lg:max-w-128"
                  sx={{ bgcolor: 'secondary.main', color: 'common.white' }}
                >
                  {aula?.aulaName}
                </Div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {horariosBySede?.map((hour, index) => (
            <TableRow key={index}>
              <TableCell className="border px-4 py-2 text-center">
                <Div
                  className="w-full text-center rounded-lg py-5 px-3"
                  sx={{ bgcolor: 'primary.main', color: '#000' }}
                >
                  {hour?.name}
                </Div>
              </TableCell>
              {aulasToDisplay.map((aula, aulaIndex) => {
                const horarioAula = aula.horarios.find(
                  (horario) => horario.horarioName === hour.name
                );

                return (
                  <TableCell
                    key={aulaIndex}
                    align="center"
                    className="border px-4 py-4 text-center "
                  >
                    <FormGroup>
                      <FormControlLabel
                        label={switchStates[horarioAula?._id]?.subjectName}
                        control={
                          <Switch
                            color="success"
                            checked={switchStates[horarioAula?._id]?.checked}
                            onChange={() =>
                              handleSwitchChange({
                                horarioId: horarioAula?._id,
                                subjectName:
                                  switchStates[horarioAula?._id]?.subjectName,
                                subject:
                                  switchStates[horarioAula?._id]?.subject,
                              })
                            }
                          />
                        }
                      />
                    </FormGroup>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Paginación */}
    </div>
  );
};

export default TablaGrillaHorarios;
