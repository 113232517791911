import React from 'react';
import CrearAula from './CrearAula';
import { useAulasColumns } from '../hooks/useAulasColumns';
import CustomDataGrid from 'app/shared/CustomDataGrid/CustomDataGrid';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import { Grid, Typography } from '@mui/material';
const Aulas = ({ aulas }) => {
  const columns = useAulasColumns();
  return (
    <>
      <CrearAula />
      <Grid item xs={12}>
        <Typography variant="h4" fontWeight="bold" mt={2}>
          Aulas:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {aulas?.length > 0 ? (
          <CustomDataGrid
            columns={columns}
            data={aulas}
            br={0}
            pageSize={15}
            id="_id"
            // pageOptions={[15, 30, 50, 100]}
            sortModel={[{ field: 'name', sort: 'asc' }]}
          />
        ) : (
          <NoDataFound2 label="No se encontraron resultados para la busqueda" />
        )}
      </Grid>
    </>
  );
};

export default Aulas;
