import client from '../config';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

export const verifyQ10User = async (user) => {
  console.log(user, 'usuario a verificar');
  const { data } = await client.post(`/user/verifyQ10User`, user);
  return data;
};

export default function useVerifyQ10User() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(verifyQ10User, {
    onSuccess: (data) => {
      console.log(data, 'data');
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      enqueueSnackbar('Usuario no encontrado', {
        variant: 'error',
      });
    },
  });
}
