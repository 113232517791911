import { useQuery } from 'react-query';
import axios from 'axios';

export const getFile = async (urlFile) => {
  if (!urlFile) return [];
  const { data } = await axios.get(urlFile, {
    responseType: 'arraybuffer',
  });
  return data;
};

export default function useFetchExcel(urlFile) {
  return useQuery([urlFile, 'excel'], () => getFile(urlFile), {
    onError: (error) => {
      console.log(error.response.data);
    },
  });
}
