import client from '../config';
import { useMutation } from 'react-query';

export const createReservaAdmin = async ({ idStudent, dataForm }) => {
  console.log(dataForm, 'reserva', idStudent);

  const { data } = await client.post(
    `reserve/reservar-admin/${idStudent}`,
    dataForm
  );
  return data;
};

export default function useCreateReservaAdmin() {
  return useMutation(createReservaAdmin, {
    onSuccess: (data) => {
      console.log(data, 'reservaa exitosa');
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
    },
  });
}
