import { useMutation, useQuery } from 'react-query';
import client from '../config';

export const getSubjectsMalla = async (idSede) => {
  const { data } = await client.get(`/subject/malla-subjects/${idSede}`);
  return data;
};

export const useGetSubjectsMallaBySede = (idSede) => {
  return useQuery(['subjectsMallaBySede', idSede], () =>
    getSubjectsMalla(idSede)
  );
};

export const useGetSubjectsMalla = () => {
  return useMutation((idSede) => getSubjectsMalla(idSede));
};
