import { useMutation, useQuery } from 'react-query';
import client from '../config';

export const getStudentsBySede = async (id) => {
  if (!id) return;
  const { data } = await client.get(`/user/get-student-bySede/${id}`);
  return data;
};

export default function useGetStudentsBySede(idSede) {
  return useQuery([idSede, 'studentsBySede'], () => getStudentsBySede(idSede), {
    refetchOnWindowFocus: false,
    onError: (error) => {
      console.log(error.response.data);
    },
  });
}

export const useGetStudentBySedeMutation = () => {
  return useMutation((id) => getStudentsBySede(id));
};
