import { useMutation, useQuery } from 'react-query';
import client from '../config';

export const getPeriodoBySede = async (idSede) => {
  if (!idSede) return [];
  const { data } = await client.get(`/jornada/get-periodos-q10/${idSede}`);
  return data;
};

export default function useGetPeriodoBySede(idSede) {
  return useQuery([idSede, 'periodoBySede'], () => getPeriodoBySede(idSede), {
    onError: (error) => {
      console.log(error.response.data);
    },
  });
}

export const useGetPeriodoBySedeMutation = () => {
  return useMutation((idSede) => getPeriodoBySede(idSede));
};
