import Div from '@jumbo/shared/Div';
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import useGetRoles from 'app/services/roles/getRoles';
import useGetSedes from 'app/services/sedes/getSedes';
import useVerifyQ10User from 'app/services/users/verifyQ10User';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SignUpContext } from '../SignUpProvider';
import { useSnackbar } from 'notistack';

const Step1 = ({ handleNext }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      document: '',
      rol: '',
      sede: '',
    },
  });
  const { setUser } = React.useContext(SignUpContext);
  const { data: roles } = useGetRoles();
  const { data: sedes } = useGetSedes();
  const { mutate: verifyQ10User, isLoading: isVerifying } = useVerifyQ10User();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    verifyQ10User(data, {
      onSuccess: (data) => {
        if (!data) {
          enqueueSnackbar('Usuario no encontrado', {
            variant: 'error',
          });
          return;
        }
        setUser(data);
        enqueueSnackbar('Usuario encontrado', {
          variant: 'success',
        });
        handleNext();
      },
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Div sx={{ mt: 1, mb: 2 }}>
        <Controller
          shouldUnregister
          name="document"
          control={control}
          rules={{
            required: 'Campo requerido',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Documento"
              type="text"
              placeholder="Documento"
              fullWidth
              helperText={errors.document?.message}
              error={!!errors.document}
            />
          )}
        />
      </Div>
      <Div sx={{ mt: 1, mb: 2 }}>
        <Controller
          shouldUnregister
          name="rol"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl error={!!errors.rol} fullWidth>
              <InputLabel id="clase">Rol</InputLabel>
              <Select
                {...field}
                labelId="clase"
                id="clase-select"
                label="Rol"
                disabled={!roles?.length}
              >
                {roles?.map((rol, index) => (
                  <MenuItem key={rol?._id} value={rol?._id}>
                    {rol.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {errors.rol ? 'Campo requerido' : null}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Div>

      <Div sx={{ mt: 1, mb: 2 }}>
        <Controller
          shouldUnregister
          name="sede"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl error={!!errors.sede} fullWidth>
              <InputLabel id="clase">Sede</InputLabel>
              <Select {...field} labelId="clase" id="clase-select" label="Sede">
                {sedes?.map((sede, index) => (
                  <MenuItem key={sede?._id} value={sede?._id}>
                    {sede?.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {errors.sede ? 'Campo requerido' : null}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Div>
      <Button
        //   onClick={() => navigate("/verificar-email")}
        disabled={isVerifying}
        startIcon={isVerifying ? <CircularProgress size={20} /> : null}
        type="submit"
        variant="contained"
        sx={{ mb: 2 }}
        fullWidth
      >
        {isVerifying ? 'Verificando' : 'Verificar'}
      </Button>
    </form>
  );
};

export default Step1;
