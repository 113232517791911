import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  Autocomplete,
  Box,
  Button,
  CardActions,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useEffect } from 'react';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import Spinner from 'app/shared/Spinner';
import CustomNumberTextField from 'app/shared/ReactHookForm/CustomNumberTextField';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import CustomAutocompleteMultipleForm from 'app/shared/ReactHookForm/CustomAutocompleteMultipleForm';
import useGetMateria from 'app/services/materias/getMateria';
import useUpdateMateria from 'app/services/materias/updateMateria';
import useGetSedes from 'app/services/sedes/getSedes';
import { useDeletePrerequisitoMateria } from 'app/services/materias/deletePrerequisitoMateria';
import useGetMateriasBySede from 'app/services/materias/getMateriasBySede';
import { useState } from 'react';
import ProgramasPrerequisitos from './ProgramasPrerequisitos';
const materiaModel = {
  materia: '',
  sede: '',
  aforo_minimo: '',
  prerequisitos: [],
  codigo: '',
  cupo_maximo: '',
};
const EditarMateria = ({ materia, onClose }) => {
  const { mutate: updateMateria, isLoading } = useUpdateMateria();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();

  const { data: materiaData, isLoading: isLoadingSede } = useGetMateria(
    materia?._id
  );

  const [selectedSede, setSelectedSede] = useState(null);
  const { data: materias, isLoading: isLoadingMaterias } =
    useGetMateriasBySede(selectedSede);
  const { mutate: deletePrerequisito } = useDeletePrerequisitoMateria();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: materiaModel,
  });
  const {
    control,
    reset,
    watch,
    resetField,
    formState: { isValid, dirtyFields },
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prerequisitos', // Nombre del campo en el formulario
  });
  const watchPrerequisitos = watch('prerequisitos', []);
  const onSubmit = (dataForm) => {
    const { prerequisitos, ...rest } = dataForm;
    const dataToSend = {
      ...rest,
      prerequisitos: prerequisitos.map((prerequisito) => {
        const { _id, programas, materiaName, ...rest } = prerequisito;
        return {
          materia: _id,
          programas: programas.map((programa) => programa._id),
        };
      }),
    };
    updateMateria(
      { dataMateria: dataToSend, id: materiaData?._id },
      { onSuccess: onClose }
    );
  };

  const onRemovePrerequisito = (newValues) => {
    if (newValues.length > watchPrerequisitos.length) return; // si se agrega un elemento no hago nada
    const removedElementId = (watchPrerequisitos || []).find(
      (oldOption) => !newValues.some((newOption) => newOption.id === oldOption)
    );
    deletePrerequisito({
      idMateria: materiaData?._id,
      idPrerequisito: removedElementId,
    });
  };
  const onChangeSede = (sede) => {
    resetField('prerequisitos');
    setSelectedSede(sede?._id);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#941d80', // Modifica el color principal
      },
    },
  });
  useEffect(() => {
    if (!materiaData) return;
    console.log(materiaData?.prerequisitos, 'materiaData?.prerequisitos');
    setSelectedSede(materiaData?.sede);
    reset({
      materia: materiaData?.materia,
      sede: materiaData?.sede,
      aforo_minimo: materiaData?.aforo_minimo,
      prerequisitos: materiaData?.prerequisitos?.map((p) => ({
        _id: p?.id,
        materiaName: p?.materia,
        ...p,
      })),
      codigo: materiaData?.codigo,
      abreviacion: materiaData?.abreviacion,
      cupo_maximo: materiaData?.cupo_maximo,
    });
  }, [materiaData]);

  if (isLoadingSede)
    return (
      <Box
        sx={{
          height: 300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </Box>
    );
  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Editar Materia - <strong>{materiaData?.materia}</strong>`
          </Typography>
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: 'background.default',
            px: 2,
            pb: 2,
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom mb={2}>
              1. Nombre:
            </Typography>
            <CustomTextField
              name={'materia'}
              control={control}
              label="Nombre"
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom mb={2}>
              2. Abreviacion:
            </Typography>
            <CustomTextField
              name={'abreviacion'}
              control={control}
              label="Abreviacion"
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom mb={2}>
              3. Aforo:
            </Typography>
            <CustomNumberTextField
              name={'aforo_minimo'}
              control={control}
              label="Aforo"
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom mb={2}>
              4. Codigo:
            </Typography>
            <CustomTextField
              name={'codigo'}
              control={control}
              label="Codigo"
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom mb={2}>
              5. Selecione la sede:
            </Typography>
            <CustomAutoComplete
              isRequired
              control={control}
              name="sede"
              label="Sedes"
              options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
              isLoading={isLoadingSedes}
              getOptionLabel={(option) => option?.name}
              onChangeCallback={onChangeSede}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom mb={2}>
              6. Cupo Maximo:
            </Typography>
            <CustomNumberTextField
              name={'cupo_maximo'}
              control={control}
              label="Cupo Maximo"
              isRequired
            />
          </Grid>
          <ThemeProvider theme={theme}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom mb={2}>
                Seleccione los prerequisitos:
              </Typography>
              <Autocomplete
                id={'prerequisitos'}
                options={
                  materias?.map((m) => ({
                    _id: m?.id,
                    materiaName: m?.materia,
                    ...m,
                  })) || []
                }
                onChange={(event, newValue) => {
                  if (newValue.length > fields.length) {
                    const newItem = newValue[newValue.length - 1];
                    console.log('newValue', newItem);
                    append({
                      _id: newItem?._id,
                      materia: newItem?._id,
                      materiaName: newItem?.materia,
                      programas: [],
                    });
                  } else {
                    const removedIndex = fields.findIndex(
                      (field) => !newValue.find((itm) => itm._id === field._id)
                    );
                    remove(removedIndex);
                  }
                }}
                loadingText="Cargando..."
                multiple
                value={fields}
                disableClearable
                loading={isLoadingMaterias}
                getOptionLabel={(option) => option.materia}
                noOptionsText="No hay opciones"
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option.materiaName}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option.materia}
                    </li>
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                classes={{ outlinedInput: 'myOutlinedInput-notchedOutline' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace' || event.key === 'Enter') {
                        event.stopPropagation();
                      }
                    }}
                    placeholder="Por favor seleccione los prerequisitos"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingMaterias ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            {fields?.map((field, index) => (
              <ProgramasPrerequisitos
                key={field.id}
                index={index}
                prerequisito={field}
                selectedSede={selectedSede}
              />
            ))}
          </ThemeProvider>
        </Grid>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            p: (theme) => theme.spacing(1.5, 2),
            pl: 3,
          }}
        >
          <Button onClick={onClose} color="secondary" sx={{ mr: 1 }}>
            Cancelar
          </Button>
          <Button
            startIcon={isLoading && <CircularProgress size={16} />}
            variant="contained"
            disabled={!isValid || _.isEmpty(dirtyFields) || isLoading}
            onClick={methods.handleSubmit(onSubmit)}
            sx={{ mr: 1 }}
          >
            {isLoading ? 'Guardando...' : 'Guardar'}
          </Button>
        </CardActions>
      </FormProvider>
    </Div>
  );
};

export default EditarMateria;
