import React from 'react';
import Page from '@jumbo/shared/Page';
import GetUserRoute from './middlewares/Roles/GetUserRoute';
import SedesPage from 'app/pages/sedes/SedesPage';
import JornadasPage from 'app/pages/jornadas/JornadasPage';
import AulasPage from 'app/pages/aulas/AulasPage';
import MateriasPage from 'app/pages/materias/MateriasPage';
import PensumPage from 'app/pages/pensum/PensumPage';
import DocentePage from 'app/pages/docentes/DocentePage';
import ProgramasPage from 'app/pages/programas/ProgramasPage';
import IntegracionesAulasVsMateriasPage from 'app/pages/integraciones-aulas/IntegracionesAulasVsMateriasPage';
import ActualizarDatosQ10Page from 'app/pages/actualizar-datos-q10/ActualizarDatosQ10Page';
import PrematriculaPage from 'app/pages/prematricula/PrematriculaPage';
import HistorialPrematriculaPage from 'app/pages/historial-prematriculas/HistorialPrematriculaPage';
import PrematriculaEstudiantesPage from 'app/pages/prematricula-estudiantes/PrematriculaEstudiantes';

const preMatriculaRoutes = [
  {
    middleware: [
      {
        element: () =>
          GetUserRoute({
            fallbackPath: '/',
            rolesPermitidos: ['Superadministrador'],
          }),
      },
    ],
    routes: [
      {
        path: '/actualizar-datos-q10',
        element: <Page component={ActualizarDatosQ10Page} />,
      },
      {
        path: '/sedes',
        element: <Page component={SedesPage} />,
      },
      {
        path: '/jornadas',
        element: <Page component={JornadasPage} />,
      },
      {
        path: '/aulas',
        element: <Page component={AulasPage} />,
      },
      {
        path: '/materias',
        element: <Page component={MateriasPage} />,
      },
      {
        path: '/pensum',
        element: <Page component={PensumPage} />,
      },
      {
        path: '/docentes',
        element: <Page component={DocentePage} />,
      },
      {
        path: '/programas',
        element: <Page component={ProgramasPage} />,
      },
      {
        path: '/integraciones-aulas',
        element: <Page component={IntegracionesAulasVsMateriasPage} />,
      },
      {
        path: '/prematricula',
        element: <Page component={PrematriculaPage} />,
      },
      {
        path: '/historial-prematriculas',
        element: <Page component={HistorialPrematriculaPage} />,
      },
      {
        path: '/prematricula-estudiantes',
        element: <Page component={PrematriculaEstudiantesPage} />,
      }
    ],
  },
];
export default preMatriculaRoutes;
