import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import Spinner from 'app/shared/Spinner/Spinner';
import useGetRolById from 'app/services/roles/getRolById';

const IsAdmin = ({ fallbackPath }) => {
  const { authUser, isLoading } = useJumboAuth();
  const {
    data: userRol,
    isLoading: isLoadingRol,
    isSuccess: isSuccessRol,
  } = useGetRolById(authUser?.rol);

  if (isLoading || isLoadingRol) {
    return <Spinner pt={30} />;
  }

  if (isSuccessRol && userRol.name === 'Estudiante') {
    return <Outlet />;
  }

  return <Navigate to={fallbackPath} />;
};

export default IsAdmin;
