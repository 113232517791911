import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deleteMateriaPensum = async ({ idMateria, idPensum }) => {
  const { data } = await client.delete(
    `/pensum/delete-materia-idMateria/${idMateria}/idSede/${idPensum}`
  );
  return data;
};

export const useDeleteMateriaPensum = (idSede) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(deleteMateriaPensum, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('pensum');
      queryClient.invalidateQueries([idSede, 'pensumBySede']);
      enqueueSnackbar('La materia ha sido eliminada con éxito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al eliminar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
};
