import { useMutation } from 'react-query';
import client from '../config';

export const getMallaBySubjectAdmin = async ({ subjectName, idSede }) => {
  const { data } = await client.get(
    `/malla/malla-by-subject-admin/${subjectName}/${idSede}`
  );
  return data;
};

export const useGetMallaBySubjectAdmin = () => {
  return useMutation((student) => getMallaBySubjectAdmin(student));
};
