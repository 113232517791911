import { memo } from 'react';
import {
  Avatar,
  Button,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
const ListMateriasDocente = memo(({ docente }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const handleDialog = () => {
    showDialog({
      title: (
        <span>
          Listado de Materias - <strong>{docente?.nombre} </strong>
        </span>
      ),
      content: (
        <>
          {docente?.materias?.length === 0 && (
            <DialogTitle sx={{ textAlign: 'center' }}>
              <span className="text-muted fs-6 fw-normal">
                No tiene materias asignadas
              </span>
            </DialogTitle>
          )}
          <List sx={{ pt: 0 }}>
            {docente?.materias?.map((m) => (
              <ListItem key={m?.id}>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      bgcolor: 'secondary.light',
                      color: 'secondary.contrastText',
                    }}
                    alt={m?.materia}
                  >
                    <AutoStoriesIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={m?.materia} />
              </ListItem>
            ))}
          </List>
        </>
      ),
    });
  };
  return (
    <Button
      // onMouseEnter={onMouseEnter}
      disableElevation
      sx={{ borderRadius: 8 }}
      startIcon={<RemoveRedEyeIcon sx={{ fontSize: 15 }} />}
      variant="contained"
      color="secondary"
      size="small"
      onClick={handleDialog}
    >
      Ver
    </Button>
  );
});

export default ListMateriasDocente;
