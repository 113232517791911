import { alpha } from '@mui/material/styles';

export const sidebarTheme = {
  type: 'light',
  palette: {
    primary: {
      main: '#941d80',
      light: '#a74596',
      dark: '#7b1869',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FFCA00',
      light: '#FFD32C',
      dark: '#D3A700',
      contrastText: '#000',
    },
    error: {
      main: '#E73145',
      light: '#FF6A70',
      dark: '#AD001E',
      contrastText: '#FFF',
    },
    warning: {
      main: '#F39711',
      light: '#FFC84C',
      dark: '#BB6900',
      contrastText: '#FFF',
    },
    info: {
      main: '#2EB5C9',
      light: '#6FE7FC',
      dark: '#008598',
      contrastText: '#FFF',
    },
    success: {
      main: '#3BD2A2',
      light: '#78FFD3',
      dark: '#00A073',
      contrastText: '#FFF',
    },
    text: {
      primary: '#FFF',
      secondary: '#f5f3f4ff',
      disabled: '#d3d3d3ff',
    },
    nav: {
      action: {
        active: '#000',
        hover: '#fff',
      },
      background: {
        active: '#FFCA00',
        hover: '#941d80',
      },
      tick: {
        active: '#941d80',
        hover: '#fff',
      },
    },
    divider: '#DEE2E6',
    background: {
      paper: '#000',
      default: '#000',
    },
    action: {
      active: '#941d80',
      hover: '#F5F7FA',
    },
  },
};
