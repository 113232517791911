import React from 'react';
import Div from '@jumbo/shared/Div';
import Link from '@mui/material/Link';
import { Box } from '@mui/material';

const Logo = ({ mini, mode, sx }) => {
  return (
    <Div sx={{ display: 'inline-flex', ...sx }}>
      <Link href={'/'} underline="none">
        {!mini ? (
          <Box
            component="img"
            src={'/logo_horizontal.png'}
            sx={{
              mt: 1,
              height: 50,
              filter: mode === 'dark' ? 'brightness(0) invert(1)' : 'none',
            }}
          />
        ) : (
          <Box
            component="img"
            src={'/logo_dna_white.png'}
            alt="logo"
            sx={{ width: 40 }}
          />
        )}
      </Link>
    </Div>
  );
};

Logo.defaultProps = {
  mode: 'light',
};

export default Logo;
