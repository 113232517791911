import { useMutation, useQuery } from 'react-query';
import client from '../config';
import { getAulasBySubjectAdmin } from './getAulasBySubjectAdmin';

export const getSubjectsBySede = async (idSede) => {
  const { data } = await client.get(`/subject/all-bySede/${idSede}`);
  return data;
};
export default function useGetSubjectsBySedeQuery(idSede) {
  return useQuery(['subjectsBySede', idSede], () => getSubjectsBySede(idSede));
}

export const useGetSubjectsBySede = () => {
  return useMutation((idSede) => getSubjectsBySede(idSede));
};

export const getSubjectsBySedeFullfilled = async (idSede) => {
  try {
    const subjects = await getSubjectsBySede(idSede);
    const updatedSubjects = await Promise.all(
      subjects.map(async (subject) => {
        const aulas = await getAulasBySubjectAdmin({
          subjectName: subject.name,
          idSede: idSede,
        });
        return { ...subject, aula: aulas };
      })
    );
    return updatedSubjects;
  } catch (error) {
    throw new Error('Error fetching subjects by sede (fulfilled)');
  }
};
export const useGetSubjectsBySedeFullfilled = () => {
  return useMutation((idSede) => getSubjectsBySedeFullfilled(idSede));
};
