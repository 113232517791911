import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  Button,
  CardActions,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import CustomNumberTextField from 'app/shared/ReactHookForm/CustomNumberTextField';
import { CustomCheckbox } from 'app/shared/ReactHookForm/CustomCheckbox';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import { useState } from 'react';
import { useEffect } from 'react';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import {
  StyledTableCell,
  StyledTableRow,
} from 'app/shared/StyledTable/StyledTable';
import DeleteIcon from '@mui/icons-material/Delete';

const EditarHorarioMateria = ({ materias, onClose }) => {
  const [aulas, setAulas] = useState([]);
  const [selectedMaterias, setSelectedMaterias] = useState([]);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });
  const {
    control,
    reset,
    formState: { isValid, dirtyFields },
  } = methods;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onSubmit = (dataForm) => {};
  useEffect(() => {
    if (!materias) return;
    const aulasFormated = materias?.reduce((aulasArray, materia) => {
      const { aula, ...materiaSinAula } = materia;

      // Para cada aula en la materia, crea un nuevo objeto de aula
      aula.forEach((aula) => {
        const nuevaAula = {
          ...aula,
          materias: [materiaSinAula], // Array de materias asociadas al aula
        };

        // Busca si ya existe un objeto de aula con el mismo _id
        const aulaExistente = aulasArray.find((a) => a._id === nuevaAula._id);

        // Si existe, agrega la materia al array de materias de ese aula, si no, agrega el nuevo objeto de aula al array
        if (aulaExistente) {
          aulaExistente.materias.push(materiaSinAula);
        } else {
          aulasArray.push(nuevaAula);
        }
      });

      return aulasArray;
    }, []);
    setAulas(aulasFormated);
    console.log(aulasFormated, 'aulasFormated');
  }, [materias]);

  useEffect(() => {
    return () => {
      setSelectedMaterias([]);
    };
  }, []);
  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={`Ajuste de Horarios y Aulas`}
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: 'background.default',
            px: 2,
            pb: 2,
          }}
        >
          <Grid item xs={12} >
            <CustomAutoComplete
              isRequired
              control={control}
              name="aula"
              label="Aula"
              options={aulas?.map((aula) => ({ id: aula._id, ...aula }))}
              getOptionLabel={(option) => option?.name}
              onChangeCallback={(value) => {
                console.log(value, 'value');
                setSelectedMaterias(value?.materias);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {selectedMaterias?.length > 0 ? (
              <Paper
                className="scrollbar"
                sx={{
                  borderRadius: '0.5rem',
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                <TableContainer sx={{ height: { xs: 350, xl: 700 } }}>
                  <Table size="small" stickyHeader aria-label="sticky table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Materia</StyledTableCell>
                        <StyledTableCell align="center">
                          Horario Actual
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {selectedMaterias
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((materia, index) => (
                          <StyledTableRow
                            key={index}
                            className={
                              index % 2 === 0 ? 'tableRow-even' : 'tableRow-odd'
                            }
                          >
                            <StyledTableCell>{materia?.name}</StyledTableCell>

                            <StyledTableCell></StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={selectedMaterias.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="Filas por página"
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              <NoDataFound2 label="No se encontraron resultados para la búsqueda" />
            )}
          </Grid>
        </Grid>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            p: (theme) => theme.spacing(1.5, 2),
            pl: 3,
          }}
        >
          <Button onClick={onClose} color="secondary" sx={{ mr: 1 }}>
            Cancelar
          </Button>
          {/* <Button
            startIcon={false && <CircularProgress size={16} />}
            variant="contained"
            disabled={!isValid || _.isEmpty(dirtyFields)}
            onClick={methods.handleSubmit(onSubmit)}
            sx={{ mr: 1 }}
          >
            {false ? 'Guardando...' : 'Guardar'}
          </Button> */}
        </CardActions>
      </FormProvider>
    </Div>
  );
};

export default EditarHorarioMateria;
