import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import client from '../config';

const closeReserva = async (idReserva) => {
  const { data } = await client.get(
    `/student-reserva/close-reserve/${idReserva}`
  );
  return data;
};

export default function useCloseReserve() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(closeReserva, {
    onSuccess: (data) => {
      if (!data) {
        enqueueSnackbar('Espera! La reserva ya se encuentra cerrada.', {
          variant: 'error',
        });
        return;
      }
      enqueueSnackbar(
        'La asistencia a esta reserva a sido cerrada con éxito.',
        {
          variant: 'success',
        }
      );
    },
    onError: (error) => {
      const { message } = error.response.data;
      console.log('error', error.response);
      enqueueSnackbar(
        message === 'NOT ALLOWED FOR RESERVE TIME'
          ? 'No puedes cerrar la reserva en este momento.'
          : 'Esta reserva no existe en nuestra base de datos.',
        {
          variant: 'error',
        }
      );
    },
  });
}
