import { useMutation, useQuery } from 'react-query';
import client from '../config';

export const getDocentesBySede = async (idSede) => {
  if (!idSede) return [];
  const { data } = await client.get(`/profesor/all-by-sede/${idSede}`);
  return data;
};

export default function useGetDocentesBySede(idSede) {
  return useQuery([idSede, 'docentesBySede'], () => getDocentesBySede(idSede), {
    onError: (error) => {
      console.log(error.response.data);
    },
  });
}

export const useGetDocentesBySedeMutation = () => {
  return useMutation((idSede) => getDocentesBySede(idSede));
};
