import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import client from '../config';
import { sendCodeEmail } from './sendCodeEmail';

const signUp = async (usuario) => {
  console.log(usuario, 'usuario a crear');
  const response = await client.post('/auth/register', usuario);
  const { data } = response;
  return data;
};

export default function useSignUp() {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((usuario) => signUp(usuario), {
    onSuccess: (user) => {
      enqueueSnackbar('Registro Exitoso', { variant: 'success' });
      console.log(user, 'usuario creado');
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      console.log(errorMessages);
      enqueueSnackbar(
        errorMessages === 'EMAIL_ON_USE'
          ? 'El usuario ya existe'
          : 'Falló de registro, por favor revisa los datos ingresados',
        { variant: 'error' }
      );
    },
  });
}
