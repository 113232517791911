import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import Spinner from 'app/shared/Spinner/Spinner';
import { useSelector } from 'react-redux';

const IsUserActive = ({ fallbackPath }) => {
  const { authUser, isLoading } = useJumboAuth();
  const { roleName } = useSelector(({ auth }) => auth);

  if (isLoading) {
    return <Spinner pt={30} />;
  }

  if (authUser?.active || roleName === 'Estudiante') {
    return <Outlet />;
  }

  return <Navigate to={fallbackPath} />;
};

export default IsUserActive;
