import Div from '@jumbo/shared/Div';
import { Grid } from '@mui/material';
import CustomDataGrid from 'app/shared/CustomDataGrid/CustomDataGrid';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import Spinner from 'app/shared/Spinner';
import React from 'react';
import { useStudentsColumns } from '../hooks/useStudentsColumns';
import { useGetStudents } from 'app/services/prematricula/getStudents';
import CustomToolbarStudents from './CustomToolbarStudents';

const StudentsList = ({ selectedSede, periodo, materia }) => {
  const columns = useStudentsColumns();
  const { data: students, isLoading } = useGetStudents({
    sede: selectedSede?._id,
    periodo,
    materia,
  });
  return (
    <Grid item xs={12}>
      {isLoading ? (
        <Div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: { xs: 300, xl: 600 },
          }}
        >
          <Spinner />
        </Div>
      ) : students?.length > 0 ? (
        <>
          <CustomDataGrid
            columns={columns}
            data={students || []}
            br={0}
            id="documento"
            height={{ xs: 630, md: 500, xl: 775 }}
            sortModel={[{ field: 'id', sort: 'asc' }]}
            isLoading={isLoading}
            toolbar={<CustomToolbarStudents />}
          />
        </>
      ) : (
        <NoDataFound2 label="No se encontraron resultados para la busqueda" />
      )}
    </Grid>
  );
};

export default StudentsList;
