import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deleteUser = async (idUser) => {
  const { data } = await client.delete(`/user/delete/${idUser}`);
  return { data };
};

export const useDeleteUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation((student) => deleteUser(student), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['users']);
      enqueueSnackbar('El usuario ha sido eliminado con éxito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar(
        'Error al eliminar el usuario, por favor intente de nuevo',
        {
          variant: 'error',
        }
      );
    },
  });
};
