import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const updateUserPermisos = async (permisos) => {
  const { data } = await client.post(`/admin/safe-permisos`, permisos);
  return data;
};

export default function useUpdateUserPermisos() {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(updateUserPermisos, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('users');
      enqueueSnackbar('Los permisos del usuario se modificaron con éxito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(errorMessages);
      enqueueSnackbar('Error al modificar los permisos del usuariom, intente nuevamente', {
        variant: 'error',
      });
    },
  });
}
