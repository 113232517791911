import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const createCursoAdicionalPrematricula = async (dataForm) => {
  console.log(dataForm, 'dataForm');
  const { data } = await client.post(`/pre-matricula/create-curso`, dataForm);
  return data;
};

export default function useCreateCursoAdicionalPrematricula() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(createCursoAdicionalPrematricula, {
    onSuccess: (data) => {
      console.log(data, 'data');
      enqueueSnackbar('Curso creado exitosamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al crear el curso', {
        variant: 'error',
      });
    },
  });
}
