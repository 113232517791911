import client from '../config';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

export const updateMalla = async (malla) => {
  console.log(malla, 'malla');
  const { data } = await client.put(`malla/updateMalla`, malla);
  return data;
};

export default function useUpdateMalla() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateMalla, {
    onSuccess: (data) => {
      console.log(data, 'malla actualizada');
      enqueueSnackbar('Malla actualizada', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      enqueueSnackbar('Error', {
        variant: 'error',
      });
    },
  });
}
