import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const createPensum = async (pensum) => {
  const { data } = await client.post(`/pensum/create`, pensum);
  return data;
};

export default function useCreatePensum() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(createPensum, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('pensum');
      queryClient.invalidateQueries([data?.sede, 'pensumBySede']);
      enqueueSnackbar('pensum creado correctamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      enqueueSnackbar(
        errorMessages === 'PROGRAMA_YA_EXISTE_EN_ESTA_SEDE'
          ? 'El programa ya existe en esta sede'
          : 'Error al crear',
        {
          variant: 'error',
        }
      );
    },
  });
}
