import React from 'react';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import MateriasList from './components/MateriasList';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import useCreatePensum from 'app/services/materias/createMateria';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import useGetSedes from 'app/services/sedes/getSedes';
import CustomNumberTextField from 'app/shared/ReactHookForm/CustomNumberTextField';
import useGetMateriasBySede from 'app/services/materias/getMateriasBySede';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ProgramasPrerequisitos from './components/ProgramasPrerequisitos';
const materiaModel = {
  materia: '',
  sede: '',
  aforo_minimo: '',
  prerequisitos: [],
  codigo: '',
  abreviacion: '',
  cupo_maximo: '',
};
const MateriasPage = () => {
  const navigate = useNavigate();

  const { mutate: create, isLoading: isCreating } = useCreatePensum();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const [selectedSede, setSelectedSede] = useState(null);
  const [selectedSedeForList, setSelectedSedeForList] = useState(null);
  const { data: materias, isLoading: isLoadingMaterias } = useGetMateriasBySede(
    selectedSede?._id
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: materiaModel,
  });

  const { control, handleSubmit, reset, setValue } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prerequisitos', // Nombre del campo en el formulario
  });
  const onSubmit = (data) => {
    const { prerequisitos, ...rest } = data;
    const dataToSend = {
      ...rest,
      prerequisitos: prerequisitos.map((prerequisito) => {
        const { _id, programas, materiaName, ...rest } = prerequisito;
        return {
          ...rest,
          programas: programas.map((programa) => programa._id),
        };
      }),
    };
    // console.log('dataToSend', dataToSend);
    create(dataToSend, { onSuccess: () => reset(materiaModel) });
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: '#941d80', // Modifica el color principal
      },
    },
  });
  const onChangeSede = (sede) => {
    setValue('prerequisitos', []);
    setSelectedSede(sede);
  };

  return (
    <FormProvider {...methods}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                <IconButton
                  onClick={() => navigate('/panel-control', { replace: true })}
                >
                  <ArrowBack />
                </IconButton>
                CREAR MATERIA
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                1. Nombre:
              </Typography>
              <CustomTextField
                name={'materia'}
                control={control}
                label="Nombre"
                isRequired
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                2. Selecione la sede:
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="sede"
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
                onChangeCallback={onChangeSede}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h6" gutterBottom mb={2}>
                3. Abreviatura:
              </Typography>
              <CustomTextField
                name={'abreviacion'}
                control={control}
                label="Abreviatura"
                isRequired
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
              <Typography variant="h6" gutterBottom mb={2}>
                4. Aforo:
              </Typography>
              <CustomNumberTextField
                name={'aforo_minimo'}
                control={control}
                label="Aforo"
                isRequired
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <Typography variant="h6" gutterBottom mb={2}>
                5. Codigo:
              </Typography>
              <CustomTextField
                name={'codigo'}
                control={control}
                label="Codigo"
                isRequired
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="h6" gutterBottom mb={2}>
                6. Cupo Maximo:
              </Typography>
              <CustomNumberTextField
                name={'cupo_maximo'}
                control={control}
                label="Cupo Maximo"
                isRequired
              />
            </Grid>
            <ThemeProvider theme={theme}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom mb={2}>
                  Seleccione los prerequisitos:
                </Typography>
                <Autocomplete
                  id={'prerequisitos'}
                  onChange={(event, newValue) => {
                    if (newValue.length > fields.length) {
                      const newItem = newValue[newValue.length - 1];
                      append({
                        _id: newItem?._id,
                        materia: newItem?._id,
                        materiaName: newItem?.materia,
                        programas: [],
                      });
                    } else {
                      const removedIndex = fields.findIndex(
                        (field) =>
                          !newValue.find((hobbie) => hobbie._id === field._id)
                      );
                      remove(removedIndex);
                    }
                  }}
                  loadingText="Cargando..."
                  multiple
                  value={fields}
                  disableClearable
                  loading={isLoadingMaterias}
                  getOptionLabel={(option) => option.materia}
                  noOptionsText="No hay opciones"
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.materiaName}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.materia}
                      </li>
                    );
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  classes={{ outlinedInput: 'myOutlinedInput-notchedOutline' }}
                  options={materias || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyDown={(event) => {
                        if (
                          event.key === 'Backspace' ||
                          event.key === 'Enter'
                        ) {
                          event.stopPropagation();
                        }
                      }}
                      placeholder="Por favor seleccione los prerequisitos"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingMaterias ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              {fields?.map((field, index) => (
                <ProgramasPrerequisitos
                  key={field.id}
                  index={index}
                  prerequisito={field}
                  selectedSede={selectedSede}
                />
              ))}
            </ThemeProvider>
            <Grid item xs={12}>
              <Button
                startIcon={isCreating ? <CircularProgress size={16} /> : null}
                disabled={isCreating}
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                fullWidth
                size="large"
              >
                {isCreating ? 'Creando...' : 'Crear'}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'}>
                LISTA DE MATERIAS
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" mb={2} gutterBottom>
                Selecciona una Sede:
              </Typography>
              <Autocomplete
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                value={selectedSedeForList}
                onChange={(event, newValue) => {
                  setSelectedSedeForList(newValue);
                }}
                getOptionLabel={(option) => option?.name}
                placeholder="Por favor seleccione una sede"
                renderInput={(params) => (
                  <TextField
                    label="Sedes"
                    placeholder="Por favor seleccione una sede"
                    {...params}
                  />
                )}
              />
            </Grid>
            <MateriasList selectedSede={selectedSedeForList} />
          </Grid>
        </CardContent>
      </Card>
    </FormProvider>
  );
};

export default MateriasPage;
