import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const updateStudent = async ({ idStudent, dataStudent }) => {
  const { data } = await client.put(
    `/user/edit-student/${idStudent}`,
    dataStudent
  );
  return data;
};

export default function useUpdateStudent() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateStudent, {
    onSuccess: (data) => {
      console.log(data, 'estudiante actualizado');
      queryClient.invalidateQueries([data?.sede, 'studentsBySede']);
      enqueueSnackbar(`Estudiante ${data?.name} actualizado`, {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar(
        'Error al actualizar estudiante, por favor intente de nuevo',
        {
          variant: 'error',
        }
      );
    },
  });
}
