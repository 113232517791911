import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { AddCircleOutline } from '@mui/icons-material';
import {
  Button,
  IconButton,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';
import {
  StyledTableCell,
  StyledTableRow,
} from 'app/shared/StyledTable/StyledTable';
import React from 'react';
import AddCurso from './AddCurso';
import CloseIcon from '@mui/icons-material/Close';
import { PrematriculaContext } from '../PrematriculaProvider';

const EstudiantesAdicionales = ({ estudiantes = [] }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const { selectedSede, selectedPeriodo } =
    React.useContext(PrematriculaContext);
  const handleAdd = () => {
    showDialog({
      title: (
        <div className="flex justify-between items-center">
          <ListItemText
            primary={`Nuevo Curso - ${selectedSede?.name} - ${selectedPeriodo?.Nombre}`}
          />

          <IconButton onClick={hideDialog}>
            <CloseIcon />
          </IconButton>
        </div>
      ),
      content: (
        <AddCurso
          selectedSede={selectedSede}
          selectedPeriodo={selectedPeriodo}
          estudiantes={estudiantes}
          hideDialog={hideDialog}
        />
      ),
      maxWidth: 'md',
    });
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <Typography variant="h3" gutterBottom my={4}>
          LISTADO DE ESTUDIANTES QUE QUEDARON POR FUERA DE LA PREMATRICULA (
          {estudiantes?.length} en total)
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleOutline />}
          onClick={handleAdd}
          color="primary"
        >
          Nuevo Curso - {selectedSede?.name} - {selectedPeriodo?.Nombre}
        </Button>
      </div>

      <Paper
        className="scrollbar"
        sx={{
          borderRadius: '0.5rem',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <TableContainer sx={{ height: { xs: 350, xl: 700 } }}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Identificacion</StyledTableCell>
                <StyledTableCell align="center">Nombre</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {estudiantes?.map((materia, index) => (
                <StyledTableRow
                  key={index}
                  className={index % 2 === 0 ? 'tableRow-even' : 'tableRow-odd'}
                >
                  <StyledTableCell align="center">
                    {materia?.id}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {materia?.nombre}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default EstudiantesAdicionales;
