import { Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useForm } from 'react-hook-form';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import useGetSedes from 'app/services/sedes/getSedes';
import useGetPeriodoBySede from 'app/services/periodo/getPeriodoBySede';
import useGetMateriasBySede from 'app/services/materias/getMateriasBySede';
import StudentsList from './components/StudentsList';

const PrematriculaEstudiantesPage = () => {
  const navigate = useNavigate();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const [selectedSede, setSelectedSede] = React.useState(null);
  const { data: periodos, isLoading: isLoadingPeriodos } = useGetPeriodoBySede(
    selectedSede?._id
  );
  const [selectedPeriodo, setSelectedPeriodo] = React.useState(null);
  const [selectedAsignatura, setSelectedAsignatura] = React.useState(null);
  const { data: materiasBySede, isLoading: isLoadingMateriasBySede } =
    useGetMateriasBySede(selectedSede?._id);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      id_sede: '',
      periodo: '',
    },
  });
  const { control, handleSubmit, resetField } = methods;

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <Card sx={{ mt: 4 }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
              <IconButton
                onClick={() => navigate('/panel-control', { replace: true })}
              >
                <ArrowBack />
              </IconButton>
              ESTUDIANTES
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomAutoComplete
              control={control}
              name="id_sede"
              label="Por favor seleccione la sede"
              options={sedes?.map((sede) => ({
                id: sede._id,
                ...sede,
              }))}
              isLoading={isLoadingSedes}
              getOptionLabel={(option) => option?.name}
              onChangeCallback={(newValue) => {
                resetField('periodo');
                setSelectedSede(newValue);
                setSelectedPeriodo(null);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomAutoComplete
              control={control}
              name="periodo"
              label="Periodo"
              options={periodos?.map((periodo) => ({
                id: periodo.Consecutivo,
                ...periodo,
              }))}
              getOptionLabel={(option) => option?.Nombre}
              isLoading={isLoadingPeriodos}
              onChangeCallback={(newValue) => {
                setSelectedPeriodo(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomAutoComplete
              control={control}
              name="materia"
              label="Asignatura"
              options={materiasBySede?.map((materia) => ({
                id: materia._id,
                ...materia,
              }))}
              getOptionLabel={(option) => option?.materia}
              isLoading={isLoadingMateriasBySede}
              onChangeCallback={(newValue) => {
                resetField('jornada');
                setSelectedAsignatura(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <StudentsList
              selectedSede={selectedSede}
              periodo={selectedPeriodo?.Consecutivo}
              materia={selectedAsignatura?._id}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PrematriculaEstudiantesPage;
