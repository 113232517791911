import Div from '@jumbo/shared/Div';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Fab,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import ScannerBox from './components/ScannerBox';
import { FormProvider, useForm } from 'react-hook-form';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import { useState } from 'react';
import useGetSedes from 'app/services/sedes/getSedes';
import { useGetHorarioBySedeMutation } from 'app/services/horario/getHorarioBySede';
import useGetAulasBySedeMutation from 'app/services/aula/getAulasBySede';
import CustomDataGrid from 'app/shared/CustomDataGrid/CustomDataGrid';
import Spinner from 'app/shared/Spinner';
import useGetReservaByAulaHorario from 'app/services/reserve/getReservaByAulaHorario';
import { useReservasColumns } from '../cancelacion-practicas/hooks/useReservasColumns';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useReservasMonitorColumns } from './hooks/useReservasMonitorColumns';
import CancelIcon from '@mui/icons-material/Cancel';
import useCloseReserve from 'app/services/reserve/closeReserve';
const EscanerQR = () => {
  const [aulaName, setAulaName] = useState('');
  const [horarioName, setHorarioName] = useState('');
  const [horarios, setHorarios] = useState([]);
  const [aulas, setAulas] = useState([]);
  const [reservas, setReservas] = useState([]);
  const columns = useReservasMonitorColumns({ aulaName, horarioName });

  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const { showDialog, hideDialog } = useJumboDialog();
  const { mutate: getHorariosBySede, isLoading: isLoadingHorarios } =
    useGetHorarioBySedeMutation();
  const { mutate: getAulas, isLoading: isLoadingAulas } = useGetAulasBySedeMutation();
  const { mutate: getReservas, isLoading: isLoadingReservas } =
    useGetReservaByAulaHorario();
  const { mutate: closeReserve, isLoading: isLoadingCloseReserve } =
    useCloseReserve();

  const methods = useForm({
    defaultValues: {
      sede: null,
      horario: null,
      aula: null,
    },
  });
  const { control, watch, resetField, handleSubmit } = methods;
  const watchSede = watch('sede');
  const handleOpenDialog = () => {
    showDialog({
      title: 'Escanear QR',
      content: <ScannerBox hideDialog={hideDialog} />,
      maxWidth: 'md',
    });
  };
  const onSubmit = (data) => {
    const aula = aulas.find((aula) => aula._id === data.aula)?.name;
    setAulaName(aula);
    const horario = horarios.find(
      (horario) => horario._id === data.horario
    )?.name;
    setHorarioName(horario);
    getReservas(data, {
      onSuccess: (data) => {
        console.log(data, 'reservas');
        setReservas(data);
      },
    });
  };

  const handleCloseReserve = () => {
    showDialog({
      variant: 'confirm',
      title: 'CERRAR RESERVA',
      content: '¿Estas seguro que deseas cerrar la reserva?',
      onYes: () => {
        hideDialog();
        closeReserve(reservas[0]?.reserve, {
          onSuccess: (data) => {
            if (!data) return;
            handleSubmit(onSubmit)();
          },
        });
      },
      onNo: hideDialog,
    });
  };
  useEffect(() => {
    resetField('horario');
    resetField('aula');
    setAulas([]);
    setHorarios([]);
    if (!watchSede) return;

    getAulas(watchSede, {
      onSuccess: (data) => {
        setAulas(data);
      },
    });
    getHorariosBySede(watchSede, {
      onSuccess: (data) => {
        setHorarios(data);
      },
    });
  }, [watchSede]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} gutterBottom>
                AGENDAMIENTOS
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomAutoComplete
                isRequired
                control={control}
                name="sede"
                label="Sedes"
                options={sedes?.map((sede) => ({
                  id: sede._id,
                  ...sede,
                }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomAutoComplete
                isRequired
                control={control}
                name="aula"
                label="Aulas"
                options={aulas?.map((aula) => ({
                  id: aula._id,
                  ...aula,
                }))}
                isLoading={isLoadingAulas}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomAutoComplete
                isRequired
                control={control}
                name="horario"
                label="Horarios"
                isLoading={isLoadingHorarios}
                options={horarios?.map((horario) => ({
                  id: horario._id,
                  ...horario,
                }))}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                fullWidth
                type="submit"
                disabled={isLoadingReservas}
                startIcon={
                  isLoadingReservas ? (
                    <CircularProgress size={20} />
                  ) : (
                    <FilterListIcon />
                  )
                }
                variant="contained"
                color="secondary"
                size="large"
                sx={{ height: '100%' }}
              >
                {isLoadingReservas ? 'Cargando...' : 'Filtrar Reserva'}
              </Button>
            </Grid>
            {isLoadingReservas ? (
              <Grid item xs={12}>
                <Div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: { xs: 300, xl: 600 },
                  }}
                >
                  <Spinner />
                </Div>
              </Grid>
            ) : reservas?.length > 0 ? (
              <>
                <Grid item xs={12} md={3}>
                  <Button
                    onClick={handleCloseReserve}
                    disabled={isLoadingCloseReserve}
                    startIcon={
                      isLoadingCloseReserve ? (
                        <CircularProgress size={20} />
                      ) : (
                        <CancelIcon />
                      )
                    }
                    variant="contained"
                    color="error"
                    size="large"
                    sx={{ height: '100%' }}
                    fullWidth
                  >
                    {isLoadingCloseReserve ? 'Cargando...' : 'Cerrar Reserva'}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <CustomDataGrid
                    columns={columns}
                    data={reservas}
                    br={0}
                    pageSize={15}
                    id="qr"
                    sortModel={[{ field: 'userName', sort: 'asc' }]}
                    isLoading={isLoadingReservas}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <NoDataFound2 label="No se encontraron resultados para la busqueda" />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <Div sx={{ m: 1, position: 'fixed', bottom: 16, right: 16 }}>
        <Fab
          onClick={handleOpenDialog}
          aria-label="qr-code-scanner"
          sx={{
            color: 'common.black',
            backgroundColor: 'primary.main',
            '&:hover': {
              backgroundColor: 'common.black',
              color: 'primary.main',
            },
          }}
          className="animate__animated animate__bounce"
        >
          <QrCodeScannerIcon />
        </Fab>
      </Div>
    </form>
  );
};

export default EscanerQR;
