import React from 'react';
import Page from '@jumbo/shared/Page';
import authRoutes from './authRoutes';
import Error404 from 'app/pages/extra-pages/Error404';
import VerifyEmail from 'app/pages/auth-pages/verify-email/VerifyEmail';
import IsVerified from './middlewares/Auth/IsVerified';
import GetRoutesForUser from './middlewares/Roles/GetRoutesForUser';
import Perfil from 'app/pages/perfil/Perfil';
import agendamientoRoutes from './agendamientoRoutes';
import adminRoutes from './adminRoutes';
import IsUserActive from './middlewares/Auth/IsUserActive';
import UnauthorizedPage from 'app/pages/auth-pages/unauthorized/UnauthorizedPage';
import Dashboard from 'app/pages/dashboard/Dashboard';
import IsAdmin from './middlewares/Roles/IsAdmin';
import preMatriculaRoutes from './preMatriculaRoutes';

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
  {
    path: '*',
    element: <Page component={Error404} layout={'solo-page'} />,
  },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
  {
    path: '/',
    element: <GetRoutesForUser />,
  },
  {
    path: '/user/verificar-email',
    element: <Page component={VerifyEmail} layout="solo-page" />,
  },
  {
    path: '/unauthorized',
    element: <Page component={UnauthorizedPage} layout={'solo-page'} />,
  },
  {
    middleware: [
      {
        element: IsVerified,
        fallbackPath: '/user/verificar-email',
      },
      {
        element: IsUserActive,
        fallbackPath: '/unauthorized',
      },
    ],
    routes: [
      {
        path: '/perfil',
        element: <Page component={Perfil} />,
      },
      {
        path: '/dashboard',
        element: <Page component={Dashboard} layout={'solo-page'} />,
      },
      ...preMatriculaRoutes,
      ...agendamientoRoutes,
      ...adminRoutes,
    ],
  },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [...authRoutes];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
};
