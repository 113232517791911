import React from 'react';
import { Box } from '@mui/material';

const Header = () => {
  return (
    <Box
      component="img"
      src="/icons/SVG/Recurso-3.svg"
      sx={{
        position: 'relative',
        zIndex: 1,
        width: { xs: 200, xl: 250 },
        mx: { xs: 'auto', md: 0 },
        // my: 2,
        mt: { xs: -1, md: 2, xl: 0 },
        display: 'block',
      }}
    />
  );
};

export default Header;
