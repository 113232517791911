import { useMutation } from 'react-query';
import client from '../config';

export const getAulasBySubject = async ({ subjectName }) => {
  const { data } = await client.get(`/subject/aulas-bySubject/${subjectName}`);
  return data;
};

export const useGetAulasBySubject = () => {
  return useMutation((student) => getAulasBySubject(student));
};
