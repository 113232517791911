import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deletePrerequisitoMateria = async ({
  idMateria,
  idPrerequisito,
}) => {
  const { data } = await client.delete(
    `/materias/delete-prerequisito/${idMateria}/idPrerequisito/${idPrerequisito}`
  );
  return data;
};

export const useDeletePrerequisitoMateria = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(deletePrerequisitoMateria, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('materias');
      enqueueSnackbar('El prerequisito ha sido eliminado con éxito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al eliminar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
};
