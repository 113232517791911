import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { IconButton } from '@mui/material';
import EditarAula from '../components/EditarAula';

const AulasActions = ({ params }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const aula = params.row;

  const handleEdit = () => {
    showDialog({
      // title: 'Editar Aula',
      content: <EditarAula aula={aula} onClose={hideDialog} />,
      maxWidth: 'xs',
    });
  };

  return (
    <>
      <IconButton color="secondary" onClick={handleEdit}>
        <EditIcon />
      </IconButton>
    </>
  );
};

export default AulasActions;
