import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deleteDocente = async (id) => {
  const { data } = await client.delete(`/profesor/delete/${id}`);
  return { data };
};

export const useDeleteDocente = (idSede) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(deleteDocente, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([idSede, 'docentesBySede']);
      queryClient.invalidateQueries('docentes');
      enqueueSnackbar('El docente ha sido eliminado con éxito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al eliminar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
};
