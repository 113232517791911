import Div from '@jumbo/shared/Div';
import { useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import CancelPracticaStudent from '../components/CancelPracticaStudent';

export const useReservasStudentColumns = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const columns = [
    {
      field: 'subjectName',
      headerName: 'Curso/Materia',
      minWidth: isDesktop ? 350 : 230,
    },

    {
      field: 'date',
      headerName: 'Fecha/Hora	',
      minWidth: isDesktop ? 150 : 130,
      renderCell: (params) => {
        return (
          <div
            className="d-flex align-items-center 
              w-full
              p-3
            justify-content-center"
          >
            <Div
              className="rounded  w-full p-3 text-center"
              sx={{
                bgcolor: (theme) => theme.palette.secondary.main,
                color: (theme) => theme.palette.secondary.contrastText,
              }}
            >
              <p>{moment(params.row.date).format('DD/MM/YYYY')}</p>
              <p>{params.row.horarioName}</p>
            </Div>
          </div>
        );
      },
    },
    {
      field: 'aulaName',
      headerName: 'Aula',
      minWidth: isDesktop ? 450 : 270,
    },
    {
      field: 'tipoPractica',
      headerName: 'Tipo De Practica',
      minWidth: isDesktop ? 300 : 200,
      valueGetter: (params) => params.row.malla?.subjectName,
    },
    {
      field: 'status',
      headerName: 'Estado',
      minWidth: isDesktop ? 200 : 100,
      renderCell: (params) => {
        return (
          <Div
            className="rounded  p-3"
            sx={{
              bgcolor: (theme) =>
                params.row?.status === 'Cancelado'
                  ? theme.palette.error.main
                  : params.row?.status === 'Inasistio'
                  ? theme.palette.primary.main
                  : params.row?.status === 'Asistio'
                  ? theme.palette.success.main
                  : params.row?.status === 'Reservado'
                  ? theme.palette.info.main
                  : theme.palette.warning.main,

              color: (theme) =>
                params.row?.status === 'Cancelado'
                  ? theme.palette.error.contrastText
                  : params.row?.status === 'Inasistio'
                  ? theme.palette.primary.contrastText
                  : params.row?.status === 'Asistio'
                  ? theme.palette.success.contrastText
                  : params.row?.status === 'Reservado'
                  ? theme.palette.info.contrastText
                  : theme.palette.warning.contrastText,
            }}
          >
            <span>{params.row.status}</span>
          </Div>
        );
      },
    },
    {
      field: 'cancelar',
      headerName: 'Cancelar clases',
      type: 'actions',
      minWidth: isDesktop ? 150 : 150,
      renderCell: (params) => <CancelPracticaStudent params={params} />,
    },
  ].filter(Boolean);

  return columns;
};
