import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deletePensum = async (id) => {
  const { data } = await client.delete(`/pensum/delete/${id}`);
  return { data };
};

export const useDeletePensum = (idSede) => {
  console.log(idSede);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(deletePensum, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([idSede, 'pensumBySede']);
      queryClient.invalidateQueries('pensum');
      enqueueSnackbar('La materia ha sido eliminada con éxito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al eliminar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
};
