import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const createAula = async (aula) => {
  console.log(aula, 'data aula');
  const { data } = await client.post(`/aula/create-aula`, aula);
  return data;
};

export default function useCreateAula() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(createAula, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['aulas', data.sede]);
      // enqueueSnackbar('Aula creada correctamente', {
      //   variant: 'success',
      // });
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      // enqueueSnackbar('Error al crear el aula', {
      //   variant: 'error',
      // });
    },
  });
}
