import React from 'react';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import authServices from '../../services/auth-services';
import { BasicAuthContext } from './BasicAuthContext';
import { useDispatch } from 'react-redux';
import { login } from 'app/redux/slices/auth/authSlice';
import { startGetttingUser } from 'app/redux/slices/auth/thunks';
import { useNavigate } from 'react-router-dom';
import { logout as signOut } from 'app/redux/slices/auth/authSlice';
import useVerifyQ10UserState from 'app/services/users/verifyQ10UserState';

const BasicAuth = ({ children }) => {
  const { setAuthValues, startAuthLoading, stopAuthLoading } = useJumboAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mutate: verifyQ10User } = useVerifyQ10UserState();
  const logout = React.useCallback(() => {
    localStorage.removeItem('auth-token');
    localStorage.removeItem('userRol');
    localStorage.removeItem('selectedModule');
    dispatch(signOut());
    startAuthLoading();
    setAuthValues({ authToken: null, authUser: null }, { delay: 1000 });
  }, [setAuthValues, startAuthLoading, stopAuthLoading, dispatch]);

  React.useEffect(() => {
    const token = localStorage.getItem('auth-token');
    startAuthLoading();
    const checkAuthUser = async () => {
      if (token) {
        try {
          const authUser = await dispatch(startGetttingUser());
          setAuthValues({ authToken: token, authUser });
        } catch (error) {
          if (error) {
            logout();
            navigate('/');
            console.log('error', error);
            return error;
          }
        }
      } else {
        setAuthValues({
          authToken: null,
          authUser: null,
        });
      }
    };
    checkAuthUser();
  }, [
    setAuthValues,
    startAuthLoading,
    stopAuthLoading,
    dispatch,
    navigate,
    logout,
  ]);

  const signIn = React.useCallback(
    (email, passWord, successCallback, errorCallback) => {
      startAuthLoading();
      authServices
        .signIn({ email, passWord })
        .then((response) => {
          if (response?.data?.token) {
            localStorage.setItem('auth-token', response?.data?.token);
            if (response?.data?.rolName !== 'Superadministrador') {
              verifyQ10User(undefined, {
                onSuccess: (q10State) => {
                  const user = {
                    ...response.data.user,
                    permisos: response.data.permisos,
                    q10State:
                      q10State?.cursos_inscritos?.includes(false) &&
                      !q10State?.pagos_pendientes
                        ? false
                        : true,
                  };
                  dispatch(login(user));
                  setAuthValues({
                    authToken: response?.data?.token,
                    authUser: user,
                  });
                },
                onError: (error) => {
                  console.log('error', error);
                  logout();
                },
              });
            } else {
              const user = {
                ...response.data.user,
                permisos: response.data.permisos,
                q10State: true,
              };
              dispatch(login(user));
              setAuthValues({
                authToken: response?.data?.token,
                authUser: user,
              });
            }

            successCallback(response?.data);
          }
        })
        .catch((error) => {
          stopAuthLoading();
          errorCallback(error);
        });
    },
    [setAuthValues, startAuthLoading, stopAuthLoading, dispatch]
  );

  const signUp = React.useCallback(() => {
    //work to finish the signup
  }, []);

  const contextValue = React.useMemo(
    () => ({
      signUp,
      signIn,
      logout,
    }),
    [signUp, signIn, logout]
  );

  return (
    <BasicAuthContext.Provider value={contextValue}>
      {children}
    </BasicAuthContext.Provider>
  );
};

export default BasicAuth;
