import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const createAulaVsMaterias = async (dataForm) => {
  console.log(dataForm, 'data enviada');
  const { data } = await client.post(`/aula-materia/create`, dataForm);
  return data;
};

export default function useCreateAulaVsMaterias() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(createAulaVsMaterias, {
    onSuccess: (data) => {
      console.log(data, 'data recibida');
      queryClient.invalidateQueries('aulasVsMaterias');
      queryClient.invalidateQueries([data?.sede, 'aulasVsMateriasBySede']);
      enqueueSnackbar('Datos guardados correctamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      enqueueSnackbar(
        errorMessages === 'YA_EXISTE_AULA_EN_LA_SEDE'
          ? 'Ya existe un aula con ese nombre en la sede seleccionada'
          : 'Error al asociar materias',
        {
          variant: 'error',
        }
      );
    },
  });
}
