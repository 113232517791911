import { useQuery } from 'react-query';
import client from '../config';

const getTiposDocumento = async () => {
  try {
    const response = await client.get('/tipe-document/all');
    const { data } = response;
    return data;
  } catch (e) {
    return e.response;
  }
};

export default function useGetTiposDocumento() {
  return useQuery(['tiposDocumento'], getTiposDocumento, { refetchOnWindowFocus: false });
}
