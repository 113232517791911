import { useQuery } from 'react-query';
import client from '../config';

export const getStudents = async ({
  sede = '',
  materia = '',
  periodo = '',
}) => {
  let url = '/pre-matricula/get-students';

  // Construye la URL con los parámetros si están presentes
  if (sede || materia || periodo) {
    const queryParams = new URLSearchParams();

    if (sede) queryParams.append('sede', sede);
    if (materia) queryParams.append('materia', materia);
    if (periodo) queryParams.append('periodo', periodo);

    url += `?${queryParams.toString()}`;
  }
  console.log(url, 'url');
  // Realiza la solicitud HTTP y devuelve los datos
  const { data } = await client.get(url);
  return data;
};

export const useGetStudents = ({ sede = '', materia = '', periodo = '' }) => {
  return useQuery(['students', { sede, materia, periodo }], () =>
    getStudents({ sede, materia, periodo })
  );
};
