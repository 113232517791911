import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deleteAulaPrematricula = async (idAula) => {
  const { data } = await client.delete(
    `/aula-pre-matricula/delete-aula/${idAula}`
  );
  return data;
};

export const useDeleteAulaPrematricula = (idSede) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation((aula) => deleteAulaPrematricula(aula), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['aulasPrematricula', idSede]);
      enqueueSnackbar('El aula fue eliminada con éxito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar('Error al eliminar el aula', { variant: 'error' });
    },
  });
};
