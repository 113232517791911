import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import userSlice from '../slices/user/userSlice';
import authSlice from '../slices/auth/authSlice';
const rootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    user: userSlice,
    auth: authSlice,
  });
};

export default rootReducer;
