export const modalidadesDocente = [
  {
    id: 'presencial ',
    name: 'presencial ',
  },
  {
    id: 'virtual ',
    name: 'virtual ',
  },
  {
    id: 'hibrido',
    name: 'hibrido',
  },
];
