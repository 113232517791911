import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  lighten,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useForm } from 'react-hook-form';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import useGetSedes from 'app/services/sedes/getSedes';
import useGetPeriodoBySede from 'app/services/periodo/getPeriodoBySede';

import FileItem from './components/FileItem';
import { useGetHistorialPrematricula } from 'app/services/historial-prematriculas/getHistorialPrematricula';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import Spinner from 'app/shared/Spinner';
import Div from '@jumbo/shared/Div';

const HistorialPrematriculaPage = () => {
  const navigate = useNavigate();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const [selectedSede, setSelectedSede] = React.useState(null);
  const { data: periodos, isLoading: isLoadingPeriodos } = useGetPeriodoBySede(
    selectedSede?._id
  );
  const [selectedPeriodo, setSelectedPeriodo] = React.useState(null);
  const { data: files, isLoading: loadingFiles } = useGetHistorialPrematricula({
    idSede: selectedSede?._id,
    periodo: selectedPeriodo?.Consecutivo,
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      id_sede: '',
      periodo: '',
    },
  });
  const { control, handleSubmit, resetField } = methods;

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <Card sx={{ mt: 4 }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
              <IconButton
                onClick={() => navigate('/panel-control', { replace: true })}
              >
                <ArrowBack />
              </IconButton>
              PREMATRICULA DE ESTUDIANTES
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomAutoComplete
              control={control}
              name="id_sede"
              label="Por favor seleccione la sede"
              options={sedes?.map((sede) => ({
                id: sede._id,
                ...sede,
              }))}
              isLoading={isLoadingSedes}
              getOptionLabel={(option) => option?.name}
              onChangeCallback={(newValue) => {
                resetField('periodo');
                setSelectedSede(newValue);
                setSelectedPeriodo(null);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomAutoComplete
              control={control}
              name="periodo"
              label="Periodo"
              options={periodos?.map((periodo) => ({
                id: periodo.Consecutivo,
                ...periodo,
              }))}
              getOptionLabel={(option) => option?.Nombre}
              isLoading={isLoadingPeriodos}
              onChangeCallback={(newValue) => {
                setSelectedPeriodo(newValue);
              }}
            />
          </Grid>
          {loadingFiles ? (
            <Grid item xs={12}>
              <Div
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: { xs: 300, lg: 500, xl: 600 },
                }}
              >
                <Spinner />
              </Div>
            </Grid>
          ) : files?.length === 0 ? (
            <Grid item xs={12}>
              <NoDataFound2 label="No se encontraron datos." />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Box
                className="p-16 w-full rounded-16 mb-24 border"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? lighten(theme.palette.background.default, 0.4)
                      : lighten(theme.palette.background.default, 0.02),
                }}
              >
                <Typography className="font-medium">
                  Documentos Excel generados{' '}
                  {selectedSede && `> ${selectedSede?.name}`}{' '}
                  {selectedPeriodo && `>${selectedPeriodo?.Nombre}`}
                </Typography>
                <div className="flex flex-wrap -m-8 mt-8">
                  {files?.map((item) => (
                    <FileItem key={item._id} item={item} />
                  ))}
                </div>
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HistorialPrematriculaPage;
