import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import { FormProvider, useForm } from 'react-hook-form';
import useGetSedes from 'app/services/sedes/getSedes';
import ListarEstudiantes from './components/ListarEstudiantes';

const Estudiantes = () => {
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const methods = useForm({
    defaultValues: {
      sede: null,
    },
  });
  const { control, watch } = methods;
  const watchSede = watch('sede', null);

  return (
    <FormProvider {...methods}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                ESTUDIANTES
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" mb={2} gutterBottom>
                1. Selecione la sede
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="sede"
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <ListarEstudiantes idSede={watchSede} />
          </Grid>
        </CardContent>
      </Card>
    </FormProvider>
  );
};

export default Estudiantes;
