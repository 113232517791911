import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { MODULOS_APP } from 'app/utils/appHelpers';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import SchoolIcon from '@mui/icons-material/School';
import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';

function ModuleAppMenu(props) {
  const user = useSelector(({ auth }) => auth);

  const selectedModule = localStorage.getItem('selectedModule');
  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <>
      <Button
        className=" min-w-40 px-0 md:px-16 py-0   "
        onClick={userMenuClick}
        sx={{ borderRadius: 16 }}
        color="primary"
        variant="contained"
        disableElevation
        disableRipple
      >
        <Avatar
          sx={{ backgroundColor: 'secondary.main', color: '#fff' }}
          className="md:mx-4"
          alt={selectedModule}
        >
          {selectedModule === MODULOS_APP.PREMATRICULA ? (
            <SchoolIcon />
          ) : (
            <CalendarMonth />
          )}
        </Avatar>
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" fontWeight={700} className=" flex">
            {selectedModule === MODULOS_APP.PREMATRICULA
              ? 'Pre Matriculas'
              : 'Agendamientos'}
          </Typography>
          <Typography
            className="text-11 font-medium capitalize"
            color="primary.contrastText"
          >
            {user?.roleName}
          </Typography>
        </div>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        <MenuItem
          component={Link}
          to="/perfil"
          onClick={() => {
            localStorage.setItem('selectedModule', MODULOS_APP.AGENDAMIENTOS);
            userMenuClose();
          }}
          role="button"
        >
          <ListItemIcon className="min-w-40">
            <CalendarMonth />
          </ListItemIcon>
          <ListItemText primary="Agendamientos DNA" />
        </MenuItem>

        <MenuItem
          component={NavLink}
          to="/perfil"
          onClick={() => {
            localStorage.setItem('selectedModule', MODULOS_APP.PREMATRICULA);
            userMenuClose();
          }}
        >
          <ListItemIcon className="min-w-40">
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText primary="Pre-Matriculas DNA" />
        </MenuItem>
      </Popover>
    </>
  );
}

export default ModuleAppMenu;
