import client from '../config';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

export const setAulaSubjects = async ({ idAula, materias }) => {
  console.log(materias, 'data a enviar');
  const { data } = await client.post(
    `/aula/set-aula-subjects/${idAula}`,
    materias
  );
  return data;
};

export default function useSetAulaSubjects() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(setAulaSubjects, {
    onSuccess: (data) => {
      enqueueSnackbar(
        'Las materias se han agregado al aula seleccionada correctamente',
        {
          variant: 'success',
        }
      );
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      enqueueSnackbar('No se han podido agregar las materias al aula', {
        variant: 'error',
      });
    },
  });
}
