import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import client from "../config";

export const sendCodeChangePass = async (email) => {
  console.log("email", email);
  const { data } = await client.post(`/user/send-changepass/${email}`);
  return data;
};

export default function useSendCodeChangePass() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(sendCodeChangePass, {
    onSuccess: (data) => {
      localStorage.setItem("resetCode", data);
      enqueueSnackbar("Codigo enviado con exito", { variant: "success" });
    },
    onError: (error) => {
      const { message } = error.response.data;
      enqueueSnackbar(
        message === "USER_NOT_FOUND"
          ? "El usuario no existe"
          : "Ocurrio un error, intente de nuevo",
        { variant: "error" }
      );
    },
  });
}
