import { memo } from 'react';
import {
  Avatar,
  Button,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
const VerPrerequisitos = memo(({ materia }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const handleDialog = () => {
    showDialog({
      title: (
        <span>
          Prerequisitos de la Materia: <strong>{materia?.materia} </strong>
        </span>
      ),
      content: (
        <>
          {materia?.prerequisitos?.length === 0 && (
            <DialogTitle sx={{ textAlign: 'center' }}>
              <span className="text-muted fs-6 fw-normal">
                No tiene prerequisitos asignados
              </span>
            </DialogTitle>
          )}

          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 500,
              '& ul': { padding: 0 },
            }}
            subheader={<li />}
          >
            {materia?.prerequisitos?.map((p) => (
              <li key={`section-${p?.id}`}>
                <ul>
                  <ListSubheader
                    sx={{
                      display: 'flex',
                      bgcolor: 'background.paper',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar className="rounded-full h-16 w-16">
                        <AutoStoriesIcon />
                      </Avatar>
                    </ListItemAvatar>

                    <span className="font-bold text-black  text-center sm:text-left">
                      {p?.materia}
                      <span className="text-purple-300 text-xs ml-8">
                        {p?.programas?.length} programa(s)
                      </span>
                    </span>
                  </ListSubheader>
                  {p?.programas?.map((programa, index) => (
                    <ListItem key={programa?._id}>
                      <ListItemText
                        primary={`${index + 1}. ${programa?.name}`}
                      />
                    </ListItem>
                  ))}
                </ul>
              </li>
            ))}
          </List>
        </>
      ),
      maxWidth: 'md',
    });
  };
  return (
    <Button
      // onMouseEnter={onMouseEnter}
      disableElevation
      sx={{ borderRadius: 8 }}
      startIcon={<RemoveRedEyeIcon sx={{ fontSize: 15 }} />}
      variant="contained"
      color="secondary"
      size="small"
      onClick={handleDialog}
    >
      Ver
    </Button>
  );
});

export default VerPrerequisitos;
