import { FormControlLabel, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Controller } from 'react-hook-form';

export const CustomCheckbox = ({
  disabled = false,
  control,
  name,
  color = 'secondary',
  label,
  defaultValue,
  shouldUnregister = false,
}) => {
  return (
    <FormControlLabel
      style={{ margin: 0 }}
      control={
        <Controller
          name={name}
          shouldUnregister={shouldUnregister}
          defaultValue={false}
          control={control}
          rules={{
            shouldUnregister: shouldUnregister,
          }}
          render={({ field: props }) => (
            <Checkbox
              {...props}
              color={color}
              disabled={disabled}
              checked={props.value}
              onChange={(e) => props.onChange(e.target.checked)}
            />
          )}
        />
      }
      color="secondary"
      label={
        <Typography variant="body2" color="inherit">
          {label}
        </Typography>
      }
    />
  );
};
