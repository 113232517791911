import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import client from '../config';

const cancelReserveStudent = async ({ idReserva }) => {
  const { data } = await client.patch(
    `/student-reserva/cancel-reserve/${idReserva}`
  );
  return data;
};

export default function useCancelReserveStudent() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(cancelReserveStudent, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('reservasByStudent');
      queryClient.invalidateQueries('studentReserves');
      enqueueSnackbar('Reserva Cancelada con éxito.', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const { message } = error.response.data;
      console.log('error', error.response);
      enqueueSnackbar('Expiraste el tiempo de cancelación', {
        variant: 'error',
      });
    },
  });
}
