import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

const CustomTextField = ({
  control,
  name,
  label,
  placeholder = 'Escriba aquí...',
  size,
  variant = 'outlined',
  multiline = false,
  type,
  margin,
  errors = {},
  rows,
  maxRows = 10,
  minRows,
  isRequired = false,
  disabled = false,
  pattern = null,
  maxLength,
  minLength,
  defaultValue,
  onChangeCallback,
  width,
  shouldUnregister = false,
  ...restProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      rules={{
        required: isRequired ? 'Campo requerido' : false,
        pattern: pattern,
        minLength: {
          value: minLength,
          message: `Minimo ${minLength} caracteres`,
        },
        maxLength: {
          value: maxLength,
          message: `Maximo ${maxLength} caracteres`,
        },
        shouldUnregister: shouldUnregister,
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          value={field.value || ''}
          onChange={(e) => {
            field.onChange(e.target.value);
            if (onChangeCallback) onChangeCallback(e.target.value);
          }}
          id={name}
          maxRows={maxRows}
          disabled={disabled}
          label={label}
          type={type}
          multiline={multiline}
          placeholder={placeholder}
          variant={variant}
          margin={margin}
          sx={{ width: width }}
          size={size}
          maxLength={maxLength}
          inputProps={{ maxLength: maxLength }}
          //rows={rows}
          minRows={minRows}
          helperText={error?.message}
          error={!!error}
          fullWidth
          {...restProps}
        />
      )}
    />
  );
};

export default CustomTextField;
