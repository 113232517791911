import EditIcon from '@mui/icons-material/Edit';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditarDocente from './EditarDocente';
import { useDeleteDocente } from 'app/services/docente/deleteDocente';

const DocenteActions = ({ params }) => {
  const { showDialog, hideDialog } = useJumboDialog();

  const docente = params.row;
  const { mutate: deleteDocente } = useDeleteDocente(docente?.sede?._id);

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          content: <EditarDocente docente={docente} onClose={hideDialog} />,
          maxWidth: 'xl',
        });
        break;
      case 'delete':
        showDialog({
          title: 'Estas seguro de eliminar este docente?',
          variant: 'confirm',
          onYes: () => deleteDocente(docente?._id, { onSuccess: hideDialog }),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  const menuItems = [
    {
      icon: <EditIcon />,
      title: 'Editar',
      action: 'edit',
    },
    {
      icon: <DeleteIcon />,
      title: 'Eliminar',
      action: 'delete',
    },
  ];

  return (
    <>
      <JumboDdMenu
        icon={<MoreHorizIcon />}
        menuItems={menuItems}
        onClickCallback={handleItemAction}
      />
    </>
  );
};

export default DocenteActions;
