import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deleteSede = async (id) => {
  const { data } = await client.delete(`/sede/delete/${id}`);
  return { data };
};

export const useDeleteSede = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation((student) => deleteSede(student), {
    onSuccess: (data) => {
      queryClient.invalidateQueries('sedes');
      enqueueSnackbar('La sede ha sido eliminada con éxito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar(
        'Error al eliminar, por favor intente de nuevo',
        {
          variant: 'error',
        }
      );
    },
  });
};
