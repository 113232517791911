import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  Box,
  Button,
  CardActions,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useEffect } from 'react';
import Spinner from 'app/shared/Spinner';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import CustomAutocompleteMultipleForm from 'app/shared/ReactHookForm/CustomAutocompleteMultipleForm';
import useGetSedes from 'app/services/sedes/getSedes';
import { useDeletePrerequisitoMateria } from 'app/services/materias/deletePrerequisitoMateria';
import useGetMateriasBySede from 'app/services/materias/getMateriasBySede';
import { useState } from 'react';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import useUpdateDocente from 'app/services/docente/updateDocente';
import useGetDocente from 'app/services/docente/getDocente';
import useGetHorarioBySede from 'app/services/horario/getHorarioBySede';
import useGetTiposDocumento from 'app/services/tiposDocumento/getTiposDocumento';
import { useDeleteMateriaDocente } from 'app/services/docente/deleteMateriaDocente';
import { useDeleteHorarioDocente } from 'app/services/docente/deleteHorarioDocente';
import { CustomCheckbox } from 'app/shared/ReactHookForm/CustomCheckbox';
import CustomSelect from 'app/shared/ReactHookForm/CustomSelect';
import { modalidadesDocente } from '../utils/modalidadesDocente';
const docenteModel = {
  nombre: '',
  tipoDocumento: '',
  documento: '',
  telefono: '',
  correo: '',
  sede: '',
  materias: [],
  horario: [],
  modalidad: '',
  estado: false,
};
const EditarDocente = ({ docente, onClose }) => {
  const { mutate: updateMateria, isLoading } = useUpdateDocente();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const { data: tiposDoc, isLoading: isLoadingTiposDocumento } =
    useGetTiposDocumento();
  const { data: docenteData, isLoading: isLoadingPensum } = useGetDocente(
    docente?._id
  );
  const [selectedSede, setSelectedSede] = useState(null);
  const { data: materias, isLoading: isLoadingMaterias } =
    useGetMateriasBySede(selectedSede);
  const { data: horarios, isLoading: isLoadingHorarios } =
    useGetHorarioBySede(selectedSede);

  const { mutate: removeMateriaDocente } =
    useDeleteMateriaDocente(selectedSede);
  const { mutate: removeHorarioDocente } =
    useDeleteHorarioDocente(selectedSede);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: docenteModel,
  });
  const {
    control,
    reset,
    watch,
    setValue,
    resetField,
    formState: { isValid, dirtyFields },
  } = methods;

  const watchMaterias = watch('materias', []);
  const watchHorario = watch('horario', []);
  const onSubmit = (dataDocente) => {
    updateMateria(
      { dataDocente, id: docenteData?._id },
      { onSuccess: onClose }
    );
  };

  const onRemoveMateria = (newValues) => {
    if (newValues.length > watchMaterias.length) return; // si se agrega un elemento no hago nada
    const removedElementId = (watchMaterias || []).find(
      (oldOption) => !newValues.some((newOption) => newOption.id === oldOption)
    );
    removeMateriaDocente({
      idDocente: docenteData?._id,
      idMateria: removedElementId,
    });
  };
  const onRemoveHorario = (newValues) => {
    if (newValues.length > watchHorario.length) return; // si se agrega un elemento no hago nada
    const removedElementId = (watchHorario || []).find(
      (oldOption) => !newValues.some((newOption) => newOption.id === oldOption)
    );
    removeHorarioDocente({
      idDocente: docenteData?._id,
      idHorario: removedElementId,
    });
  };
  const onChangeSede = (sede) => {
    setValue('materias', []);
    setValue('horario', []);
    setSelectedSede(sede?._id);
  };
  useEffect(() => {
    if (!docenteData) return;
    setSelectedSede(docenteData?.sede);
    reset({
      nombre: docenteData?.nombre,
      tipoDocumento: docenteData?.tipoDocumentoId,
      documento: docenteData?.documento,
      telefono: docenteData?.telefono,
      correo: docenteData?.correo,
      sede: docenteData?.sede,
      materias: docenteData?.materias?.map((p) => p?.id) || [],
      horario: docenteData?.horario?.map((p) => p?.id) || [],
      modalidad: docenteData?.modalidad,
      estado: docenteData?.estado,
    });
  }, [docenteData]);

  if (isLoadingPensum)
    return (
      <Box
        sx={{
          height: 300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </Box>
    );
  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Editar Docente: <strong>{docenteData?.nombre}</strong>`
          </Typography>
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: 'background.default',
            px: 2,
            pb: 2,
          }}
        >
          <Grid item xs={12} md={3} xl={3}>
            <Typography variant="h6" gutterBottom mb={2}>
              1. Nombre:
            </Typography>
            <CustomTextField
              isRequired
              control={control}
              name="nombre"
              label="Nombre"
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <Typography variant="h6" gutterBottom mb={2}>
              2. Tipo de documento:
            </Typography>
            <CustomAutoComplete
              isRequired
              control={control}
              name="tipoDocumento"
              label="Tipo de documento"
              options={tiposDoc?.map((tipo) => ({ id: tipo._id, ...tipo }))}
              isLoading={isLoadingTiposDocumento}
              getOptionLabel={(option) => option?.name}
            />
          </Grid>

          <Grid item xs={12} md={3} xl={3}>
            <Typography variant="h6" gutterBottom mb={2}>
              3. Documento:
            </Typography>
            <CustomTextField
              isRequired
              control={control}
              name="documento"
              label="Documento"
            />
          </Grid>

          <Grid item xs={12} md={3} xl={3}>
            <Typography variant="h6" gutterBottom mb={2}>
              4. Telefono:
            </Typography>
            <CustomTextField
              isRequired
              control={control}
              name="telefono"
              label="Telefono"
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <Typography variant="h6" gutterBottom mb={2}>
              5. Correo:
            </Typography>
            <CustomTextField
              isRequired
              control={control}
              name="correo"
              label="Correo"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom mb={2}>
              6. Selecione la sede:
            </Typography>
            <CustomAutoComplete
              isRequired
              control={control}
              name="sede"
              label="Sedes"
              options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
              isLoading={isLoadingSedes}
              getOptionLabel={(option) => option?.name}
              onChangeCallback={onChangeSede}
            />
          </Grid>

          <Grid item xs={12} md={3} xl={3}>
            <Typography variant="h6" gutterBottom mb={2}>
              7. Seleccione las materias:
            </Typography>
            <CustomAutocompleteMultipleForm
              name="materias"
              label={'Materias'}
              placeholder={'Seleccione las materias'}
              control={control}
              limitTags={10}
              options={materias?.map((m) => ({ id: m?._id, ...m })) || []}
              getOptionLabel={(option) => option?.materia}
              isLoading={isLoadingMaterias}
              optionLabel="materia"
              onChangeCallback={onRemoveMateria}
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <Typography variant="h6" gutterBottom mb={2}>
              8. Selecione el horario:
            </Typography>
            <CustomAutocompleteMultipleForm
              name="horario"
              label={'Horario'}
              placeholder={'Seleccione el horario'}
              control={control}
              limitTags={10}
              options={horarios?.map((m) => ({ id: m?._id, ...m })) || []}
              getOptionLabel={(option) => option?.name}
              isLoading={isLoadingHorarios}
              optionLabel="name"
              onChangeCallback={onRemoveHorario}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomSelect
              control={control}
              name={'modalidad'}
              label="Modalidad"
              menuItems={modalidadesDocente}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomCheckbox control={control} name="estado" label="Estado" />
          </Grid>
        </Grid>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            p: (theme) => theme.spacing(1.5, 2),
            pl: 3,
          }}
        >
          <Button onClick={onClose} color="secondary" sx={{ mr: 1 }}>
            Cancelar
          </Button>
          <Button
            startIcon={isLoading && <CircularProgress size={16} />}
            variant="contained"
            disabled={!isValid || _.isEmpty(dirtyFields) || isLoading}
            onClick={methods.handleSubmit(onSubmit)}
            sx={{ mr: 1 }}
          >
            {isLoading ? 'Guardando...' : 'Guardar'}
          </Button>
        </CardActions>
      </FormProvider>
    </Div>
  );
};

export default EditarDocente;
