import Div from '@jumbo/shared/Div';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import UserActions from '../components/UserActions';

export const useUserColumns = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const columns = [
    {
      field: 'name',
      headerName: 'Nombre',
      minWidth: isDesktop ? 450 : 190,
    },
    {
      field: 'rol',
      headerName: 'Rol',
      minWidth: isDesktop ? 400 : 150,
      valueGetter: (params) => params.row?.rol?.name,
      renderCell: (params) => {
        const rol = params.row?.rol?.name;
        return (
          <div className="d-flex align-items-center w-full p-3 justify-content-center">
            <Div
              className="rounded  w-full p-3 text-center"
              sx={{
                bgcolor: (theme) =>
                  rol === 'Administrativo'
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
                color: (theme) =>
                  rol === 'Administrativo'
                    ? theme.palette.primary.contrastText
                    : theme.palette.secondary.contrastText,
              }}
            >
              <Typography
                variant="body1"
                className="text-capitalize text-ellipsis text-12"
              >
                {params.row?.rol?.name}
              </Typography>
            </Div>
          </div>
        );
      },
    },
    {
      field: 'sede',
      headerName: 'Sede',
      minWidth: isDesktop ? 200 : 100,
      valueGetter: (params) => params.row?.sede?.name,
    },
    {
      field: 'document',
      headerName: 'Documento',
      minWidth: isDesktop ? 150 : 120,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'email',
      minWidth: isDesktop ? 300 : 220,
    },

    {
      field: 'phone',
      headerName: 'Celular',
      minWidth: isDesktop ? 200 : 100,
    },

    {
      field: 'createAt',
      headerName: 'Creación',
      minWidth: 100,
      valueGetter: (params) => params.row?.createAt?.slice(0, 10),
    },

    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      minWidth: 100,
      renderCell: (params) => <UserActions params={params} />,
    },
  ].filter(Boolean);

  return columns;
};
