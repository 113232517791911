import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import CustomNumberTextField from 'app/shared/ReactHookForm/CustomNumberTextField';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import useCreateAula from 'app/services/aula/createAula';
import useCreateMalla from 'app/services/malla/createMalla';
const CrearAula = () => {
  const { control, handleSubmit, setValue } = useFormContext();
  const { mutate: createAula, isLoading: isLoadingAula } = useCreateAula();
  const { mutate: createMalla, isLoading: isLoadingMalla } = useCreateMalla();
  const onSubmit = (data) => {
    createAula(
      {
        name: data.aulaName,
        aforo: data.aulaAforo,
        sede: data.sede,
      },
      {
        onSuccess: () => {
          createMalla(data.sede, {
            onSuccess: () => {
              setValue('aulaName', '');
              setValue('aulaAforo', 0);
            },
          });
        },
      }
    );
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" fontWeight="bold">
          Crea una nueva aula:
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextField
          name={'aulaName'}
          control={control}
          label="Nombre"
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomNumberTextField
          name={'aulaAforo'}
          control={control}
          label="Aforo "
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          fullWidth
          disabled={isLoadingAula || isLoadingMalla}
          startIcon={
            isLoadingAula || isLoadingMalla ? (
              <CircularProgress size={20} />
            ) : (
              <AddIcon />
            )
          }
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          size="large"
          color="primary"
          sx={{ mt: 1 }}
        >
          {isLoadingAula || isLoadingMalla ? 'Creando...' : 'Crear'}
        </Button>
      </Grid>
    </>
  );
};

export default CrearAula;
