import MateriaActions from '../components/MateriaActions';
import VerPrerequisitos from '../components/VerPrerequisitos';

export const useMateriasColumns = () => {
  const columns = [
    {
      field: 'codigo',
      headerName: 'Código',
      minWidth: 100,
    },
    {
      field: 'materia',
      headerName: 'Materia',
      minWidth: 200,
    },
    {
      field: 'abreviacion',
      headerName: 'Abreviación',
      minWidth: 100,
    },
    {
      field: 'sede',
      headerName: 'Sede',
      minWidth: 200,
      valueGetter: (params) => params.row?.sede?.name,
    },
    {
      field: 'aforo_minimo',
      headerName: 'Aforo mínimo',
      minWidth: 200,
    },
    {
      field: 'cupo_maximo',
      headerName: 'Cupo máximo',
      minWidth: 200,
    },
    {
      field: 'prerequisitos',
      headerName: 'Prerequisitos',
      minWidth: 130,
      type: 'actions',
      renderCell: (params) => <VerPrerequisitos materia={params.row} />,
    },

    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      minWidth: 100,
      renderCell: (params) => <MateriaActions params={params} />,
    },
  ].filter(Boolean);

  return columns;
};
