import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from '@mui/material';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import React from 'react';
import useGetJornadasBySede from 'app/services/jornada/getJornadaBySede';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  StyledTableCell,
  StyledTableRow,
} from 'app/shared/StyledTable/StyledTable';
import { useState } from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Spinner from 'app/shared/Spinner';
import JornadaActions from './JornadaActions';
import { useDeleteHorarioJornada } from 'app/services/jornada/deleteHorarioJornada';

const JornadasList = ({ selectedSede }) => {
  const { data: jornadas, isLoading } = useGetJornadasBySede(selectedSede?.id);
  const { showDialog, hideDialog } = useJumboDialog();
  const { mutate: deleteHorarioJornada, isLoading: isDeletingHorario } =
    useDeleteHorarioJornada(selectedSede?.id);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteHorario = ({ horario, jornada }) => {
    console.log({ horario, jornada });
    showDialog({
      title: `Estas seguro de eliminar el horario ${horario?.horario} de la Jornada  ${jornada?.jornada}?`,
      variant: 'confirm',
      onYes: () =>
        deleteHorarioJornada(
          {
            idJornada: jornada?._id,
            idHorario: horario?.idHorario,
          },
          {
            onSuccess: hideDialog,
          }
        ),
      onNo: hideDialog,
    });
  };

  return (
    <Grid item xs={12}>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: { xs: 300, xl: 600 },
          }}
        >
          <Spinner />
        </Box>
      ) : jornadas?.length > 0 ? (
        <Paper
          className="scrollbar"
          sx={{
            borderRadius: '0.5rem',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <TableContainer
            sx={{
              height: { xs: 400, md: 500, xl: 700 },
            }}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Jornada</StyledTableCell>
                  <StyledTableCell>Horario</StyledTableCell>
                  <StyledTableCell align="center">Sede</StyledTableCell>
                  <StyledTableCell align="center">Acciones</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {jornadas
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((jornada, index) => (
                    <StyledTableRow
                      key={index}
                      className={
                        index % 2 === 0 ? 'tableRow-even' : 'tableRow-odd'
                      }
                    >
                      <StyledTableCell colSpan={1}>
                        {jornada?.jornada}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          {jornada?.horario?.length === 0 && (
                            <Chip size="small" label="Sin Asignar" />
                          )}
                          {jornada?.horario?.map((horario, index) => (
                            <div
                              key={horario?._id}
                              className="flex  items-center"
                            >
                              <Chip
                                color="primary"
                                size="small"
                                sx={{ mb: 0.5 }}
                                label={horario?.horarioName}
                              />
                              <IconButton
                                disabled={isDeletingHorario}
                                onClick={() =>
                                  handleDeleteHorario({
                                    horario,
                                    jornada,
                                  })
                                }
                                size="small"
                                color="error"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          ))}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {selectedSede?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {/* <IconButton
                          disabled={isDeleting}
                          onClick={() => handleDeleteJornada(jornada)}
                          size="small"
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton size="small" color="info">
                          <DeleteIcon />
                        </IconButton> */}
                        <JornadaActions jornada={jornada} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={jornadas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Filas por página"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <NoDataFound2 label="No se encontraron resultados para la búsqueda" />
      )}
    </Grid>
  );
};

export default JornadasList;

// {isLoading ? (
//   <Div
//     sx={{
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       height: { xs: 300, xl: 600 },
//     }}
//   >
//     <Spinner />
//   </Div>
// ) : jornadas?.length > 0 ? (
//   <CustomDataGrid
//     columns={columns}
//     data={jornadas?.map((j) => ({ ...j, sede: selectedSede?.name }))}
//     br={0}
//     id="_id"
//     height={{ xs: 630, md: 450, xl: 775 }}
//     sortModel={[{ field: 'name', sort: 'asc' }]}
//     isLoading={isLoading}
//   />
// ) : (
//   <NoDataFound2 label="No se encontraron resultados para la busqueda" />
// )}
