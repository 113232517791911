import SedeActions from '../components/SedeActions';

export const useSedeColumns = () => {
  const columns = [
    {
      field: 'name',
      headerName: 'Sede',
      minWidth: 400,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      minWidth: 100,
      renderCell: (params) => <SedeActions params={params} />,
    },
  ].filter(Boolean);

  return columns;
};
