import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const removeMateriaFromAula = async ({ idAula, idMateria }) => {
  const { data } = await client.delete(
    `/aula-materia/delete-materia-idMateria/${idMateria}/idAulaMateria/${idAula}`
  );
  return data;
};

export const useDeleteMateriaFromAula = (idSede) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(removeMateriaFromAula, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('aulasVsMaterias');
      queryClient.invalidateQueries([idSede, 'aulasVsMateriasBySede']);
      enqueueSnackbar('La materia ha sido removida con éxito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al eliminar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
};
