import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import Spinner from 'app/shared/Spinner/Spinner';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const UserPermission = ({ fallbackPath = '/', allowedModules = [] }) => {
  const { isLoading } = useJumboAuth();
  const { roleName, permisos } = useSelector(({ auth }) => auth);
  // Verifica si al menos uno de los módulos permitidos está presente en los permisos
  const isAllowed = allowedModules.some(
    (module) =>
      permisos?.find((permiso) => permiso?.permiso?.type === module)?.estado
  );

  if (isLoading) return <Spinner pt={30} />;

  if (roleName === 'Superadministrador') return <Outlet />;

  if (isAllowed) return <Outlet />;

  return <Navigate to={fallbackPath} />;
};

export default UserPermission;
