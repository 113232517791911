import EditIcon from '@mui/icons-material/Edit';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import PasswordIcon from '@mui/icons-material/Password';
import EditUser from './EditarUsuario';
import EditUserPassword from './EditUserPassword';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import useUnableUser from 'app/services/users/unableUser';
import useActivateUser from 'app/services/users/activateUser';
import { useDeleteUser } from 'app/services/users/deleteUser';
import EditarPermisos from './EditarPermisos';
import { useSelector } from 'react-redux';
const UserActions = ({ params }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const { roleName } = useSelector(({ auth }) => auth);

  const { mutate: deleteUser } = useDeleteUser();
  const { mutate: unableUser } = useUnableUser();
  const { mutate: enableUser } = useActivateUser();
  const user = params.row;

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          content: <EditUser user={user} onClose={hideDialog} />,
          maxWidth: 'md',
        });
        break;
      case 'delete':
        showDialog({
          title: 'Estas seguro de eliminar este usuario?',
          variant: 'confirm',
          onYes: () => deleteUser(user?._id, { onSuccess: hideDialog }),
          onNo: hideDialog,
        });
        break;
      case 'changePassword':
        showDialog({
          content: <EditUserPassword user={user} onClose={hideDialog} />,
        });
        break;
      case 'enable':
        showDialog({
          title: 'Estas seguro de activar este usuario?',
          variant: 'confirm',
          onYes: () => enableUser(user?._id, { onSuccess: hideDialog }),
          onNo: hideDialog,
        });
        break;
      case 'unable':
        showDialog({
          title: 'Estas seguro de desactivar este usuario?',
          variant: 'confirm',
          onYes: () => unableUser(user?._id, { onSuccess: hideDialog }),
          onNo: hideDialog,
        });
        break;
      case 'editPermissions':
        showDialog({
          content: <EditarPermisos user={user} onClose={hideDialog} />,
        });
        break;
      default:
        break;
    }
  };

  const menuItems = [
    {
      icon: <EditIcon />,
      title: 'Editar',
      action: 'edit',
    },
    {
      icon: <PasswordIcon />,
      title: 'Cambiar Contraseña',
      action: 'changePassword',
    },
    roleName === 'Superadministrador' && {
      icon: <PasswordIcon />,
      title: 'Editar Permisos',
      action: 'editPermissions',
    },
    user?.active
      ? {
          icon: <DoDisturbOnIcon />,
          title: 'Desactivar',
          action: 'unable',
        }
      : {
          icon: <CheckIcon />,
          title: 'Activar',
          action: 'enable',
        },
    {
      icon: <DeleteIcon />,
      title: 'Eliminar',
      action: 'delete',
    },
  ].filter(Boolean);

  return (
    <>
      <JumboDdMenu
        icon={<MoreHorizIcon />}
        menuItems={menuItems}
        onClickCallback={handleItemAction}
      />
    </>
  );
};

export default UserActions;
