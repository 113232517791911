import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import TrashIcon from '@mui/icons-material/Delete';
import { useForm } from 'react-hook-form';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import useGetSedes from 'app/services/sedes/getSedes';
import ListaCursos from './components/ListaCursos';
import { PrematriculaProvider } from './PrematriculaProvider';
import EstudiantesAdicionales from './components/EstudiantesAdicionales';
import { useGetPrematriculaBySedePeriodo } from 'app/services/prematricula/getPrematriculaBySedePeriodo';
import Spinner from 'app/shared/Spinner';
import Div from '@jumbo/shared/Div';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import CustomFabLoadingButton from 'app/shared/CustomFabLoadingButton/CustomFabLoadingButton';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useGetEstudiantesSinAsignatura } from 'app/services/prematricula/getEstudiantesSinAsignatura';
import { useGetPrematriculaQ10 } from 'app/services/prematricula/getPrematriculaQ10';
import { useQueryClient } from 'react-query';
import useSavePrematricula from 'app/services/prematricula/savePrematricula';
import { useDeletePrematriculaBySedePeriodo } from 'app/services/prematricula/deletePrematriculaBySedePeriodo';
const PrematriculaPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { showDialog, hideDialog } = useJumboDialog();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const [selectedSede, setSelectedSede] = React.useState(null);
  const [students, setStudents] = React.useState([]);
  const { data: cursos, isLoading } = useGetPrematriculaBySedePeriodo(
    selectedSede?._id
  );
  const { mutate: getPrematriculaQ10, isLoading: isLoadingPrematriculaQ10 } =
    useGetPrematriculaQ10();
  const { mutate: deletePrematricula, isLoading: isDeleting } =
    useDeletePrematriculaBySedePeriodo(selectedSede?._id);
  const {
    data: estudiantesSinAsignatura,
    isLoading: isLoadingEstudiantesSinAsignatura,
  } = useGetEstudiantesSinAsignatura(selectedSede?._id);
  const {
    mutate: saveFile,
    isLoading: isLoadingSaveFile,
    isSuccess,
  } = useSavePrematricula();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      id_sede: '',
      periodo: '',
    },
  });
  const { control, resetField } = methods;

  useEffect(() => {
    if (selectedSede) {
      getPrematriculaQ10(selectedSede?._id, {
        onSuccess: (data) => {
          queryClient.invalidateQueries([
            'getPrematriculaBySedePeriodo',
            selectedSede?._id,
          ]);
          queryClient.invalidateQueries([
            'getEstudiantesSinAsignatura',
            selectedSede?._id,
          ]);
          console.log('response', data);
        },
        onError: (error) => console.error(error.response),
      });
    }
  }, [selectedSede]);

  useEffect(() => {
    if (estudiantesSinAsignatura?.length > 0) {
      const formatedStudents = [];
      estudiantesSinAsignatura.forEach((itm) => {
        itm?.estudiantes?.forEach((estudiante) => {
          formatedStudents.push(estudiante);
        });
      });
      console.log('formatedStudents', formatedStudents);
      setStudents(formatedStudents);
    }
  }, [estudiantesSinAsignatura]);

  const handleDownloadXLSX = async () => {
    try {
      setLoading(true);
      hideDialog();
      const formatedCursos = cursos?.map((curso) => {
        return {
          Código: curso.materia?.codigo,
          'Nombre del curso': curso.curso,
          Sede: curso.sedeName,
          Programa: curso.modalidad ?? '-',
          Pensum: curso.pensum ?? '-',
          Materia: curso.materia?.materia,
          'Cantidad de estudiantes': curso.cantidad_estudiantes,
          Periodo: curso.Nombre,
          'Fecha de inicio': curso.Fecha_inicio
            ? moment(curso.Fecha_inicio).format('DD/MM/YYYY')
            : '',
          'Fecha de fin': curso.Fecha_fin
            ? moment(curso.Fecha_fin).format('DD/MM/YYYY')
            : '',
        };
      });

      const ws = XLSX.utils.json_to_sheet(formatedCursos);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `prematricula`);
      // Guardar el archivo (puedes ajustar la lógica según tu necesidad)
      await XLSX.writeFile(wb, `DNA PREMATRICULA -${selectedSede?.name}.xlsx`);
      // Convertir el libro de Excel a un ArrayBuffer
      const arrayBuffer = XLSX.write(wb, { type: 'array' });
      // Crear un Blob a partir del ArrayBuffer
      const blob = new Blob([arrayBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Agregar el Blob al FormData
      saveFile({ file: blob, selectedSede });
      setSuccess(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    showDialog({
      variant: 'confirm',
      title: `¿Estás seguro de descargar el Archivo Excel DNA PREMATRICULA -${selectedSede?.name}.xlsx?`,
      onYes: handleDownloadXLSX,
      onNo: hideDialog,
    });
  };

  const handleDelete = () => {
    showDialog({
      variant: 'confirm',
      title: `¿Estás seguro de eliminar la pre-matricula de ${
        selectedSede?.name
      } correspondiente al periodo ${
        cursos[0]?.Nombre || estudiantesSinAsignatura[0]?.Nombre
      } ?`,
      onYes: () => {
        deletePrematricula(
          {
            idSede: selectedSede?._id,
            periodo:
              estudiantesSinAsignatura[0]?.Consecutivo ||
              cursos[0]?.Consecutivo,
          },
          {
            onSuccess: () => {
              hideDialog();
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            },
            onError: hideDialog,
          }
        );
      },
      onNo: hideDialog,
    });
  };
  return (
    <PrematriculaProvider
      selectedSede={selectedSede}
      selectedPeriodo={
        estudiantesSinAsignatura?.length > 0
          ? {
              Consecutivo: estudiantesSinAsignatura[0]?.Consecutivo,
              Nombre: estudiantesSinAsignatura[0]?.Nombre,
              Fecha_inicio: estudiantesSinAsignatura[0]?.Fecha_inicio,
              Fecha_fin: estudiantesSinAsignatura[0]?.Fecha_fin,
            }
          : null
      }
    >
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                <IconButton
                  onClick={() => navigate('/panel-control', { replace: true })}
                >
                  <ArrowBack />
                </IconButton>
                PREMATRICULA DE ESTUDIANTES
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomAutoComplete
                isRequired
                control={control}
                name="id_sede"
                label="Por favor seleccione la sede"
                options={sedes?.map((sede) => ({
                  id: sede._id,
                  ...sede,
                }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
                onChangeCallback={(newValue) => {
                  resetField('periodo');
                  console.log('newValue', newValue);
                  setSelectedSede(newValue);
                  setStudents([]);
                }}
              />
            </Grid>

            {isLoading ||
            isLoadingEstudiantesSinAsignatura ||
            isLoadingPrematriculaQ10 ? (
              <Grid item xs={12}>
                <Div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: { xs: 300, lg: 500, xl: 600 },
                  }}
                >
                  <Spinner />
                </Div>
              </Grid>
            ) : cursos?.length === 0 && students?.length === 0 ? (
              <Grid item xs={12}>
                <NoDataFound2 label="No se encontraron cursos para el periodo seleccionado. Por favor seleccione otro periodo." />
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <Box component={'div'} className="flex justify-between mb-16">
                    <Typography variant="h3" gutterBottom my={2}>
                      LISTADO DE CURSOS DISPONIBLES - PERIODO{' '}
                      {cursos?.length > 0
                        ? cursos[0]?.Nombre
                        : estudiantesSinAsignatura?.length > 0
                        ? estudiantesSinAsignatura[0]?.Nombre
                        : ''}{' '}
                      ({cursos?.length} en total)
                    </Typography>
                    <Button
                      startIcon={<TrashIcon />}
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleDelete}
                    >
                      Eliminar Pre-matricula
                    </Button>
                  </Box>
                  <ListaCursos cursos={cursos} />
                </Grid>
                {students?.length > 0 && selectedSede && (
                  <Grid item xs={12}>
                    <EstudiantesAdicionales estudiantes={students} />
                  </Grid>
                )}
                {cursos?.length > 0 && (
                  <CustomFabLoadingButton
                    onClick={handleConfirm}
                    isSuccess={isSuccess}
                    loading={loading || isLoadingSaveFile}
                  />
                )}
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </PrematriculaProvider>
  );
};

export default PrematriculaPage;
