import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import { FormProvider, useForm } from 'react-hook-form';
import useGetSedes from 'app/services/sedes/getSedes';
import { useState } from 'react';
import { useGetStudentBySedeMutation } from 'app/services/users/getStudentsBySede';
import { useEffect } from 'react';
import { useGetReservesByStudentMutation } from 'app/services/reserve/getReservesByStudent';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import Div from '@jumbo/shared/Div';
import Spinner from 'app/shared/Spinner';
import CustomDataGrid from 'app/shared/CustomDataGrid/CustomDataGrid';
import { useReservasColumns } from './hooks/useReservasColumns';
const CancelarPracticas = () => {
  const columns = useReservasColumns();
  const [students, setStudents] = useState([]);
  const [reservas, setReservas] = useState([]);
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const { mutate: getStudents, isLoading: isLoadingStudents } =
    useGetStudentBySedeMutation();
  const { mutate: getReservas, isLoading: isLoadingReservas } =
    useGetReservesByStudentMutation();

  const methods = useForm({
    defaultValues: {
      sede: null,
      estudiante: null,
      refetch: false,
    },
  });
  const { control, watch, resetField } = methods;
  const watchSede = watch('sede');
  const watchEstudiante = watch('estudiante');
  const watchRefetch = watch('refetch');
  useEffect(() => {
    resetField('estudiante');
    setStudents([]);
    if (!watchSede) {
      return;
    }
    getStudents(watchSede, {
      onSuccess: (data) => {
        setStudents(data);
      },
    });
  }, [watchSede]);

  useEffect(() => {
    setReservas([]);
    if (!watchEstudiante) return;
    getReservas(watchEstudiante, {
      onSuccess: (reservas) => {
        console.log(reservas, 'reservas');
        setReservas(reservas);
        resetField('refetch');
      },
    });
  }, [watchEstudiante, watchRefetch]);

  return (
    <FormProvider {...methods}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                CANCELAR PRÁCTICAS
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" mb={2} gutterBottom>
                1. Selecione la sede
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="sede"
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" mb={2} gutterBottom>
                2. Selecione el estudiante
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="estudiante"
                label="Estudiantes"
                placeholder="Buscar por nombre o documento de identidad"
                options={students?.map((student) => ({
                  id: student._id,
                  ...student,
                }))}
                isLoading={isLoadingStudents}
                getOptionLabel={(option) =>
                  `${option?.name}  - doc: ${option?.document}`
                }
              />
            </Grid>
            <Grid item xs={12}>
              {isLoadingReservas ? (
                <Div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: { xs: 300, xl: 600 },
                  }}
                >
                  <Spinner />
                </Div>
              ) : reservas?.length > 0 ? (
                <CustomDataGrid
                  columns={columns}
                  data={reservas}
                  br={0}
                  pageSize={15}
                  id="qr"
                  // pageOptions={[15, 30, 50, 100]}
                  sortModel={[{ field: 'subjectName', sort: 'asc' }]}
                  isLoading={isLoadingReservas}
                />
              ) : (
                <NoDataFound2 label="No se encontraron resultados para la busqueda" />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </FormProvider>
  );
};

export default CancelarPracticas;
