import React from 'react';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import { FormProvider, useForm } from 'react-hook-form';
import useGetSedes from 'app/services/sedes/getSedes';
import { useState } from 'react';
import { useEffect } from 'react';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import Div from '@jumbo/shared/Div';
import Spinner from 'app/shared/Spinner';
import CustomDataGrid from 'app/shared/CustomDataGrid/CustomDataGrid';
import { useHistorialReservasColumns } from './hooks/useHistorialReservasColumns';
import useGetReservasBySede from 'app/services/reserve/getReservaBySede';
import { CustomDatePicker } from 'app/shared/ReactHookForm/CustomDatePicker';
import { FilterList } from '@mui/icons-material';
import { write, utils, writeFile } from 'xlsx';
import moment from 'moment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CustomSelect from 'app/shared/ReactHookForm/CustomSelect';
const HistorialAgendamientos = () => {
  const columns = useHistorialReservasColumns();
  const [reservas, setReservas] = useState([]);
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const { mutate: getReservas, isLoading: isLoadingReservas } =
    useGetReservasBySede();
  const [isExporting, setIsExporting] = useState(false);

  const methods = useForm({
    defaultValues: {
      sede: null,
      refetch: false,
      startDate: '',
      endDate: '',
      tipoPractica: 'TODAS',
    },
  });
  const { control, watch, resetField, handleSubmit } = methods;
  const watchSede = watch('sede');
  const watchRefetch = watch('refetch');
  const fetchReservas = () => {
    getReservas(watchSede, {
      onSuccess: (data) => {
        const filteredReservas = data
          ?.filter((item) => !!item.reserva.malla)
          .map((item) => {
            const {
              reserva: { malla, ...restReserva },
              ...rest
            } = item;
            return { ...malla, ...restReserva, ...rest };
          });

        setReservas(filteredReservas);
      },
    });
  };

  const handleExportToExcel = () => {
    setIsExporting(true);

    // Crear un objeto de trabajo de Excel
    const workbook = utils.book_new();
    const formattedReservas = reservas?.map((reserva) => {
      return {
        Sede: reserva?.sede?.name,
        Aula: reserva?.aula?.name,
        Usuario: reserva?.user?.name,
        Fecha: reserva?.date,
        Horario: reserva?.horario?.name,
        Estado: reserva?.status?.name,
      };
    });

    // Crear una hoja de trabajo de Excel
    const reservasWorksheet = utils.json_to_sheet(formattedReservas);

    // Agregar la hoja de trabajo al libro de Excel
    utils.book_append_sheet(workbook, reservasWorksheet, 'HistorialReservas');
    // Generar el archivo Excel
    const excelBuffer = write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Convertir el buffer de Excel en un blob
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Descargar el archivo Excel
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = `Historial de reservas - ${moment().format(
      'DD-MM-YYYY'
    )}.xlsx`;
    downloadLink.click();
    setIsExporting(false);
  };

  const onSubmit = (data) => {
    const { sede, startDate, endDate, tipoPractica } = data;
    getReservas(watchSede, {
      onSuccess: (data) => {
        const filteredReservas = data
          ?.filter((item) => !!item.reserva.malla)
          .filter((reserva) => {
            // Filtrar por tipo de práctica si no es 'TODAS'
            if (tipoPractica !== 'TODAS') {
              // Aquí verificamos si el nombre del subject incluye 'audición'
              const isAudicion = reserva?.subject?.name
                .toLowerCase()
                .includes('audición');
              console.log('isAudicion', isAudicion);
              return tipoPractica === 'AUDICION' ? isAudicion : !isAudicion;
            }
            return true; // No se aplica filtro de tipo de práctica
          })
          .map((item) => {
            const {
              reserva: { malla, ...restReserva },
              ...rest
            } = item;
            return { ...malla, ...restReserva, ...rest };
          });
        const filteredReservasByDate = filteredReservas?.filter((reserva) => {
          if (!startDate && !endDate) {
            return true; // No date filter applied, return all reservations
          }
          const reservaDate = new Date(reserva.date);
          const filterStartDate = startDate ? new Date(startDate) : null;
          const filterEndDate = endDate ? new Date(endDate) : null;

          if (filterStartDate && filterEndDate) {
            return (
              reservaDate >= filterStartDate && reservaDate <= filterEndDate
            );
          } else if (filterStartDate) {
            return reservaDate >= filterStartDate;
          } else if (filterEndDate) {
            return reservaDate <= filterEndDate;
          }
        });
        setReservas(filteredReservasByDate);
      },
    });
  };
  useEffect(() => {
    if (!watchSede) return;
    fetchReservas();
  }, [watchSede]);

  return (
    <FormProvider {...methods}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                HISTORIAL DE AGENDAMIENTOS
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomAutoComplete
                isRequired
                control={control}
                name="sede"
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSelect
                control={control}
                name="tipoPractica"
                label="Tipo de practica"
                isRequired
                menuItems={[
                  { id: 'TODAS', name: 'Todas' },
                  { id: 'AUDICION', name: 'Practica por audición' },
                  { id: 'LIBRE', name: 'Practica libre' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomDatePicker
                isRequired
                control={control}
                name="startDate"
                label="Fecha de inicio"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomDatePicker
                isRequired
                control={control}
                name="endDate"
                label="Fecha de fin"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                fullWidth
                startIcon={<FilterList />}
                color="secondary"
                onClick={handleSubmit(onSubmit)}
              >
                Filtrar
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                fullWidth
                disabled={isExporting}
                onClick={handleExportToExcel}
                startIcon={<FileDownloadIcon />}
                color="success"
              >
                Exportar a Excel
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {isLoadingReservas ? (
                <Div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: { xs: 300, xl: 600 },
                  }}
                >
                  <Spinner />
                </Div>
              ) : reservas?.length > 0 ? (
                <CustomDataGrid
                  columns={columns}
                  data={reservas}
                  br={0}
                  pageSize={100}
                  pageOptions={[50, 100, 200]}
                  id="_id"
                  height={{ xs: 630, sm: 1000, md: 600, xl: 775 }}
                  sortModel={[{ field: 'date', sort: 'desc' }]}
                  isLoading={isLoadingReservas}
                />
              ) : (
                <NoDataFound2 label="No se encontraron resultados para la busqueda" />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </FormProvider>
  );
};

export default HistorialAgendamientos;
