import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from '@mui/material';
import { green } from '@mui/material/colors';
import useFetchExcel from 'app/services/prematricula/useFetchExcel';
import Spinner from 'app/shared/Spinner';
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DownloadIcon from '@mui/icons-material/Download';
import {
  StyledTableCell,
  StyledTableRow,
} from 'app/shared/StyledTable/StyledTable';
const ExcelViewer = ({ urlFile }) => {
  const { data: datosExcel, isLoading } = useFetchExcel(urlFile);
  const [data, setData] = useState([]);
  const handleDownload = async () => {
    const link = document.createElement('a');
    link.href = urlFile;
    link.download = 'plantilla_clientes.xlsx';
    link.click();
  };

  useEffect(() => {
    if (!datosExcel) return;
    try {
      const workbook = XLSX.read(datosExcel, { type: 'binary', raw: true });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setData(excelData);
    } catch (error) {
      console.error('Error fetching Excel data: ', error);
    }
  }, [datosExcel]);
  if (isLoading)
    return (
      <div className="flex justify-center items-center ">
        <Spinner />
      </div>
    );
  return (
    <div className="w-full">
      <Paper
        className="scrollbar"
        sx={{
          borderRadius: '0.5rem',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <TableContainer
          sx={{
            height: { xs: 400, md: 500, xl: 700 },
          }}
        >
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                {data.length > 0 &&
                  data[0].map((cell, index) => (
                    <StyledTableCell key={index}>{cell}</StyledTableCell>
                  ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, index) => (
                <StyledTableRow
                  key={index}
                  className={index % 2 === 0 ? 'tableRow-even' : 'tableRow-odd'}
                >
                  {row.map((cell, index2) => (
                    <StyledTableCell
                      key={index2}
                      className="border border-gray-400 px-4 py-2"
                    >
                      {cell}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Button
        variant="contained"
        onClick={handleDownload}
        size="large"
        startIcon={<DownloadIcon />}
        sx={{
          height: '100%',
          marginTop: 8,
          backgroundColor: green[600],
          color: '#fff',
          borderRadius: 8,
        }}
      >
        Descargar
      </Button>
    </div>
  );
};

export default ExcelViewer;
