import React from 'react';
import Page from '@jumbo/shared/Page';
import GetUserRoute from './middlewares/Roles/GetUserRoute';
import AgendamientosAdmin from 'app/pages/agendamientos/AgendamientosAdmin';
import Agendamientos from 'app/pages/agendamientos/Agendamientos';
import CancelarPracticas from 'app/pages/cancelacion-practicas/CancelarPracticas';
import EscanerQR from 'app/pages/escanerQR/EscanerQR';
import Grilla from 'app/pages/grilla/Grilla';
import Integraciones from 'app/pages/integraciones/Integraciones';
import HistorialAgendamientos from 'app/pages/historial-agendamientos/HistorialAgendamientos';
import CancelarPracticasEstudiante from 'app/pages/cancelacion-practicas/CancelarPracticasEstudiante';
import UserPermission from './middlewares/Auth/UserPermission';
import Estudiantes from 'app/pages/gestionar-estudiantes/Estudiantes';
import UsersPage from 'app/pages/users/UsersPage';

const agendamientoRoutes = [
  {
    middleware: [
      {
        element: () =>
          GetUserRoute({
            fallbackPath: '/',
            rolesPermitidos: [
              'Superadministrador',
              'Docente',
              'Monitor',
              'Administrativo',
            ],
          }),
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: () =>
              UserPermission({
                allowedModules: ['Lector QR'],
              }),
          },
        ],
        routes: [
          {
            path: '/lectura-qr/',
            element: <Page component={EscanerQR} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: () =>
              UserPermission({ allowedModules: ['Modificar Malla'] }),
          },
        ],
        routes: [
          {
            path: '/grilla-horarios/',
            element: <Page component={Grilla} />,
          },
        ],
      },

      {
        middleware: [
          {
            element: () =>
              UserPermission({
                allowedModules: ['Agregar Materias', 'Agregar Alulas'],
              }),
          },
        ],
        routes: [
          {
            path: '/integraciones',
            element: <Page component={Integraciones} />,
          },
        ],
      },
      {
        middleware: [
          { element: () => UserPermission({ allowedModules: ['Historial'] }) },
        ],
        routes: [
          {
            path: '/historial-agendamientos/',
            element: <Page component={HistorialAgendamientos} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: () =>
              UserPermission({ allowedModules: ['Perfiles Estudiantes'] }),
          },
        ],
        routes: [
          {
            path: '/estudiantes/',
            element: <Page component={Estudiantes} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: () =>
              UserPermission({
                allowedModules: ['Perfiles Administrador, Docente y Monitor'],
              }),
          },
        ],
        routes: [
          {
            path: '/usuarios/',
            element: <Page component={UsersPage} />,
          },
        ],
      },
    ],
  },
  {
    middleware: [
      {
        element: () =>
          GetUserRoute({
            fallbackPath: '/',
            rolesPermitidos: ['Estudiante', 'Superadministrador'],
          }),
      },
    ],
    routes: [
      {
        path: '/agendar-practicas/',
        element: <Page component={Agendamientos} />,
      },
      {
        path: '/agendamientos/',
        element: <Page component={CancelarPracticasEstudiante} />,
      },
    ],
  },
];
export default agendamientoRoutes;
