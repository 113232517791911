import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import moment from 'moment';

export const CustomDatePicker = ({
  name,
  control,
  label,
  disabled = false,
  size ,
  isRequired = false,
  errors,
  defaultValue,
  fullWidth = true,
  shouldUnregister = false,
}) => {
  return (
    <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        defaultValue={defaultValue}
        shouldUnregister={shouldUnregister}
        control={control}
        rules={{
          required: isRequired ? 'Campo requerido' : false,
          shouldUnregister: shouldUnregister,
        }}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { error },
        }) => (
          <DatePicker
            label={label}
            disabled={disabled}
            inputFormat="dd-MM-yyyy"
            onChange={onChange}
            value={new Date(value)}
            slotProps={{
              textField: {
                size,
                error: !!error,
                helperText: error?.message,
                fullWidth,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
