import { useQuery } from 'react-query';
import client from '../config';

const getSedes = async () => {
  try {
    const response = await client.get('/sede/all/register');
    const { data } = response;
    return data;
  } catch (e) {
    return e.response;
  }
};

export default function useGetSedes() {
  return useQuery(['sedes'], getSedes, { refetchOnWindowFocus: false });
}
