import {useRoutes} from "react-router-dom";
import {buildRoutes} from "@jumbo/utils";
import useJumboApp from "@jumbo/hooks/useJumboApp";
import {useEffect, useMemo} from "react";

const useJumboRoutes = (routes) => {
    const {rebuildRoutes, setRebuildRoutes} = useJumboApp();

    const generatedRoutes = useMemo(() => {
        return buildRoutes([...routes])
    }, [routes])

    useEffect(() => {
        if(rebuildRoutes) {
            setRebuildRoutes(false)
        }
    }, [rebuildRoutes, setRebuildRoutes])

    return useRoutes(generatedRoutes);
};

export default useJumboRoutes;