import Div from '@jumbo/shared/Div';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import useGetAulasBySedeMutation from 'app/services/aula/getAulasBySede';
import useGetSedes from 'app/services/sedes/getSedes';
import { useGetSubjectsBySedeFullfilled } from 'app/services/subject/getSubjectsBySede';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import Spinner from 'app/shared/Spinner';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Aulas from './components/Aulas';
import Materias from './components/Materias';
import { useSelector } from 'react-redux';

const Integraciones = () => {
  const { permisos, roleName } = useSelector(({ auth }) => auth);

  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const { mutate: getAulas, isLoading: isLoadingAulas } = useGetAulasBySedeMutation();
  const { mutate: getMaterias, isLoading: isLoadingMaterias } =
    useGetSubjectsBySedeFullfilled();
  const [aulas, setAulas] = useState([]);
  const [materias, setMaterias] = useState([]);
  const [refetchMaterias, setRefetchMaterias] = useState(false);
  const isAllowedMateria =
    permisos?.find((permiso) => permiso?.permiso?.type === 'Agregar Materias')
      ?.estado || roleName === 'Superadministrador';
  const isAllowedAula =
    permisos?.find((permiso) => permiso?.permiso?.type === 'Agregar Alulas')
      ?.estado || roleName === 'Superadministrador';
  const options = [
    isAllowedAula && { id: 'AULAS', name: 'Gestion de aulas' },
    isAllowedMateria && { id: 'MATERIAS', name: 'Gestion de materias' },
  ].filter(Boolean);

  const methods = useForm({
    defaultValues: {
      sede: null,
      tipoIntegracion: null,
      aulaName: '',
      aulaAforo: 0,
      aula: null,
      materias: [],
      refetch: false,
    },
  });
  const { control, watch, resetField } = methods;
  const watchSede = watch('sede');
  const watchTipoIntegracion = watch('tipoIntegracion');
  const refetch = watch('refetch');
  useEffect(() => {
    setAulas([]);
    setMaterias([]);
    if (!watchSede) return;
    getAulas(watchSede, {
      onSuccess: (data) => {
        setAulas(data);
      },
    });
    getMaterias(watchSede, {
      onSuccess: (data) => {
        console.log(data, 'materias con aulas');
        setMaterias(data);
        setRefetchMaterias(false);
        resetField('refetch');
      },
    });
  }, [watchSede, refetch, refetchMaterias]);

  return (
    <Card sx={{ mt: 4 }}>
      <CardContent>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                INTEGRACIONES
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" mb={2} gutterBottom>
                1. Selecciona el tipo de integración:
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="tipoIntegracion"
                label="Tipo de Integración"
                options={options}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" mb={2} gutterBottom>
                2. Selecciona una Sede:
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="sede"
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>

            {isLoadingAulas || isLoadingMaterias ? (
              <Grid item xs={12}>
                <Div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: { xs: 300, xl: 600 },
                  }}
                >
                  <Spinner />
                </Div>
              </Grid>
            ) : watchTipoIntegracion === 'AULAS' && !!watchSede ? (
              <Aulas aulas={aulas} />
            ) : watchTipoIntegracion === 'MATERIAS' && !!watchSede ? (
              <Materias
                materias={materias}
                aulas={aulas}
                setRefetchMaterias={setRefetchMaterias}
              />
            ) : (
              <Grid item xs={12}>
                <NoDataFound2 />
              </Grid>
            )}
          </Grid>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export default Integraciones;
