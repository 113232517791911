export const exampleCursos = [
  createData(
    'ABC123',
    'Curso de React',
    'Sede Principal - Mañana',
    'Ingeniería de Sistemas',
    'Pensum 2022',
    'Programación Avanzada',
    30,
    '2024-01',
    '2024-02-01',
    '2024-06-30'
  ),
  createData(
    'DEF456',
    'Curso de Angular',
    'Sede Secundaria - Tarde',
    'Ingeniería de Software',
    'Pensum 2023',
    'Desarrollo Web',
    25,
    '2024-01',
    '2024-02-15',
    '2024-07-15'
  ),
  createData(
    'GHI789',
    'Curso de Python',
    'Sede Terciaria - Noche',
    'Ciencia de Datos',
    'Pensum 2022',
    'Análisis de Datos',
    35,
    '2024-02',
    '2024-03-10',
    '2024-08-10'
  ),
  createData(
    'JKL012',
    'Curso de Java',
    'Sede Principal - Mañana',
    'Ingeniería de Sistemas',
    'Pensum 2022',
    'Desarrollo de Aplicaciones',
    20,
    '2024-02',
    '2024-03-01',
    '2024-08-31'
  ),
  createData(
    'MNO345',
    'Curso de C++',
    'Sede Secundaria - Tarde',
    'Ingeniería de Software',
    'Pensum 2023',
    'Programación Orientada a Objetos',
    22,
    '2024-01',
    '2024-02-15',
    '2024-07-15'
  ),
  createData(
    'PQR678',
    'Curso de HTML/CSS',
    'Sede Terciaria - Noche',
    'Diseño Gráfico',
    'Pensum 2022',
    'Diseño Web',
    18,
    '2024-02',
    '2024-03-10',
    '2024-08-10'
  ),
  createData(
    'STU901',
    'Curso de SQL',
    'Sede Principal - Mañana',
    'Ciencia de Datos',
    'Pensum 2022',
    'Bases de Datos',
    25,
    '2024-03',
    '2024-04-01',
    '2024-09-30'
  ),
  createData(
    'VWX234',
    'Curso de JavaScript',
    'Sede Secundaria - Tarde',
    'Ingeniería de Software',
    'Pensum 2023',
    'Desarrollo Web',
    30,
    '2024-03',
    '2024-04-15',
    '2024-09-15'
  ),
  createData(
    'YZA567',
    'Curso de PHP',
    'Sede Terciaria - Noche',
    'Desarrollo de Aplicaciones',
    'Pensum 2022',
    'Programación Web',
    28,
    '2024-04',
    '2024-05-01',
    '2024-10-31'
  ),
  createData(
    'BCD890',
    'Curso de Ruby on Rails',
    'Sede Principal - Mañana',
    'Ingeniería de Software',
    'Pensum 2023',
    'Desarrollo Web Avanzado',
    20,
    '2024-04',
    '2024-05-15',
    '2024-10-15'
  ),
  createData(
    'EFG123',
    'Curso de Flutter',
    'Sede Secundaria - Tarde',
    'Ingeniería de Software',
    'Pensum 2023',
    'Desarrollo de Aplicaciones Móviles',
    25,
    '2024-05',
    '2024-06-01',
    '2024-11-30'
  ),
  createData(
    'HIJ456',
    'Curso de Swift',
    'Sede Terciaria - Noche',
    'Desarrollo de Aplicaciones',
    'Pensum 2022',
    'Desarrollo de iOS',
    18,
    '2024-05',
    '2024-06-15',
    '2024-11-15'
  ),
  createData(
    'KLM789',
    'Curso de React Native',
    'Sede Principal - Mañana',
    'Ingeniería de Software',
    'Pensum 2023',
    'Desarrollo de Aplicaciones Móviles',
    22,
    '2024-06',
    '2024-07-01',
    '2024-12-31'
  ),
];

function createData(
  codigo,
  nombre,
  sedeJornada,
  programa,
  pensum,
  asignatura,
  cupoMaximo,
  periodo,
  fechaInicio,
  fechaFin
) {
  return {
    codigo,
    nombre,
    sedeJornada,
    programa,
    pensum,
    asignatura,
    cupoMaximo,
    periodo,
    fechaInicio,
    fechaFin,
    estudiantes: [
      {
        nombreEstudiante: 'Juan Perez',
        identificacion: '123456789',
      },
      {
        nombreEstudiante: 'Maria Rodriguez',
        identificacion: '987654321',
      },
    ],
  };
}

export const estudiantes = [
  {
    nombre: 'Juan Perez',
    identificacion: '123456781',
  },
  {
    nombre: 'Maria Perez',
    identificacion: '123456782',
  },
  {
    nombre: 'Pedro Perez',
    identificacion: '123456783',
  },
  {
    nombre: 'Luis Perez',
    identificacion: '123456784',
  },
  {
    nombre: 'Ana Perez',
    identificacion: '123456785',
  },
  {
    nombre: 'Sofia Perez',
    identificacion: '123456786',
  },
  {
    nombre: 'Carlos Perez',
    identificacion: '123456787',
  },
  {
    nombre: 'Luisa Perez',
    identificacion: '123456788',
  },
  {
    nombre: 'Luis Perez',
    identificacion: '123456789',
  },
];
