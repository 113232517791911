import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import Spinner from 'app/shared/Spinner/Spinner';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const GetUserRoute = ({ fallbackPath, rolesPermitidos }) => {
  const { isLoading, isAuthenticated } = useJumboAuth();
  const { roleName } = useSelector(({ auth }) => auth);

  if (!isAuthenticated) return <Navigate to={'/'} />;

  if (isLoading) return <Spinner pt={30} />;

  if (roleName === 'Superadministrador') return <Outlet />;

  if (rolesPermitidos.includes(roleName)) return <Outlet />;

  return <Navigate to={fallbackPath} />;
};

export default GetUserRoute;
