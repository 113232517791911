import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  Button,
  CardActions,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useUpdateStudentPassword from 'app/services/users/updateStudentPassword';
const schema = yup.object().shape({
  passWord: yup
    .string()
    .required('Campo requerido')
    .min(6, 'La contraseña debe tener al menos 6 caracteres.')
    .test(
      'tiene-caracteres-especiales-y-mayuscula',
      '*La contraseña debe contener al menos un dígito, una letra mayúscula y un carácter especial (e.g. !@#$%^&*()_.+).',
      (value) => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_.+])/.test(
          value
        );
      }
    ),
  confirmPassWord: yup
    .string()
    .required('Campo requerido')
    .oneOf([yup.ref('passWord'), null], 'Las contraseñas deben coincidir'),
});
const EditUserPassword = ({ user, onClose }) => {
  const { mutate: updatePassword, isLoading } = useUpdateStudentPassword();
  const [showPassword, setShowPassword] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      passWord: '',
      confirmPassWord: '',
    },
  });
  const {
    control,
    formState: { isValid, dirtyFields },
  } = methods;
  const onSubmit = (dataStudent) => {
    updatePassword(
      {
        email: user.email,
        passWord: dataStudent.passWord,
      },
      { onSuccess: onClose }
    );
  };

  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Cambiar Contraseña: <strong>{user?.name}</strong>`
          </Typography>
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: 'background.default',
            px: 2,
            pb: 2,
          }}
        >
          <Grid item xs={12}>
            <Controller
              name="passWord"
              control={control}
              rules={{
                required: 'Campo requerido',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Contraseña"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Contraseña"
                  fullWidth
                  helperText={error ? error.message : null}
                  error={!!error}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="confirmPassWord"
              control={control}
              rules={{
                required: 'Campo requerido',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Confirmar contraseña"
                  autoComplete='off'
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Confirmar contraseña"
                  fullWidth
                  helperText={error ? error.message : null}
                  error={!!error}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            p: (theme) => theme.spacing(1.5, 2),
            pl: 3,
          }}
        >
          <Button onClick={onClose} color="secondary" sx={{ mr: 1 }}>
            Cancelar
          </Button>
          <Button
            startIcon={isLoading && <CircularProgress size={16} />}
            variant="contained"
            disabled={!isValid || _.isEmpty(dirtyFields) || isLoading}
            onClick={methods.handleSubmit(onSubmit)}
            sx={{ mr: 1 }}
          >
            {isLoading ? 'Guardando...' : 'Guardar'}
          </Button>
        </CardActions>
      </FormProvider>
    </Div>
  );
};

export default EditUserPassword;
