import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deletePrematriculaBySedePeriodo = async ({ idSede, periodo }) => {
  const { data } = await client.delete(
    `/pre-matricula/prematricula-by-sede/${idSede}/periodo/${periodo}`
  );
  return { data };
};

export const useDeletePrematriculaBySedePeriodo = (idSede) => {
  console.log(idSede);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(deletePrematriculaBySedePeriodo, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['getPrematriculaBySedePeriodo', idSede]);
      queryClient.invalidateQueries(['getEstudiantesSinAsignatura', idSede]);

      enqueueSnackbar('Eliminado correctamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al eliminar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
};
