import Div from '@jumbo/shared/Div';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import useGetSede from 'app/services/sedes/getSede';
import { useFormContext } from 'react-hook-form';
import AulasActions from '../helpers/AulasActions';

export const useAulasColumns = () => {
  const { watch } = useFormContext();
  const watchSede = watch('sede', '');
  const { data: sede, isLoading } = useGetSede(watchSede);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const columns = [
    {
      field: 'sede',
      headerName: 'Sede',
      minWidth: isDesktop ? 500 : 400,

      renderCell: (params) => {
        if (isLoading) return <CircularProgress size={20} />;
        return <span>{sede?.name || 'No hay sede'}</span>;
      },
    },
    {
      field: 'name',
      headerName: 'Aula',
      minWidth: isDesktop ? 750 : 400,
    },

    {
      field: 'status',
      headerName: 'Estado',
      minWidth: isDesktop ? 200 : 130,
      valueGetter: ({ row }) => {
        if (!row.estado) return 'Inhabilitada';
        if (row.consecutivo === -1) return 'Agregado';
        return 'Existente';
      },

      renderCell: ({ row }) => {
        return (
          <Div
            className="rounded text-center  p-3 "
            sx={{
              minWidth: 120,
              bgcolor: (theme) =>
                !row.estado
                  ? theme.palette.error.main
                  : row.consecutivo === -1
                  ? theme.palette.info.main
                  : theme.palette.success.main,
              color: (theme) =>
                !row.estado
                  ? theme.palette.error.contrastText
                  : row.consecutivo === -1
                  ? theme.palette.info.contrastText
                  : theme.palette.success.contrastText,
            }}
          >
            <span>
              {!row.estado
                ? 'Inhabilitada'
                : row.consecutivo === -1
                ? 'Agregado'
                : 'Existente'}
            </span>
          </Div>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      minWidth: isDesktop ? 150 : 150,
      renderCell: (params) => <AulasActions params={params} />,
    },
  ].filter(Boolean);

  return columns;
};
