import client from '../config';
import { useMutation } from 'react-query';

export const getReservasBySede = async (idSede) => {
  const { data } = await client.get(
    `/student-reserva/reserves-bySede/${idSede}`
  );
  return data;
};

export default function useGetReservasBySede() {
  return useMutation(getReservasBySede, {
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
    },
  });
}
