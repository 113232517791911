import client from '../config';
import { useMutation } from 'react-query';

export const verifyCupoReserve = async (reserva) => {
  const { data } = await client.post(`reserve/verify-cupo`, reserva);
  return data;
};

export default function useVerifyCupoReserva() {
  return useMutation(verifyCupoReserve, {
    onSuccess: (data) => {
      console.log(data, 'verifyCupoReserve');
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
    },
  });
}
