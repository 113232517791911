import { useMutation, useQuery } from 'react-query';
import client from '../config';

export const getPensumBySede = async (idSede) => {
  if (!idSede) return [];
  const { data } = await client.get(`/pensum/all-by-sede/${idSede}`);
  return data;
};

export default function useGetPensumBySede(idSede) {
  return useQuery([idSede, 'pensumBySede'], () => getPensumBySede(idSede), {
    onError: (error) => {
      console.log(error.response.data);
    },
  });
}

export const useGetPensumBySedeMutation = () => {
  return useMutation((idSede) => getPensumBySede(idSede));
};
