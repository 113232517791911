import React from 'react';
import JumboLayout from '@jumbo/components/JumboLayout';
import Header from '../shared/headers/Header';
import Sidebar from '../shared/sidebars/Sidebar';
import Footer from '../shared/footers/Footer';
import {
  useJumboLayout,
  useJumboLayoutSidebar,
} from '@jumbo/components/JumboLayout/hooks';
import useJumboHeaderTheme from '@jumbo/hooks/useJumboHeaderTheme';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { SIDEBAR_STYLES } from '@jumbo/utils/constants';
import { headerTheme as theme4 } from '../../themes/header/theme4';
import { headerTheme as defaultTheme } from '../../themes/header/default';
import useApp from '../../hooks/useApp';
import layoutConfig, { layoutConfigMobile } from './layoutConfig';
import { useMediaQuery, useTheme } from '@mui/material';
import PreMatriculaSideBar from '../shared/sidebars/PreMatriculaSideBar/PreMatriculaSideBar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MODULOS_APP } from 'app/utils/appHelpers';

const PRE_MATRICULA_ALLOWED_ROLES = ['Superadministrador'];
const VerticalDefault = ({ children }) => {
  const user = useSelector(({ auth }) => auth);
  const selectedModule = localStorage.getItem('selectedModule');
  const navigate = useNavigate();
  const { setJumboLayout } = useJumboLayout();
  const { headerTheme, setHeaderTheme } = useJumboHeaderTheme();
  const { theme } = useJumboTheme();
  const theme2 = useTheme();
  const isMobile = useMediaQuery(theme2.breakpoints.down('md'));
  const appBarBgColor = React.useMemo(
    () => headerTheme.components?.MuiAppBar?.styleOverrides?.root?.background,
    [headerTheme]
  );
  const { sidebarOptions } = useJumboLayoutSidebar();
  const appData = useApp();

  React.useEffect(() => {
    const layoutConfigToSet = isMobile ? layoutConfigMobile : layoutConfig;
    setJumboLayout(layoutConfigToSet);
  }, [setJumboLayout, isMobile]);

  React.useEffect(() => {
    if (
      appBarBgColor === '#F5F7FA' &&
      sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
    ) {
      setHeaderTheme({ ...theme, ...theme4 });
      appData.setAppState({
        prevHeaderBgColor: theme?.mode === 'dark' ? '#222D45' : '#F5F7FA',
      });
    } else if (
      appData.prevHeaderBgColor &&
      appData.prevHeaderBgColor === '#F5F7FA'
    ) {
      setHeaderTheme({ ...theme, ...defaultTheme });
    }
  }, [sidebarOptions.style, appData.setAppState, setHeaderTheme, theme]);

  React.useEffect(() => {
    if (
      !selectedModule &&
      PRE_MATRICULA_ALLOWED_ROLES.includes(user.roleName)
    ) {
      navigate('/dashboard');
    }
  }, [selectedModule, user.roleName]);

  return (
    <JumboLayout
      header={<Header />}
      sidebar={
        PRE_MATRICULA_ALLOWED_ROLES.includes(user.roleName) &&
        selectedModule === MODULOS_APP.PREMATRICULA ? (
          <PreMatriculaSideBar />
        ) : (
          <Sidebar />
        )
      }
      footer={<Footer />}
      headerSx={{
        height: 80,
      }}
    >
      {children}
      {/* <JumboCustomizer/> */}
      {/* <JumboCustomizerButton/> */}
    </JumboLayout>
  );
};

export default VerticalDefault;
