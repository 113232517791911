import { useQuery } from 'react-query';
import client from '../config';

export const getAulaVsMateriasBySede = async (idSede) => {
  if (!idSede) return [];
  const { data } = await client.get(`/aula-materia/all-by-sede/${idSede}`);
  return data;
};

export default function useGetAulaVsMateriasBysede(idSede) {
  return useQuery(
    [idSede, 'aulasVsMateriasBySede'],
    () => getAulaVsMateriasBySede(idSede),
    {
      onError: (error) => {
        console.log(error.response.data);
      },
    }
  );
}
