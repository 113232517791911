import Div from '@jumbo/shared/Div';
import { Button, CircularProgress, TextField } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect } from 'react';
import useGetTiposDocumento from 'app/services/tiposDocumento/getTiposDocumento';
import { SignUpContext } from '../SignUpProvider';
import CustomSelect from 'app/shared/ReactHookForm/CustomSelect';
import CustomAutocompleteMultipleForm from 'app/shared/ReactHookForm/CustomAutocompleteMultipleForm';
import useGetProgramasBySede from 'app/services/programas/getProgramasBySede';
import useVerifyProgram from 'app/services/users/verifyProgram';
import { useSnackbar } from 'notistack';
const Step2 = ({ handleNext, handleBack }) => {
  const { user, setUser } = React.useContext(SignUpContext);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      phone: '',
      tipe_document: '',
      programas: [],
    },
  });
  const { mutate: verifyPrograms, isLoading: isVerifying } = useVerifyProgram();
  const { data: tiposDocumento } = useGetTiposDocumento();
  const { data: programas, isLoading: isLoadingProgramas } =
    useGetProgramasBySede(user?.sede);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (dataForm) => {
    verifyPrograms(
      {
        sede: user?.sede,
        document: user?.document,
        program_code: dataForm?.program_code,
      },
      {
        onSuccess: (data) => {
          const isUserInPrograms = data?.verify?.every(
            (elemento) => elemento === true
          );
          if (!isUserInPrograms) {
            enqueueSnackbar(
              'El usuario no esta en los programas seleccionados, Por favor diríjase a la plataforma de Q10 y verifiqué el programa correcto.',
              {
                variant: 'error',
              }
            );
            return;
          }
          enqueueSnackbar('Usuario verificado', {
            variant: 'success',
          });
          setUser({ ...user, ...dataForm });
          handleNext();
        },
      }
    );
  };
  useEffect(() => {
    if (user && tiposDocumento?.length > 0) {
      reset({
        name: user?.name,
        phone: user?.phone,
        tipe_document: user?.tipe_document,
      });
    }
  }, [user, tiposDocumento, handleBack, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Div sx={{ mt: 1, mb: 2 }}>
        <Controller
          shouldUnregister
          name="name"
          control={control}
          rules={{
            required: 'Campo requerido',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Nombres y apellidos"
              type="text"
              placeholder="Nombres y apellidos"
              fullWidth
              helperText={errors.name?.message}
              error={!!errors.name}
            />
          )}
        />
      </Div>
      <Div sx={{ mt: 1, mb: 2 }}>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: 'Campo requerido',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              disabled
              label="Celular"
              type="text"
              placeholder="Celular"
              fullWidth
              helperText={errors.phone?.message}
              error={!!errors.phone}
            />
          )}
        />
      </Div>

      <Div sx={{ mt: 1, mb: 2 }}>
        <CustomSelect
          name="tipe_document"
          control={control}
          disabled
          isRequired
          label="Tipo de documento"
          menuItems={
            tiposDocumento?.map((item) => ({
              id: item._id,
              name: item.name,
            })) || []
          }
        />
      </Div>
      <Div sx={{ mt: 1, mb: 2 }}>
        <CustomAutocompleteMultipleForm
          name="program_code"
          label={'Programas'}
          control={control}
          isRequired
          options={
            programas?.map((item) => ({
              id: item.codigo,
              ...item,
            })) || []
          }
          limitTags={1}
          getOptionLabel={(option) => option.name}
          loading={isLoadingProgramas}
        />
      </Div>
      <Div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Button
          disabled={isVerifying}
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
          variant="contained"
          color="secondary"
          fullWidth
        >
          Atras
        </Button>
        <Button
          disabled={isVerifying}
          startIcon={isVerifying ? <CircularProgress size={20} /> : null}
          type="submit"
          variant="contained"
          fullWidth
        >
          {isVerifying ? 'Verificando' : 'Continuar'}
        </Button>
      </Div>
    </form>
  );
};

export default Step2;
