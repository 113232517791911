import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const updateUser = async ({ idUser, dataUser }) => {
  const { data } = await client.put(`/user/edit-user/${idUser}`, dataUser);
  return data;
};

export default function useUserUpdate() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateUser, {
    onSuccess: (data) => {
      console.log(data, 'user actualizado');
      queryClient.invalidateQueries('users');
      enqueueSnackbar(`Usuario ${data?.name} actualizado`, {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar(
        'Error al actualizar, por favor intente de nuevo',
        {
          variant: 'error',
        }
      );
    },
  });
}
