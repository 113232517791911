import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import useGetSedes from 'app/services/sedes/getSedes';
import UsersList from './components/UserList';
import useGetTiposDocumento from 'app/services/tiposDocumento/getTiposDocumento';
import useGetRoles from 'app/services/roles/getRoles';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import useCreateAdminUser from 'app/services/users/createAdmin';
import useCreateMonitorUser from 'app/services/users/createMonitor';
import useCreateDocenteUser from 'app/services/users/createDocente';
import CustomNumberTextField from 'app/shared/ReactHookForm/CustomNumberTextField';
import useVerifyQ10User from 'app/services/users/verifyQ10User';
const userModel = {
  name: '',
  sede: null,
  document: '',
  phone: '',
  email: '',
  tipe_document: null,
  rol: null,
};
const UsersPage = () => {
  const [showPassword, setShowPassword] = useState(false);

  const { data: tiposDocumento, isLoading: isLoadingTiposDocumento } =
    useGetTiposDocumento();
  const { data: roles, isLoading: isLoadingRoles } = useGetRoles();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const { mutate: createAdmin, isLoading: isLoadingCreateAdmin } =
    useCreateAdminUser();
  const { mutate: createMonitor, isLoading: isLoadingCreateMonitor } =
    useCreateMonitorUser();
  const { mutate: createDocente, isLoading: isLoadingCreateDocente } =
    useCreateDocenteUser();
  const { mutate: verifyDocenteQ10, isLoading: isLoadingVerifyDocenteQ10 } =
    useVerifyQ10User();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: userModel,
  });

  const { control, handleSubmit, reset, setError } = methods;
  const onSubmit = (dataUser) => {
    const { rol, ...rest } = dataUser;
    switch (rol) {
      case 'Administrativo':
        createAdmin(rest, { onSuccess: () => reset(userModel) });
        break;
      case 'Monitor':
        createMonitor(rest, { onSuccess: () => reset(userModel) });
        break;
      case 'Docente':
        verifyDocenteQ10(
          {
            document: rest.document,
            rol: roles.find((rol) => rol.name === 'Docente')._id,
            sede: rest.sede,
          },
          {
            onSuccess: (data) => {
              createDocente(
                {
                  codigo_rol: 0,
                  ...rest,
                },
                { onSuccess: () => reset(userModel) }
              );
            },
          }
        );

        break;
      default:
        break;
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                CREAR USUARIO
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom mb={2}>
                1. Selecione la sede
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="sede"
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom mb={2}>
                2. Selecione un tipo de usuario
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="rol"
                label="Roles"
                options={roles
                  ?.filter(
                    (rol) =>
                      rol.name !== 'Superadministrador' &&
                      rol.name !== 'Estudiante'
                  )
                  .map((rol) => ({ id: rol.name, ...rol }))}
                isLoading={isLoadingRoles}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom mb={2}>
                3. Selecione el tipo de documento
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="tipe_document"
                label="Documentos"
                options={tiposDocumento
                  ?.filter(
                    (tipoDocumento) =>
                      tipoDocumento.name === 'Cédula de Ciudadanía' ||
                      tipoDocumento.name === 'Tarjeta de Identidad'
                  )
                  .map((doc) => ({
                    id: doc._id,
                    ...doc,
                  }))}
                isLoading={isLoadingTiposDocumento}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom mb={2}>
                4. Nombres & Apellidos
              </Typography>
              <CustomTextField
                name={'name'}
                control={control}
                label="Nombres & Apellidos"
                isRequired
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom mb={2}>
                5. Documento de identidad
              </Typography>
              <CustomNumberTextField
                name={'document'}
                control={control}
                label="Documento"
                isRequired
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom mb={2}>
                6. Celular
              </Typography>
              <CustomNumberTextField
                name={'phone'}
                control={control}
                label="Celular"
                isRequired
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom mb={2}>
                7. Correo
              </Typography>
              <CustomTextField
                name={'email'}
                control={control}
                label="Correo"
                isRequired
                type="email"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom mb={2}>
                8. Contraseña
              </Typography>
              <Controller
                name="passWord"
                control={control}
                rules={{
                  required: 'Campo requerido',
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Contraseña"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Contraseña"
                    fullWidth
                    helperText={error ? error.message : null}
                    error={!!error}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                startIcon={
                  isLoadingCreateAdmin ||
                  isLoadingCreateMonitor ||
                  isLoadingCreateDocente ? (
                    <CircularProgress size={16} />
                  ) : null
                }
                disabled={
                  isLoadingCreateAdmin ||
                  isLoadingCreateMonitor ||
                  isLoadingCreateDocente
                }
                sx={{ mt: { md: 5 } }}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                size="large"
              >
                {isLoadingCreateAdmin ||
                isLoadingCreateMonitor ||
                isLoadingCreateDocente
                  ? 'Creando...'
                  : 'Crear'}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'}>
                LISTA MONITORES & DOCENTES
              </Typography>
            </Grid>
            <UsersList />
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default UsersPage;
