import React, { useState } from 'react';
import AddMateriasToAula from './AddMateriasToAula';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import {
  StyledTableCell,
  StyledTableRow,
} from 'app/shared/StyledTable/StyledTable';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormContext } from 'react-hook-form';
import useGetSede from 'app/services/sedes/getSede';
import { useDeleteAulaSubject } from 'app/services/aula/deleteAulaSubject';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import EditarHorarioMateria from './EditarHorarioMateria';

const Materias = ({ materias, aulas, setRefetchMaterias }) => {
  const { watch, setValue } = useFormContext();
  const { showDialog, hideDialog } = useJumboDialog();
  const watchSede = watch('sede', '');
  const { data: sede, isLoading: isLoadingSede } = useGetSede(watchSede);
  const { mutate: deleteAula, isLoading: isDeleting } = useDeleteAulaSubject();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditHorarios = () => {
    showDialog({
      content: <EditarHorarioMateria materias={materias} onClose={hideDialog} />,
      maxWidth: 'xl',
    });
  };

  const handleDelete = ({ idMateria, idAula }) => {
    showDialog({
      variant: 'confirm',
      title: '¿Estas seguro de eliminar esta aula de esta materia?',
      description: 'Esta accion no se puede deshacer',
      onYes: () => {
        deleteAula(
          {
            idMateria,
            idAula,
          },
          {
            onSuccess: () => {
              setRefetchMaterias(true);
              hideDialog();
            },
          }
        );
      },
      onNo: hideDialog,
    });
  };

  return (
    <>
      <AddMateriasToAula aulas={aulas} />

      <Grid item xs={12} className="flex justify-between items-center">
        <Typography variant="h4" fontWeight="bold" mt={2}>
          Materias:
        </Typography>
        {/* <Button
          variant="outlined"
          color="secondary"
          onClick={handleEditHorarios}
        >
          Ajuste de Horarios y Aulas
        </Button> */}
      </Grid>
      <Grid item xs={12}>
        {materias?.length > 0 ? (
          <Paper
            className="scrollbar"
            sx={{
              borderRadius: '0.5rem',
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <TableContainer
              sx={{
                height: { xs: 400, md: 500, xl: 700 },
              }}
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Acciones</StyledTableCell>
                    <StyledTableCell>Aulas</StyledTableCell>
                    <StyledTableCell>Materia</StyledTableCell>
                    <StyledTableCell align="center">Sede</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {materias
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((materia, index) => (
                      <StyledTableRow
                        key={index}
                        className={
                          index % 2 === 0 ? 'tableRow-even' : 'tableRow-odd'
                        }
                      >
                        <StyledTableCell align="center">
                          {materia?.aula?.length === 0 ? (
                            <IconButton
                              disabled={materia?.aula?.length === 0}
                              size="small"
                              color="primary"
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {materia?.aula?.map((aula, index) => (
                                <IconButton
                                  key={index}
                                  onClick={() =>
                                    handleDelete({
                                      idMateria: materia._id,
                                      idAula: aula._id,
                                    })
                                  }
                                  size="small"
                                  color="secondary"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ))}
                            </Box>
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                          >
                            {materia?.aula?.length === 0 && (
                              <Chip size="small" label="Sin Asignar" />
                            )}
                            {isDeleting ? (
                              <CircularProgress size={20} />
                            ) : (
                              materia?.aula?.map((aula, index) => (
                                <Chip
                                  sx={{ my: 0.5 }}
                                  key={index}
                                  color="primary"
                                  label={aula?.name}
                                  size="small"
                                />
                              ))
                            )}
                          </Box>
                        </StyledTableCell>

                        <StyledTableCell colSpan={1}>
                          {materia?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {isLoadingSede ? (
                            <CircularProgress size={20} />
                          ) : (
                            sede?.name
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={materias.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Filas por página"
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        ) : (
          <NoDataFound2 label="No se encontraron resultados para la búsqueda" />
        )}
      </Grid>
    </>
  );
};

export default Materias;
