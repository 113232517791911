import React from 'react';
import Div from '@jumbo/shared/Div';
import {
  Box,
  Card,
  CardContent,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  alpha,
  useMediaQuery,
} from '@mui/material';
import Link from '@mui/material/Link';
import bgImg from '../../../assets/images/bg_mixer.jpg';
import { useTheme } from '@emotion/react';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import SignUpProvider from './SignUpProvider';
import Step3 from './components/Step3';

const steps = [
  {
    label: 'VERIFICAR USUARIO Q10',
    content: <Step1 />,
  },
  {
    label: 'SELECCIONAR PROGRAMA',
    content: <Step2 />,
  },
  {
    label: 'REGISTRAR USUARIO',
    content: <Step3 />,
  },
];
const SignUp = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const stepsWithHandleNext = steps.map((step) => ({
    ...step,
    content: React.cloneElement(step.content, {
      handleNext,
      handleBack,
    }),
  }));

  return (
    <Div
      sx={{
        minWidth: { xs: '100%', sm: 720, md: 800 },
        maxWidth: '100%',
        margin: 'auto',
        p: { xs: 2, md: 4 },
        py: { xs: 6, md: 0 },
      }}
    >
      {isDesktop && (
        <>
          <Box
            component={'img'}
            src="/backgrounds/bg_purple1.png"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: '100%',
              height: '50vh',
              objectPosition: 'center',
              zIndex: -1,
              boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.25)',
              // msTransform: 'scaleX(-1)',
            }}
          />
          <Box
            component={'img'}
            src="/icons/SVG/LogoDNA.svg"
            sx={{
              position: 'absolute',
              top: '30%', // Centra verticalmente en el centro del contenedor padre
              left: '50%', // Centra horizontalmente en el centro del contenedor padre
              transform: 'translate(-50%, -50%)', // Centra completamente
              zIndex: -2,
              width: '65%',
            }}
          />
        </>
      )}

      <Card
        sx={{
          display: 'flex',
          minWidth: 0,
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <CardContent
          sx={{
            flex: '0 1 300px',
            position: 'relative',
            backgrounColor: (theme) => theme.palette.primary.dark,
            background: `#222328ff url(${bgImg}) no-repeat center`,
            backgroundSize: 'cover',

            '&::after': {
              display: 'inline-block',
              position: 'absolute',
              content: `''`,
              inset: 0,
              backgroundColor: (theme) =>
                alpha(theme.palette.secondary.main, 0.72),
            },
          }}
        >
          <Div
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              color: 'common.white',
              position: 'relative',
              zIndex: 1,
              height: '100%',
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={'h3'}
                color={'inherit'}
                fontWeight={500}
                mb={3}
              >
                Registrate aquí
              </Typography>

              <Typography variant={'body1'}>
                ¿Ya tienes una cuenta?{' '}
                <Link
                  href="/user/login"
                  color="primary.main"
                  fontWeight={600}
                  fontSize={15}
                  fontStyle={'italic'}
                  underline={'hover'}
                >
                  Iniciar sesión
                </Link>
              </Typography>
            </Div>

            <Div sx={{ mt: 'auto' }}>
              <Link href="/user/login" underline="none" sx={{ display: 'inline-flex' }}>
                <Box
                  component="img"
                  src={'/logo_horizontal.png'}
                  sx={{ height: 50 }}
                  mr={1}
                />
              </Link>
            </Div>
          </Div>
        </CardContent>
        <CardContent
          sx={{
            flex: 1,
            p: { xs: 2, md: 4 },
          }}
        >
          <SignUpProvider>
            <Stepper activeStep={activeStep} orientation="vertical">
              {stepsWithHandleNext.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === 2 ? (
                        <Typography variant="caption">
                          Ultimo paso para completar tu registro
                        </Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    <Box sx={{ mb: 2, pl: 2 }}>{step?.content}</Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </SignUpProvider>
        </CardContent>
      </Card>
    </Div>
  );
};

export default SignUp;
