import React from 'react';
import Avatar from '@mui/material/Avatar';
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import JumboDdPopover from '@jumbo/components/JumboDdPopover';
import Div from '@jumbo/shared/Div';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { useBasicAuth } from '../../../auth-providers/BasicAuth/hooks';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import SpeakerIcon from '@mui/icons-material/Speaker';

const AuthUserDropdown = () => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const { logout } = useBasicAuth();
  const { authUser } = useJumboAuth();
  const onLogout = () => {
    logout();
    navigate('/user/login');
  };

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <Avatar
            src={authUser?.nombre}
            sizes={'small'}
            sx={{
              background:
                'linear-gradient(90deg, rgba(131,29,180,1) 0%, rgba(95,66,173,1) 35%, rgba(206,103,255,1) 100%)',
              color: 'common.white',
              borderColor: 'background.paper',
              boxShadow: 25,
              cursor: 'pointer',
            }}
          >
            <SpeakerIcon />
          </Avatar>
        }
      >
        <Div
          sx={{
            minWidth: 200,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: (theme) => theme.spacing(2.5),
          }}
        >
          <Avatar
            src={authUser?.nombre}
            alt={authUser?.nombre}
            sx={{
              background:
                'linear-gradient(90deg, rgba(131,29,180,1) 0%, rgba(95,66,173,1) 35%, rgba(206,103,255,1) 100%)',
              color: 'common.white',
              borderColor: 'background.paper',
              width: 60,
              height: 60,
              mb: 2,
            }}
          >
            <SpeakerIcon />
          </Avatar>
          <Typography variant={'h5'}>{authUser?.nombre}</Typography>
          <Typography variant={'body1'} color="text.secondary">
            {authUser?.email}
          </Typography>
        </Div>
        <Divider />
        <nav>
          <List disablePadding sx={{ pb: 1 }}>
            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Cerrar sesion" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
