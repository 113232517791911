import { useMutation, useQuery } from 'react-query';
import client from '../config';

export const getMateriasBySede = async (idSede) => {
  if (!idSede) return [];
  const { data } = await client.get(`/materias/all-by-sede/${idSede}`);
  return data;
};

export default function useGetMateriasBySede(idSede) {
  return useQuery([idSede, 'materiasBySede'], () => getMateriasBySede(idSede), {
    onError: (error) => {
      console.log(error.response.data);
    },
  });
}

export const useGetMateriasBySedeMutation = () => {
  return useMutation((idSede) => getMateriasBySede(idSede));
};
