import Div from '@jumbo/shared/Div';
import { Grid } from '@mui/material';
import CustomDataGrid from 'app/shared/CustomDataGrid/CustomDataGrid';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import Spinner from 'app/shared/Spinner';
import React from 'react';
import { useProgramasColumns } from '../hooks/useProgramasColumns';
import useGetProgramasBySede from 'app/services/programas/getProgramasBySede';

const ProgramasList = ({ selectedSede }) => {
  const columns = useProgramasColumns();
  const { data: aulasBySede, isLoading } = useGetProgramasBySede(selectedSede?.id);
  return (
    <Grid item xs={12}>
      {isLoading ? (
        <Div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: { xs: 300, xl: 600 },
          }}
        >
          <Spinner />
        </Div>
      ) : aulasBySede?.length > 0 ? (
        <CustomDataGrid
          columns={columns}
          data={
            aulasBySede?.map((aula) => ({ ...aula, sede: selectedSede })) || []
          }
          br={0}
          id="_id"
          height={{ xs: 630, md: 500, xl: 775 }}
          sortModel={[{ field: 'name', sort: 'asc' }]}
          isLoading={isLoading}
          pageSize={50}
        />
      ) : (
        <NoDataFound2 label="No se encontraron resultados para la busqueda" />
      )}
    </Grid>
  );
};

export default ProgramasList;
