import { useMutation, useQuery, useQueryClient } from 'react-query';
import client from '../config';

export const getAulasBySede = async (idSede) => {
  if (!idSede) return [];
  const { data } = await client.get(`/aula/all-bySede/${idSede}`);
  return data;
};

export const useGetAulasBySede = (idSede) => {
  return useQuery(['aulas', idSede], () => getAulasBySede(idSede));
};

export default function useGetAulasBySedeMutation() {
  const queryClient = useQueryClient();
  return useMutation(getAulasBySede, {
    onSuccess: (data) => {
      if (data?.length > 0)
        queryClient.setQueryData(['aulas', data[0].sede], data);
    },
    onError: (error) => {
      console.log(error.response);
    },
  });
}
