import React from 'react';
import { Box } from '@mui/material';
import Div from '@jumbo/shared/Div';

const Spinner = ({ pt = 0 }) => {
  return (
    <Div
      sx={{
        display: 'flex',
        minWidth: 0,
        alignItems: 'center',
        alignContent: 'center',
        height: '100%',
        pt: pt,
      }}
    >
      {/* <Box
        className="logo-loader"
        component="img"
        src={'/icons/SVG/logoDNA.svg'}
      /> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1169.377"
        height="1169.394"
        className="logo-loader"
        viewBox="0 0 1169.377 1169.394"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectángulo_83"
              data-name="Rectángulo 83"
              width="1169.377"
              height="1169.394"
              fill="#DEE2E6"
            />
          </clipPath>
        </defs>
        <g id="Grupo_74" data-name="Grupo 74" clip-path="url(#clip-path)">
          <path
            id="Trazado_35"
            data-name="Trazado 35"
            d="M584.693,1169.394C262.293,1169.394,0,907.1,0,584.7S262.293,0,584.693,0s584.684,262.293,584.684,584.7-262.29,584.7-584.684,584.7m0-1151.084C272.388,18.31,18.31,272.387,18.31,584.7s254.078,566.392,566.383,566.392S1151.067,897,1151.067,584.7,896.992,18.31,584.693,18.31"
            fill="#DEE2E6"
          />
          <path
            id="Trazado_36"
            data-name="Trazado 36"
            d="M347.324,247.868a323.658,323.658,0,0,0-56.435-114.335l47.487,134.95Z"
            transform="translate(551.449 253.143)"
            fill="#DEE2E6"
            fill-rule="evenodd"
          />
          <path
            id="Trazado_37"
            data-name="Trazado 37"
            d="M717.7,282.2,675.335,161.8,620.606,488.947,567.089,329.285l-51.475,159.26-54.7-327.968L406.842,299.01l-42.46-141.219L328.333,517.265,257.208,324.513,208.751,495.891,148.844,168.485,106.464,277.657,91.2,254.306c-.982,10.292-1.526,20.71-1.526,31.257,0,179.5,145.508,325.009,325.018,325.009S739.7,465.061,739.7,285.562a327.442,327.442,0,0,0-3.345-46.343Z"
            transform="translate(170 299.13)"
            fill="#DEE2E6"
            fill-rule="evenodd"
          />
          <path
            id="Trazado_38"
            data-name="Trazado 38"
            d="M97.368,359.293,152.3,217.784l56.89,310.907,41.148-145.543,61.6,166.939,37.584-374.708,55.891,185.9,59.878-153.306,52.995,317.738,44.3-137.087,46.8,139.612,53.3-318.6C603.086,136.449,512.3,89.674,410.566,89.674c-157.656,0-289.047,112.259-318.714,261.187Z"
            transform="translate(174.127 169.998)"
            fill="#DEE2E6"
            fill-rule="evenodd"
          />
          <path
            id="Trazado_39"
            data-name="Trazado 39"
            d="M457.359,847.54C242.209,847.54,67.17,672.5,67.17,457.357S242.209,67.168,457.359,67.168,847.545,242.207,847.545,457.357,672.507,847.54,457.359,847.54m0-769.458c-209.133,0-379.275,170.142-379.275,379.275S248.226,836.632,457.359,836.632,836.634,666.49,836.634,457.357,666.492,78.082,457.359,78.082"
            transform="translate(127.337 127.333)"
            fill="#DEE2E6"
          />
          <path
            id="Trazado_40"
            data-name="Trazado 40"
            d="M478.184,900.179c-232.69,0-422-189.306-422-422s189.309-422,422-422,422,189.309,422,422-189.306,422-422,422m0-833.083C251.511,67.1,67.1,251.508,67.1,478.181s184.412,411.09,411.085,411.09,411.085-184.415,411.085-411.09S704.856,67.1,478.184,67.1"
            transform="translate(106.512 106.506)"
            fill="#DEE2E6"
          />
          <path
            id="Trazado_41"
            data-name="Trazado 41"
            d="M495.291,943.417c-247.1,0-448.13-201.028-448.13-448.13s201.028-448.13,448.13-448.13,448.127,201.031,448.127,448.13-201.025,448.13-448.127,448.13m0-885.345c-241.082,0-437.219,196.134-437.219,437.216S254.209,932.506,495.291,932.506,932.51,736.372,932.51,495.288,736.373,58.072,495.291,58.072"
            transform="translate(89.405 89.399)"
            fill="#DEE2E6"
          />
          <path
            id="Trazado_42"
            data-name="Trazado 42"
            d="M512.521,986.967c-261.613,0-474.449-212.837-474.449-474.446S250.909,38.069,512.521,38.069s474.446,212.84,474.446,474.452S774.131,986.967,512.521,986.967m0-937.984c-255.6,0-463.538,207.943-463.538,463.538S256.926,976.056,512.521,976.056,976.06,768.116,976.06,512.521,768.117,48.983,512.521,48.983"
            transform="translate(72.175 72.169)"
            fill="#DEE2E6"
          />
          <path
            id="Trazado_43"
            data-name="Trazado 43"
            d="M532.136,1036.545C254,1036.545,27.725,810.269,27.725,532.133S254,27.722,532.136,27.722,1036.545,254,1036.545,532.133s-226.276,504.411-504.408,504.411m0-997.909c-272.115,0-493.5,221.382-493.5,493.5s221.382,493.5,493.5,493.5,493.5-221.385,493.5-493.5-221.382-493.5-493.5-493.5"
            transform="translate(52.559 52.554)"
            fill="#DEE2E6"
          />
          <path
            id="Trazado_44"
            data-name="Trazado 44"
            d="M551.748,1086.112C257.1,1086.112,17.38,846.4,17.38,551.747S257.1,17.377,551.748,17.377s534.368,239.718,534.368,534.37S846.4,1086.112,551.748,1086.112m0-1057.821c-288.636,0-523.457,234.821-523.457,523.456S263.112,1075.2,551.748,1075.2,1075.2,840.38,1075.2,551.747,840.381,28.291,551.748,28.291"
            transform="translate(32.948 32.942)"
            fill="#DEE2E6"
          />
        </g>
      </svg>
    </Div>
  );
};

export default Spinner;
