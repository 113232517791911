import { useQuery } from 'react-query';
import client from '../config';

const getRoles = async () => {
  try {
    const response = await client.get('/rol/all');
    const { data } = response;
    return data;
  } catch (e) {
    return e.response;
  }
};

export default function useGetRoles() {
  return useQuery(['roles'], getRoles, { refetchOnWindowFocus: false });
}
