import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  Box,
  Button,
  CardActions,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useEffect } from 'react';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import useUpdateSede from 'app/services/sedes/updateSede';
import useGetSede from 'app/services/sedes/getSede';
import Spinner from 'app/shared/Spinner';

const EditarSede = ({ sede, onClose }) => {
  const { mutate: updateSede, isLoading } = useUpdateSede();
  const { data: sedeData, isLoading: isLoadingSede } = useGetSede(sede?._id);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      code: '',
    },
  });
  const {
    control,
    reset,
    formState: { isValid, dirtyFields },
  } = methods;
  const onSubmit = (dataSede) => {
    updateSede({ dataSede, id: sedeData?._id }, { onSuccess: onClose });
  };
  useEffect(() => {
    if (!sedeData) return;
    reset({
      name: sedeData?.name,
      code: sedeData?.code,
    });
  }, [sedeData]);

  if (isLoadingSede)
    return (
      <Box
        sx={{
          height: 300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </Box>
    );
  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Editar Sede: <strong>{sedeData?.name}</strong>`
          </Typography>
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: 'background.default',
            px: 2,
            pb: 2,
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom mb={2}>
              Nombre:
            </Typography>
            <CustomTextField
              name={'name'}
              control={control}
              label="Nombre"
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom mb={2}>
              Codigo:
            </Typography>
            <CustomTextField
              name={'code'}
              control={control}
              label="Codigo"
              isRequired
            />
          </Grid>
        </Grid>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            p: (theme) => theme.spacing(1.5, 2),
            pl: 3,
          }}
        >
          <Button onClick={onClose} color="secondary" sx={{ mr: 1 }}>
            Cancelar
          </Button>
          <Button
            startIcon={isLoading && <CircularProgress size={16} />}
            variant="contained"
            disabled={!isValid || _.isEmpty(dirtyFields) || isLoading}
            onClick={methods.handleSubmit(onSubmit)}
            sx={{ mr: 1 }}
          >
            {isLoading ? 'Guardando...' : 'Guardar'}
          </Button>
        </CardActions>
      </FormProvider>
    </Div>
  );
};

export default EditarSede;
