import { useQuery } from 'react-query';
import client from '../config';

export const getEstudiantesSinAsignatura = async (idSede) => {
  if (!idSede) return [];
  const { data } = await client.get(
    `/pre-matricula/get-sin-asignar-by-sede/${idSede}`
  );
  return data;
};

export const useGetEstudiantesSinAsignatura = (idSede) => {
  return useQuery(['getEstudiantesSinAsignatura', idSede], () =>
    getEstudiantesSinAsignatura(idSede)
  );
};
