import Div from '@jumbo/shared/Div';
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useMemo } from 'react';
import { ExportButton } from '../excel-export/ExportButton';
import { debounce } from 'lodash';
import { Stack } from '@mui/material';

const CustomToolbarStudents = (props) => {
  const apiRef = useGridApiContext();
  const [searchValue, setSearchValue] = React.useState('');

  const updateSearchValue = useMemo(() => {
    return debounce((newValue) => {
      apiRef.current.setQuickFilterValues(
        newValue.split(' ').filter((word) => word !== '')
      );
    }, 500);
  }, [apiRef]);

  const handleSearchValueChange = (event) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    updateSearchValue(newValue);
  };
  useEffect(() => {
    if (searchValue === '') {
      apiRef?.current?.setQuickFilterValues([]);
    }
  }, [searchValue, apiRef]);
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <GridToolbarContainer {...props}>
        <Div
          sx={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Div
            sx={{
              padding: (theme) => theme.spacing(0, 2),
              height: '100%',
              position: 'absolute',
              pointerEvents: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 2,
            }}
          >
            <SearchIcon />
          </Div>
          <InputBase
            autoComplete="off"
            sx={{
              color: 'inherit',
              display: 'flex',
              borderRadius: 24,
              backgroundColor: (theme) =>
                theme.jumboComponents.JumboSearch.background,

              '& .MuiInputBase-input': {
                padding: (theme) => theme.spacing(1, 1, 1, 0),
                // vertical padding + font size from searchIcon
                paddingLeft: (theme) => `calc(1em + ${theme.spacing(4)})`,
                transition: (theme) => theme.transitions.create('width'),
                width: '100%',
                height: 24,
              },
            }}
            placeholder={'Buscar'}
            inputProps={{ 'aria-label': 'Buscar', autoComplete: 'off' }}
            onChange={handleSearchValueChange}
            value={searchValue}
          />
        </Div>
      </GridToolbarContainer>
      <ExportButton />
    </Stack>
  );
};

export default CustomToolbarStudents;
