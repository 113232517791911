import React, { useEffect } from 'react';
import Div from '@jumbo/shared/Div';
import { Box, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import shadows from '@mui/material/styles/shadows';
import { alpha } from '@mui/material/styles';
import bgImg from '../../../assets/images/bg_mixer.jpg';
import { Navigate, useNavigate } from 'react-router-dom';
import { useBasicAuth } from 'app/auth-providers/BasicAuth/hooks';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const UnauthorizedPage = () => {
  const navigate = useNavigate();
  //   basic auth
  const { logout } = useBasicAuth();
  const { authUser, isAuthenticated } = useJumboAuth();
  const onLogout = () => {
    logout();
    navigate('/user/login');
  };
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
      navigate('/user/login');
    }
  }, [isAuthenticated, logout, navigate]);

  if (authUser?.active && authUser?.rolName === 'Superadministrador') {
    return <Navigate to="/dashboard" />;
  }
  if (authUser?.active) return <Navigate to="/perfil" />;
  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        p: (theme) => theme.spacing(4),
        background: `url(${bgImg}) no-repeat center`,
        backgroundSize: 'cover',

        '&::after': {
          display: 'inline-block',
          position: 'absolute',
          content: `''`,
          inset: 0,
          backgroundColor: (theme) => alpha(theme.palette.common.black, 0.75),
        },
      }}
    >
      <Div
        sx={{
          maxWidth: '100%',
          position: 'relative',
          zIndex: 1,
          width: 360,
          textAlign: 'center',
          mt: 'auto',
        }}
      >
        <Avatar
          alt={authUser?.name}
          sx={{
            width: 90,
            height: 90,
            mx: 'auto',
            mb: 2,
            boxShadow: shadows[3],
          }}
        />
        <Typography variant={'h2'} mb={0.5} color={'common.white'}>
          Bienvenido
        </Typography>
        <Typography variant={'h2'} mb={0.5} color={'common.white'}>
          {authUser?.name} - {authUser?.email}
        </Typography>
        <Typography variant={'body1'} my={3} color={'common.white'}>
          Lo sentimos, parece que tu cuenta ha sido bloqueada por el
          administrador de la plataforma. Para obtener más información sobre la
          razón del bloqueo y cómo resolver el problema, por favor contacta con
          el equipo de soporte.
        </Typography>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          sx={{ mt: 3 }}
          onClick={onLogout}
        >
          VOLVER
        </Button>
      </Div>
      <Div sx={{ mt: 'auto', position: 'relative', zIndex: 1 }}>
        <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
          <Box
            component="img"
            src={'/logo_horizontal.png'}
            sx={{ height: { xs: 50, xl: 70 } }}
          />
        </Link>
      </Div>
    </Div>
  );
};

export default UnauthorizedPage;
