import { configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers";

// Crea un objeto de historial de navegación con la librería "history"
const history = createBrowserHistory();

// Crea una instancia del middleware de enrutamiento usando el objeto de historial
const routeMiddleware = routerMiddleware(history);

// Crea la instancia del store utilizando la función "configureStore"
// y la función "rootReducer" que combina todos los reducers
const configStore = () => {
  const store = configureStore({
    reducer: rootReducer(history),
    middleware: [thunkMiddleware, routeMiddleware],
    devTools: true,
  });
  // Habilita el reemplazo dinámico de reducers si está disponible
  if (module.hot) {
    module.hot.accept("../reducers", () => {
      const newRootReducer = require("../reducers").default;
      store.replaceReducer(newRootReducer(history));
    });
  }
  return store;
};
export default configStore;
export { history };
