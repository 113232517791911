import { setRoleName } from "app/redux/slices/auth/authSlice";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import client from "../config";

export const getRolById = async (id) => {
  console.log("id", id);
  const { data } = await client.get(`/rol/byid/${id}`);
  return data;
};

export default function useGetRolById(idRol) {
  const dispatch = useDispatch();
  return useQuery([idRol, "auth-user-rol"], () => getRolById(idRol), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      dispatch(setRoleName(data?.name));
    },
    onError: (error) => {
      console.log(error.response.data);
    },
  });
}
