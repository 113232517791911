import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  Button,
  CardActions,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import CustomNumberTextField from 'app/shared/ReactHookForm/CustomNumberTextField';
import { useEffect } from 'react';
import useGetSedes from 'app/services/sedes/getSedes';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import useGetTiposDocumento from 'app/services/tiposDocumento/getTiposDocumento';
import useUpdateStudent from 'app/services/users/updateStudent';

const EditarEstudiante = ({ student, onClose }) => {
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();

  const { mutate: updateStudent, isLoading } = useUpdateStudent();
  const { data: tiposDocumento, isLoading: isLoadingTiposDocumento } =
    useGetTiposDocumento();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      sede: null,
      document: '',
      phone: '',
      email: '',
      tipe_document: null,
    },
  });
  const {
    control,
    reset,
    formState: { isValid, dirtyFields },
  } = methods;
  const onSubmit = (dataStudent) => {
    updateStudent(
      { dataStudent, idStudent: student?._id },
      { onSuccess: onClose }
    );
  };
  useEffect(() => {
    if (!student) return;
    reset({
      name: student.name,
      sede: student?.sede?._id,
      document: student.document,
      phone: student.phone,
      email: student.email,
      tipe_document: student?.tipe_document?._id,
    });
  }, [student]);
  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Editar Estudiante: <strong>{student?.name}</strong>`
          </Typography>
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: 'background.default',
            px: 2,
            pb: 2,
          }}
        >
          <Grid item xs={12} md={6}>
            <CustomAutoComplete
              isRequired
              control={control}
              name="sede"
              label="Sedes"
              options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
              isLoading={isLoadingSedes}
              getOptionLabel={(option) => option?.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomAutoComplete
              isRequired
              control={control}
              name="tipe_document"
              label="Tipo de Documento"
              options={tiposDocumento?.map((doc) => ({ id: doc._id, ...doc }))}
              isLoading={isLoadingTiposDocumento}
              getOptionLabel={(option) => option?.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'name'}
              control={control}
              label="Nombres & Apellidos"
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'document'}
              control={control}
              label="Documento"
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomNumberTextField
              name={'phone'}
              control={control}
              label="Celular"
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'email'}
              control={control}
              label="Correo"
              isRequired
              type="email"
            />
          </Grid>
        </Grid>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            p: (theme) => theme.spacing(1.5, 2),
            pl: 3,
          }}
        >
          <Button onClick={onClose} color="secondary" sx={{ mr: 1 }}>
            Cancelar
          </Button>
          <Button
            startIcon={isLoading && <CircularProgress size={16} />}
            variant="contained"
            disabled={!isValid || _.isEmpty(dirtyFields) || isLoading}
            onClick={methods.handleSubmit(onSubmit)}
            sx={{ mr: 1 }}
          >
            {isLoading ? 'Guardando...' : 'Guardar'}
          </Button>
        </CardActions>
      </FormProvider>
    </Div>
  );
};

export default EditarEstudiante;
