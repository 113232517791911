import React, { useEffect, useState } from 'react';
import Div from '@jumbo/shared/Div';
import {
  Box,
  CircularProgress,
  FormHelperText,
  Paper,
  Typography,
} from '@mui/material';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import shadows from '@mui/material/styles/shadows';
import { alpha } from '@mui/material/styles';
import bgImg from '../../../assets/images/bg_mixer.jpg';
import { Controller, useForm } from 'react-hook-form';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Navigate, useNavigate } from 'react-router-dom';
import useVerifyCodeEmail from 'app/services/auth/verifyCodeEmail';
import { useBasicAuth } from 'app/auth-providers/BasicAuth/hooks';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import useSendCodeEmail from 'app/services/auth/sendCodeEmail';
import { useSnackbar } from 'notistack';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    defaultValues: {
      code: '',
    },
  });
  const {
    mutate: verifyCode,
    isError: isErrorVerifyCode,
    isLoading: isLoadingVerify,
  } = useVerifyCodeEmail();

  useEffect(() => {
    if (!!isErrorVerifyCode) {
      setError('code', {
        type: 'manual',
        message: 'El codigo ingresado es incorrecto',
      });
    }
  }, [isErrorVerifyCode, setError]);
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useBasicAuth();
  const { authUser } = useJumboAuth();
  const { mutate: sendCodeEmail, isLoading: isSendingCode } =
    useSendCodeEmail();
  if (authUser?.active && authUser?.rolName === 'Superadministrador') {
    return <Navigate to="/dashboard" />;
  }
  if (authUser?.verify) return <Navigate to="/perfil" />;

  const onLogout = () => {
    logout();
    navigate('/user/login');
  };
  const verifyEmail = (code) => {
    // verificar si el codigo es correcto

    if (code.length === 4) {
      const emailCode = localStorage.getItem('emailCode');
      // console.log(emailCode, 'emailCode is');
      if (code !== emailCode) {
        enqueueSnackbar('El codigo no es correcto', { variant: 'error' });
        setError('code', {
          type: 'manual',
          message: 'El codigo ingresado es incorrecto',
        });

        return;
      }
      localStorage.removeItem('emailCode');
      enqueueSnackbar('Codigo correcto', { variant: 'success' });

      verifyCode({ correo: authUser?.email }, { onSuccess: onLogout });
    }
  };

  const handleSendCode = () => {
    // reenviar codigo
    sendCodeEmail(authUser?.email);
    reset({ code: '' });
  };

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        p: (theme) => theme.spacing(4),
        background: `url(${bgImg}) no-repeat center`,
        backgroundSize: 'cover',

        '&::after': {
          display: 'inline-block',
          position: 'absolute',
          content: `''`,
          inset: 0,
          backgroundColor: (theme) => alpha(theme.palette.common.black, 0.75),
        },
      }}
    >
      <Div
        sx={{
          maxWidth: '100%',
          position: 'relative',
          zIndex: 1,
          width: 360,
          textAlign: 'center',
          // mt: 'auto',
        }}
      >
        <Avatar
          alt={authUser?.name}
          sx={{
            width: 80,
            height: 80,
            mx: 'auto',
            mb: 2,
            boxShadow: shadows[3],
          }}
        />
        <Typography variant={'h2'} mb={2} color={'common.white'}>
          {authUser?.name} - {authUser?.email}
        </Typography>
        <Typography variant={'body1'} mb={3} color={'common.white'}>
          Enhorabuena! Tu registro se ha completado correctamente. Antes de que
          puedas comenzar a utilizar nuestra plataforma, necesitamos confirmar
          tu cuenta. Por favor, revisa tu correo electrónico.
        </Typography>
        <form onSubmit={handleSubmit(verifyEmail)}>
          <Controller
            name="code"
            control={control}
            rules={{
              required: true,
              minLength: {
                value: 4,
                message: 'Codigo invalido',
              },
            }}
            render={({ field }) => (
              <Paper
                sx={{
                  backgroundColor: 'secondary.main',
                  borderRadius: 1,
                  color: 'common.white',
                  px: 2,
                  pt: 3,
                  pb: 1,
                }}
              >
                <MuiOtpInput
                  // sx={{ gap: 1 }}
                  onChange={(otp) => {
                    field.onChange(otp);
                    verifyEmail(otp);
                  }}
                  value={field.value}
                  autoFocus
                  TextFieldsProps={{
                    disabled: isLoadingVerify,
                    placeholder: '-',
                    sx: {
                      borderRadius: 1,
                      color: 'common.white',
                      backgroundColor: 'common.black',
                      '& input': {
                        textAlign: 'center',
                        fontSize: 30,
                        fontWeight: 600,
                        color: 'common.white',
                      },
                    },
                    error: errors['code'],
                  }}
                  validateChar={(character, index) => true}
                  length={4}
                />
                {!!errors ? (
                  <FormHelperText sx={{ mt: 2 }} error>
                    {errors?.code?.message}
                  </FormHelperText>
                ) : null}
              </Paper>
            )}
          />
        </form>
        <Button
          disabled={isSendingCode}
          startIcon={isSendingCode ? <CircularProgress size={20} /> : null}
          onClick={handleSendCode}
          fullWidth
          size="large"
          variant="contained"
          sx={{ mt: 2 }}
        >
          {isSendingCode
            ? 'Enviando codigo...'
            : 'Reenviar codigo de verificacion'}
        </Button>

        <Button
          type="submit"
          color="secondary"
          fullWidth
          variant="contained"
          size="large"
          sx={{ mt: 2 }}
          onClick={onLogout}
        >
          VOLVER
        </Button>
      </Div>
      <Div
        sx={{
          mt: 'auto',
          position: 'relative',
          zIndex: 1,
          mt: { xs: 4, md: 2, xl: 4 },
        }}
      >
        <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
          <Box
            component="img"
            src={'/logo_horizontal.png'}
            sx={{ height: { xs: 50, xl: 70 } }}
          />
        </Link>
      </Div>
    </Div>
  );
};

export default VerifyEmail;
