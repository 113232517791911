import React, { useEffect } from 'react';
import Header from './Header';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import About from './components/About';
import { Avatar, Box, Chip, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useSelector } from 'react-redux';
import InfoCursos from './components/InfoCursos';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import SpeakerIcon from '@mui/icons-material/Speaker';
import { getCargaAcademica } from 'app/services/users/getCargaAcademica';
import { useQueryClient } from 'react-query';
import CargaAcademicaByProgramas from './components/CargaAcademicaByProgramas';

const useProfileLayout = () => {
  const { theme } = useJumboTheme();
  return React.useMemo(
    () => ({
      header: {
        sx: {
          height: { xs: 280, lg: 350 },
          mt: -4,
          mb: { xs: -10, md: -13 },
          mx: { xs: -4, lg: -6 },
          p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
          background: `url(/backgrounds/contadores-nosotros-dna.jpg) no-repeat center`,
          backgroundSize: 'cover',
          color: 'common.white',
          position: 'relative',
        },
      },
      sidebar: {
        sx: {
          mr: 3.75,
          width: { xs: '100%', lg: '33%' },
          [theme.breakpoints.down('lg')]: {
            minHeight: 0,
            mr: 0,
            order: 2,
          },
        },
      },
      wrapper: {
        sx: {
          [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
          },
        },
      },
      main: {
        sx: {
          [theme.breakpoints.down('lg')]: {
            minHeight: 0,
          },
        },
      },
    }),
    [theme]
  );
};
const Perfil = () => {
  const profileLayout = useProfileLayout();
  const [selectedTab, setSelectedTab] = useState(0);
  const { authUser } = useJumboAuth();
  const queryClient = useQueryClient();
  const { roleName, id } = useSelector(({ auth }) => auth);
  function handleTabChange(event, value) {
    setSelectedTab(value);
  }

  useEffect(() => {
    if (roleName !== 'Estudiante') return;
    queryClient.prefetchQuery(['cargaAcademica', id], () =>
      getCargaAcademica()
    );
  }, [authUser, queryClient]);
  return (
    <JumboContentLayout
      header={<Header />}
      // sidebar={<UserProfileSidebar />}
      layoutOptions={profileLayout}
    >
      <>
        <Box className="flex flex-col border-b border-gray-300 rounded-16 bg-white shadow-2xl  mb-24 ">
          <div className="flex flex-col flex-0 lg:flex-row items-center max-w-5xl w-full mx-auto px-32 lg:h-72">
            <div className="-mt-36 lg:-mt-64 rounded-full">
              <Avatar
                sx={{
                  background:
                  'linear-gradient(90deg, rgba(131,29,180,1) 0%, rgba(95,66,173,1) 35%, rgba(206,103,255,1) 100%)',
                  color: 'common.white',
                  borderColor: 'background.paper',
                  width: { xs: 120, md: 140 },
                  height: { xs: 120, md: 140 },
                }}
                className="w-320 border-4"
                alt="User avatar"
              >
                <SpeakerIcon sx={{fontSize: 80}}/>
              </Avatar>
            </div>

            <div className="flex flex-col items-center lg:items-start  lg:mt-0 lg:ml-32 ">
              <Typography className="text-lg font-bold leading-none ">
                {authUser?.name}
              </Typography>
              <Typography color="text.secondary">{roleName}</Typography>
            </div>

            <div className="hidden lg:flex h-40 -mb-14 mx-16 border-l-2" />

            <div className="flex items-center  lg:mt-0 pt-5 space-x-24 gap-5">
              <Chip
                className="w-50 h-40 p-0 rounded-full"
                label={authUser?.q10State ? 'Activo' : 'Inactivo'}
                sx={{
                  backgroundColor: authUser?.q10State ? '#4caf50' : '#f44336',
                  color: 'white',
                }}
              />
            </div>

            {roleName === 'Estudiante' && (
              <div className="flex flex-1 justify-end my-16 lg:my-0">
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons={false}
                  className="-mx-4 min-h-40"
                  classes={{
                    indicator:
                      'flex justify-center bg-transparent w-full h-full',
                  }}
                  TabIndicatorProps={{
                    children: (
                      <Box
                        sx={{ bgcolor: 'text.disabled' }}
                        className="w-full h-full rounded-full opacity-20"
                      />
                    ),
                  }}
                >
                  <Tab
                    className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12 "
                    disableRipple
                    label="Información personal"
                  />
                  <Tab
                    className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12 "
                    disableRipple
                    label="Mis programas"
                  />
                </Tabs>
              </div>
            )}
          </div>
          {selectedTab === 0 && <About />}
          {selectedTab === 1 && <CargaAcademicaByProgramas />}
        </Box>
      </>
    </JumboContentLayout>
  );
};

export default Perfil;
