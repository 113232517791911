import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

const CustomNumberTextField = ({
  control,
  name,
  onChangeCallback = () => {},
  label,
  size,
  variant = 'outlined',
  errors = {},
  isRequired = false,
  disabled = false,
  pattern = {
    value: /^[0-9]*$/, // Acepta solo números
    message: 'Ingrese solo números',
  },
  maxLength,
  minLength,
  defaultValue = 0,
  width,
  shouldUnregister = false,
  validate = {},
}) => {
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{
        required: isRequired ? '*Obligatorio  ' : false,
        pattern: pattern,
        minLength: {
          value: minLength,
          message: `Minimo ${minLength} caracteres`,
        },
        maxLength: {
          value: maxLength,
          message: `Maximo ${maxLength} caracteres`,
        },
        validate: validate,
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          disabled={disabled}
          value={field.value}
          size={size}
          variant={variant}
          label={label}
          sx={{ width: width }}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (!isNaN(value)) {
              field.onChange(value);
              onChangeCallback(value);
            } else {
              field.onChange(0);
              onChangeCallback(0);
            }
          }}
          InputProps={{
            // startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputProps: {
              style: {
                textAlign: 'right',
              },
            },
          }}
          helperText={error?.message}
          error={!!error}
          fullWidth
        />
      )}
    />
  );
};

export default CustomNumberTextField;
