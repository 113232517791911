import { DataGrid, esES } from '@mui/x-data-grid';
import { Box, Paper } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CustomNoRowsOverlay from '../CustomNoRowsOverlay/CustomNoRowsOverlay';
import CustomDataGridToolbar from '../CustomDataGridToolbar/CustomDataGridToolbar';
import { alpha } from '@mui/material';

import './style.css';
const CustomDataGrid = ({
  columns = [],
  data = [],
  br,
  id = 'id',
  toolbar,
  pageSize = 50,
  pageOptions = [30, 50, 100], 
  sortModel = [{ field: 'fecha_creacion', sort: 'desc' }],
  height = { xs: 630, sm: 1000, md: 475, xl: 775 },
  onRowClick,
  onCellDoubleClick,
  isLoading = false,
  density = 'standard',
}) => {
  return (
    <Paper
      sx={{
        backgroundColor: 'background.paper',
        boxShadow: br
          ? `0 0.5rem 1.25rem ${alpha('#660708ff', 0.175)}`
          : 'none',
        borderRadius: br ? br : 5,
      }}
    >
      <Box component="div" sx={{ height: height, width: '100%' }}>
        <DataGrid
          className="MyDataGrid"
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            border: 0,
            px: 2,
          }}
          density={density}
          rows={data}
          columns={columns}
          initialState={{
            ...data?.initialState,
            sorting: {
              sortModel: sortModel,
            },
            pagination: {
              paginationModel: {
                pageSize: pageSize,
              },
            },
          }}
          getRowId={(row) => row[id]}
          pageSizeOptions={pageOptions ? pageOptions : []}
          components={{
            Toolbar: () =>
              toolbar ? toolbar : CustomDataGridToolbar('Buscar'),
            NoRowsOverlay: CustomNoRowsOverlay,
            NoResultsOverlay: CustomNoRowsOverlay,
          }}
          loading={isLoading}
          onRowClick={onRowClick}
          onCellDoubleClick={onCellDoubleClick}
          // disableColumnFilter
          // disableColumnMenu
          // disableColumnSelector
          // disableDensitySelector
          pagination
          disableRowSelectionOnClick
        />
      </Box>
    </Paper>
  );
};

export default CustomDataGrid;
