import Div from '@jumbo/shared/Div';
import AulaActions from '../components/AulaActions';

export const useAulaColumns = () => {
  const columns = [
    {
      field: 'name',
      headerName: 'Aula',
      minWidth: 400,
    },
    {
      field: 'sede',
      headerName: 'Sede',
      minWidth: 250,
      valueGetter: (params) => params.row.sede.name,
    },
    {
      field: 'aforo_clase',
      headerName: 'Aforo Clase',
      minWidth: 100,
    },

    {
      field: 'estado',
      headerName: 'Estado',
      minWidth: 200,
      valueGetter: ({ row }) => {
        if (!row.estado) return 'Inhabilitada';
        if (row.consecutivo === -1) return 'Agregado';
        return 'Existente';
      },

      renderCell: ({ row }) => {
        return (
          <Div
            className="rounded text-center  p-3 "
            sx={{
              minWidth: 120,
              bgcolor: (theme) =>
                !row.estado
                  ? theme.palette.error.main
                  : row.consecutivo === -1
                  ? theme.palette.info.main
                  : theme.palette.success.main,
              color: (theme) =>
                !row.estado
                  ? theme.palette.error.contrastText
                  : row.consecutivo === -1
                  ? theme.palette.info.contrastText
                  : theme.palette.success.contrastText,
            }}
          >
            <span>
              {!row.estado
                ? 'Inhabilitada'
                : row.consecutivo === -1
                ? 'Agregado'
                : 'Existente'}
            </span>
          </Div>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      minWidth: 100,
      renderCell: (params) => <AulaActions params={params} />,
    },
  ];

  return columns;
};
