import React, { useState } from 'react';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import JornadasList from './components/JornadasList';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import useCreateJornada from 'app/services/jornada/createJornada';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import useGetSedes from 'app/services/sedes/getSedes';
import CustomAutocompleteMultipleForm from 'app/shared/ReactHookForm/CustomAutocompleteMultipleForm';
import useGetHorariosPrerequisitoBySede, {
  useGetHorariosPrerequisitoBySedeMutation,
} from 'app/services/horario-prerequisitos/getHorariosPrerequisitoBySede';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
const jornadaModel = {
  jornada: '',
  sede: '',
  horario: [],
};
const JornadasPage = () => {
  const navigate = useNavigate();
  const { mutate: create, isLoading: isCreating } = useCreateJornada();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const [selectedSede, setSelectedSede] = useState(null);
  const [selectedSedeForList, setSelectedSedeForList] = useState(null);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: jornadaModel,
  });

  const { control, handleSubmit, reset, watch, resetField } = methods;
  const { data: horarios, isLoading: isLoadingHorarios } =
    useGetHorariosPrerequisitoBySede(selectedSede?.id);
  const { mutate: getHorarios, isLoading: isFetchingHorarios } =
    useGetHorariosPrerequisitoBySedeMutation();

  const onSubmit = (data) => {
    const { horario, ...rest } = data;
    create(
      { horario: horario?.map((h) => ({ idHorario: h })), ...rest },
      { onSuccess: () => reset(jornadaModel) }
    );
  };
  const onChangeSede = (sede) => {
    resetField('horario');
    getHorarios(sede?.id);
    setSelectedSede(sede);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                <IconButton
                  onClick={() => navigate('/panel-control', { replace: true })}
                >
                  <ArrowBack />
                </IconButton>
                CREAR JORNADA
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom mb={2}>
                1. Tipo Jornada (Mañana, Tarde, Noche...)
              </Typography>
              <CustomTextField
                name={'jornada'}
                control={control}
                label="Nombre de la jornada"
                isRequired
                placeholder="Ej: Mañana"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom mb={2}>
                2. Selecione la sede:
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="sede"
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
                onChangeCallback={(data) => onChangeSede(data)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                HORARIO
              </Typography>

              <CustomAutocompleteMultipleForm
                name="horario"
                label={'Horarios'}
                control={control}
                isRequired
                limitTags={10}
                options={
                  horarios?.map((horario) => ({
                    id: horario?._id,
                    ...horario,
                  })) || []
                }
                getOptionLabel={(option) => option?.name}
                isLoading={isLoadingHorarios || isFetchingHorarios}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                startIcon={isCreating ? <CircularProgress size={16} /> : null}
                disabled={isCreating}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                size="large"
              >
                {isCreating ? 'Creando...' : 'Crear'}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'}>
                LISTA DE JORNADAS
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" mb={2} gutterBottom>
                Selecciona una Sede:
              </Typography>
              <Autocomplete
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                value={selectedSedeForList}
                onChange={(event, newValue) => {
                  setSelectedSedeForList(newValue);
                }}
                getOptionLabel={(option) => option?.name}
                placeholder="Por favor seleccione una sede"
                renderInput={(params) => (
                  <TextField
                    label="Sedes"
                    placeholder="Por favor seleccione una sede"
                    {...params}
                  />
                )}
              />
            </Grid>
            <JornadasList selectedSede={selectedSedeForList} />
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default JornadasPage;
