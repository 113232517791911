import client from '../config';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

export const createMalla = async (idSede) => {
  const { data } = await client.post(`/malla/createMalla/${idSede}`, []);
  return data;
};

export default function useCreateMalla() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(createMalla, {
    onSuccess: (data) => {
      enqueueSnackbar('Aula creada correctamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      enqueueSnackbar('Error al crear el aula', {
        variant: 'error',
      });
    },
  });
}
