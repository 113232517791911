import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import { Button, CardActions, CircularProgress, Grid } from '@mui/material';
import Div from '@jumbo/shared/Div';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import CustomNumberTextField from 'app/shared/ReactHookForm/CustomNumberTextField';
import { useEffect } from 'react';
import { CustomCheckbox } from 'app/shared/ReactHookForm/CustomCheckbox';
import useUpdateAula from 'app/services/aula/updateAula';

const EditarAula = ({ aula, onClose }) => {
  const { mutate: updateAula, isLoading } = useUpdateAula();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      aforo: 0,
      estado: false,
    },
  });
  const {
    control,
    reset,
    formState: { isValid, dirtyFields },
  } = methods;
  const onSubmit = (dataForm) => {
    updateAula(
      {
        idAula: aula._id,
        aula: dataForm,
      },
      {
        onSuccess: () => {
          onClose();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
      }
    );
  };
  useEffect(() => {
    if (!aula) return;
    reset({
      name: aula.name,
      aforo: aula.aforo,
      estado: aula.estado,
    });
  }, [aula]);
  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={`Editar Aula ${aula?.name}`}
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: 'background.default',
            px: 2,
            pb: 2,
          }}
        >
          <Grid item xs={12}>
            <CustomTextField
              label="Nombre"
              name="name"
              control={control}
              isRequired
            />
          </Grid>
          <Grid item xs={12}>
            <CustomNumberTextField
              name={'aforo'}
              control={control}
              label="Aforo"
              isRequired
            />
          </Grid>
          <Grid item xs={12}>
            <CustomCheckbox control={control} name="estado" label="Estado" />
          </Grid>
        </Grid>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            p: (theme) => theme.spacing(1.5, 2),
            pl: 3,
          }}
        >
          <Button onClick={onClose} color="secondary" sx={{ mr: 1 }}>
            Cancelar
          </Button>
          <Button
            startIcon={isLoading && <CircularProgress size={16} />}
            variant="contained"
            disabled={!isValid || _.isEmpty(dirtyFields) || isLoading}
            onClick={methods.handleSubmit(onSubmit)}
            sx={{ mr: 1 }}
          >
            {isLoading ? 'Guardando...' : 'Guardar'}
          </Button>
        </CardActions>
      </FormProvider>
    </Div>
  );
};

export default EditarAula;
