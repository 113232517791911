import Div from '@jumbo/shared/Div';
import DocenteActions from '../components/DocenteActions';
import HorariosDocente from '../components/HorariosDocente';
import ListMateriasDocente from '../components/ListMateriasDocente';

export const useDocenteColumns = () => {
  const columns = [
    {
      field: 'nombre',
      headerName: 'Nombre',
      minWidth: 200,
    },
    {
      field: 'telefono',
      headerName: 'Telefono',
      minWidth: 100,
    },
    {
      field: 'sede',
      headerName: 'Sede',
      minWidth: 100,
      valueGetter: (params) => params.row?.sede?.name,
    },
    {
      field: 'tipoDocumento',
      headerName: 'Tipo Doc',
      minWidth: 100,
    },
    {
      field: 'documento',
      headerName: 'Documento',
      minWidth: 130,
    },
    {
      field: 'correo',
      headerName: 'Correo',
      minWidth: 100,
    },
    {
      field: 'modalidad',
      headerName: 'Modalidad',
      minWidth: 100,
    },
    {
      field: 'materias',
      headerName: 'Materias',
      minWidth: 50,
      type: 'actions',
      renderCell: (params) => <ListMateriasDocente docente={params.row} />,
    },
    {
      field: 'horario',
      headerName: 'Horario',
      minWidth: 50,
      renderCell: (params) => <HorariosDocente docente={params.row} />,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      minWidth: 50,
      renderCell: ({ row }) => {
        return (
          <Div
            className="rounded text-center  p-3 "
            sx={{
              // minWidth: 120,
              bgcolor: (theme) =>
                !row.estado
                  ? theme.palette.error.main
                  : theme.palette.success.main,
              color: (theme) =>
                !row.estado
                  ? theme.palette.error.contrastText
                  : theme.palette.success.contrastText,
            }}
          >
            <span>
              {!row.estado
                ? 'Inactivo'
                : 'Activo'}
            </span>
          </Div>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      minWidth: 100,
      renderCell: (params) => <DocenteActions params={params} />,
    },
  ].filter(Boolean);

  return columns;
};
