import Div from '@jumbo/shared/Div';
import { Grid } from '@mui/material';
import useGetStudentsBySede from 'app/services/users/getStudentsBySede';
import CustomDataGrid from 'app/shared/CustomDataGrid/CustomDataGrid';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';
import Spinner from 'app/shared/Spinner';
import React from 'react';
import { useStudentsColumns } from '../hooks/useStudentsColumns';

const ListarEstudiantes = ({ idSede }) => {
  const columns = useStudentsColumns();

  const { data: students, isLoading: isLoadingStudents } =
    useGetStudentsBySede(idSede);
  return (
    <Grid item xs={12}>
      {isLoadingStudents ? (
        <Div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: { xs: 300, xl: 600 },
          }}
        >
          <Spinner />
        </Div>
      ) : students?.length > 0 ? (
        <CustomDataGrid
          columns={columns}
          data={students}
          br={0}
          pageSize={100}
          id="_id"
          height={{ xs: 630, sm: 1000, md: 600, xl: 775 }}
          pageOptions={[50, 100, 200]}
          sortModel={[{ field: 'name', sort: 'asc' }]}
          isLoading={isLoadingStudents}
        />
      ) : (
        <NoDataFound2 label="No se encontraron resultados para la busqueda" />
      )}
    </Grid>
  );
};

export default ListarEstudiantes;
