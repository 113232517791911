import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import client from "../config";

const updatePassword = async (updatedUser) => {
  const { data } = await client.patch(`/user/password-change`, updatedUser);
  return data;
};

export default function useUpdatePassword() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updatePassword, {
    onSuccess: (data) => {
      enqueueSnackbar("¡Hola! Tu contraseña ha sido cambiada con éxito.", {
        variant: "success",
      });
    },
    onError: (error) => {
      const { message } = error.response.data;
      console.log("error", error.response);
      enqueueSnackbar(
        message === "CODE_TIME_EXPIRED"
          ? "El código ha expirado"
          : "Error al actualizar la contraseña",
        { variant: "error" }
      );
    },
  });
}
