import EditIcon from '@mui/icons-material/Edit';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditarMateria from './EditarMateria';
import { useSelector } from 'react-redux';
import { useDeleteMateria } from 'app/services/materias/deleteMateria';

const MateriaActions = ({ params }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const { roleName } = useSelector(({ auth }) => auth);

  const materia = params.row;
  const { mutate: deleteMateria } = useDeleteMateria(materia?.sede?._id);

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          content: <EditarMateria materia={materia} onClose={hideDialog} />,
          maxWidth: 'md',
        });
        break;
      case 'delete':
        showDialog({
          title: 'Estas seguro de eliminar esta Materia?',
          variant: 'confirm',
          onYes: () => deleteMateria(materia?._id, { onSuccess: hideDialog }),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  const menuItems = [
    {
      icon: <EditIcon />,
      title: 'Editar',
      action: 'edit',
    },
    {
      icon: <DeleteIcon />,
      title: 'Eliminar',
      action: 'delete',
    },
  ];

  return (
    <>
      <JumboDdMenu
        icon={<MoreHorizIcon />}
        menuItems={menuItems}
        onClickCallback={handleItemAction}
      />
    </>
  );
};

export default MateriaActions;
