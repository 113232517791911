import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
const NoDataFound2 = ({
  label = ' Por favor seleccione una opcion',
  height = { xs: 300, lg: 450, xl: 800 },
}) => {
  return (
    <Stack
      sx={{ textAlign: 'center', height: height }}
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Box
        component="img"
        src="/icons/SVG/composeMusicPurple.svg"
        sx={{
          height: { xs: 100, md: 180, xl: 300 },
        }}
      />

      <Typography variant={'h4'} gutterBottom>
        {label}
      </Typography>
    </Stack>
  );
};

export default NoDataFound2;
