import client from '../config';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

export const verifyQ10UserState = async () => {
  const { data } = await client.post(`/user/verify-stateQ10`);
  return data;
};

export default function useVerifyQ10UserState() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(verifyQ10UserState, {
    onSuccess: (data) => {},
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
    },
  });
}
