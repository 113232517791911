import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deleteMateria = async (id) => {
  const { data } = await client.delete(`/materias/delete/${id}`);
  return { data };
};

export const useDeleteMateria = (idSede) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(deleteMateria, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([idSede, 'materiasBySede']);
      queryClient.invalidateQueries('materias');
      enqueueSnackbar('La materia ha sido eliminada con éxito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al eliminar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
};
