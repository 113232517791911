import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const createJornada = async (dataForm) => {
  console.log(dataForm, 'dataForm');
  const { data } = await client.post(`/jornada/create`, dataForm);
  return data;
};

export default function useCreateJornada() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(createJornada, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('jornadas');
      queryClient.invalidateQueries([data?.sede, 'jornadasBySede']);
      console.log(data, 'data');
      enqueueSnackbar('Jornada creada correctamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      enqueueSnackbar('Error al crear', {
        variant: 'error',
      });
    },
  });
}
