import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import useGetMateriasBySede from 'app/services/materias/getMateriasBySede';
import useGetProgramasBySede from 'app/services/programas/getProgramasBySede';
import useCreateCursoAdicionalPrematricula from 'app/services/prematricula/createCursoAdicionalPrematricula';
import { useQueryClient } from 'react-query';
import useGetJornadasBySede from 'app/services/jornada/getJornadaBySede';

const AddCurso = ({
  selectedSede,
  selectedPeriodo,
  estudiantes = [],
  hideDialog,
}) => {
  const queryClient = useQueryClient();
  const [selectedJornada, setSelectedJornada] = React.useState(null);
  const [selectedAsignatura, setSelectedAsignatura] = React.useState(null);
  const { data: materiasBySede, isLoading: isLoadingMateriasBySede } =
    useGetMateriasBySede(selectedSede?._id);
  const { data: jornadas, isLoading: isLoadingJornadas } = useGetJornadasBySede(
    selectedSede?._id
  );
  const { data: programasBySede, isLoading: isLoadingProgramasBySede } =
    useGetProgramasBySede(selectedSede?._id);
  const { mutate: createCurso, isLoading } =
    useCreateCursoAdicionalPrematricula();
  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sede: '',
      jornada: '',
      programa: '',
      horario: '',
      materia: '',
      estudiantes: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'estudiantes',
    rules: {
      required: 'Por favor seleccione los estudiantes',
      maxLength: {
        value: selectedAsignatura?.cupo_maximo,
        message: `El cupo máximo es ${selectedAsignatura?.cupo_maximo}`,
      },
      minLength: {
        value: selectedAsignatura?.aforo_minimo,
        message: `El aforo mínimo es ${selectedAsignatura?.aforo_minimo}`,
      },
    },
  });
  const onSubmit = (dataForm) => {
    const { estudiantes, ...rest } = dataForm;
    createCurso(
      {
        ...rest,
        ...selectedPeriodo,
        sede: selectedSede?._id,
        sedeName: 'string',
        estudiantes: estudiantes.map((estudiante) => ({
          id: estudiante.identificacion,
          nombre: estudiante.nombre,
        })),
        cantidad_estudiantes: estudiantes.length,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            'getEstudiantesSinAsignatura',
            selectedSede?._id,
          ]);
          queryClient.invalidateQueries([
            'getPrematriculaBySedePeriodo',
            selectedSede?._id,
          ]);
          hideDialog();
        },
      }
    );
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <CustomAutoComplete
            isRequired
            control={control}
            name="materia"
            label="Asignatura"
            options={materiasBySede?.map((materia) => ({
              id: materia._id,
              ...materia,
            }))}
            getOptionLabel={(option) => option?.materia}
            isLoading={isLoadingMateriasBySede}
            onChangeCallback={(newValue) => {
              resetField('jornada');
              setSelectedAsignatura(newValue);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutoComplete
            isRequired
            control={control}
            name="jornada"
            label="Jornada"
            options={jornadas?.map((jornada) => ({
              id: jornada._id,
              ...jornada,
            }))}
            getOptionLabel={(option) => option?.jornada}
            isLoading={isLoadingJornadas}
            onChangeCallback={(newValue) => {
              resetField('horario');
              setSelectedJornada(newValue);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutoComplete
            isRequired
            control={control}
            name="horario"
            label="Horario"
            options={
              selectedJornada?.horario?.map((horario) => ({
                id: horario._id,
                ...horario,
              })) || []
            }
            getOptionLabel={(option) => option?.horarioName}
            isLoading={isLoadingJornadas}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomAutoComplete
            isRequired
            control={control}
            name="programa"
            label="Programa"
            options={programasBySede
              ?.map((programa) => ({
                id: programa._id,
                ...programa,
              }))
              ?.filter(
                (programa) =>
                  programa?.name?.toLowerCase() ===
                    'base programas presenciales' ||
                  programa?.name?.toLowerCase() === 'base programas virtuales'
              )}
            getOptionLabel={(option) => option?.name}
            isLoading={isLoadingProgramasBySede}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id={'estudiantes'}
            options={estudiantes?.map((estudiante) => ({
              identificacion: estudiante.id,
              nombre: estudiante.nombre,
            }))}
            onChange={(event, newValue) => {
              if (newValue.length > fields.length) {
                const newItem = newValue[newValue.length - 1];
                console.log('newValue', newItem);
                append(newItem);
              } else {
                const removedIndex = fields.findIndex(
                  (field) =>
                    !newValue.find(
                      (itm) => itm.identificacion === field.identificacion
                    )
                );
                remove(removedIndex);
              }
            }}
            loadingText="Cargando..."
            multiple
            value={fields}
            disableClearable
            loading={false}
            getOptionLabel={(option) => option.nombre}
            noOptionsText="No hay opciones"
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.nombre}
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) =>
              option.identificacion === value.identificacion
            }
            renderInput={(params) => (
              <TextField
                {...params}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' || event.key === 'Enter') {
                    event.stopPropagation();
                  }
                }}
                error={!!errors?.estudiantes}
                helperText={
                  fields.length < selectedAsignatura?.aforo_minimo
                    ? `El aforo minimo es de ${selectedAsignatura?.aforo_minimo}`
                    : fields.length > selectedAsignatura?.cupo_maximo
                    ? `El cupo maximo es de ${selectedAsignatura?.cupo_maximo}`
                    : ''
                }
                placeholder="Por favor seleccione los estudiantes"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {false ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              isLoading ||
              isLoadingJornadas ||
              isLoadingMateriasBySede ||
              isLoadingProgramasBySede
            }
            startIcon={isLoading ? <CircularProgress size={20} /> : null}
          >
            {isLoading ? 'Guardando...' : 'Guardar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddCurso;
