import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const updateAula = async ({ idAula, aula }) => {
  const { data } = await client.put(`/aula/update-aula/${idAula}`, aula);
  return data;
};

export default function useUpdateAula() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateAula, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['aulas', data?.sede]);
      enqueueSnackbar('Aula actualizada exitosamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      enqueueSnackbar('Error al actualizar', {
        variant: 'error',
      });
    },
  });
}
