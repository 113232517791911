import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  Box,
  Button,
  CardActions,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useEffect } from 'react';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import useUpdateJornada from 'app/services/jornada/updateJornada';
import useGetJornada from 'app/services/jornada/getJornada';
import Spinner from 'app/shared/Spinner';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import { useState } from 'react';
import useGetHorariosPrerequisitoBySede, {
  useGetHorariosPrerequisitoBySedeMutation,
} from 'app/services/horario-prerequisitos/getHorariosPrerequisitoBySede';
import useGetSedes from 'app/services/sedes/getSedes';
import CustomAutocompleteMultipleForm from 'app/shared/ReactHookForm/CustomAutocompleteMultipleForm';
import { useDeleteHorarioJornada } from 'app/services/jornada/deleteHorarioJornada';

const EditarJornada = ({ jornada, onClose }) => {
  const { mutate: updateJornada, isLoading } = useUpdateJornada();
  const { data: jornadaData, isLoading: isLoadingJornada } = useGetJornada(
    jornada?._id
  );
  const [selectedSede, setSelectedSede] = useState(null);
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const { data: horarios, isLoading: isLoadingHorarios } =
    useGetHorariosPrerequisitoBySede(selectedSede);
  const { mutate: getHorarios, isLoading: isFetchingHorarios } =
    useGetHorariosPrerequisitoBySedeMutation();
  const { mutate: deleteHorarioJornada } =
    useDeleteHorarioJornada(selectedSede);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      jornada: '',
      sede: '',
      horario: [],
    },
  });
  const {
    control,
    reset,
    resetField,
    watch,
    formState: { isValid, dirtyFields },
  } = methods;

  const watchHorarios = watch('horario', []);
  const onSubmit = (dataForm) => {
    const { horario, ...rest } = dataForm;
    updateJornada(
      {
        dataForm: { horario: horario?.map((h) => ({ idHorario: h })), ...rest },
        id: jornadaData?._id,
      },
      { onSuccess: onClose }
    );
  };
  const onRemoveHorario = (newValues) => {
    if (newValues.length > watchHorarios.length) return; // si se agrega un elemento no hago nada
    const removedElementId = (watchHorarios || []).find(
      (oldOption) => !newValues.some((newOption) => newOption.id === oldOption)
    );
    deleteHorarioJornada({
      idJornada: jornadaData?._id,
      idHorario: removedElementId,
    });
  };

  useEffect(() => {
    if (!jornadaData) return;
    reset({
      jornada: jornadaData?.jornada,
      sede: jornadaData?.sede,
      horario: jornadaData?.horario?.map((h) => h?.idHorario) || [],
    });
    setSelectedSede(jornadaData?.sede);
  }, [jornadaData]);

  const onChangeSede = (sede) => {
    getHorarios(sede?.id);
    setSelectedSede(sede?.id);
    resetField('horario');
  };
  if (isLoadingJornada)
    return (
      <Box
        sx={{
          height: 300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </Box>
    );
  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Editar Jornada: <strong>{jornadaData?.jornada}</strong>`
          </Typography>
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: 'background.default',
            px: 2,
            pb: 2,
          }}
        >
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'jornada'}
              control={control}
              label="Nombre de la jornada"
              isRequired
              placeholder="Ej: Mañana"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomAutoComplete
              isRequired
              control={control}
              name="sede"
              label="Sedes"
              options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
              isLoading={isLoadingSedes}
              getOptionLabel={(option) => option?.name}
              onChangeCallback={(data) => onChangeSede(data)}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomAutocompleteMultipleForm
              name="horario"
              label={'Horarios'}
              control={control}
              isRequired
              limitTags={10}
              options={
                horarios?.map((horario) => ({
                  id: horario?._id,
                  ...horario,
                })) || []
              }
              onChangeCallback={onRemoveHorario}
              getOptionLabel={(option) => option?.name}
              isLoading={isLoadingHorarios || isFetchingHorarios}
            />
          </Grid>
        </Grid>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            p: (theme) => theme.spacing(1.5, 2),
            pl: 3,
          }}
        >
          <Button onClick={onClose} color="secondary" sx={{ mr: 1 }}>
            Cancelar
          </Button>
          <Button
            startIcon={isLoading && <CircularProgress size={16} />}
            variant="contained"
            disabled={!isValid || _.isEmpty(dirtyFields) || isLoading}
            onClick={methods.handleSubmit(onSubmit)}
            sx={{ mr: 1 }}
          >
            {isLoading ? 'Guardando...' : 'Guardar'}
          </Button>
        </CardActions>
      </FormProvider>
    </Div>
  );
};

export default EditarJornada;
