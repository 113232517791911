import React from "react";
import Login from "app/pages/auth-pages/login/Login";
import Page from "@jumbo/shared/Page";
import RecoveryPassword from "app/pages/auth-pages/reset-password/RecoveryPassword";
import SignUp from "app/pages/auth-pages/signup/SignUp";

const authRoutes = [
  {
    path: "/user/login",
    element: (
      <Page component={Login} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
  {
    path: "/user/registrarse",
    element: <Page component={SignUp} layout={"solo-page"} />,
  },
  {
    path: "/user/recuperar-password",
    element: <Page component={RecoveryPassword} layout={"solo-page"} />,
  },
];

export default authRoutes;
