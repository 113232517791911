import React from 'react';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import ProgramasList from './components/ProgramasList';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import CustomNumberTextField from 'app/shared/ReactHookForm/CustomNumberTextField';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import useGetSedes from 'app/services/sedes/getSedes';
import CustomSelect from 'app/shared/ReactHookForm/CustomSelect';
import useCreatePrograma from 'app/services/programas/createPrograma';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';

const programaModel = {
  name: '',
  codigo: '',
  abreviatura: '',
  modalidad: '',
  sede: '',
  tipo: '',
};
const ProgramasPage = () => {
  const navigate = useNavigate();
  const { mutate: create, isLoading: isCreating } = useCreatePrograma();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const [selectedSede, setSelectedSede] = React.useState(null);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: programaModel,
  });

  const { control, handleSubmit, reset } = methods;
  const onSubmit = (data) => {
    create(data, { onSuccess: () => reset(programaModel) });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                <IconButton
                  onClick={() => navigate('/panel-control', { replace: true })}
                >
                  <ArrowBack />
                </IconButton>
                CREAR PROGRAMA
              </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                1. Nombre del Programa:
              </Typography>
              <CustomTextField
                name={'name'}
                control={control}
                label="Nombre"
                isRequired
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                2. Selecione la sede:
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="sede"
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                3. Codigo:
              </Typography>
              <CustomTextField
                name={'codigo'}
                control={control}
                label="Codigo"
                isRequired
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                4. Abreviatura:
              </Typography>
              <CustomTextField
                name={'abreviatura'}
                control={control}
                label="Abreviatura"
                isRequired
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                5. Tipo:
              </Typography>
              <CustomSelect
                name={'modalidad'}
                control={control}
                label={'Tipo'}
                isRequired
                menuItems={[
                  { id: 'NINGUNA', name: 'NINGUNA' },
                  { id: 'ESTANDAR', name: 'ESTANDAR' },
                  { id: 'INTENSIVO', name: 'INTENSIVO' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                6. Modalidad:
              </Typography>
              <CustomSelect
                name={'tipo'}
                control={control}
                label={'Modalidad'}
                isRequired
                menuItems={[
                  { id: 'virtual', name: 'VIRTUAL' },
                  { id: 'presencial', name: 'PRESENCIAL' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <Button
                startIcon={isCreating ? <CircularProgress size={16} /> : null}
                disabled={isCreating}
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mt: { md: 5 } }}
                fullWidth
                size="large"
              >
                {isCreating ? 'Creando...' : 'Crear'}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'}>
                LISTA DE PROGRAMAS
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" mb={2} gutterBottom>
                Selecciona una Sede:
              </Typography>
              <Autocomplete
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                value={selectedSede}
                onChange={(event, newValue) => {
                  setSelectedSede(newValue);
                }}
                getOptionLabel={(option) => option?.name}
                placeholder="Por favor seleccione una sede"
                renderInput={(params) => (
                  <TextField
                    label="Sedes"
                    placeholder="Por favor seleccione una sede"
                    {...params}
                  />
                )}
              />
            </Grid>
            <ProgramasList selectedSede={selectedSede} />
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default ProgramasPage;
