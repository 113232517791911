import React from 'react';
import Page from '@jumbo/shared/Page';
import GetUserRoute from './middlewares/Roles/GetUserRoute';
import AgendamientosAdmin from 'app/pages/agendamientos/AgendamientosAdmin';
import CancelarPracticas from 'app/pages/cancelacion-practicas/CancelarPracticas';
import PanelControl from 'app/pages/panel-control/PanelControl';

const adminRoutes = [
  {
    middleware: [
      {
        element: () =>
          GetUserRoute({
            fallbackPath: '/',
            rolesPermitidos: ['Superadministrador'],
          }),
      },
    ],
    routes: [
      {
        path: '/agendar-practicas/admin',
        element: <Page component={AgendamientosAdmin} />,
      },
      {
        path: '/cancelar-practicas/',
        element: <Page component={CancelarPracticas} />,
      },
      {
        path: '/panel-control/',
        element: <Page component={PanelControl} />,
      },
    ],
  },
];
export default adminRoutes;
