import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import EditarAulaVsMaterias from './EditarAulaVsMaterias';
import { IconButton, Tooltip } from '@mui/material';

const AulasVsMateriasActions = ({ aula }) => {
  const { showDialog, hideDialog } = useJumboDialog();

  const handleItemAction = () => {
    showDialog({
      content: <EditarAulaVsMaterias aula={aula} onClose={hideDialog} />,
      maxWidth: 'md',
    });
  };

  return (
    <Tooltip
      title={
        aula?._id === aula?.aulaId
          ? 'Recuerde, primero crear el aula'
          : 'Editar'
      }
    >
      <IconButton
        disabled={aula?._id === aula?.aulaId}
        size="small"
        onClick={handleItemAction}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AulasVsMateriasActions;
