import React from 'react';
import {AuthContext} from "@jumbo/components/JumboAuthProvider/JumboAuthContext";
import {useLocation, useNavigate} from "react-router-dom";
import {matchRoutePaths} from "@jumbo/utils";

const init = (restProps) => {

    return ({
        authToken: "not-set",
        authUser: null,
        isLoading: true,
        isAuthenticated: false,
        onlyAuthAccessData: {...restProps?.onlyAuthAccessData} ?? {
            routes: [],
            fallbackPath: "/"
        },
        onlyNotAuthAccessData: {...restProps?.onlyNotAuthAccessData} ?? {
            routes: [],
            fallbackPath: null
        },
    })
}

const authReducer = (state, action) => {
    switch (action.type) {
        case "set-auth-values":
            const {authToken = null, authUser = null, restValues} = action.payload;

            if(!authUser && !authToken) {
                return {
                    authToken: null,
                    authUser: null,
                    isLoading: false,
                    isAuthenticated: false,
                    onlyAuthAccessData: state.onlyAuthAccessData,
                    onlyNotAuthAccessData: state.onlyNotAuthAccessData,
                }
            }
            return {
                ...state,
                authUser,
                authToken,
                isLoading: false,
                isAuthenticated: !!authUser && !!authToken,
                ...restValues
            }

        case "set-only-auth-access-data":
            return {
                ...state,
                onlyAuthAccessData: action.payload?.onlyAuthAccessData ?? state.onlyAuthAccessData,
            }

        case "set-only-not-auth-access-data":
            return {
                ...state,
                onlyNotAuthAccessData: action.payload?.onlyNotAuthAccessData ?? state.onlyNotAuthAccessData
            }

        case "start-loading":
            return {
                ...state,
                isLoading: true,
            }

        case "stop-loading":
            return {
                ...state,
                isLoading: false,
            }

        default:
            console.error(`You passed an action.type: ${action.type} in JumboAuthProvider which doesn't exist`);
    }
}

const JumboAuthProvider = ({children, providerComponent, providerProps, ...restProps}) => {
    const [authData, setAuthData] = React.useReducer(authReducer, restProps, init);
    const location =  useLocation();
    const navigate = useNavigate();


    const AuthServiceProvider = React.useMemo(() => {
        if(providerComponent) {
            return providerComponent
        }
        return React.Fragment;
    }, [providerComponent]);

    const authServiceProviderProps = React.useMemo(() => {
        return providerProps ? providerProps : {};
    }, [providerProps]);

    React.useEffect(() => {
        if(authData.isLoading)
            return;

        if(authData.isAuthenticated) {
            const routes = [...authData.onlyNotAuthAccessData?.routes] ?? [];
            const fallbackPath = authData.onlyNotAuthAccessData?.fallbackPath ?? "";

            if(Array.isArray(routes) && routes.length) {
                if(matchRoutePaths(routes, location)) {
                    navigate(fallbackPath)
                }
            }
        }
        else {
            const routes = [...authData.onlyAuthAccessData?.routes] ?? [];
            const fallbackPath = authData.onlyAuthAccessData?.fallbackPath ?? "";

            if(Array.isArray(routes) && routes.length) {
                if(matchRoutePaths(routes, location)) {
                    navigate(fallbackPath);
                }
            }
        }

    }, [
        authData.isLoading,
        authData.isAuthenticated,
        authData.onlyAuthAccessData,
        authData.onlyNotAuthAccessData,
        location,
        navigate,
    ]);

    const setAuthValues = React.useCallback((authValues, options) => {
        const {
            authToken,
            authUser,
            isLoading,
            isAuthenticated,
            onlyAuthAccessData,
            onlyNotAuthAccessData,
            ...restValues
        } = authValues;

        if(options?.delay) {
            setTimeout(() => {
                setAuthData({
                    type: "set-auth-values",
                    payload: {
                        authToken: authToken,
                        authUser: authUser,
                        ...(typeof restValues === "object" ? restValues : {}),
                    },
                })
            }, options?.delay ?? 0);
            return;
        }
        setAuthData({
            type: "set-auth-values",
            payload: {
                authToken: authToken,
                authUser: authUser,
                ...(typeof restValues === "object" ? restValues : {}),
            },
        })
    }, []);

    const startAuthLoading = React.useCallback(() => {
        setAuthData({
            type: "start-loading"
        })
    }, []);

    const stopAuthLoading = React.useCallback(() => {
        setAuthData({
            type: "stop-loading"
        });
    }, []);


    const setOnlyAuthAccessData = React.useCallback((data) => {
        setAuthData({
            type: "set-only-auth-access-data",
            payload: data,
        })
    }, []);

    const setOnlyNotAuthAccessData = React.useCallback((data) => {
        setAuthData({
            type: "set-only-not-auth-access-data",
            payload: data
        })
    }, []);

    const contextValue = React.useMemo(() => {
        return {
            ...authData,
            setAuthValues,
            setOnlyAuthAccessData,
            setOnlyNotAuthAccessData,
            startAuthLoading,
            stopAuthLoading
        }
    }, [authData, setAuthValues, startAuthLoading, stopAuthLoading, setOnlyAuthAccessData, setOnlyNotAuthAccessData]);

    return (
        <AuthContext.Provider value={contextValue}>
            <AuthServiceProvider {...authServiceProviderProps}>
                {children}
            </AuthServiceProvider>
        </AuthContext.Provider>
    );
};

JumboAuthProvider.defaultProps = {}

export default JumboAuthProvider;