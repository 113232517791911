import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { Button } from '@mui/material';
import useCancelReserve from 'app/services/reserve/cancelReserve';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const CancelPracticaButton = ({ params }) => {
  const { watch, resetField, setValue } = useFormContext();
  const { showDialog, hideDialog } = useJumboDialog();
  const { mutate: cancelPractica } = useCancelReserve();
  const watchEstudiante = watch('estudiante');
  const handleCancel = () => {
    showDialog({
      variant: 'confirm',
      title: '¿Estás seguro de cancelar la práctica?',
      onYes: () => {
        console.log(params.row);
        cancelPractica(
          {
            idReserva: params.row.reserve,
            idUser: watchEstudiante,
          },
          {
            onSuccess: () => {
              hideDialog();
              setValue('refetch', true, { shouldValidate: true }, false, true);
            },
          }
        );
      },
      onNo: hideDialog,
    });
  };

  return (
    <div>
      <Button
        size="small"
        disabled={params.row.status === 'Cancelado'}
        variant="contained"
        color="error"
        onClick={handleCancel}
      >
        Cancelar
      </Button>
    </div>
  );
};

export default CancelPracticaButton;
