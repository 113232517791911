import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  StyledTableCell,
  StyledTableRow,
} from 'app/shared/StyledTable/StyledTable';
import { exampleCursos } from '../utils/data';
import { Tooltip } from '@mui/material';
import { PrematriculaContext } from '../PrematriculaProvider';
import { arrCursos } from '../utils/cursos';
import moment from 'moment';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <StyledTableRow sx={{ '& > *': { borderBottom: 0 } }}>
        <StyledTableCell>
          <Tooltip
            title={
              row.estudiantes.length === 0
                ? 'No hay estudiantes preinscritos'
                : 'Ver estudiantes preinscritos'
            }
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row?.materia?.codigo ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="right">{row?.curso ?? '-'}</StyledTableCell>
        <StyledTableCell align="right">{row.sedeName}</StyledTableCell>
        <StyledTableCell align="right">{row.modalidad ?? '-'}</StyledTableCell>
        <StyledTableCell align="right">{row.pensum ?? '-'} </StyledTableCell>
        <StyledTableCell align="right">{row.materia?.materia}</StyledTableCell>
        <StyledTableCell align="right">
          {row.cantidad_estudiantes}
        </StyledTableCell>
        <StyledTableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          {row.Nombre ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          {row.Fecha_inicio
            ? moment(row.Fecha_inicio).format('DD/MM/YYYY')
            : '-'}
        </StyledTableCell>
        <StyledTableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          {row.Fecha_fin ? moment(row.Fecha_fin).format('DD/MM/YYYY') : '-'}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={11}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Estudiantes Preinscritos ({row.estudiantes.length} en total)
              </Typography>
              {row.estudiantes.length === 0 ? (
                <Typography
                  variant="body1"
                  gutterBottom
                  component="div"
                  align="center"
                  my={2}
                >
                  No hay estudiantes preinscritos
                </Typography>
              ) : (
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Nombre del Estudiante</StyledTableCell>
                      <StyledTableCell>Identificacion</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {row.estudiantes.map((estudiantesRow) => (
                      <StyledTableRow key={estudiantesRow.date}>
                        <StyledTableCell component="th" scope="row">
                          {estudiantesRow.nombre}
                        </StyledTableCell>
                        <StyledTableCell>{estudiantesRow.id}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function ListaCursos({ cursos = [] }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell />
            <StyledTableCell>Codigo</StyledTableCell>
            <StyledTableCell align="right">Nombre</StyledTableCell>
            <StyledTableCell align="center">Sede - Jornada</StyledTableCell>
            <StyledTableCell align="right">Programa</StyledTableCell>
            <StyledTableCell align="right">Pensum</StyledTableCell>
            <StyledTableCell align="right">Asignatura</StyledTableCell>
            <StyledTableCell align="right">Cupo Maximo</StyledTableCell>
            <StyledTableCell align="right">Periodo</StyledTableCell>
            <StyledTableCell align="right">Fecha Inicio</StyledTableCell>
            <StyledTableCell align="right">Fecha Fin</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {cursos.length === 0 ? (
            <StyledTableRow>
              <StyledTableCell colSpan={11} align="center">
                No hay cursos disponibles
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            cursos?.map((row, index) => <Row key={index} row={row} />)
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
