import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;
const client = axios.create({
  baseURL: BASE_URL,
  //   withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});
//todo: define interceptors and other configuration like baseURL, headers etc. here
client.interceptors.request.use((config) => {
  const token = localStorage.getItem('auth-token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});
export default client;
