import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import CardCurso from './CardCurso';
import Div from '@jumbo/shared/Div';
import useGetCargaAcademica from 'app/services/users/getCargaAcademica';
import { Button, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import Spinner from 'app/shared/Spinner';
import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import { ArrowBack } from '@mui/icons-material';

const filtros = [
  {
    id: 1,
    nombre: 'Todos los cursos',
  },
  {
    id: 2,
    nombre: 'Cursos Finalizados',
  },
  {
    id: 3,
    nombre: 'En Curso',
  },
  {
    id: 4,
    nombre: 'Cancelado por Inasistencia',
  },
  {
    id: 5,
    nombre: 'Homologación',
  },
];
const InfoCursos = ({ setData, data }) => {
  const [cursos, setCursos] = useState(data);
  const [selectedFilter, setSelectedFilter] = useState({
    id: 1,
    menuEl: null,
  });
  function handleChangeProject(id) {
    const filterConditions = {
      1: () => true,
      2: (item) => item.estado === 'Finalizada',
      3: (item) => item.estado === 'En Curso',
      4: (item) => item.estado === 'Cancelado por Inasistencia',
      5: (item) => item.estado === 'Homologación',
    };

    const selectedFilterFunction = filterConditions[id] || (() => false);

    const filteredCourses = data.filter(selectedFilterFunction);

    setCursos(filteredCourses);
    setSelectedFilter({
      id,
      menuEl: null,
    });
  }

  function handleOpenProjectMenu(event) {
    setSelectedFilter({
      id: selectedFilter.id,
      menuEl: event.currentTarget,
    });
  }
  function handleCloseProjectMenu() {
    setSelectedFilter({
      id: selectedFilter.id,
      menuEl: null,
    });
  }

  return (
    <Div
      sx={{
        minHeight: 300,
        mb: 4,
      }}
    >
      <Typography
        variant="h3"
        fontWeight={'bold'}
        mb={2}
        className="text-4xl font-bold mb-4 text-gray-800 border-b-2 border-gray-200 pb-2"
      >
        <IconButton onClick={() => setData([])}>
          <ArrowBack />
        </IconButton>
        Mis Cursos
      </Typography>

      <>
        <Button
          onClick={handleOpenProjectMenu}
          className="flex items-center border border-solid border-b-0 rounded-t-xl rounded-b-0 h-40 px-16 text-13 sm:text-16"
          variant="default"
          sx={{
            minWidth: { xs: '100%', md: 300 },
            backgroundColor: (theme) => theme.palette.background.default,
            borderColor: (theme) => theme.palette.divider,
            mb: 2,
          }}
          endIcon={<ExpandMoreIcon />}
        >
          {_.find(filtros, { id: selectedFilter.id })?.nombre}
        </Button>
        <Menu
          id="project-menu"
          anchorEl={selectedFilter.menuEl}
          open={Boolean(selectedFilter.menuEl)}
          onClose={handleCloseProjectMenu}
        >
          {filtros &&
            filtros.map((project) => (
              <MenuItem
                key={project.id}
                onClick={(ev) => {
                  handleChangeProject(project.id);
                }}
              >
                {project.nombre}
              </MenuItem>
            ))}
        </Menu>
        <JumboScrollbar
          autoHeight
          autoHeightMin={500}
          autoHide
          autoHideDuration={400}
          autoHideTimeout={500}
        >
          <Grid container spacing={3}>
            {cursos?.length === 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  variant="h3"
                  mb={2}
                  className="text-4xl text-center "
                >
                  No hay resultados
                </Typography>
              </Grid>
            )}
            {cursos?.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <CardCurso curso={item} />
              </Grid>
            ))}
          </Grid>
        </JumboScrollbar>
      </>
    </Div>
  );
};

export default InfoCursos;
