import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import Spinner from 'app/shared/Spinner/Spinner';

const IsVerified = ({ fallbackPath }) => {
  const { authUser, isLoading } = useJumboAuth();

  if (isLoading) {
    return <Spinner pt={30} />;
  }

  if (!authUser?.verify) {
    return <Navigate to={fallbackPath} />;
  }
  return <Outlet />;
};

export default IsVerified;
