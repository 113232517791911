import React, { Suspense } from 'react';
import { IconButton } from '@mui/material';
import menus from './menus';
import JumboVerticalNavbar from '@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar';
import { DrawerHeader } from '@jumbo/components/JumboLayout/style';
import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import useJumboSidebarTheme from '@jumbo/hooks/useJumboSidebarTheme';
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from '@jumbo/utils/constants/layout';
import Logo from '../../../../shared/Logo';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Zoom from '@mui/material/Zoom';
import Div from '@jumbo/shared/Div';
import SidebarSkeleton from './SidebarSkeleton';
import { useJumboLayoutSidebar } from '@jumbo/components/JumboLayout/hooks';
import { useSelector } from 'react-redux';
import AdminMenu from './AdminMenu';
import estudianteMenu from './estudianteMenu';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

const Sidebar = () => {
  const { roleName, permisos } = useSelector(({ auth }) => auth);
  const [menuItems, setMenuItems] = React.useState(menus);

  const userPermisos = permisos.reduce(
    (obj, item) => ({
      ...obj,
      [item.permiso.type]: { id: item._id, estado: item.estado },
    }),
    {}
  );
  const isIntegrationsActive =
    userPermisos['Agregar Materias']?.estado ||
    userPermisos['Agregar Alulas']?.estado;
  const isGestionUsuariosActive =
    userPermisos['Perfiles Administrador, Docente y Monitor']?.estado ||
    userPermisos['Perfiles Estudiantes']?.estado;

  const userWithPermissionsMenu = [
    {
      label: 'Mi Cuenta',
      type: 'section',
      children: [
        {
          uri: '/perfil',
          label: 'Perfil',
          type: 'nav-item',
          icon: <AccountBoxOutlinedIcon sx={{ fontSize: 20 }} />,
        },
      ],
    },
    {
      label: 'Gestión de Prácticas',
      type: 'section',
      children: [
        userPermisos['Lector QR']?.estado && {
          uri: '/lectura-qr',
          label: 'Lector de QR',
          type: 'nav-item',
          icon: <QrCodeScannerIcon sx={{ fontSize: 20 }} />,
        },
        userPermisos['Modificar Malla']?.estado && {
          uri: '/grilla-horarios',
          label: 'Grilla DNA',
          type: 'nav-item',
          icon: <CalendarViewMonthIcon sx={{ fontSize: 20 }} />,
        },
        isIntegrationsActive && {
          uri: '/integraciones',
          label: 'Integraciones',
          type: 'nav-item',
          icon: <IntegrationInstructionsIcon sx={{ fontSize: 20 }} />,
        },
        userPermisos['Historial']?.estado && {
          uri: '/historial-agendamientos',
          label: 'Historial',
          type: 'nav-item',
          icon: <HistoryEduIcon sx={{ fontSize: 20 }} />,
        },
      ],
    },
    isGestionUsuariosActive && {
      label: 'Gestión de Usuarios',
      type: 'section',
      children: [
        userPermisos['Perfiles Administrador, Docente y Monitor']?.estado && {
          uri: '/usuarios',
          label: 'Usuarios',
          type: 'nav-item',
          icon: <GroupIcon sx={{ fontSize: 20 }} />,
        },
        userPermisos['Perfiles Estudiantes']?.estado && {
          uri: '/estudiantes',
          label: 'Estudiantes',
          type: 'nav-item',
          icon: <SchoolIcon sx={{ fontSize: 20 }} />,
        },
      ],
    },
  ].filter(Boolean);
  React.useEffect(() => {
    const roleMenus = {
      Superadministrador: AdminMenu,
      Monitor: userWithPermissionsMenu,
      Docente: userWithPermissionsMenu,
      Administrativo: userWithPermissionsMenu,
      Estudiante: estudianteMenu,
    };

    const updatedMenuItems = roleMenus[roleName] || menus;
    setMenuItems(updatedMenuItems);
  }, [roleName, setMenuItems]);

  return (
    <React.Fragment>
      <SidebarHeader />
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                alignContent: 'center',
                px: 3,
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          <JumboVerticalNavbar translate items={menuItems} />
        </Suspense>
      </JumboScrollbar>
    </React.Fragment>
  );
};

const SidebarHeader = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { sidebarTheme } = useJumboSidebarTheme();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <DrawerHeader
          sx={{
            pb: 0,
          }}
        >
          <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />
          {sidebarOptions?.view !== SIDEBAR_VIEWS.MINI && (
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                onClick={() => setSidebarOptions({ open: false })}
              >
                <MenuOpenIcon />
              </IconButton>
            </Zoom>
          )}
        </DrawerHeader>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
