import { useMutation, useQueryClient } from 'react-query';
import client from '../config';
import { useSnackbar } from 'notistack';

export const deleteMateriaDocente = async ({ idMateria, idDocente }) => {
  const { data } = await client.delete(
    `/profesor/delete-materia-idMateria/${idMateria}/idProfesor/${idDocente}`
  );
  return data;
};

export const useDeleteMateriaDocente = (idSede) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(deleteMateriaDocente, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('docentes');
      queryClient.invalidateQueries([idSede, 'docentesBySede']);
      enqueueSnackbar('La materia ha sido removida con éxito', {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al eliminar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
};
