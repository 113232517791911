import Div from '@jumbo/shared/Div';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import StudentsActions from '../components/StudentsActions';

export const useStudentsColumns = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const columns = [
    {
      field: 'name',
      headerName: 'Nombre',
      minWidth: isDesktop ? 450 : 263,
    },
    {
      field: 'programa',
      headerName: 'Programa',
      minWidth: isDesktop ? 400 : 300,
      valueGetter: (params) => params.row?.program_code[0]?.abreviatura,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center w-full p-3 justify-content-center">
            <Div
              className="rounded  w-full p-3 text-center"
              sx={{
                bgcolor: (theme) => theme.palette.secondary.main,
                color: (theme) => theme.palette.secondary.contrastText,
              }}
            >
              <Typography
                variant="body1"
                className="text-capitalize text-ellipsis text-12"
              >
                {params.row?.program_code[0]?.abreviatura}
              </Typography>
            </Div>
          </div>
        );
      },
    },
    {
      field: 'document',
      headerName: 'Documento',
      minWidth: isDesktop ? 150 : 100,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'email',
      minWidth: isDesktop ? 300 : 200,
    },
    {
      field: 'phone',
      headerName: 'Celular',
      minWidth: isDesktop ? 200 : 100,
    },

    {
      field: 'createAt',
      headerName: 'Creación',
      minWidth: 100,
      valueGetter: (params) => params.row?.createAt?.slice(0, 10),
    },

    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'actions',
      minWidth: 100,
      renderCell: (params) => <StudentsActions params={params} />,
    },
  ].filter(Boolean);

  return columns;
};
