import { useMutation } from 'react-query';
import client from '../config';

export const getMallaBySubject = async (subjectName) => {
  const { data } = await client.get(`/malla/malla-by-subject/${subjectName}`);
  return data;
};

export const useGetMallaBySubject = () => {
  return useMutation((subjectName) => getMallaBySubject(subjectName));
};
