import { useQuery } from 'react-query';
import client from '../config';

const getMaterias = async () => {
  try {
    const response = await client.get('/materias/all');
    const { data } = response;
    return data;
  } catch (e) {
    return e.response;
  }
};

export default function useGetMaterias() {
  return useQuery(['materias'], getMaterias);
}
