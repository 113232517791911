import React from "react";
import { SnackbarProvider } from "notistack";

const AppSnackbar = ({ children }) => {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={3000}
      maxSnack={20}
    >
      {children}
    </SnackbarProvider>
  );
};

export default AppSnackbar;
