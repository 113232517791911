import React, { useState } from 'react';
import { exampleCursos } from './utils/data';

export const PrematriculaContext = React.createContext({});

export const PrematriculaProvider = ({
  children,
  selectedSede,
  selectedPeriodo,
}) => {
  const contextValue = { selectedSede, selectedPeriodo };

  return (
    <PrematriculaContext.Provider value={contextValue}>
      {children}
    </PrematriculaContext.Provider>
  );
};
