import React from 'react';
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Grid,
  ListSubheader,
  TextField,
  Typography,
} from '@mui/material';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useEffect } from 'react';
import { useGetHorarioBySedeMutation } from 'app/services/horario/getHorarioBySede';
import Spinner from 'app/shared/Spinner';
import useGetCargaAcademica from 'app/services/users/getCargaAcademica';
import { useGetMallaBySubject } from 'app/services/malla/getMallaBySubject';
import { useGetAulasBySubject } from 'app/services/subject/getAulasBySubject';
import { useSelector } from 'react-redux';
import TablaAgendamientos from './components/TablaAgendamientos';
import { styled, lighten, darken } from '@mui/system';
export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.secondary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.secondary.light, 0.85)
      : darken(theme.palette.secondary.main, 0.8),
}));

export const GroupItems = styled('ul')({
  padding: 0,
});
const Agendamientos = () => {
  const { sede: AuthUserSede } = useSelector(({ auth }) => auth);
  const [materias, setMaterias] = useState([]);
  const [aulas, setAulas] = useState([]);
  const [malla, setMalla] = useState([]);
  const [horarios, setHorarios] = useState([]);

  const { data: dataMaterias, isLoading: isLoadingMaterias } =
    useGetCargaAcademica();
  const { mutate: getAulas, isLoading: isLoadingAulas } =
    useGetAulasBySubject();
  const { mutate: getMalla, isLoading: isLoadingMalla } =
    useGetMallaBySubject();
  const { mutate: getHorariosBySede, isLoading: isLoadingHorarios } =
    useGetHorarioBySedeMutation();
  const methods = useForm({
    defaultValues: {
      materia: null,
      aula: null,
      tipoPractica: null,
    },
  });
  const { control, watch, resetField, setValue } = methods;
  const watchMateria = watch('materia');
  const watchAula = watch('aula');
  useEffect(() => {
    resetField('estudiante');
    if (!AuthUserSede) return;

    getHorariosBySede(AuthUserSede, {
      onSuccess: (data) => {
        setHorarios(data);
      },
    });
  }, [AuthUserSede]);

  useEffect(() => {
    if (!dataMaterias) return;
    const formattedData = dataMaterias.reduce((result, program) => {
      const programCode = program.programCode;
      const nombrePrograma = program.nombrePrograma;

      const cursosFormatted = program.cursos
        .map((curso) => ({
          programCode,
          nombrePrograma,
          codigo_matricula: curso.codigo_matricula,
          periodo: curso.periodo,
          asignatura: curso.asignatura,
          estado: curso.estado,
        }))
        .filter(
          (curso) =>
            curso.estado === 'En Curso' ||
            curso.estado === 'Finalizada' ||
            curso.estado === 'Homologación'
        );

      return result.concat(cursosFormatted);
    }, []);
    console.log(formattedData, 'opciones');

    setMaterias(formattedData || []);
  }, [dataMaterias]);

  useEffect(() => {
    resetField('aula');
    setAulas([]);
    setMalla([]);
    if (!watchMateria) {
      return;
    }
    getAulas(
      {
        subjectName: watchMateria?.trim(),
      },
      {
        onSuccess: (aulas) => {
          setAulas(aulas || []);
        },
      }
    );
  }, [watchMateria]);

  useEffect(() => {
    if (!watchAula) {
      return;
    }
    getMalla(watchMateria?.trim(), {
      onSuccess: (malla) => {
        const filterMallaByAula = malla?.filter(
          (item) => item?.aula === watchAula
        );
        setMalla(filterMallaByAula);
      },
    });
  }, [watchAula]);

  return (
    <FormProvider {...methods}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} gutterBottom>
                AGENDAR PRACTICAS
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4">1. Selecione la materia</Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'materia'}
                rules={{
                  required: 'Campo requerido',
                }}
                control={control}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    id="course-autocomplete"
                    options={materias}
                    loading={isLoadingMaterias}
                    groupBy={(option) => option.nombrePrograma}
                    getOptionLabel={(option) => option.asignatura}
                    value={
                      value
                        ? materias.find((option) => {
                            return value === option.asignatura;
                          }) ?? value
                        : null
                    }
                    onChange={(_, data) => {
                      const selectedValue = data
                        ? data.asignatura
                          ? data.asignatura
                          : data
                        : null;
                      console.log(data, 'selectedValue');
                      setValue('programCode', data?.programCode ?? '');
                      onChange(selectedValue);
                    }}
                    // value={selectedCourse}
                    // onChange={(event, newValue) => {
                    //   setSelectedCourse(newValue);
                    // }}
                    // sx={{ width: 300 }}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Selecciona un curso" />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">2. Selecione el aula</Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomAutoComplete
                isRequired
                control={control}
                name="aula"
                label="Aulas"
                options={aulas?.map((aula) => ({
                  id: aula._id,
                  ...aula,
                }))}
                isLoading={isLoadingAulas}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">
                3. Selecione el tipo de practica
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomAutoComplete
                isRequired
                control={control}
                name="tipoPractica"
                label="Tipo de practica"
                options={['Practica libre', 'Practica por audición']}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </Grid>
            {horarios?.length > 0 && malla?.length > 0 && (
              <Grid item xs={12} className="overflow-x-auto scrollbar">
                {isLoadingHorarios || isLoadingMalla ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 500,
                    }}
                  >
                    <Spinner />
                  </Box>
                ) : (
                  <TablaAgendamientos
                    horariosBySede={horarios}
                    malla={malla}
                    aulas={aulas}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </FormProvider>
  );
};

export default Agendamientos;
