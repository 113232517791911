import { useMutation, useQuery, useQueryClient } from 'react-query';
import client from '../config';

export const getAulasPrematriculaBySede = async (idSede) => {
  if (!idSede) return [];
  const { data } = await client.get(`/aula-pre-matricula/all-bySede/${idSede}`);
  return data;
};

export const useGetAulasPrematriculaBySede = (idSede) => {
  return useQuery(['aulasPrematricula', idSede], () =>
    getAulasPrematriculaBySede(idSede)
  );
};

export default function useGetAulasPrematriculaBySedeMutation() {
  const queryClient = useQueryClient();
  return useMutation(getAulasPrematriculaBySede, {
    onSuccess: (data) => {
      if (data?.length > 0)
        queryClient.setQueryData(['aulasPrematricula', data[0].sede], data);
    },
    onError: (error) => {
      console.log(error.response);
    },
  });
}
