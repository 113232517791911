import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const updateMateria = async ({ id, dataMateria }) => {
  console.log(id, dataMateria, 'dataMateria');
  const { data } = await client.patch(`/materias/update/${id}`, dataMateria);
  return data;
};

export default function useUpdateMateria() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateMateria, {
    onSuccess: (data) => {
      console.log(data, 'data actualizada');
      queryClient.invalidateQueries([data?.sede, 'materiasBySede']);
      queryClient.invalidateQueries('materias');
      enqueueSnackbar(`Actualizacion exitosa`, {
        variant: 'success',
      });
    },
    onError: (error) => {
      console.log(error.response);
      enqueueSnackbar('Error al actualizar, por favor intente de nuevo', {
        variant: 'error',
      });
    },
  });
}
