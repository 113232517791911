import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

const CustomSelect = ({
  control,
  name,
  variant = 'outlined',
  label,
  errors = {},
  isRequired = false,
  menuItems = [],
  disabled = false,
  shouldUnregister = false,
  size,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister={shouldUnregister}
      rules={{
        required: isRequired ? 'Campo requerido' : false,
        shouldUnregister: shouldUnregister,
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl disabled={disabled} size={size} error={!!error} fullWidth>
          <InputLabel id={name}>{label}</InputLabel>
          <Select
            {...field}
            value={field.value || ''}
            labelId={name}
            id={name}
            variant={variant}
            label={label}
          >
            {menuItems?.map((item, index) => (
              <MenuItem key={name + index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default CustomSelect;
