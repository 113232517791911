import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import Div from '@jumbo/shared/Div';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import useGetCargaAcademica from 'app/services/users/getCargaAcademica';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';

import React from 'react';
import Spinner from 'app/shared/Spinner';
import { useState } from 'react';
import InfoCursos from './InfoCursos';

const CargaAcademicaByProgramas = () => {
  const { data, isLoading } = useGetCargaAcademica();
  const [cursos, setCursos] = useState([]);
  const [programa, setPrograma] = useState(null);
  return (
    <Div
      sx={{
        minHeight: 300,
        px: 6,
        mb: 4,
      }}
    >
      <Typography
        variant="h3"
        fontWeight={'bold'}
        mb={2}
        className="text-4xl font-bold mb-4 text-gray-800 border-b-2 border-gray-200 pb-2"
      >
        Mis Programas {cursos?.length > 0 && ` > ${programa?.nombrePrograma}`}
      </Typography>
      {isLoading ? (
        <Div
          sx={{
            height: 300,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </Div>
      ) : cursos?.length > 0 ? (
        <InfoCursos data={cursos} setData={setCursos} />
      ) : (
        <>
          <Grid container spacing={3}>
            {data?.length === 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  variant="h3"
                  mb={2}
                  className="text-4xl text-center "
                >
                  No hay resultados
                </Typography>
              </Grid>
            )}
            {data?.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    height: 200,
                    backgroundImage:
                      'linear-gradient(45deg, #3BD2A2 30%, #40eb5b 90%)',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setPrograma(item);
                    setCursos(item?.cursos);
                  }}
                >
                  <CardActions
                    disableSpacing
                    sx={{ p: 0, alignItems: 'stretch', height: '100%' }}
                  >
                    <Div
                      sx={{
                        display: 'flex',
                        width: 126,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        backgroundColor: 'common.white',
                        borderRadius: '50%',
                        outline: 'solid 10px rgba(255, 255, 255, 0.2)',
                        margin: '0 10px 0 -60px',
                      }}
                    >
                      <Div
                        sx={{
                          display: 'flex',
                          minWidth: 56,
                          height: 56,
                          mr: '6px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <GraphicEqIcon sx={{ fontSize: 36 }} />
                      </Div>
                    </Div>
                    <CardContent sx={{ p: 2.5, flex: 1, alignSelf: 'center' }}>
                      <Typography variant={'h4'} color={'common.white'}>
                        <strong>{item?.nombrePrograma}</strong>
                      </Typography>
                      <Typography variant={'h6'} color={'common.white'} mb={0}>
                        {item?.cursos?.length} Materias
                      </Typography>
                    </CardContent>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Div>
  );
};

export default CargaAcademicaByProgramas;
