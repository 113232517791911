import React from 'react';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
const menus = [
  {
    label: 'Mi Cuenta',
    type: 'section',
    children: [
      {
        uri: '/perfil',
        label: 'Perfil',
        type: 'nav-item',
        icon: <AccountBoxOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
