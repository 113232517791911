import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  Box,
  Button,
  CardActions,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useEffect } from 'react';
import Spinner from 'app/shared/Spinner';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import CustomAutocompleteMultipleForm from 'app/shared/ReactHookForm/CustomAutocompleteMultipleForm';
import useGetSedes from 'app/services/sedes/getSedes';
import { useDeletePrerequisitoMateria } from 'app/services/materias/deletePrerequisitoMateria';
import useGetMateriasBySede from 'app/services/materias/getMateriasBySede';
import { useState } from 'react';
import useGetProgramasBySede from 'app/services/programas/getProgramasBySede';
import useGetPensum from 'app/services/pensum/getPensum';
import useUpdatePensum from 'app/services/pensum/updatePensum';
import { useDeleteMateriaPensum } from 'app/services/pensum/deleteMateriaPensum';
const materiaModel = {
  sede: '',
  programa: '',
  materias: [],
};
const EditarPensum = ({ pensum, onClose }) => {
  const { mutate: updateMateria, isLoading } = useUpdatePensum();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();

  const { data: pensumData, isLoading: isLoadingPensum } = useGetPensum(
    pensum?._id
  );
  const [selectedSede, setSelectedSede] = useState(null);
  const { data: materias, isLoading: isLoadingMaterias } =
    useGetMateriasBySede(selectedSede);
  const { data: programas, isLoading: isLoadingProgramas } =
    useGetProgramasBySede(selectedSede);
  const { mutate: deleteMateriaPensum } = useDeleteMateriaPensum(selectedSede);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: materiaModel,
  });
  const {
    control,
    reset,
    watch,
    resetField,
    formState: { isValid, dirtyFields },
  } = methods;

  const watchPrerequisitos = watch('materias', []);
  const onSubmit = (dataPensum) => {
    updateMateria({ dataPensum, id: pensumData?._id }, { onSuccess: onClose });
  };

  const onRemoveMateria = (newValues) => {
    if (newValues.length > watchPrerequisitos.length) return; // si se agrega un elemento no hago nada
    const removedElementId = (watchPrerequisitos || []).find(
      (oldOption) => !newValues.some((newOption) => newOption.id === oldOption)
    );
    deleteMateriaPensum({
      idPensum: pensumData?._id,
      idMateria: removedElementId,
    });
  };
  const onChangeSede = (sede) => {
    resetField('prerequisitos');
    setSelectedSede(sede?._id);
  };
  useEffect(() => {
    if (!pensumData) return;
    setSelectedSede(pensumData?.sede);
    reset({
      sede: pensumData?.sede,
      programa: pensumData?.programaId,
      materias: pensumData?.materias?.map((p) => p?.id) || [],
    });
  }, [pensumData]);

  if (isLoadingPensum)
    return (
      <Box
        sx={{
          height: 300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </Box>
    );
  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Editar Pensum - <strong>{pensumData?.program}</strong>`
          </Typography>
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: 'background.default',
            px: 2,
            pb: 2,
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom mb={2}>
              1. Selecione la sede:
            </Typography>
            <CustomAutoComplete
              isRequired
              control={control}
              name="sede"
              label="Sedes"
              options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
              isLoading={isLoadingSedes}
              getOptionLabel={(option) => option?.name}
              onChangeCallback={onChangeSede}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom mb={2}>
              2. Selecione el programa:
            </Typography>
            <CustomAutoComplete
              isRequired
              control={control}
              name="programa"
              label="Programas"
              options={programas?.map((sede) => ({ id: sede._id, ...sede }))}
              isLoading={isLoadingProgramas}
              getOptionLabel={(option) => option?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom mb={2}>
              3. Seleccione las materias:
            </Typography>
            <CustomAutocompleteMultipleForm
              name="materias"
              label={'Materias'}
              placeholder={'Seleccione las materias'}
              control={control}
              limitTags={10}
              options={materias?.map((m) => ({ id: m?._id, ...m })) || []}
              getOptionLabel={(option) => option?.materia}
              isLoading={isLoadingMaterias}
              optionLabel="materia"
              onChangeCallback={onRemoveMateria}
            />
          </Grid>
        </Grid>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            p: (theme) => theme.spacing(1.5, 2),
            pl: 3,
          }}
        >
          <Button onClick={onClose} color="secondary" sx={{ mr: 1 }}>
            Cancelar
          </Button>
          <Button
            startIcon={isLoading && <CircularProgress size={16} />}
            variant="contained"
            disabled={!isValid || _.isEmpty(dirtyFields) || isLoading}
            onClick={methods.handleSubmit(onSubmit)}
            sx={{ mr: 1 }}
          >
            {isLoading ? 'Guardando...' : 'Guardar'}
          </Button>
        </CardActions>
      </FormProvider>
    </Div>
  );
};

export default EditarPensum;
