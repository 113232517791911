import client from '../config';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

export const verifyProgram = async (user) => {
  const { data } = await client.post(`/user/verifyProgram`, user);
  return data;
};

export default function useVerifyProgram() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(verifyProgram, {
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      enqueueSnackbar('Usuario no encontrado', {
        variant: 'error',
      });
    },
  });
}
