export const arrCursos = [
  {
    materia: 'Introducción a la Música',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 18,
    estudiantes: [
      {
        nombre: 'JUAN FERNANDO MALDONADO FONSECA',
        id: '114131164749',
        programa: ['PRODUCTOR DE AUDIO ESTÁNDAR  (MÚSICA)'],
        cantidad_materias_preinscritas: 1,
      },
      {
        nombre: 'JUAN DIEGO GOMEZ SANCHEZ',
        id: '111377184621',
        programa: ['TECNICO LABORAL DJ PRODUCTOR ESTANDAR'],
        cantidad_materias_preinscritas: 8,
      },
      {
        nombre: 'DYLAN ALEJANDRO MAYORGA GOMEZ',
        id: '111378714193',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'JORGE HUMBERTO CASTAÑEDA MARTINEZ',
        id: '119543578587',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'JULIAN DAVID MONTOYA RUIZ',
        id: '111557810639',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'KEVIN NICOLAS CASTILLO SARMIENTO',
        id: '113672542688',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'MARIA CAMILA NUNCIRA JIMENEZ',
        id: '118465397967',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SEBASTIAN DAVID PRIETO ROMERO',
        id: '119155786172',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SERGIO ALEJANDRO PANCHE BELLO',
        id: '117169971242',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'ANDRES JULIAN ORJUELA RAMOS',
        id: '111909258068',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'JORGE EDUARDO ARZUZA DONADO',
        id: '110199520506',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'JOSE SANTIAGO MARTINEZ CARREÑO',
        id: '119502742827',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'ZHARIK NICOLE ECHEVERRY MORENO',
        id: '112811619572',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'DAVID SANTIAGO ACOSTA DIAZ',
        id: '112289109836',
        programa: ['TECNICO LABORAL EN MUSICA Y COMPOSICION INTENSIVO'],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'HAROL SEBASTIAN PEREZ AGUILAR',
        id: '117653468965',
        programa: ['TECNICO INTEGRAL ESTANDAR'],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'DANIEL STEVENS FORERO DOMINGUEZ',
        id: '118982399190',
        programa: ['TECNICO INTEGRAL INTENSIVO'],
        cantidad_materias_preinscritas: 4,
      },
      {
        nombre: 'HELDER FABIAN ARIZA RAMIREZ',
        id: '119454701298',
        programa: ['TECNICO INTEGRAL INTENSIVO'],
        cantidad_materias_preinscritas: 4,
      },
      {
        nombre: 'MICHAEL JUSEPPE CALDERON CUELLAR',
        id: '113640523440',
        programa: ['TECNICO INTEGRAL INTENSIVO'],
        cantidad_materias_preinscritas: 4,
      },
    ],
  },
  {
    materia: 'Bases de Composición 2',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 14,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'JUAN DIEGO GOMEZ SANCHEZ',
        id: '111377184621',
        programa: ['TECNICO LABORAL DJ PRODUCTOR ESTANDAR'],
        cantidad_materias_preinscritas: 8,
      },
      {
        nombre: 'DYLAN ALEJANDRO MAYORGA GOMEZ',
        id: '111378714193',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'JORGE HUMBERTO CASTAÑEDA MARTINEZ',
        id: '119543578587',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'JULIAN DAVID MONTOYA RUIZ',
        id: '111557810639',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'KEVIN NICOLAS CASTILLO SARMIENTO',
        id: '113672542688',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'MARIA CAMILA NUNCIRA JIMENEZ',
        id: '118465397967',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SEBASTIAN DAVID PRIETO ROMERO',
        id: '119155786172',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SERGIO ALEJANDRO PANCHE BELLO',
        id: '117169971242',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'ANDRES JULIAN ORJUELA RAMOS',
        id: '111909258068',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'JORGE EDUARDO ARZUZA DONADO',
        id: '110199520506',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'JOSE SANTIAGO MARTINEZ CARREÑO',
        id: '119502742827',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'ZHARIK NICOLE ECHEVERRY MORENO',
        id: '112811619572',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
    ],
  },
  {
    materia: 'Armonía I',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 2,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
    ],
  },
  {
    materia: 'Audio Digital',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 2,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
    ],
  },
  {
    materia: 'Cuerpo y Sonido',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 19,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'JUAN DIEGO GOMEZ SANCHEZ',
        id: '111377184621',
        programa: ['TECNICO LABORAL DJ PRODUCTOR ESTANDAR'],
        cantidad_materias_preinscritas: 8,
      },
      {
        nombre: 'DYLAN ALEJANDRO MAYORGA GOMEZ',
        id: '111378714193',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'JORGE HUMBERTO CASTAÑEDA MARTINEZ',
        id: '119543578587',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'JULIAN DAVID MONTOYA RUIZ',
        id: '111557810639',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'KEVIN NICOLAS CASTILLO SARMIENTO',
        id: '113672542688',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'MARIA CAMILA NUNCIRA JIMENEZ',
        id: '118465397967',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SEBASTIAN DAVID PRIETO ROMERO',
        id: '119155786172',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SERGIO ALEJANDRO PANCHE BELLO',
        id: '117169971242',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'ANDRES JULIAN ORJUELA RAMOS',
        id: '111909258068',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'JORGE EDUARDO ARZUZA DONADO',
        id: '110199520506',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'JOSE SANTIAGO MARTINEZ CARREÑO',
        id: '119502742827',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'ZHARIK NICOLE ECHEVERRY MORENO',
        id: '112811619572',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'DAVID SANTIAGO ACOSTA DIAZ',
        id: '112289109836',
        programa: ['TECNICO LABORAL EN MUSICA Y COMPOSICION INTENSIVO'],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'HAROL SEBASTIAN PEREZ AGUILAR',
        id: '117653468965',
        programa: ['TECNICO INTEGRAL ESTANDAR'],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'DANIEL STEVENS FORERO DOMINGUEZ',
        id: '118982399190',
        programa: ['TECNICO INTEGRAL INTENSIVO'],
        cantidad_materias_preinscritas: 4,
      },
      {
        nombre: 'HELDER FABIAN ARIZA RAMIREZ',
        id: '119454701298',
        programa: ['TECNICO INTEGRAL INTENSIVO'],
        cantidad_materias_preinscritas: 4,
      },
      {
        nombre: 'MICHAEL JUSEPPE CALDERON CUELLAR',
        id: '113640523440',
        programa: ['TECNICO INTEGRAL INTENSIVO'],
        cantidad_materias_preinscritas: 4,
      },
    ],
  },
  {
    materia: 'Emprendimiento',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 2,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
    ],
  },
  {
    materia: 'Entrenamiento Auditivo',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 16,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'JUAN DIEGO GOMEZ SANCHEZ',
        id: '111377184621',
        programa: ['TECNICO LABORAL DJ PRODUCTOR ESTANDAR'],
        cantidad_materias_preinscritas: 8,
      },
      {
        nombre: 'DYLAN ALEJANDRO MAYORGA GOMEZ',
        id: '111378714193',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'JORGE HUMBERTO CASTAÑEDA MARTINEZ',
        id: '119543578587',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'JULIAN DAVID MONTOYA RUIZ',
        id: '111557810639',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'KEVIN NICOLAS CASTILLO SARMIENTO',
        id: '113672542688',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'MARIA CAMILA NUNCIRA JIMENEZ',
        id: '118465397967',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SEBASTIAN DAVID PRIETO ROMERO',
        id: '119155786172',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SERGIO ALEJANDRO PANCHE BELLO',
        id: '117169971242',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'ANDRES JULIAN ORJUELA RAMOS',
        id: '111909258068',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'JORGE EDUARDO ARZUZA DONADO',
        id: '110199520506',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'JOSE SANTIAGO MARTINEZ CARREÑO',
        id: '119502742827',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'ZHARIK NICOLE ECHEVERRY MORENO',
        id: '112811619572',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'DAVID SANTIAGO ACOSTA DIAZ',
        id: '112289109836',
        programa: ['TECNICO LABORAL EN MUSICA Y COMPOSICION INTENSIVO'],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'HAROL SEBASTIAN PEREZ AGUILAR',
        id: '117653468965',
        programa: ['TECNICO INTEGRAL ESTANDAR'],
        cantidad_materias_preinscritas: 6,
      },
    ],
  },
  {
    materia: 'Fundamentos de Sonido',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 6,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'JUAN DIEGO GOMEZ SANCHEZ',
        id: '111377184621',
        programa: ['TECNICO LABORAL DJ PRODUCTOR ESTANDAR'],
        cantidad_materias_preinscritas: 8,
      },
      {
        nombre: 'DYLAN ALEJANDRO MAYORGA GOMEZ',
        id: '111378714193',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'JULIAN DAVID MONTOYA RUIZ',
        id: '111557810639',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SERGIO ALEJANDRO PANCHE BELLO',
        id: '117169971242',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
    ],
  },
  {
    materia: 'Historia de la Música',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 4,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'JUAN DIEGO GOMEZ SANCHEZ',
        id: '111377184621',
        programa: ['TECNICO LABORAL DJ PRODUCTOR ESTANDAR'],
        cantidad_materias_preinscritas: 8,
      },
      {
        nombre: 'HAROL SEBASTIAN PEREZ AGUILAR',
        id: '117653468965',
        programa: ['TECNICO INTEGRAL ESTANDAR'],
        cantidad_materias_preinscritas: 6,
      },
    ],
  },
  {
    materia: 'Music Business 1',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 2,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
    ],
  },
  {
    materia: 'Music Business 2',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 2,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
    ],
  },
  {
    materia: 'Síntesis',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 2,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
    ],
  },
  {
    materia: 'Sonido en Vivo 2018',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 1,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
    ],
  },
  {
    materia: 'Controladores Avanzados',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 1,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
    ],
  },
  {
    materia: 'Unidades Digitales',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 1,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
    ],
  },
  {
    materia: 'Edición de Audio',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 9,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
      {
        nombre: 'JORGE HUMBERTO CASTAÑEDA MARTINEZ',
        id: '119543578587',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'KEVIN NICOLAS CASTILLO SARMIENTO',
        id: '113672542688',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'MARIA CAMILA NUNCIRA JIMENEZ',
        id: '118465397967',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SEBASTIAN DAVID PRIETO ROMERO',
        id: '119155786172',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'HAROL SEBASTIAN PEREZ AGUILAR',
        id: '117653468965',
        programa: ['TECNICO INTEGRAL ESTANDAR'],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'DANIEL STEVENS FORERO DOMINGUEZ',
        id: '118982399190',
        programa: ['TECNICO INTEGRAL INTENSIVO'],
        cantidad_materias_preinscritas: 4,
      },
      {
        nombre: 'HELDER FABIAN ARIZA RAMIREZ',
        id: '119454701298',
        programa: ['TECNICO INTEGRAL INTENSIVO'],
        cantidad_materias_preinscritas: 4,
      },
      {
        nombre: 'MICHAEL JUSEPPE CALDERON CUELLAR',
        id: '113640523440',
        programa: ['TECNICO INTEGRAL INTENSIVO'],
        cantidad_materias_preinscritas: 4,
      },
    ],
  },
  {
    materia: 'Equipos Profesionales',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 1,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
    ],
  },
  {
    materia: 'Mezcla Visual',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 1,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
    ],
  },
  {
    materia: 'Tornamesas',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 1,
    estudiantes: [
      {
        nombre: 'ALEJANDRO null HURTADO CAÑAS',
        id: '112967204401',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO ESTANDAR',
          'TECNICO LABORAL DJ PRODUCTOR ESTANDAR',
        ],
        cantidad_materias_preinscritas: 29,
      },
    ],
  },
  {
    materia: 'Apreciación Musical',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 13,
    estudiantes: [
      {
        nombre: 'JUAN DIEGO GOMEZ SANCHEZ',
        id: '111377184621',
        programa: ['TECNICO LABORAL DJ PRODUCTOR ESTANDAR'],
        cantidad_materias_preinscritas: 8,
      },
      {
        nombre: 'DYLAN ALEJANDRO MAYORGA GOMEZ',
        id: '111378714193',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'JORGE HUMBERTO CASTAÑEDA MARTINEZ',
        id: '119543578587',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'JULIAN DAVID MONTOYA RUIZ',
        id: '111557810639',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'KEVIN NICOLAS CASTILLO SARMIENTO',
        id: '113672542688',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'MARIA CAMILA NUNCIRA JIMENEZ',
        id: '118465397967',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SEBASTIAN DAVID PRIETO ROMERO',
        id: '119155786172',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SERGIO ALEJANDRO PANCHE BELLO',
        id: '117169971242',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'ANDRES JULIAN ORJUELA RAMOS',
        id: '111909258068',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'JORGE EDUARDO ARZUZA DONADO',
        id: '110199520506',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'JOSE SANTIAGO MARTINEZ CARREÑO',
        id: '119502742827',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'ZHARIK NICOLE ECHEVERRY MORENO',
        id: '112811619572',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'DAVID SANTIAGO ACOSTA DIAZ',
        id: '112289109836',
        programa: ['TECNICO LABORAL EN MUSICA Y COMPOSICION INTENSIVO'],
        cantidad_materias_preinscritas: 6,
      },
    ],
  },
  {
    materia: 'Fundamentos de Audio',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 17,
    estudiantes: [
      {
        nombre: 'JUAN DIEGO GOMEZ SANCHEZ',
        id: '111377184621',
        programa: ['TECNICO LABORAL DJ PRODUCTOR ESTANDAR'],
        cantidad_materias_preinscritas: 8,
      },
      {
        nombre: 'DYLAN ALEJANDRO MAYORGA GOMEZ',
        id: '111378714193',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'JORGE HUMBERTO CASTAÑEDA MARTINEZ',
        id: '119543578587',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'JULIAN DAVID MONTOYA RUIZ',
        id: '111557810639',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'KEVIN NICOLAS CASTILLO SARMIENTO',
        id: '113672542688',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'MARIA CAMILA NUNCIRA JIMENEZ',
        id: '118465397967',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SEBASTIAN DAVID PRIETO ROMERO',
        id: '119155786172',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'SERGIO ALEJANDRO PANCHE BELLO',
        id: '117169971242',
        programa: ['TECNICO LABORAL DJ PRODUCTOR INTENSIVO'],
        cantidad_materias_preinscritas: 7,
      },
      {
        nombre: 'ANDRES JULIAN ORJUELA RAMOS',
        id: '111909258068',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'JORGE EDUARDO ARZUZA DONADO',
        id: '110199520506',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'JOSE SANTIAGO MARTINEZ CARREÑO',
        id: '119502742827',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'ZHARIK NICOLE ECHEVERRY MORENO',
        id: '112811619572',
        programa: [
          'TECNICO LABORAL EN EDICION, GRABACION, MEZCLA Y MASTERIZACION DE SONIDO INTENSIVO',
        ],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'DAVID SANTIAGO ACOSTA DIAZ',
        id: '112289109836',
        programa: ['TECNICO LABORAL EN MUSICA Y COMPOSICION INTENSIVO'],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'HAROL SEBASTIAN PEREZ AGUILAR',
        id: '117653468965',
        programa: ['TECNICO INTEGRAL ESTANDAR'],
        cantidad_materias_preinscritas: 6,
      },
      {
        nombre: 'DANIEL STEVENS FORERO DOMINGUEZ',
        id: '118982399190',
        programa: ['TECNICO INTEGRAL INTENSIVO'],
        cantidad_materias_preinscritas: 4,
      },
      {
        nombre: 'HELDER FABIAN ARIZA RAMIREZ',
        id: '119454701298',
        programa: ['TECNICO INTEGRAL INTENSIVO'],
        cantidad_materias_preinscritas: 4,
      },
      {
        nombre: 'MICHAEL JUSEPPE CALDERON CUELLAR',
        id: '113640523440',
        programa: ['TECNICO INTEGRAL INTENSIVO'],
        cantidad_materias_preinscritas: 4,
      },
    ],
  },
  {
    materia: 'Piano Básico',
    jornada: 'Semana 7:00AM A 1:00PM',
    sede: '634f01538edfde16cb5e8318',
    cantidad_estudiantes: 1,
    estudiantes: [
      {
        nombre: 'DAVID SANTIAGO ACOSTA DIAZ',
        id: '112289109836',
        programa: ['TECNICO LABORAL EN MUSICA Y COMPOSICION INTENSIVO'],
        cantidad_materias_preinscritas: 6,
      },
    ],
  },
];
