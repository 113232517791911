import React from 'react';
import moment from 'moment';
import 'moment/locale/es'; // Importa el idioma español de moment
import { Box, Button, ListItemText, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useVerifyDate from 'app/services/reserve/verifyDate';
import useVerifyCupoReserva from 'app/services/reserve/verifyCupoReserve';
import Spinner from 'app/shared/Spinner';
import useCreateReservaAdmin from 'app/services/reserve/createReservaAdmin';
import NoDataFound2 from 'app/shared/NoDataFound/NoDataFound2';

const generateWeekdays = () => {
  const weekdays = [];
  let currentDate = moment();

  while (weekdays.length < 6) {
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      weekdays.push(currentDate.format('YYYY-M-D'));
    }
    currentDate = currentDate.add(1, 'day');
  }

  return weekdays;
};

const TablaAgendamientosAdmin = ({
  horariosBySede,
  malla,
  aulas,
  students,
}) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const { mutate: verifyDate, isLoading: isLoadingVerifyDate } =
    useVerifyDate();
  const { mutate: createReservaAdmin, isLoading: isCreating } =
    useCreateReservaAdmin();
  const { mutate: verifyCupoReserve, isLoading: isLoadingVerifyCupoReserve } =
    useVerifyCupoReserva();
  const { handleSubmit } = useFormContext();
  moment.locale('es'); // Establece el idioma español
  const [horarios, setHorarios] = React.useState([]);
  const weekdays = generateWeekdays();
  const Swal = useSwalWrapper();
  const sweetAlerts = ({ title, variant, text = null }) => {
    Swal.fire({
      icon: variant,
      title: title,
      text: text,
    });
  };

  const verificarCupo = (dataForm) => {
    verifyCupoReserve(
      {
        malla: dataForm.idMalla,
        date: dataForm.weekday,
        subject: dataForm.materia,
      },
      {
        onSuccess: (isValid) => {
          console.log(isValid, 'verifyCupoReserve');
          if (!isValid) {
            sweetAlerts({
              title: 'Aforo lleno',
              variant: 'error',
            });
            return;
          }
          crearReserva(dataForm);
        },
      }
    );
  };

  const crearReserva = (dataForm) => {
    createReservaAdmin(
      {
        idStudent: dataForm.estudiante,
        dataForm: {
          malla: dataForm.idMalla,
          // date: moment().format('YYYY-M-D'),
          date: dataForm.weekday,
          subject: dataForm.materia.trim(),
        },
      },
      {
        onSuccess: (data) => {
          console.log(data, 'reservaa exitosa');
          sweetAlerts({
            title: 'Reserva exitosa',
            variant: 'success',
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
        onError: (error) => {
          sweetAlerts({
            title: 'Error',
            variant: 'error',
            text: 'No se pudo realizar la reserva',
          });
        },
      }
    );
  };
  const onYes = (dataForm) => {
    hideDialog();
    //Verificar si fecha
    verifyDate(
      {
        fechaReserva: dataForm.weekday,
        dataForm: {
          malla: dataForm.idMalla,
          date: dataForm.weekday,
        },
      },
      {
        onSuccess: (isAvailable) => {
          if (!isAvailable) {
            sweetAlerts({
              title: 'Fecha no disponible',
              text: 'Ya tienes inscrito una practica este dia',
              variant: 'error',
            });
            return;
          }
          verificarCupo(dataForm);
        },
      }
    );
  };

  const onSubmit = (data) => {
    const aula = aulas.find((aula) => aula._id === data.aula);
    const student = students.find((student) => student._id === data.estudiante);
    showDialog({
      variant: 'confirm',
      title: (
        <Typography variant="h2" fontWeight={'bold'}>
          Confirmar agendamiento
        </Typography>
      ),
      content: (
        <>
          <ListItemText primary="Asignatura" secondary={data.materia} />
          <ListItemText primary="Alumno" secondary={student?.name} />
          <ListItemText
            primary="Tipo de practica"
            secondary={data.tipoPractica}
          />
          <ListItemText primary="Aula" secondary={aula?.name} />
          <ListItemText
            primary="Fecha"
            secondary={
              <Typography variant="h6" color="error">
                {moment(data.weekday).format('dddd, MMM D')}
              </Typography>
            }
          />
          <ListItemText
            primary="Hora"
            secondary={
              <Typography variant="h6" color="error">
                {data.name}
              </Typography>
            }
          />
        </>
      ),
      onYes: () => onYes(data),
      onNo: hideDialog,
    });
  };

  useEffect(() => {
    if (!horariosBySede) return;
    const newHorarios = horariosBySede?.map((horario) => {
      const horarioDisponible = malla?.find((m) => m.horario === horario._id);
      return {
        ...horario,
        disponible: horarioDisponible ? true : false,
        idMalla: horarioDisponible ? horarioDisponible?._id : null,
      };
    });
    setHorarios(newHorarios);
    // Ahora puedes hacer algo con la matriz newHorarios actualizada
  }, [malla, horariosBySede]);
  if (isLoadingVerifyDate || isLoadingVerifyCupoReserve || isCreating)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 600,
        }}
      >
        <Spinner />
      </Box>
    );

  if (malla.length === 0 || horarios.length === 0)
    return <NoDataFound2 label="No hay horarios disponibles" />;

  return (
    <table className="min-w-full bg-white">
      <thead>
        <tr>
          <th className="border px-4 py-2">Hora</th>
          {weekdays?.map((weekday, index) => (
            <th key={index} className="border px-4 py-2">
              {moment(weekday).format('dddd, MMM D')}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {horarios?.map((hour, index) => (
          <tr key={index}>
            <td className="border px-4 py-2 text-center">{hour?.name}</td>
            {weekdays?.map((weekday, index) => (
              <td key={index} className="border px-4 py-4  text-center">
                {hour?.disponible ? (
                  <Button
                    onClick={() => {
                      handleSubmit((data) =>
                        onSubmit({ ...hour, weekday, ...data })
                      )();
                    }}
                    size="small"
                    color="success"
                    sx={{
                      color: '#fff',
                      backgroundColor: '#4caf50',
                      '&:hover': {
                        backgroundColor: '#388e3c',
                        opacity: [0.9, 0.8, 0.7],
                      },
                      borderRadius: 8,
                      px: 2,
                    }}
                  >
                    Disponible
                  </Button>
                ) : (
                  <Typography variant="h6" color="error">
                    No disponible
                  </Typography>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TablaAgendamientosAdmin;
