import React from 'react';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import DocentesList from './components/DocentesList';
import CustomTextField from 'app/shared/ReactHookForm/CustomTextField';
import CustomAutoComplete from 'app/shared/ReactHookForm/CustomAutoComplete';
import useGetSedes from 'app/services/sedes/getSedes';
import CustomAutocompleteMultipleForm from 'app/shared/ReactHookForm/CustomAutocompleteMultipleForm';
import useGetMateriasBySede from 'app/services/materias/getMateriasBySede';
import { useState } from 'react';
import useGetHorarioBySede from 'app/services/horario/getHorarioBySede';
import useGetTiposDocumento from 'app/services/tiposDocumento/getTiposDocumento';
import useCreateDocente from 'app/services/docente/createDocente';
import { CustomCheckbox } from 'app/shared/ReactHookForm/CustomCheckbox';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import CustomSelect from 'app/shared/ReactHookForm/CustomSelect';
import { modalidadesDocente } from './utils/modalidadesDocente';
const docenteModel = {
  nombre: '',
  tipoDocumento: '',
  documento: '',
  telefono: '',
  correo: '',
  sede: '',
  materias: [],
  horario: [],
  modalidad: '',
  estado: true,
};
const DocentePage = () => {
  const navigate = useNavigate();
  const { mutate: create, isLoading: isCreating } = useCreateDocente();
  const { data: sedes, isLoading: isLoadingSedes } = useGetSedes();
  const { data: tiposDoc, isLoading: isLoadingTiposDocumento } =
    useGetTiposDocumento();
  const [selectedSede, setSelectedSede] = useState(null);
  const [selectedSedeForList, setSelectedSedeForList] = useState(null);
  const { data: materias, isLoading: isLoadingMaterias } = useGetMateriasBySede(
    selectedSede?._id
  );
  const { data: horarios, isLoading: isLoadingHorarios } = useGetHorarioBySede(
    selectedSede?._id
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: docenteModel,
  });

  const { control, handleSubmit, reset, resetField } = methods;
  const onSubmit = (data) => {
    create(data, { onSuccess: () => reset(docenteModel) });
  };

  const onChangeSede = (sede) => {
    resetField('materias');
    resetField('horario');
    setSelectedSede(sede);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'} mb={2} gutterBottom>
                <IconButton
                  onClick={() => navigate('/panel-control', { replace: true })}
                >
                  <ArrowBack />
                </IconButton>
                CREAR DOCENTE
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                1. Nombre:
              </Typography>
              <CustomTextField
                isRequired
                control={control}
                name="nombre"
                label="Nombre"
              />
            </Grid>

            <Grid item xs={12} md={3} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                2. Tipo de documento:
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="tipoDocumento"
                label="Tipo de documento"
                options={tiposDoc?.map((tipo) => ({ id: tipo._id, ...tipo }))}
                isLoading={isLoadingTiposDocumento}
                getOptionLabel={(option) => option?.name}
              />
            </Grid>

            <Grid item xs={12} md={3} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                2. Documento:
              </Typography>
              <CustomTextField
                isRequired
                control={control}
                name="documento"
                label="Documento"
              />
            </Grid>

            <Grid item xs={12} md={3} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                3. Telefono:
              </Typography>
              <CustomTextField
                isRequired
                control={control}
                name="telefono"
                label="Telefono"
              />
            </Grid>
            <Grid item xs={12} md={3} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                4. Correo:
              </Typography>
              <CustomTextField
                isRequired
                control={control}
                name="correo"
                label="Correo"
              />
            </Grid>

            <Grid item xs={12} md={3} xl={3}>
              <Typography variant="h6" gutterBottom mb={2}>
                5. Selecione la sede:
              </Typography>
              <CustomAutoComplete
                isRequired
                control={control}
                name="sede"
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                getOptionLabel={(option) => option?.name}
                onChangeCallback={onChangeSede}
              />
            </Grid>
            <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom mb={2}>
                6. Selecione la modalidad:
              </Typography>
              <CustomSelect
                control={control}
                name={'modalidad'}
                label="Modalidad"
                isRequired
                menuItems={modalidadesDocente}
              />
            </Grid>
            <Grid item xs={12} md={2}>
            <Typography variant="h6" gutterBottom mb={2}>
                7. Estado:
              </Typography>
              <CustomCheckbox control={control} name="estado" label="Estado" />
            </Grid>
            <Grid item xs={12} md={6} >
              <Typography variant="h6" gutterBottom mb={2}>
                8. Seleccione las materias:
              </Typography>
              <CustomAutocompleteMultipleForm
                name="materias"
                label={'Materias'}
                placeholder={'Seleccione las materias'}
                control={control}
                limitTags={10}
                isRequired
                options={materias?.map((m) => ({ id: m?._id, ...m })) || []}
                getOptionLabel={(option) => option?.materia}
                isLoading={isLoadingMaterias}
                optionLabel="materia"
              />
            </Grid>
            <Grid item xs={12} md={6} >
              <Typography variant="h6" gutterBottom mb={2}>
                9. Selecione el horario:
              </Typography>
              <CustomAutocompleteMultipleForm
                name="horario"
                label={'Horario'}
                isRequired
                placeholder={'Seleccione el horario'}
                control={control}
                limitTags={10}
                options={horarios?.map((m) => ({ id: m?._id, ...m })) || []}
                getOptionLabel={(option) => option?.name}
                isLoading={isLoadingHorarios}
                optionLabel="name"
              />
            </Grid>

            
            <Grid item xs={12} md={4}>
              <Button
                startIcon={isCreating ? <CircularProgress size={16} /> : null}
                disabled={isCreating}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                size="large"
              >
                {isCreating ? 'Creando...' : 'Crear'}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={'Bold'}>
                LISTADO DE DOCENTES
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" mb={2} gutterBottom>
                Selecciona una Sede:
              </Typography>
              <Autocomplete
                label="Sedes"
                options={sedes?.map((sede) => ({ id: sede._id, ...sede }))}
                isLoading={isLoadingSedes}
                value={selectedSedeForList}
                onChange={(event, newValue) => {
                  setSelectedSedeForList(newValue);
                }}
                getOptionLabel={(option) => option?.name}
                placeholder="Por favor seleccione una sede"
                renderInput={(params) => (
                  <TextField
                    label="Sedes"
                    placeholder="Por favor seleccione una sede"
                    {...params}
                  />
                )}
              />
            </Grid>
            <DocentesList selectedSede={selectedSedeForList} />
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default DocentePage;
