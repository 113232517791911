import { useQuery } from 'react-query';
import client from '../config';

export const getProgramasBySede = async (idSede) => {
  if (!idSede) return [];
  const { data } = await client.get(`program/all-bySede/${idSede}`);
  return data;
};

export default function useGetProgramasBySede(id) {
  return useQuery([id, 'programasBySede'], () => getProgramasBySede(id), {
    onError: (error) => {
      console.log(error.response.data);
    },
  });
}
