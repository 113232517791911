import client from '../config';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const updateAulaPrematricula = async ({ idAula, aula }) => {
  const { data } = await client.put(
    `/aula-pre-matricula/update-aula/${idAula}`,
    aula
  );
  return data;
};

export default function useUpdateAulaPrematricula() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateAulaPrematricula, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['aulasPrematricula', data?.sede]);
      enqueueSnackbar('Aula actualizada exitosamente', {
        variant: 'success',
      });
    },
    onError: (error) => {
      const errorMessages = error.response.data.message;
      console.log(error.response);
      enqueueSnackbar('Error al actualizar', {
        variant: 'error',
      });
    },
  });
}
